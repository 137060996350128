import React, {
  useState,
  useEffect,
  useContext,
  useCallback,
  useMemo,
  useRef,
} from "react";
import { useNavigate } from "react-router-dom";
import {
  Box,
  Grid,
  Button,
  Typography,
  Button as MuiButton,
  Backdrop,
  CircularProgress,
} from "@mui/material";
import { GetLocationListApi } from "../../apis/locationApis";
import MyContext from "../../components/MyContext";
import Notification from "../../components/Notification";
// import MyGridComponent from "./MyGridComponent.js";
import Dialogs from "../../components/DocSummaryDialog";
import dayjs from "dayjs";
// import React, { useState, useRef, useEffect } from "react";
import { AgGridReact } from "ag-grid-react";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-alpine.css";
// import { Box, Button } from "@mui/material";
// import { AgGridReact } from "ag-grid-react";
import convertToAgGridFormat from "../../components/utils/convertToAgGridFormat";
// import { useAppInsightsContext } from "@microsoft/applicationinsights-react-js";
import useCommonParams from "../../components/utils/useCommonParams";
// import { DocumentContext } from "../../components/utils/DocumentContextProvider";
import { logTelemetry } from "../../logTelemetry";
import { useDispatch, useSelector } from "react-redux";
import { setActiveComponent } from "../../redux/actions/index";
import {
  setIsFromLocation,
  setLocationLookup,
} from "../../redux/actions/location";
import formatDateStamp from "../../components/utils/getDateStamp";
import { GetSectionRights } from "../../components/utils/activeComponent";
import StaticModuleClass from "../../components/utils/staticModuleClass";
// import CollapsibleTable from "./CollapsibleTable";
// import { list } from "./list";

const Location = () => {
  const dispatch = useDispatch();
  const accessDetails = useSelector((state) => state.accessRights);
  const segmentId = useSelector((state) => state.segmentID);
  const moduleId = useSelector((state) => state.moduleID);
  const [formData, setFormData] = useState();
  const [filtersApplied, setFiltersApplied] = useState(false);
  const gridRef = useRef(null);
  const [rowData] = useState([
    {
      code: "82454",
      name: "Ankit, G",
      type: "Other",
      address: "Chester",
      phoneNumber: "1234567890",
      extension: "123",
      email: "ankit.gu...",
      username: "Ankit",
      locationGroup: "Primary",
      defaultLocation: "Emergency Room ...",
      role: "CLP Admin...",
      primaryLocation: "Primary Location A",
      primaryLocationId: "PL001",
    },
    {
      code: "45321",
      name: "Doe, John",
      type: "Admin",
      address: "New York",
      phoneNumber: "0987654321",
      extension: "456",
      email: "john.doe@...",
      username: "johndoe",
      locationGroup: "Secondary",
      defaultLocation: "ICU",
      role: "Staff Admin...",
      primaryLocation: "Primary Location B",
      primaryLocationId: "PL002",
    },
  ]);

  const [columnDefs] = useState([
    { headerName: "Code", field: "code", sortable: true, filter: true },
    { headerName: "Name", field: "name", sortable: true, filter: true },
    { headerName: "Type", field: "type", sortable: true, filter: true },
    { headerName: "Address", field: "address", sortable: true, filter: true },
    {
      headerName: "Phone Number",
      field: "phoneNumber",
      sortable: true,
      filter: true,
    },
    {
      headerName: "Extension",
      field: "extension",
      sortable: true,
      filter: true,
    },
    { headerName: "Email", field: "email", sortable: true, filter: true },
    { headerName: "Username", field: "username", sortable: true, filter: true },
    {
      headerName: "Location Group",
      field: "locationGroup",
      sortable: true,
      filter: true,
    },
    {
      headerName: "Default Location",
      field: "defaultLocation",
      sortable: true,
      filter: true,
    },
    { headerName: "Role", field: "role", sortable: true, filter: true },
    {
      headerName: "Primary Location",
      field: "primaryLocation",
      sortable: true,
      filter: true,
    },
    {
      headerName: "Primary Location Id",
      field: "primaryLocationId",
      sortable: true,
      filter: true,
    },
  ]);
  const [sectionAccess, setSectionAccess] = useState({ StaffList: false });

  const defaultColDef = useMemo(
    () => ({
      resizable: true,
      sortable: true,
      // editable: true,
      //   floatingFilter: true,
      // filters: true,
      menuTabs: ["filterMenuTab", "generalMenuTab"],
    }),
    []
  );
  const navigate = useNavigate();
  const { mutateAsync } = GetLocationListApi();
  // const { mutateAsync: lookUpsApi } = GetLookUpsForDSApi();
  // const { mutateAsync: GetExportElementsApi } = GetExportElements();
  // let details = JSON.parse(sessionStorage?.getItem("details"));
  // let details = useSelector((state) => JSON.parse(state.loginDetails));

  const loginDetails = useSelector((state) => state.loginDetails);

  let details = useMemo(() => {
    if (loginDetails) {
      return JSON.parse(loginDetails);
    }
    return null;
  }, [loginDetails]);

  // const { details?.companyId } = useContext(MyContext);
  const [loading, setLoading] = useState(false);
  const [lookUpData, setLookUpData] = useState({});
  const [gridApi, setGridApi] = useState(null);
  const [notify, setNotify] = useState({
    isOpen: false,
    message: "",
    type: "error",
  });
  const [rows, setRows] = useState([]);
  // const [filtersApplied, setFiltersApplied] = useState(false);
  const [subStatusData, setSubStatusData] = useState([]);
  const [open, setOpen] = useState(false);
  let [filters, setFilters] = useState({});
  const agree = () => {
    setOpen(false);
    // setFilters({});
  };
  const disagree = () => {
    setOpen(false);
  };

  const onGridReady = useCallback((params) => {
    setGridApi(params.api);
    params.api.sizeColumnsToFit();
  }, []);

  useEffect(() => {
    const accessList = {
      StaffList: GetSectionRights(accessDetails, segmentId, moduleId, StaticModuleClass.STAFF_LIST_SECID),
    };
    setSectionAccess(accessList);
  }, []);

  function btnExportXlClick() {
    if (gridApi) {
      const formattedDate = formatDateStamp();

      gridApi.exportDataAsExcel({
        fileName: `Staff List_${formattedDate}`,
      });
    } else {
      console.error("Grid API not available");
    }
  }

  function btnExportCsvClick() {
    if (gridRef.current) {
      const formattedDate = formatDateStamp();

      gridRef.current.api.exportDataAsCsv({
        fileName: `Staff List_${formattedDate}`,
      });
    } else {
      console.error("Grid reference is not available");
    }
  }

  async function getLocationList() {
    setLoading(true);
    try {
      const payload = {
        commonParams: {
          organizationID: details?.orgId,
          companyID: details?.companyId,
          coCode: details?.companyCode,
          groupID: details?.groupID,
          timeZone: details?.timeZone,
          sessionId: details?.sesionId,
          loginUserId: `${details?.userId}`,
          loginUserName: details?.userName,
          AuditTrail: 0,
          editTrail: 0,
          dataSource: "",
        },
      };
      const res = await mutateAsync(payload);
      if (res?.status?.code === 200) {
        setNotify({
          isOpen: true,
          message: `${res?.status?.message}`,
          type: "success",
        });
        let data = res?.data;
        const rows = data?.map((item, index) => ({
          ...item,
          index: index + 1,
        }));
        setRows(rows);
        setLoading(false);
      } else {
        logTelemetry(
          "failed",
          "Location",
          "getDocumentList",
          `${res?.status?.message}`
        );
        setLoading(false);
        setRows([]);
        setNotify({
          isOpen: true,
          message: `${res?.status?.message}`,
          type: "warning",
        });
      }
    } catch (e) {
      logTelemetry("Failed", "Location", "getDocumentList", e.message, e);
      console.error(e, "error in catch");
      setLoading(false);
      setNotify({
        isOpen: true,
        message: e.message,
        type: "error",
      });
    }
  }

  function handleClearValues() {
    setFiltersApplied(false);
    setFormData(filterFormInitialData);
    setOpen(false);
    getDocumentList(filterFormInitialData);
  }
  // list

  async function lookUpsApiCall() {
    setLoading(true);
    try {
      // console.clear();
      const payload = {
        commonParams: {
          organizationID: details?.orgId,
          companyID: details?.companyId,
          coCode: details?.companyCode,
          groupID: details?.groupID,
          timeZone: details?.timeZone,
          sessionId: details?.sesionId,
          loginUserId: `${details?.userId}`,
          loginUserName: details?.userName,
          AuditTrail: 0,
          editTrail: 0,
          dataSource: "",
        },
        configId: "91596",
        moduleId: "0",
        SectionId: "0",
        RequestType: "0",
        RecordId: "0",
      };
      const res = await lookUpsApi(payload);
      // console.log(res, " Result ");
      if (res?.status?.code === 200) {
        // setNotify({
        //   isOpen: true,
        //   message: `${res?.status?.message}`,
        //   type: "success",
        // });
        res?.data?.statusList?.length > 0
          ? res?.data?.statusList?.unshift({
              description: "All",
              code: "0",
            })
          : [];
        setLookUpData(res?.data);
        setLoading(false);
      } else {
        setLoading(false);
        setNotify({
          isOpen: true,
          message: `${res?.status?.message}`,
          type: "warning",
        });
        logTelemetry(
          "failed",
          "Location",
          "lookUpsApiCall",
          `${res?.status?.message}`
        );
      }
    } catch (e) {
      logTelemetry("Failed", "Location", "lookUpsApiCall", e.message, e);
      console.error(e, "error");
      setLoading(false);
      setNotify({
        isOpen: true,
        message: e.message,
        type: "error",
      });
    }
  }

  function handleSubmit() {
    // console.log(formData);
    getDocumentList(formData);
    setFiltersApplied(true);
    setOpen(false);
  }

  function btnRefreshClick() {
    getDocumentList(filterFormInitialData);
    setFormData(filterFormInitialData);
    setFiltersApplied(false);
  }

  function btnExportXlClick() {
    if (gridApi) {
      const formattedDate = formatDateStamp();

      gridApi.exportDataAsExcel({
        fileName: `Staff List_${formattedDate}`,
      });
    } else {
      console.error("Grid API not available");
    }
  }

  function btnExportCsvClick() {
    if (gridRef.current) {
      const formattedDate = formatDateStamp();

      gridRef.current.api.exportDataAsCsv({
        fileName: `Staff List_${formattedDate}`,
      });
    } else {
      console.error("Grid reference is not available");
    }
  }

  return (
    <>
      <Grid container>
        <Grid item={true} xs={12} sm={12}>
          <Grid container>
            <Grid
              item={true}
              xs={12}
              sm={12}
              className="!my-3 flex justify-between"
            >
              <Typography variant="h6">Staff List</Typography>
              {/* <Box>
                  <Tooltip title="Apply Filter" className="!mx-3">
                    <MuiButton
                      className={"filterButton"}
                      onClick={() => btnFilterClick()}
                    >
                      {filtersApplied ? (
                        <Badge variant="dot" color="error">
                          <FilterAltIcon />
                        </Badge>
                      ) : (
                        <FilterAltIcon />
                      )}
                    </MuiButton>
                  </Tooltip>
                  <Tooltip title="Refresh">
                    <MuiButton
                      className={"filterButton"}
                      onClick={() => btnRefreshClick()}
                    >
                      <RefreshIcon />
                    </MuiButton>
                  </Tooltip>
                </Box> */}
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      {sectionAccess.StaffList && (
        <div>
          <Box className="ag-theme-alpine locationVHgt relative">
            <div className="absolute z-10 top-[6px] right-[-2px]">
              <Button
                variant="outlined"
                color="primary"
                onClick={btnExportCsvClick}
                sx={{ marginRight: 1 }}
                className="button-styles"
              >
                export csv
                <i className="fa-solid fa-file-csv fa-lg ml-2"></i>
              </Button>
              <Button
                variant="outlined"
                color="primary"
                onClick={btnExportXlClick}
                sx={{ marginRight: 1 }}
                className="button-styles"
              >
                export xl
                <i className="fa-regular fa-file-excel fa-lg ml-2"></i>
              </Button>
            </div>
            <AgGridReact
              ref={gridRef}
              rowData={rowData}
              columnDefs={columnDefs}
              defaultColDef={{
                minWidth: 150,
              }}
              pagination={true}
              onRowClicked={(e) => {
                dispatch(setLocationLookup(e.data));
                dispatch(setActiveComponent("/location-details"));
                dispatch(setIsFromLocation(false));
                // navigate("/location-details", {
                //   state: e.data,
                // })
              }}
              // rowSelection="multiple"
              paginationPageSize={100}
              paginationPageSizeSelector={[100, 250, 500, 1000]}
              onGridReady={onGridReady}
              alwaysShowVerticalScroll={true}
              alwaysShowHorizontalScroll={true}
              domLayout="autoHeight"
              animateRows={true}
              rowGroupPanelShow="always"
              suppressCopyRowsToClipboard={true}
            />
          </Box>
        </div>
      )}
      {open ? (
        <Dialogs
          open={true}
          cancel={agree}
          submit={disagree}
          // page={page}
          setFilters={setFilters}
          filters={filters}
          // setIsFilters={setIsFilters}
          setOpen={setOpen}
          lookUpData={lookUpData}
          formData={formData}
          setFormData={setFormData}
          handleAutocompleteChange={handleAutocompleteChange}
          handleInputChange={handleInputChange}
          handleDateChange={handleDateChange}
          handleSubmit={handleSubmit}
          subStatusData={subStatusData}
          handleClearValues={handleClearValues}
        />
      ) : null}
      <Backdrop
        sx={{ color: "#1a73e8", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={loading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <Notification notify={notify} setNotify={setNotify} />
    </>
  );
};

export default Location;
