class Constants {
  constructor() {}

  // Document Recognizer
  static STORE_ACTIVE_COMPONENT = "SET_ACTIVE_COMPONENT";
  static CLEAR_ACTIVE_COMPONENT = "CLEAR_ACTIVE_COMPONENT";
  static STORE_LOOKUP_DATA = "SET_LOOKUP_DATA";
  static STORE_DID = "SET_DID";
  static STORE_FILTERED_DATA = "SET_FILTERED_DATA";
  static STORE_SET_FORM_DATA = "STORE_SET_FORM_DATA";
  static STORE_SET_PAGE_MODEL = "STORE_SET_PAGE_MODEL";
  static STORE_SET_SORT_MODEL = "STORE_SET_SORT_MODEL";
  static STORE_SET_FILTER_APPLIED = "STORE_SET_FILTER_APPLIED";
  static STORE_RESET_PREVIOUS_COMP_DATA = "STORE_RESET_PREVIOUS_COMP_DATA";
  static STORE_SET_FILTER_MODEL = "STORE_SET_FILTER_MODEL";
  static STORE_LOCATION_DATA = "SET_LOCATION_DATA";
  static STORE_REPORT_DATA = "STORE_REPORT_DATA";
  static STORE_REPORT_DATA_GRID = "STORE_REPORT_DATA_GRID";
  static STORE_REPORT_INFO = "STORE_REPORT_INFO";
  static STORE_PATIENT_INFO = "STORE_PATIENT_INFO";
  static STORE_PREV_SEGID = "STORE_PREV_SEGID";
  static STORE_PREV_MODID = "STORE_PREV_MODID";
  // Patient List
  static STORE_COSMOS_ID = "SET_COSMOS_ID";
  static STORE_PATIENT_FILTERED_DATA = "SET_PATIENT_FILTERED_DATA";

  static SET_PREVIOUS_AND_CURRENT_COMPONENT =
    "SET_PREVIOUS_AND_CURRENT_COMPONENT";
  static SET_PREVIOUS_AND_CURRENT_SEGID = "SET_PREVIOUS_AND_CURRENT_SEGID";

  static SET_LOGIN_DETAILS = "LOGIN_DETAILS";
  static SET_ACCESS_RIGHTS = "SET_ACCESS_RIGHTS";
  static SET_LOGIN_TOKEN = "LOGIN_TOKEN";
  static SEND_SMS_ID = "SEND_SMS_ID";
  static SEGMENT_ID = "SEGMENT_ID";
  static SECTION_ID = "SECTION_ID";
  static CURRENT_SEGMENT_ID = "CURRENT_SEGMENT_ID";
  static PREVIOUS_SEGMENT_ID = "PREVIOUS_SEGMENT_ID";
  static MODULE_ID = "MODULE_ID";
  static CURRENT_MODULE_ID = "CURRENT_MODULE_ID";
  static PREVIOUS_MODULE_ID = "PREVIOUS_MODULE_ID";
  static ORDER_STATUS_ID = "ORDER_STATUS_ID";
  static ORDER_STATUS_DESCRIPTION = "ORDER_STATUS_DESCRIPTION";
  static ORDER_SUB_STATUS_ID = "ORDER_SUB_STATUS_ID";
  static ORDER_SUB_STATUS_DESCRIPTION = "ORDER_SUB_STATUS_DESCRIPTION";
  static FROM_DATE = "FROM_DATE";
  static TO_DATE = "TO_DATE";
  

  //patient
  static PATIENT_SET_FORM_DATA = "PATIENT_SET_FORM_DATA";
  static PATIENT_SET_PAGE_MODEL = "PATIENT_SET_PAGE_MODEL";
  static PATIENT_SET_FILTER_MODEL = "PATIENT_SET_FILTER_MODEL";
  static PATIENT_SET_SORT_MODEL = "PATIENT_SET_SORT_MODEL";
  static PATIENT_SET_FILTER_APPLIED = "PATIENT_SET_FILTER_APPLIED";
  static RESET_PATIENT_DATA = "RESET_PATIENT_DATA";

  static CAPTURE_DETAILS_DATA = "CAPTURE_DETAILS_DATA";
  static CAPTURE_DATA = "CAPTURE_DATA";
  static CAPTUR_SMS_DATA = "CAPTUR_SMS_DATA";
  static CLAIM_STATUS_DATA = "Claim_STATUS_DATA";

  //Teparory
  static STORE_ISFROM_LOCATION = "STORE_ISFROM_LOCATION";
}

export default Constants;
