import React, {
  useState,
  useEffect,
  useContext,
  useCallback,
  useMemo,
  useRef,
} from "react";
import { useNavigate } from "react-router-dom";
import {
  Box,
  Grid,
  Button,
  Typography,
  Button as MuiButton,
  Backdrop,
  CircularProgress,
  Tooltip,
  Paper,
  IconButton,
  InputBase,
  Divider,
  Badge,
} from "@mui/material";
import { GetLocationListApi } from "../../apis/locationApis";
import MyContext from "../../components/MyContext";
import Notification from "../../components/Notification";
import FilterModel from "./ChildWindow/FilterModel";
import dayjs from "dayjs";
import { AgGridReact } from "ag-grid-react";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-alpine.css";
import RefreshIcon from "@mui/icons-material/Refresh";
import FilterAltIcon from "@mui/icons-material/FilterAlt";
import SearchIcon from "@mui/icons-material/Search";
import ClearIcon from "@mui/icons-material/Clear";
import FilterListIcon from "@mui/icons-material/FilterList";
import ClearAllIcon from "@mui/icons-material/Clear";

import { useDispatch, useSelector } from "react-redux";
import { setActiveComponent } from "../../redux/actions/index";
import {
  setIsFromLocation,
  setLocationLookup,
} from "../../redux/actions/location";
import formatDateStamp from "../../components/utils/getDateStamp";
import { GetSectionRights } from "../../components/utils/activeComponent";
import StaticModuleClass from "../../components/utils/staticModuleClass";

const Location = () => {
  const dispatch = useDispatch();
  const accessDetails = useSelector((state) => state.accessRights);
  const segmentId = useSelector((state) => state.segmentID);
  const moduleId = useSelector((state) => state.moduleID);
  const [formData, setFormData] = useState();
  const [filtersApplied, setFiltersApplied] = useState(false);
  const gridRef = useRef();
  const [rowData, setRowData] = useState([
    {
      name: "Alliance Spine And Pain Centers",
      address:
        "1388 WELLBROOK CIR NE, COVINGTON, GA, 30012-3872, United States",
      county: "CHEROKEE",
      primaryPhone: "7709299033",
      secondaryPhone: "",
      code: "14578",
      organizationName: "CHIROPACTIC XP",
      organizationCode: "DEVCLP",
    },
    {
      name: "Aetna",
      address: "1 Main St, MONROE TOWNSHIP, NJ, 08831, United States",
      county: "MIDDLESEX",
      primaryPhone: "1231231231",
      secondaryPhone: "",
      code: "14542",
      organizationName: "CHIROPACTIC XP",
      organizationCode: "DEVCLP",
    },
    {
      name: "CHIROPACTIC XP",
      address: "134 Riverstone, Canton, GA, 30114, United States",
      county: "MIDDLESEX",
      primaryPhone: "5086772554",
      secondaryPhone: "",
      code: "14564",
      organizationName: "CHIROPACTIC XP",
      organizationCode: "DEVCLP",
    },
    {
      name: "NJ Milltown Health Provider",
      address: "3894 Galway Ln, Houston, TX, 77080, United States",
      county: "MIDDLESEX",
      primaryPhone: "1234567890",
      secondaryPhone: "",
      code: "14313",
      organizationName: "CHIROPACTIC XP",
      organizationCode: "DEVCLP",
    },
    {
      name: "Insite Medical NJ PA",
      address:
        "19035 W CAPITOL DR  STE 101, Brookfield, WI, 53045, United States",
      county: "CAMDEN",
      primaryPhone: "8334946724",
      secondaryPhone: "",
      code: "14311",
      organizationName: "CHIROPACTIC XP",
      organizationCode: "DEVCLP",
    },
    {
      name: "Houston Health Services",
      address:
        "3120 Princeton Pike Fl 1, Lawrenceville, NJ, 08648, United States",
      county: "HARRIS",
      primaryPhone: "6282946292",
      secondaryPhone: "",
      code: "14309",
      organizationName: "CHIROPACTIC XP",
      organizationCode: "DEVCLP",
    },
    {
      name: "BILLTEC Corp2",
      address: "24 Milltown Rd, East Brunswick, NJ, 08816, United States",
      county: "HENNEPIN",
      primaryPhone: "9528974252",
      secondaryPhone: "",
      code: "14115",
      organizationName: "CHIROPACTIC XP",
      organizationCode: "DEVCLP",
    },
    {
      name: "NJ Milltown Health Provider",
      address: "225 S. SWOOPE AVE. #211, MAITLAND, FL, 32751, United States",
      county: "MIDDLESEX",
      primaryPhone: "1234567890",
      secondaryPhone: "",
      code: "14313",
      organizationName: "CHIROPACTIC XP",
      organizationCode: "DEVCLP",
    },
    {
      name: "Insite Medical NJ PA",
      address: "155 Stelton Rd # 8854, Piscataway, NJ, 08854, United States",
      county: "CAMDEN",
      primaryPhone: "8334946724",
      secondaryPhone: "",
      code: "14311",
      organizationName: "CHIROPACTIC XP",
      organizationCode: "DEVCLP",
    },
    {
      name: "Houston Health Services",
      address: "2 Milltown Rd, East Brunswick, NJ, 08816, United States",
      county: "HARRIS",
      primaryPhone: "6282946292",
      secondaryPhone: "",
      code: "14309",
      organizationName: "CHIROPACTIC XP",
      organizationCode: "DEVCLP",
    },
    {
      name: "BILLTEC Corp2",
      address: "11 Milltown Rd, East Brunswick, NJ, 08816, United States",
      county: "HENNEPIN",
      primaryPhone: "9528974252",
      secondaryPhone: "",
      code: "14115",
      organizationName: "CHIROPACTIC XP",
      organizationCode: "DEVCLP",
    },
  ]);

  const [columns, setColumns] = useState([
    { headerName: "Location Name", field: "name", width: 300 },
    { headerName: "Address", field: "address", width: 400 },
    { headerName: "County", field: "county", width: 150 },
    {
      headerName: "Primary Phone",
      field: "primaryPhone",
      width: 180,
    },
    {
      headerName: "Secondary Phone",
      field: "secondaryPhone",
      width: 180,
    },
    { headerName: "Code", field: "code", width: 120 },
    {
      headerName: "Organization Name",
      field: "organizationName",
      width: 220,
    },
    {
      headerName: "Organization Code",
      field: "organizationCode",
      width: 200,
    },
  ]);
  const defaultColDef = useMemo(
    () => ({
      resizable: true,
      sortable: true,
      // editable: true,
      //   floatingFilter: true,
      // filters: true,
      menuTabs: ["filterMenuTab", "generalMenuTab"],
    }),
    []
  );
  const navigate = useNavigate();
  const { mutateAsync } = GetLocationListApi();
  // const { mutateAsync: lookUpsApi } = GetLookUpsForDSApi();
  // const { mutateAsync: GetExportElementsApi } = GetExportElements();
  // let details = JSON.parse(sessionStorage?.getItem("details"));
  // let details = useSelector((state) => JSON.parse(state.loginDetails));

  const loginDetails = useSelector((state) => state.loginDetails);

  let details = useMemo(() => {
    if (loginDetails) {
      return JSON.parse(loginDetails);
    }
    return null;
  }, [loginDetails]);

  const [loading, setLoading] = useState(false);
  const [lookUpData, setLookUpData] = useState({});
  const [gridApi, setGridApi] = useState(null);
  const [notify, setNotify] = useState({
    isOpen: false,
    message: "",
    type: "error",
  });
  const [rows, setRows] = useState([]);
  const [open, setOpen] = useState(false);
  let [filters, setFilters] = useState({});
  const [sectionAccess, setSectionAccess] = useState({ LocationList: false });

  // Filter actions start
  function btnApply() {
    // getDocumentList(formData);
    setFiltersApplied(true);
    setOpen(false);
  }

  function btnClear() {
    setFiltersApplied(false);
    // setFormData(filterFormInitialData);
    setOpen(false);
    // getDocumentList(filterFormInitialData);
  }

  const btnClose = () => {
    setOpen(false);
    // setFilters({});
  };

  const handleAutocompleteChange = (name, value) => {
    if (name === "status") {
      const res = lookUpData?.subStatusList?.filter(
        (item) => item.code === value.code
      );
      res.unshift({
        description: "All",
        key: "0",
      });
      setFormData(res);
    }
    setFormData({ ...formData, [name]: value });
  };

  // Filter actions end

  const onGridReady = useCallback((params) => {
    setGridApi(params.api);
    params.api.sizeColumnsToFit();
  }, []);

  useEffect(() => {
    const accessList = {
      LocationList: GetSectionRights(accessDetails, segmentId, moduleId, StaticModuleClass.LOCATION_LIST_SECID),
    };
    setSectionAccess(accessList);
  }, []);

  // const defaultColDef = {
  //   flex: 1,
  //   minWidth: 100,
  //   resizable: true,
  // };

  // const onGridReady = (params) => {
  //   gridRef.current = params.api;
  //   gridRef.current.sizeColumnsToFit();
  // };

  function btnFilterClick() {
    setOpen(true);
  }

  function btnExportXlClick() {
    if (gridApi) {
      const formattedDate = formatDateStamp();

      gridApi.exportDataAsExcel({
        fileName: `Location List_${formattedDate}`,
      });
    } else {
      console.error("Grid API not available");
    }
  }

  function btnExportCsvClick() {
    if (gridRef.current) {
      const formattedDate = formatDateStamp();

      gridRef.current.api.exportDataAsCsv({
        fileName: `Location List_${formattedDate}`,
      });
    } else {
      console.error("Grid reference is not available");
    }
  }

  async function getLocationList() {
    setLoading(true);
    try {
      const payload = {
        commonParams: {
          organizationID: details?.orgId,
          companyID: details?.companyId,
          coCode: details?.companyCode,
          groupID: details?.groupID,
          timeZone: details?.timeZone,
          sessionId: details?.sesionId,
          loginUserId: `${details?.userId}`,
          loginUserName: details?.userName,
          AuditTrail: 0,
          editTrail: 0,
          dataSource: "",
        },
      };
      const res = await mutateAsync(payload);
      if (res?.status?.code === 200) {
        setNotify({
          isOpen: true,
          message: `${res?.status?.message}`,
          type: "success",
        });
        let data = res?.data;
        const rows = data?.map((item, index) => ({
          ...item,
          index: index + 1,
        }));
        setRows(rows);
        setLoading(false);
      } else {
        // logTelemetry(
        //   "failed",
        //   "Location",
        //   "getDocumentList",
        //   `${res?.status?.message}`
        // );
        setLoading(false);
        setRows([]);
        setNotify({
          isOpen: true,
          message: `${res?.status?.message}`,
          type: "warning",
        });
      }
    } catch (e) {
      // logTelemetry("Failed", "Location", "getDocumentList", e.message, e);
      console.error(e, "error in catch");
      setLoading(false);
      setNotify({
        isOpen: true,
        message: e.message,
        type: "error",
      });
    }
  }

  async function lookUpsApiCall() {
    setLoading(true);
    try {
      // console.clear();
      const payload = {
        commonParams: {
          organizationID: details?.orgId,
          companyID: details?.companyId,
          coCode: details?.companyCode,
          groupID: details?.groupID,
          timeZone: details?.timeZone,
          sessionId: details?.sesionId,
          loginUserId: `${details?.userId}`,
          loginUserName: details?.userName,
          AuditTrail: 0,
          editTrail: 0,
          dataSource: "",
        },
        configId: "91596",
        moduleId: "0",
        SectionId: "0",
        RequestType: "0",
        RecordId: "0",
      };
      const res = await lookUpsApi(payload);
      // console.log(res, " Result ");
      if (res?.status?.code === 200) {
        // setNotify({
        //   isOpen: true,
        //   message: `${res?.status?.message}`,
        //   type: "success",
        // });
        res?.data?.statusList?.length > 0
          ? res?.data?.statusList?.unshift({
              description: "All",
              code: "0",
            })
          : [];
        setLookUpData(res?.data);
        setLoading(false);
      } else {
        setLoading(false);
        setNotify({
          isOpen: true,
          message: `${res?.status?.message}`,
          type: "warning",
        });
        // logTelemetry(
        //   "failed",
        //   "Location",
        //   "lookUpsApiCall",
        //   `${res?.status?.message}`
        // );
      }
    } catch (e) {
      // logTelemetry("Failed", "Location", "lookUpsApiCall", e.message, e);
      console.error(e, "error");
      setLoading(false);
      setNotify({
        isOpen: true,
        message: e.message,
        type: "error",
      });
    }
  }

  function btnRefreshClick() {
    // getDocumentList(filterFormInitialData);
    // setFormData(filterFormInitialData);
    // setFiltersApplied(false);
  }

  function btnExportXlClick() {
    if (gridApi) {
      const formattedDate = formatDateStamp();

      gridApi.exportDataAsExcel({
        fileName: `Location List_${formattedDate}`,
      });
    } else {
      console.error("Grid API not available");
    }
  }

  function btnExportCsvClick() {
    if (gridRef.current) {
      const formattedDate = formatDateStamp();

      gridRef.current.api.exportDataAsCsv({
        fileName: `Location List_${formattedDate}`,
      });
    } else {
      console.error("Grid reference is not available");
    }
  }

  const btnRecordClick = (e) => {
    dispatch(setLocationLookup(e.data));
    dispatch(setActiveComponent("/location-details"));
    dispatch(setIsFromLocation(true));
  };

  const btnCreateNew = () => {
    setNotify({
      isOpen: true,
      message: "This feature has not been implemented yet!",
      type: "warning",
    });
  };

  return (
    <div>
      <Grid container>
        <Grid item={true} xs={12} sm={12}>
          <Grid container>
            <Grid
              item={true}
              xs={12}
              sm={12}
              className="!my-4 flex justify-between"
            >
              <Typography variant="h6">Location List</Typography>
              <Box>
                {/* <Tooltip title="Refresh">
                  <MuiButton
                    className={"filterButton"}
                    onClick={() => btnRefreshClick()}
                  >
                    <RefreshIcon />
                  </MuiButton>
                </Tooltip> */}
                <Button
                  variant="outlined"
                  color="primary"
                  onClick={btnCreateNew}
                  className="button-styles"
                >
                  Create New Location
                </Button>
              </Box>
            </Grid>
          </Grid>
        </Grid>
      </Grid>

      {/* Filter ribbun */}
      <Grid container>
        <Grid item xs={12} sm={12}>
          <span>
            Showing records for the location: {formData?.location?.companyName}{" "}
            From: {dayjs(formData?.fromDate).format("MM/DD/YYYY")} To:{" "}
            {dayjs(formData?.toDate).format("MM/DD/YYYY")}. You may change
            Filters by clicking on filter Icon.
          </span>
          <Tooltip title="Apply Filter">
            <FilterListIcon
              onClick={() => btnFilterClick()}
              color="primary"
              className="secondaryIcon"
            />
          </Tooltip>
          <Tooltip title="Clear Filters">
            <ClearAllIcon
              placeholder="Clear All"
              onClick={() => {}}
              color="primary"
              className="secondaryIcon"
            />
          </Tooltip>
        </Grid>
      </Grid>

      {/* Search control and icons buttons */}

      <Grid gap={2} className="w-full flex justify-between mb-2 mt-4 md:mt-0">
        <div className="w-full">
          <Paper
            component="form"
            sx={{ p: "2px 4px", display: "flex", alignItems: "center" }}
          >
            {/* Clear button on the left if searchVal is not empty */}

            {/* <IconButton
              sx={{ p: "10px" }}
              aria-label="clear"
              onClick={() => {}}
            >
              <ClearIcon />
            </IconButton> */}

            <InputBase
              sx={{ ml: 1, flex: 1 }}
              value={formData?.searchVal}
              placeholder="Search by Location Name"
              id="Search by name or mobile number or email"
              name="Search by name or mobile number or email"
              onChange={(e) => {}}
              onKeyDown={(event) => {
                if (event.key === "Enter") {
                  event.preventDefault();
                }
              }}
            />

            <Divider sx={{ height: 28, m: 0.5 }} orientation="vertical" />

            <IconButton
              type="button"
              sx={{ p: "10px" }}
              aria-label="search"
              className="search-icon"
              onClick={(e) => {
                e.preventDefault();
              }}
            >
              <SearchIcon />
            </IconButton>
          </Paper>
        </div>
        <div>
          <Tooltip title="Refresh" placement="top" arrow>
            <MuiButton
              className={"filterButton"}
              onClick={() => btnRefreshClick()}
            >
              <RefreshIcon />
            </MuiButton>
          </Tooltip>
        </div>
        <div>
          <Tooltip title="Apply Filter" placement="top" arrow>
            <MuiButton
              className={"filterButton"}
              onClick={() => btnFilterClick()}
            >
              {filtersApplied ? (
                <Badge variant="dot" color="error">
                  <FilterAltIcon />
                </Badge>
              ) : (
                <FilterAltIcon />
              )}
            </MuiButton>
          </Tooltip>
        </div>

        {/* <div>
          <Tooltip title="More" placement="top" arrow>
            <MuiButton
              className={"filterButton"}
              aria-controls={open ? "account-menu" : undefined}
              aria-haspopup="true"
              aria-expanded={open ? "true" : undefined}
              onClick={handleMenu}
            >
              <MoreVertIcon />
            </MuiButton>
          </Tooltip>
          <Menu
            anchorEl={anchorEl}
            id="account-menu"
            open={Boolean(anchorEl)}
            onClose={handleMoreClose}
            slotProps={{
              paper: {
                elevation: 0,
                sx: {
                  overflow: "visible",
                  filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
                  mt: 1.5,
                  "& .MuiAvatar-root": {
                    width: 32,
                    height: 32,
                    ml: -0.5,
                    mr: 1,
                  },
                  "&::before": {
                    content: '""',
                    display: "block",
                    position: "absolute",
                    top: 0,
                    right: 14,
                    width: 10,
                    height: 10,
                    bgcolor: "background.paper",
                    transform: "translateY(-50%) rotate(45deg)",
                    zIndex: 0,
                  },
                },
              },
            }}
            transformOrigin={{ horizontal: "right", vertical: "top" }}
            anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
          >
            <MenuItem onClick={() => handleAssignUnAssign()}>Assign</MenuItem>
            <MenuItem onClick={() => unAssign()}>Un-Assign</MenuItem>
          </Menu>
        </div> */}
      </Grid>

      {sectionAccess.LocationList && (
        <div>
          <Box className="ag-theme-alpine locationVHgt relative">
            <div className="absolute z-10 top-[6px] right-[-2px]">
              <Button
                variant="outlined"
                color="primary"
                onClick={btnExportCsvClick}
                sx={{ marginRight: 1 }}
                className="button-styles"
              >
                export csv
                <i className="fa-solid fa-file-csv fa-lg ml-2"></i>
              </Button>
              <Button
                variant="outlined"
                color="primary"
                onClick={btnExportXlClick}
                sx={{ marginRight: 1 }}
                className="button-styles"
              >
                export xl
                <i className="fa-regular fa-file-excel fa-lg ml-2"></i>
              </Button>
            </div>
            <AgGridReact
              ref={gridRef}
              rowData={rowData ?? []}
              columnDefs={columns}
              defaultColDef={defaultColDef}
              pagination={true}
              onRowClicked={(e) => btnRecordClick(e)}
              paginationPageSize={50}
              paginationPageSizeSelector={[50, 250, 500, 1000]}
              onGridReady={onGridReady}
              alwaysShowVerticalScroll={true}
              alwaysShowHorizontalScroll={true}
              domLayout="autoHeight"
              animateRows={true}
              rowGroupPanelShow="always"
              suppressCopyRowsToClipboard={true}
              loading={loading}
            />
          </Box>
        </div>
      )}

      <FilterModel
        open={open}
        btnClear={btnClear}
        btnClose={btnClose}
        btnApply={btnApply}
        setFilters={setFilters}
        filters={filters}
        formData={formData}
        lookUpData={lookUpData}
        setFormData={setFormData}
        handleAutocompleteChange={handleAutocompleteChange}
      />
      <Backdrop
        sx={{ color: "#1a73e8", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={loading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <Notification notify={notify} setNotify={setNotify} />
    </div>
  );
};

export default Location;
