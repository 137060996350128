import dayjs from "dayjs";

const convertToAgGridFormat = (muiRows, muiColumns) => {
  // Convert rows
  const agGridRows = muiRows?.map((row) => {
    const formattedRow = { ...row };

    Object.keys(formattedRow).forEach((key) => {
      const value = formattedRow[key];
      if (
        dayjs(value).isValid() &&
        isNaN(value) &&
        !value.includes("\n") &&
        !value.includes("Model-") &&
        !/^\d+-\d+$/.test(value)
      ) {
        formattedRow[key] = dayjs(value).format("MM/DD/YYYY");
      }
    });
    return formattedRow;
  });

  // Convert columns
  const agGridColumns = muiColumns.map((column) => {
    const { field, headerName, Width, renderCell, ...otherProps } = column;
    const agColumn = {
      field,
      headerName,
      enableRowGroup: true,
      filter: true,
      width: Width, // Rename Width to width
      ...otherProps,
    };

    if (renderCell) {
      agColumn.cellRenderer = (params) => {
        return renderCell({ ...params });
      };
    }

    return agColumn;
  });

  return { agGridRows, agGridColumns };
};

export default convertToAgGridFormat;
