import {
  Typography,
  Button,
  Grid,
  Box,
  Card,
  CardContent,
  Container,
} from "@mui/material";
import RestartAltIcon from "@mui/icons-material/RestartAlt";
import { getBatchNumbersApi } from "../../apis/customReports";
import React, {
  useContext,
  useState,
  useEffect,
  useRef,
  useCallback,
  useMemo,
} from "react";
import MyContext from "../../components/MyContext";
import FilterControl from "./FilterControl";
import dayjs from "dayjs";
import convertToAgGridFormat from "../../components/utils/convertToAgGridFormat";
import { AgGridReact } from "ag-grid-react";
import { logTelemetry } from "../../logTelemetry";
import { TabContext } from "../../components/utils/TabProvider";
import getErrorMessage from "../../components/utils/getErrorMessage";
import { useDispatch, useSelector } from "react-redux";
import {
  setReportsData,
  setRepInfo,
  setReportDataGrid,
} from "../../redux/actions/reports";
import formatDateStamp from "../../components/utils/getDateStamp";

function CustomReportsModule({
  reportId,
  setLoading,
  setNotify,
  reportName,
  setDialogState,
  currentTab,
  setCurrentTab,
  payload,
  setPayload,
}) {
  // const { globalreportsData } = useContext(TabContext);
  const dispatch = useDispatch();
  const {
    filtersData: filters,
    dataGrid,
    repInfo,
  } = useSelector(
    (state) => state?.previousComponent?.currentComponentData || {}
  );
  // console.log({ dataGrid, repInfo });

  const gridRef = useRef(null);
  const [gridApi, setGridApi] = useState(null);
  const { mutateAsync } = getBatchNumbersApi();
  // const [repInfo, setRepInfo] = useState({});
  const [columns, setColumns] = useState([]);
  const [rows, setRows] = useState([]);
  // const [loading, setLoading] = useState(false);
  // const [selectedBatchNumber, setSelectedBatchNumber] = useState(null);

  // let details = useSelector((state) => JSON.parse(state.loginDetails));
  // let details = useSelector((state) => JSON.parse(state.loginDetails));

  const loginDetails = useSelector((state) => state.loginDetails);

  let details = useMemo(() => {
    if (loginDetails) {
      return JSON.parse(loginDetails);
    }
    return null;
  }, [loginDetails]);

  let locationData = details?.aILocations;
  locationData.unshift({
    companyName: "",
    companyId: "0",
  });

  const [batchNumbers, setBatchNumbers] = useState([]);

  // const [notify, setNotify] = useState({
  //   isOpen: false,
  //   message: "",
  //   type: "error",
  // });

  const escapeValue = (value) => {
    if (typeof value === "string") {
      value = value.replace(/"/g, '""');
      if (value.includes(",")) {
        value = `"${value}"`;
      }
    }
    return value;
  };

  const btnExportCsvClick = () => {
    if (gridRef.current) {
      const formattedDate = formatDateStamp();

      gridRef.current.api.exportDataAsCsv({
        fileName: `${reportName}_${formattedDate}`,
      });
    } else {
      console.error("Grid reference is not available");
    }
  };

  const btnExportXlClick = () => {
    if (gridApi) {
      const formattedDate = formatDateStamp();

      gridApi.exportDataAsExcel({
        fileName: `${reportName}_${formattedDate}`,
      });
    } else {
      console.error("Grid API not available");
    }
  };

  const onGridReady = useCallback((params) => {
    setGridApi(params.api);
    params.api.sizeColumnsToFit();
  }, []);

  useEffect(() => {
    if (reportId && currentTab !== 2) {
      // console.log({ filters, dataGrid, repInfo }, " useEffect ");
      getInitialData();
      // debugger;
      if (dataGrid) {
        setColumns(dataGrid.agGridColumns);
        setRows(dataGrid.agGridRows);
      }
      // } else {
      //   btnExecuteClick();
      // }
    }
    if (currentTab === 2) {
      getInitialDataa();
    }
    return () => {
      setNotify({
        isOpen: false,
      });
    };
  }, []);

  useEffect(() => {
    setColumns([]);
    setRows([]);
  }, [reportName, reportId]);

  async function getInitialData() {
    // const [loading, setLoading] = useState(false);
    setLoading(true);
    try {
      // console.clear();
      const payload = {
        commonParams: {
          organizationID: details?.orgId,
          companyID: details?.companyId,
          coCode: details?.companyCode,
          groupID: details?.groupID,
          timeZone: details?.timeZone,
          sessionId: details?.sesionId,
          loginUserId: `${details?.userId}`,
          loginUserName: details?.userName,
          AuditTrail: 0,
          editTrail: 0,
          dataSource: "",
        },
        configId: 91886,
        moduleId: 70140,
        reportId,
        reportType: 0,
        repInformation: {
          reportId: 0,
          locationId: 0,
          reportName: "",
          status: "",
          createdUser: "",
          parentCategory: 0,
          subCategory: 0,
          detailCategory: 0,
          reportDesc: "",
          fields: [],
          filterFields: [],
          groupByFields: [],
        },
        subRepInformation: {
          entity: "",
          reportTitle: "",
          column: "",
          params: [
            {
              fieldName: "",
              fieldValue: "",
            },
          ],
        },
      };
      const res = await mutateAsync(payload);
      // debugger;
      if (res?.status?.code === 200) {
        dispatch(setRepInfo(res?.data?.reportInfo));
        setBatchNumbers(res?.data?.reportInfo?.fields?.[0]?.valueList);
        const filtersData = res?.data?.reportInfo?.filterFields?.map(
          (filterField) => setValue(filterField)
        );
        dispatch(setReportsData(filtersData));
        setLoading(false);
      } else {
        logTelemetry(
          "failed",
          "CustomReportsModule",
          "getInitialData",
          `${res?.status?.message}`
        );
        setLoading(false);
        setNotify({
          isOpen: true,
          message: getErrorMessage(res?.status?.message),
          type: "error",
        });
      }
    } catch (e) {
      logTelemetry(
        "failed",
        "CustomReportsModule",
        "getInitialData",
        e.message,
        e
      );
      console.error(e, "error");
      setLoading(false);
      setNotify({
        isOpen: true,
        message: "getInitaialData " + e.message,
        type: "error",
      });
    }
  }

  function getParams() {
    const res = payload?.params?.data;
    const result = payload?.res?.data?.subRepJson?.[0]?.subrepDet?.params?.map(
      (t) => {
        if (t.fieldValue.includes("^")) {
          const fieldKey = t.fieldValue.split("^")[0]; // Get the part before "^"
          return {
            fieldName: t.fieldName,
            fieldValue: res[fieldKey], // Access the value from object `a`
          };
        } else {
          return {
            fieldName: t.fieldName,
            fieldValue: t.fieldValue,
          };
        }
      }
    );
    return result;
  }

  async function getInitialDataa() {
    // const [loading, setLoading] = useState(false);
    setLoading(true);
    try {
      // console.clear();
      const payload = {
        commonParams: {
          organizationID: details?.orgId,
          companyID: details?.companyId,
          coCode: details?.companyCode,
          groupID: details?.groupID,
          timeZone: details?.timeZone,
          sessionId: details?.sesionId,
          loginUserId: `${details?.userId}`,
          loginUserName: details?.userName,
          AuditTrail: 0,
          editTrail: 0,
          dataSource: "",
        },
        configId: 91886,
        moduleId: 70140,
        reportId,
        reportType: 1,
        repInformation: {
          reportId: 0,
          locationId: 0,
          reportName: "",
          status: "",
          createdUser: "",
          parentCategory: 0,
          subCategory: 0,
          detailCategory: 0,
          reportDesc: "",
          fields: [],
          filterFields: [],
          groupByFields: [],
        },
        subRepInformation: {
          entity: "",
          reportTitle: "",
          column: "",
          params: getParams(),
        },
      };
      const res = await mutateAsync(payload);
      // debugger;
      if (res?.status?.code === 200) {
        const cols = transformObj(res?.data?.reportPreviewFields?.headers?.[0]);
        const rowss = res?.data?.reportPreviewFields?.searchValues?.map(
          (itm, index) => ({
            ...itm,
            id: index + 1,
          })
        );
        const { agGridColumns, agGridRows } = convertToAgGridFormat(
          rowss,
          cols
        );
        setColumns(agGridColumns);
        setRows(agGridRows);
        setLoading(false);
      } else {
        logTelemetry(
          "failed",
          "CustomReportsModule",
          "getInitialData",
          `${res?.status?.message}`
        );
        setLoading(false);
        setNotify({
          isOpen: true,
          message: getErrorMessage(res?.status?.message),
          type: "error",
        });
      }
    } catch (e) {
      logTelemetry(
        "failed",
        "CustomReportsModule",
        "getInitialData",
        e.message,
        e
      );
      console.error(e, "error");
      setLoading(false);

      setNotify({
        isOpen: true,
        message: "getInitialData" + getErrorMessage(e.message),
        type: "error",
      });
    }
  }

  async function btnExecuteClick() {
    // let allMandatoryHaveValues = true;

    // for (let i = 0; i < filters.length; i++) {
    //   if (filters[i].isMandatoryFilter) {
    //     console.log("Checking item:", filters[i]); // Log item to debug
    //     if (
    //       filters[i].value1 === "" ||
    //       filters[i].value1 === null ||
    //       filters[i].value1 === undefined
    //     ) {
    //       allMandatoryHaveValues = false;
    //       break;
    //     }
    //   }
    // }

    // if (!allMandatoryHaveValues) {
    //   console.log("Not all mandatory fields are filled."); // Log if condition fails
    //   setNotify({
    //     isOpen: true,
    //     message: "Please fill all * Fields",
    //     type: "error",
    //   });
    //   return;
    // }

    setLoading(true);
    try {
      // debugger;
      let executeObject = {
        ...repInfo,
        fields: repInfo?.fields?.map((field) => ({
          ...field,
          valueList: null,
        })),
        filterFields: repInfo?.filterFields?.map((filterField) => ({
          ...filterField,
          valueList: null,
        })),
      };

      const payload = {
        commonParams: {
          organizationID: details?.orgId,
          companyID: details?.companyId,
          coCode: details?.companyCode,
          groupID: details?.groupID,
          timeZone: details?.timeZone,
          sessionId: details?.sesionId,
          loginUserId: `${details?.userId}`,
          loginUserName: details?.userName,
          AuditTrail: 0,
          editTrail: 0,
          dataSource: "",
        },
        configId: 91886,
        moduleId: 70140,
        reportId,
        reportType: 0,
        // repInformation: { ...repInfo },
        repInformation: executeObject,
        subRepInformation: {
          entity: "",
          reportTitle: "",
          column: "",
          params: [
            {
              fieldName: "",
              fieldValue: "",
            },
          ],
        },
      };
      // debugger;

      const res = await mutateAsync(payload);
      // console.log(res, " Result ");
      if (res?.status?.code === 200) {
        setNotify({
          isOpen: true,
          message: `${res?.status?.message}`,
          type: "success",
        });
        setPayload({ payload, res });
        // setBatchNumbers(res?.data?.reportInfo?.fields?.[0]?.valueList);
        // data.reportPreviewFields.searchValues
        const cols = transformObj(res?.data?.reportPreviewFields?.headers?.[0]);
        const rowss = res?.data?.reportPreviewFields?.searchValues?.map(
          (itm, index) => ({
            ...itm,
            id: index + 1,
          })
        );
        const { agGridColumns, agGridRows } = convertToAgGridFormat(
          rowss,
          cols
        );
        dispatch(
          setReportDataGrid({
            agGridColumns,
            agGridRows,
          })
        );
        setColumns(agGridColumns);
        setRows(agGridRows);
        setLoading(false);
      } else {
        logTelemetry(
          "failed",
          "CustomReportsModule",
          "btnExecuteClick",
          `${res?.status?.message}`
        );
        setLoading(false);
        setDialogState({
          open: true,
          title: "What Went Wrong",
          subTitle: getErrorMessage(res?.status?.message),
          type: "single",
        });
        // setNotify({
        //   isOpen: true,
        //   message: `${res?.status?.message}`,
        //   type: "error",
        // });
      }
    } catch (e) {
      logTelemetry(
        "failed",
        "CustomReportsModule",
        "btnExecuteClick",
        e.message,
        e
      );
      console.log(e, "error");
      setLoading(false);
      setDialogState({
        open: true,
        title: "What Went Wrong",
        subTitle: "btnExecuteClick" + e.message,
        type: "single",
      });
      // setNotify({
      //   isOpen: true,
      //   message: "btnExecuteClick" + e.message,
      //   type: "error",
      // });
    }
  }

  const CustomButtonComponent = (params) => {
    return (
      <p
        style={{
          color: "blue",
          cursor: "pointer",
        }}
        onClick={() => {
          setLoading(true);
          setTimeout(() => {
            setPayload((prev) => ({
              ...prev,
              params,
            }));
            setCurrentTab(2, true, params.value);
          }, 1000);
        }}
      >
        {params.value}
      </p>
    );
  };

  function transformObj(originalObj) {
    const columns = [];
    for (const key in originalObj) {
      if (originalObj.hasOwnProperty(key)) {
        const splitValue = originalObj[key].split("^");
        const objRes = {
          field: key,
          headerName: splitValue[1],
          width: Number(splitValue[3]),
        };
        if (splitValue[splitValue.length - 1] === "A") {
          objRes.cellRenderer = CustomButtonComponent;
          columns.push(objRes);
        } else {
          columns.push(objRes);
        }
      }
    }
    return columns;
  }

  const handleFilterChange = (index, newValue, controlType) => {
    // console.log({ index, newValue, controlType }, " RRRRRR ");
    const updatedFilters = [...filters];
    if (controlType == "dropdown") {
      updatedFilters[index].value1 = newValue ? newValue.field_Id : null;
    } else if (controlType == "Date") {
      updatedFilters[index].value1 = dayjs(newValue).format("MM/DD/YYYY");
    } else {
      updatedFilters[index].value1 = newValue;
    }
    dispatch(setReportsData(updatedFilters));
    // setFilters(updatedFilters);
  };

  const getValue = (filter) => {
    if (filter?.value1 && filter?.valueList?.length > 0) {
      return (
        filter.valueList.find((item) => item.field_Id === filter.value1) || null
      );
    } else if (filter?.controlType === "dropdown") {
      return filter?.valueList?.[0] || null;
    } else if (filter?.controlType === "Date") {
      return filter?.value1;
    }
    return null;
  };

  function setValue(filter) {
    let newFilter = filter;
    if (newFilter?.controlType === "dropdown" && newFilter.value1 === "") {
      newFilter.value1 = newFilter?.valueList?.[0]?.field_Id;
    }
    return newFilter;
  }

  return (
    <div>
      <Grid>
        <div>
          {/* <Container> */}
          <Grid
            container
            alignItems="center"
            justifyContent="space-between"
            // xs={12}
            // sm={12}
            className="mb-3"
          >
            <Typography level="h4">
              {currentTab !== 2 ? reportName : "Sub Reports Module"}
            </Typography>

            {reportName && currentTab !== 2 && (
              <Box>
                <Button
                  variant="outlined"
                  sx={{ ml: 1 }}
                  endIcon={<RestartAltIcon />}
                  onClick={() => {
                    btnExecuteClick();
                  }}
                  className="button-styles btnHeight"
                >
                  Execute
                </Button>
              </Box>
            )}
          </Grid>
          {currentTab !== 2 && (
            <Card>
              <CardContent>
                <Grid container className="mt-4">
                  <Grid container spacing={2}>
                    {filters?.map((filter, index) => (
                      <Grid item xs={3} sm={3} md={3} key={index}>
                        <FilterControl
                          size="small"
                          controlType={filter.controlType}
                          valueList={filter?.valueList}
                          label={filter.displayField}
                          value={getValue(filter)}
                          onChange={(newValue) =>
                            handleFilterChange(
                              index,
                              newValue,
                              filter.controlType
                            )
                          }
                          isMandatory={filter.isMandatoryFilter}
                        />
                      </Grid>
                    ))}
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          )}
          <div className="mt-3">
            {rows?.length > 0 && (
              <Box className="ag-theme-alpine repScrHgt relative">
                <div className="absolute z-10 top-[6px] right-[-2px]">
                  <Button
                    variant="outlined"
                    color="primary"
                    onClick={btnExportCsvClick}
                    sx={{ marginRight: 1 }}
                    className="button-styles"
                  >
                    export csv
                    <i className="fa-solid fa-file-csv fa-lg ml-2"></i>
                  </Button>
                  <Button
                    variant="outlined"
                    color="primary"
                    onClick={btnExportXlClick}
                    sx={{ marginRight: 1 }}
                    className="button-styles"
                  >
                    export xl
                    <i className="fa-regular fa-file-excel fa-lg ml-2"></i>
                  </Button>
                </div>
                <AgGridReact
                  ref={gridRef}
                  rowData={rows}
                  columnDefs={columns}
                  defaultColDef={{
                    minWidth: 150,
                  }}
                  pagination={true}
                  paginationPageSize={100}
                  paginationPageSizeSelector={[100, 250, 500, 1000]}
                  onGridReady={onGridReady}
                  alwaysShowVerticalScroll={true}
                  alwaysShowHorizontalScroll={true}
                  domLayout="autoHeight"
                  animateRows={true}
                  rowGroupPanelShow="always"
                  suppressCopyRowsToClipboard={true}
                />
              </Box>
            )}
          </div>
          {/* </Container> */}
        </div>
      </Grid>
    </div>
  );
}

export default CustomReportsModule;
