import Constants from "../constants";

export const setSegmentId = (data) => {
    return {
        type: Constants.SEGMENT_ID,
        payload: data,
    };
};

export const setPreviousSegmentId = (data) => {
    return {
        type: Constants.PREVIOUS_SEGMENT_ID,
        payload: data,
    };
};