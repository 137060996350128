import React, { useEffect, useMemo, useState } from "react";
import { useLocation } from "react-router-dom";
import {
  Grid,
  Button,
  Typography,
  Card,
  CardContent,
  Box,
  Container,
} from "@mui/material";
import tickGif from "../../assets/tick.jpg";

import Breadcrumb from "../../components/Breadcrumb";
import { setPatientInfoLookup } from "../../redux/actions/patientInfo";
import { setActiveComponent } from "../../redux/actions";
import { useDispatch, useSelector } from "react-redux";
import { setModuleId, setPreviousModuleId } from "../../redux/actions/moduleId";
import {
  setPreviousSegmentId,
  setSegmentId,
} from "../../redux/actions/segmentId";
import StaticModuleClass from "../../components/utils/staticModuleClass";

const SendSMS = () => {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const dataa = JSON.parse(queryParams.get("state"));
  const dispatch = useDispatch();
  const SMSId = useSelector((state) => state.sentSMSID);
  const segmentId = useSelector((state) => state.segmentID);
  const moduleId = useSelector((state) => state.moduleID);
  const previousSegmentId = useSelector((state) => state.previousSegmentID);
  const previousModuleId = useSelector((state) => state.previousModuleID);

  const details = useMemo(() => {
    if (SMSId) {
      return JSON.parse(SMSId);
    }
    return null;
  }, [SMSId]);

  function btnTrackStatus() {
    dispatch(setPatientInfoLookup(details));
    dispatch(setActiveComponent("/PATIENT-LIST/PATIENT-DETAILS"));
  }

  function btnBackClick() {
    // dispatch(setPreviousModuleId(moduleId));
    // dispatch(setPreviousSegmentId(segmentId));

    dispatch(setModuleId(StaticModuleClass.INSURANCE_MODID));
    dispatch(setSegmentId(StaticModuleClass.INTAKE_SEGID));
    dispatch(setActiveComponent("/patient-list"));
  }

  function btnSMSClick() {
    dispatch(setActiveComponent("/PATIENT-LIST/SEND-SMS-OR-EMAIL"));
  }

  const breadcrumbItems = [
    { label: "Insurance Verification List", link: "/patient-list" },
    { label: "Send SMS", link: "/patient-list/send-sms-or-email" },
    { label: "Sent SMS", link: "/patient-list/send-sms-or-email/sms-form" },
  ];

  return (
    <div>
      {/* <Grid xs={6} sm={6} className="my-2 md:mt-8 flex items-center">
        <Breadcrumb items={breadcrumbItems} isRedux={true} />
      </Grid> */}
      <Container maxWidth="xl">
        <Grid container>
          <Grid item={true} xs={12} sm={12}>
            <Grid container>
              <Grid
                item={true}
                xs={12}
                sm={12}
                className="flex justify-between !my-4"
              >
                <Typography variant="h6">Sent SMS</Typography>
                <Box>
                  <>
                    <Button
                      className="button-styles !mr-2"
                      variant="outlined"
                      onClick={() => btnSMSClick()}
                    >
                      Send SMS or Email
                    </Button>
                    <Button
                      className="button-styles"
                      variant="outlined"
                      onClick={() => btnBackClick()}
                    >
                      Back To List
                    </Button>
                  </>
                </Box>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Card>
          <CardContent>
            <div className="flex justify-center flex-col items-center">
              <div>
                <img src={tickGif} alt="Tick" />
              </div>
              <div className="flex flex-col items-center">
                <Typography variant="h6" gutterBottom>
                  {dataa} Sent Successfully
                </Typography>
                <Typography variant="body1" gutterBottom>
                  You may view details submitted by recipient from the last page
                </Typography>
                <div className="!mt-6">
                  <Button
                    className="button-styles"
                    variant="outlined"
                    onClick={() => btnTrackStatus()}
                  >
                    Track Status
                  </Button>
                </div>
              </div>
            </div>
          </CardContent>
        </Card>
      </Container>
    </div>
  );
};

export default SendSMS;
