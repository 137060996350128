import React, { useState } from "react";
import {
  TextField,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

const MyModal = ({
  open,
  handleClose,
  inputJSON,
  handleInputChange,
  handleContinue,
}) => {
  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="customized-dialog-title"
      sx={{
        "& .MuiDialog-paper": {
          width: "900px",
          maxWidth: "90vw",
        },
      }}
    >
      <DialogTitle id="customized-dialog-title">
        Input JSON
        <CloseIcon
          aria-label="close"
          className="button-stylesDialog cursor-pointer windowClose"
          onClick={handleClose}
        />
      </DialogTitle>
      <DialogContent dividers>
        <TextField
          multiline
          placeholder="Paste inputJSON here..."
          fullWidth
          value={inputJSON}
          onChange={handleInputChange}
          className="txtJson"
        />
      </DialogContent>
      <DialogActions sx={{ m: 0, p: 2 }}>
        <Button
          onClick={handleContinue}
          className="button-styles !min-w-[100px]"
          color="primary"
        >
          Continue
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default MyModal;
