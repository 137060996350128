import React, { useMemo, useState, useEffect } from "react";
import {
  Grid,
  Box,
  Button,
  Card,
  CardContent,
  Autocomplete,
  TextField,
  Typography,
  IconButton,
  Divider,
  Container,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  FormGroup,
  FormControlLabel,
  Checkbox,
  Backdrop,
  CircularProgress,
  Tooltip,
} from "@mui/material";
import { useNavigate, useLocation } from "react-router-dom";
import DeleteSweepIcon from "@mui/icons-material/DeleteSweep";
import {
  PostAddOutlined,
  Delete,
  NoteAddOutlined,
  Visibility,
  ArrowBackOutlined,
} from "@mui/icons-material";
import {
  UploadBatchFilesApi,
  uploadSelectedFilesAPI,
} from "../../apis/documentVerification";
import Notification from "../../components/Notification";
import ClearStatesConfirmationDialog from "../importModule/ClearStatesConfirmationDialog";
import { withAITracking } from "@microsoft/applicationinsights-react-js";
import { reactPlugin } from "../../AppInsights";
import PreviewModal from "./PreviewModal";
import { logTelemetry } from "../../logTelemetry";
import { useDispatch, useSelector } from "react-redux";
import { setActiveComponent, setLookUpDatas } from "../../redux/actions/index";
import getErrorMessage from "../../components/utils/getErrorMessage";
import {
  GetConfigurationId,
  GetSectionRights,
} from "../../components/utils/activeComponent";
import StaticModuleClass from "../../components/utils/staticModuleClass";

// reactPlugin
const options = ["Detect", "Validate", "Verify"];

const BatchUpload = () => {
  const dispatch = useDispatch();
  const accessDetails = useSelector((state) => state.accessRights);
  const segmentId = useSelector((state) => state.segmentID);
  const moduleId = useSelector((state) => state.moduleID);
  const { lookUpData } = useSelector(
    (state) => state?.previousComponent?.previousComponentData || {}
  );

  const [open, setOpen] = useState(false);

  const [currentIndex, setCurrentIndex] = useState(0);
  const [kvExe, setKvExe] = useState("");
  const [llmExe, setLlmExe] = useState("");
  const [validate, setValidate] = useState("");
  const [verify, setVerify] = useState("");

  const handleOpen = () => setOpen(true);
  const handleCloseModal = () => setOpen(false);

  const navigate = useNavigate();
  const location = useLocation();
  // const optionsDetect = location.state.inputTypeList
  //   ?.slice(1)
  //   ?.map((option) => ({
  //     name: option.description,
  //     value: typeof option.id !== "string" ? `${option.id}` : option.id,
  //   }));
  let optionsDetect = [];

  if (location.pathname == "/home") {
    // console.log(lookUpData?.inputTypeList);

    optionsDetect =
      lookUpData &&
      lookUpData?.inputTypeList?.slice(1)?.map((option) => ({
        name: option.description,
        code: option.code,
        value: typeof option.id !== "string" ? `${option.id}` : option.id,
      }));
  } else {
    optionsDetect = location?.state?.inputTypeList?.slice(1)?.map((option) => ({
      name: option.description,
      code: option.code,
      value: typeof option.id !== "string" ? `${option.id}` : option.id,
    }));
  }

  const loginDetails = useSelector((state) => state.loginDetails);

  let details = useMemo(() => {
    if (loginDetails) {
      return JSON.parse(loginDetails);
    }
    return null;
  }, [loginDetails]);

  const [tableData, setTableData] = useState([]);
  const [failureFileNames, setFailureFileNames] = useState([]);
  const [processClicked, setProcessClicked] = useState(false);
  const [applySameForAll, setApplySameForAll] = useState(false);
  const [applySameForAll1, setApplySameForAll1] = useState(false);
  const [fileIndex, setFileIndex] = useState();
  const { mutateAsync } = uploadSelectedFilesAPI();
  const { mutateAsync: ApiCall } = UploadBatchFilesApi();
  const [loading, setLoading] = useState(false);
  const [notify, setNotify] = useState({
    isOpen: false,
    message: "",
    type: "error",
  });
  const intial = {
    open: false,
    title: "",
    subTitle: "",
    type: "",
  };
  const [dialogState, setDialogState] = useState(intial);
  const [sectionAccess, setSectionAccess] = useState({ UploadBatch: false });
  const [functionAccess, setFunctionAccess] = useState({ Process: 0 });

  const radioBtnChange = (event) => {
    const val = event.target.checked;
    if (val && tableData.length > 1 && tableData[0].type) {
      const updatedVal = [...tableData];
      let res = updatedVal.map((row) => ({ ...row, type: updatedVal[0].type }));
      setTableData(res);
    }
    setApplySameForAll(event.target.checked);
  };

  const handleInputChangeLatest = (setter) => (e) => {
    const value = event.target.value;
    if (value === "0" || value === "1" || value === "") {
      setter(value); // Allow only 0, 1, or empty for clearing
    }
  };

  const radioBtnChange1 = (event) => {
    const val = event.target.checked;
    if (val && tableData.length > 1 && tableData[0].detect) {
      const updatedVal = [...tableData];
      let res = updatedVal.map((row) => ({
        ...row,
        detect: updatedVal[0].detect,
      }));
      setTableData(res);
    }
    setApplySameForAll1(event.target.checked);
  };

  const handleClose = () => {
    setDialogState(intial);
  };

  const handleConfirm = (title) => {
    if (title === "Attention Required") {
      setTableData([]);
      setApplySameForAll(false);
      setApplySameForAll1(false);
    }
    if (title === "Are You Sure?") {
      const updatedFiles = [...tableData].filter(
        (file, indx) => fileIndex !== indx
      );
      setTableData(updatedFiles);
    }
    setDialogState(intial);
  };

  // Function to handle file selection
  const handleFileSelect = (event) => {
    const newFiles = Array.from(event.target.files);

    // Check for duplicates
    const existingFiles = tableData.map(
      (data) => data.file.name + data.file.size
    );
    const filteredNewFiles = newFiles.filter(
      (file) => !existingFiles.includes(file.name + file.size)
    );

    // Function to detect file type based on magic numbers
    const detectFileType = (file) => {
      return new Promise((resolve) => {
        const reader = new FileReader();
        reader.onloadend = () => {
          const uintArray = new Uint8Array(reader.result);

          // Determine the MIME type based on magic numbers
          let typeOfFile = "application/octet-stream"; // Default MIME type
          if (uintArray[0] === 0xff && uintArray[1] === 0xd8) {
            typeOfFile = "image/jpeg"; // JPEG
          } else if (
            uintArray[0] === 0x89 &&
            uintArray[1] === 0x50 &&
            uintArray[2] === 0x4e &&
            uintArray[3] === 0x47
          ) {
            typeOfFile = "image/png"; // PNG
          } else if (
            uintArray[0] === 0x25 &&
            uintArray[1] === 0x50 &&
            uintArray[2] === 0x44 &&
            uintArray[3] === 0x46
          ) {
            typeOfFile = "application/pdf"; // PDF
          } else if (
            (uintArray[0] === 0x4d &&
              uintArray[1] === 0x4d &&
              uintArray[2] === 0x00 &&
              uintArray[3] === 0x2a) || // TIFF Big Endian
            (uintArray[0] === 0x49 &&
              uintArray[1] === 0x49 &&
              uintArray[2] === 0x2a &&
              uintArray[3] === 0x00) // TIFF Little Endian
          ) {
            typeOfFile = "image/tiff"; // TIFF
          }

          resolve(typeOfFile);
        };

        // Read the first few bytes of the file as ArrayBuffer
        reader.readAsArrayBuffer(file.slice(0, 4));
      });
    };

    // Map each file to include its detected fileType
    const processFiles = async () => {
      const filesWithTypes = await Promise.all(
        filteredNewFiles.map(async (file) => {
          const fileType = await detectFileType(file);
          return { file, fileType };
        })
      );

      //this loop is for to pass detect by default with out onChange even on control
      for (const file of filesWithTypes) {
        file.type = "Detect";
      }

      setTableData([...tableData, ...filesWithTypes]);
    };

    // Start processing files
    processFiles();

    // Reset the input value
    event.target.value = null;
  };

  // Function to handle file deletion
  const handleFileDelete = (fileToDelete, index) => {
    setFileIndex(index);
    setDialogState({
      open: true,
      title: "Are You Sure?",
      subTitle: "you want to remove this file? This action cannot be undone.",
      type: "double",
    });
  };

  function handleAutocompleteChange(e, newValue, indx) {
    if (!indx && applySameForAll && tableData.length > 1) {
      const prevValues = [...tableData];
      let res = prevValues.map((row) => ({ ...row, type: newValue }));
      setTableData(res);
      return;
    }
    const prevValues = [...tableData];
    prevValues[indx].type = newValue;
    setTableData(prevValues);
    if (applySameForAll) setApplySameForAll(false);
  }

  function handleAutocompleteChange1(e, newValue, indx) {
    if (!indx && applySameForAll1 && tableData.length > 1) {
      const prevValues = [...tableData];
      let res = prevValues.map((row) => ({ ...row, detect: newValue }));
      setTableData(res);
      return;
    }
    const prevValues = [...tableData];
    prevValues[indx].detect = newValue;
    setTableData(prevValues);
    if (applySameForAll1) setApplySameForAll1(false);
  }

  async function btnProcessBatchClick() {
    setProcessClicked(true);
    if (tableData.length === 0) {
      setNotify({
        isOpen: true,
        message:
          "No file(s) selected to process, Please upload/select files to process in batch",
        type: "warning",
      });
      return;
    } else {
      for (let i = 0; i < tableData.length; i++) {
        const item = tableData[i];
        let missingFields = [];
        if (!item.type) {
          missingFields.push("Function to be Performed");
        }
        if (!item.detect) {
          missingFields.push("Data to be Detected");
        }
        if (missingFields.length > 0) {
          setDialogState({
            open: true,
            title: "Warning",
            subTitle: `File #${
              i + 1
            } is missing the following fields: ${missingFields.join(", ")}`,
            type: "single",
          });
          return false;
        }
      }
    }
    setLoading(true);
    let PresignUrlRequests = tableData?.map((tableRow, index) => ({
      mimeType: tableRow.file.type,
      fileName: tableRow.file.name,
      id: index + 1,
      file: tableRow.file,
    }));

    const payload = {
      commonParams: {
        organizationID: details?.orgId,
        companyID: details?.companyId,
        coCode: details?.companyCode,
        groupID: details?.groupID,
        timeZone: details?.timeZone,
        sessionId: details?.sesionId,
        loginUserId: `${details?.userId}`,
        loginUserName: details?.userName,
        AuditTrail: 0,
        editTrail: 0,
        dataSource: "",
      },
      PresignUrlRequests,
    };
    const res = await mutateAsync(payload);

    let successFiles = [];
    let failureFiles = [];
    res?.arr?.forEach((val) => {
      if (val?.response?.data === "") {
        successFiles.push(val);
      } else {
        failureFiles.push(val);
      }
    });
    // console.log(failureFiles);
    // console.log(successFiles);
    if (successFiles.length === res?.arr?.length) {
      setNotify({
        isOpen: true,
        message: "All files uploaded successfully to the blob",
        type: "success",
      });
      batchUploadApiCall(successFiles, true);
    } else {
      const missing = failureFiles
        ?.map((item) => item?.inputData?.fileName)
        .join(", ");
      setNotify({
        isOpen: true,
        message: missing
          ? `The following files had issues while uploading to the blob: ${missing}`
          : "Some files have issues while uploading to the blob.",
        type: "error",
      });
      logTelemetry(
        "Failed",
        "batchUpload",
        "btnProcessBatchClick",
        missing
          ? `The following files had issues while uploading to the blob: ${missing}`
          : "Some files have issues while uploading to the blob."
      );
      const failureFileNames = failureFiles?.map(
        (item) => item.inputData?.fileName
      );
      setFailureFileNames(failureFileNames);
      batchUploadApiCall(successFiles, false, failureFileNames);
    }
  }

  async function batchUploadApiCall(files, isFromSuccess, failureFileNamess) {
    const commonParams = {
      ExtractJson: "",
      PromptJson: "",
      FinalJson: "",
      ValidateJson: "",
      VerifyJson: "",
    };
    const latestData = isFromSuccess
      ? tableData
      : tableData?.filter((row) => failureFileNamess.includes(row.file.name));

    const DocsToVerify = files.map((item, index) => ({
      ...commonParams,
      BlodFileId: item.signData.fileId,
      FileName: item.signData.fileName,
      InputType: latestData[index].detect.code,
      Action: latestData[index].type,
    }));

    const otherPayload = {
      configId: "91234",
      transactionType: "B",
      RunKve: kvExe ? kvExe : "",
      RunLlm: llmExe ? llmExe : "",
      RunValidate: validate ? validate : "",
      RunVerify: verify ? verify : "",
      commonParams: {
        organizationID: details?.orgId,
        companyID: details?.companyId,
        coCode: details?.companyCode,
        groupID: details?.groupID,
        timeZone: details?.timeZone,
        sessionId: details?.sesionId,
        loginUserId: `${details?.userId}`,
        loginUserName: details?.userName,
        AuditTrail: 0,
        editTrail: 0,
        dataSource: "",
      },
      DocsToVerify,
    };
    try {
      const result = await ApiCall(otherPayload);
      if (result.status.code === 200) {
        const message = result?.status?.message;
        const messages = message ? message.split("~") : [];
        const formattedMessage =
          messages.length > 1
            ? messages.map((msg, index) => (
                <React.Fragment key={index}>
                  {msg}
                  {index < messages.length - 1 && <br />}
                </React.Fragment>
              ))
            : message;
        setLoading(false);
        setDialogState({
          open: true,
          title: "success",
          subTitle: formattedMessage,
          type: "single",
        });

        if (!isFromSuccess) {
          setNotify({
            isOpen: true,
            message: "success files are removed",
            type: "warning",
          });
          const updated = [...tableData]?.filter((row) =>
            failureFileNamess.includes(row.file.name)
          );
          setTableData(updated);
        } else {
          setTableData([]);
        }
        setApplySameForAll(false);
        setApplySameForAll1(false);
      } else {
        logTelemetry(
          "Failed",
          "BatchUpload",
          "batchUploadApiCall",
          result.status.message ?? "Presign second API is fail"
        );
        setDialogState({
          open: true,
          title: "Presign Failed",
          subTitle: getErrorMessage(result?.status?.message),
          type: "single",
        });
      }
    } catch (e) {
      logTelemetry("Failed", "batchUpload", "batchUploadApiCall", e.message, e);
      console.error(e.message);
      setDialogState({
        open: true,
        title: "Error",
        subTitle: "batchUploadApiCall : " + e.message,
        type: "single",
      });
    }
  }

  function btnRemoveAllClick() {
    setDialogState({
      open: true,
      title: "Attention Required",
      subTitle:
        "Are you sure you want to remove all files? This action cannot be undone.",
      type: "double",
    });
  }

  function iconPreviewClick(index) {
    setOpen(true);
    setCurrentIndex(index);
  }

  useEffect(() => {
    const accessUploadBatch = {
      UploadBatch: GetSectionRights(
        accessDetails,
        segmentId,
        moduleId,
        StaticModuleClass.DOCUMENT_RECOGNIZER_UPLOAD_BATCH_SECID
      ),
    };
    setSectionAccess(accessUploadBatch);
    const functionAccess = {
      Process: GetConfigurationId(
        accessDetails,
        segmentId,
        moduleId,
        StaticModuleClass.DOCUMENT_RECOGNIZER_UPLOAD_BATCH_SECID,
        StaticModuleClass.DOCUMENT_RECOGNIZER_PROCESS_FUNID
      ),
    };
    setFunctionAccess(functionAccess);
  }, []);

  return (
    <div>
      {/* {sectionAccess.UploadBatch && (
        <> */}
      <Grid container>
        <Grid item xs={12} sm={12} className="my-2 md:mt-8">
          <div>
            <Container>
              <Grid
                container
                alignItems="center"
                justifyContent="space-between"
                // xs={12}
                // sm={12}
                className="my-4"
              >
                <Typography variant="h6">Batch Process</Typography>
                <Box>
                  <Button
                    variant="outlined"
                    endIcon={<ArrowBackOutlined />}
                    className="button-styles btnHeight"
                    // onClick={() =>
                    //   navigate("/patient-list/document-verification")
                    // }
                    onClick={() => {
                      dispatch(
                        setActiveComponent(
                          "/patient-list/document-verification"
                        )
                      );
                    }}
                  >
                    Back
                  </Button>
                </Box>
              </Grid>

              <Grid
                container
                alignItems="center"
                justifyContent="space-between"
                // xs={12}
                // sm={12}
                className="my-2"
              >
                <Typography variant="medium">
                  Selected Files ({tableData.length})
                </Typography>
                <Box>
                  <Button
                    variant="outlined"
                    endIcon={<NoteAddOutlined />}
                    component="label"
                    htmlFor="file-input"
                    className="button-styles btnHeight !mr-3"
                  >
                    Add {tableData.length > 0 ? "More" : null} Documents
                    <input
                      type="file"
                      id="file-input"
                      style={{ display: "none" }}
                      // accept=".pdf,.jpeg,.jpg,.png,.tif,.tiff"
                      onChange={handleFileSelect}
                      multiple
                    />
                  </Button>
                  {functionAccess.Process != 0 && (
                    <>
                      <Button
                        variant="outlined"
                        endIcon={<PostAddOutlined />}
                        className="button-styles btnHeight"
                        onClick={() => btnProcessBatchClick()}
                      >
                        Process Batch
                      </Button>
                    </>
                  )}
                </Box>
              </Grid>

              <Grid container spacing={2} className="pb-4">
                <Grid item xs={12}>
                  <TableContainer
                    sx={{ border: "1px solid #e0e0e0", borderRadius: "4px" }}
                  >
                    <Table>
                      <TableHead>
                        <TableRow
                          sx={{
                            backgroundColor: "#f5f5f5",
                            minWidth: "30px",
                          }}
                        >
                          <TableCell
                            sx={{
                              padding: "8px",
                              width: "2%",
                              textAlign: "center",
                            }}
                          >
                            <IconButton
                              color="error"
                              aria-label="delete"
                              onClick={() => btnRemoveAllClick()}
                              sx={{ marginLeft: "auto" }}
                            >
                              {tableData.length > 1 && (
                                <Tooltip
                                  title="Remove All Files"
                                  placement="top"
                                  arrow
                                >
                                  <DeleteSweepIcon
                                    color="error"
                                    sx={{ cursor: "pointer" }}
                                    onClick={() => btnRemoveAllClick()}
                                  />
                                </Tooltip>
                              )}
                            </IconButton>
                          </TableCell>
                          <TableCell
                            sx={{
                              width: "2%",
                              textAlign: "center",
                              padding: "13px",
                              fontWeight: "700",
                            }}
                          >
                            Preview
                          </TableCell>
                          <TableCell
                            sx={{
                              width: "22%",
                              padding: "13px 6px",
                              fontWeight: "700",
                              textAlign: "left",
                            }}
                          >
                            Document Name
                          </TableCell>

                          {/* <TableCell
                            sx={{
                              width: "10%",
                              textAlign: "center",
                              padding: "13px 6px",
                              fontWeight: "700",
                              textAlign: "left",
                            }}
                          >
                            Select Function to be Performed
                          </TableCell> */}
                          <TableCell
                            sx={{
                              width: "39%",
                              padding: "13px 6px",
                              fontWeight: "700",
                              textAlign: "left",
                            }}
                          >
                            Select Data to be Detected
                          </TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody className="bg-white tableCelBdrClr">
                        {
                          tableData.length > 0 ? (
                            <TableRow>
                              <TableCell
                                sx={{ padding: "8px", width: "2%" }}
                              ></TableCell>
                              <TableCell
                                sx={{ padding: "8px", width: "1%" }}
                              ></TableCell>
                              <TableCell sx={{ padding: "8px", width: "22%" }}>
                                <Box
                                  className="flex justify-between"
                                  gap={0.5}
                                  width={410}
                                >
                                  <TextField
                                    label="KV_EXE"
                                    value={kvExe}
                                    onChange={handleInputChangeLatest(setKvExe)}
                                    inputProps={{
                                      maxLength: 1, // Limit input to a single character
                                    }}
                                    variant="outlined"
                                    size="small"
                                  />
                                  <TextField
                                    label="LLM_EXE"
                                    value={llmExe}
                                    onChange={handleInputChangeLatest(
                                      setLlmExe
                                    )}
                                    inputProps={{
                                      maxLength: 1,
                                    }}
                                    variant="outlined"
                                    size="small"
                                  />
                                  <TextField
                                    label="Validate"
                                    value={validate}
                                    onChange={handleInputChangeLatest(
                                      setValidate
                                    )}
                                    inputProps={{
                                      maxLength: 1,
                                    }}
                                    variant="outlined"
                                    size="small"
                                  />
                                  <TextField
                                    label="Verify"
                                    value={verify}
                                    onChange={handleInputChangeLatest(
                                      setVerify
                                    )}
                                    inputProps={{
                                      maxLength: 1,
                                    }}
                                    variant="outlined"
                                    size="small"
                                  />
                                </Box>
                              </TableCell>

                              {/* <TableCell sx={{ padding: "8px", width: "10%" }}>
                                <FormGroup>
                                  <FormControlLabel
                                    control={
                                      <Checkbox
                                        name="apply all"
                                        id="apply all"
                                        checked={applySameForAll}
                                        onChange={radioBtnChange}
                                      />
                                    }
                                    label="Apply Same for All"
                                  />
                                </FormGroup>
                              </TableCell> */}
                              <TableCell sx={{ padding: "8px", width: "39%" }}>
                                <FormGroup>
                                  <FormControlLabel
                                    control={
                                      <Checkbox
                                        name="apply all1"
                                        id="apply all1"
                                        checked={applySameForAll1}
                                        onChange={radioBtnChange1}
                                      />
                                    }
                                    label="Apply Same for All"
                                  />
                                </FormGroup>
                              </TableCell>
                            </TableRow>
                          ) : (
                            ""
                          )
                          // (
                          //   <Typography variant="subtitle1" gutterBottom>
                          //     Click on Add Documents to Select file's
                          //   </Typography>
                          // )
                        }

                        {tableData.length > 0 &&
                          tableData?.map(({ file, type, detect }, index) => (
                            <TableRow key={index + " " + type + detect}>
                              <TableCell
                                sx={{
                                  padding: "8px",
                                  width: "2%",
                                  textAlign: "center",
                                }}
                              >
                                <IconButton
                                  color="error"
                                  aria-label="delete"
                                  onClick={() => handleFileDelete(file, index)}
                                  sx={{ marginLeft: "auto" }}
                                >
                                  <Delete />
                                </IconButton>
                              </TableCell>
                              <TableCell
                                sx={{
                                  padding: "8px",
                                  width: "2%",
                                  textAlign: "center",
                                }}
                              >
                                <IconButton
                                  aria-label="preview"
                                  onClick={() => iconPreviewClick(index)}
                                >
                                  <Visibility />
                                </IconButton>
                              </TableCell>
                              <TableCell sx={{ padding: "8px", width: "22%" }}>
                                <Box
                                  key={index}
                                  sx={{
                                    display: "flex",
                                    alignItems: "center",
                                  }}
                                >
                                  <Typography variant="body1">
                                    {file?.name}
                                  </Typography>
                                </Box>
                              </TableCell>
                              {/* <TableCell sx={{ padding: "8px", width: "10%" }}>
                                <Autocomplete
                                  key={index + type}
                                  options={options}
                                  value={type || options[0]}
                                  disableClearable
                                  autoSelect={false}
                                  onChange={(event, newValue) =>
                                    handleAutocompleteChange(
                                      event,
                                      newValue,
                                      index
                                    )
                                  }
                                  disabled
                                  renderInput={(params) => (
                                    <TextField
                                      {...params}
                                      variant="outlined"
                                      size="small"
                                      error={processClicked && !type}
                                    />
                                  )}
                                />
                              </TableCell> */}
                              <TableCell sx={{ padding: "8px", width: "39%" }}>
                                <Autocomplete
                                  key={index + detect}
                                  options={optionsDetect}
                                  value={detect}
                                  getOptionLabel={(option) => option.name}
                                  isOptionEqualToValue={(option, value) =>
                                    option.name === value.name
                                  }
                                  disableClearable
                                  autoSelect={false}
                                  onChange={(event, newValue) =>
                                    handleAutocompleteChange1(
                                      event,
                                      newValue,
                                      index
                                    )
                                  }
                                  renderInput={(params) => (
                                    <TextField
                                      {...params}
                                      variant="outlined"
                                      size="small"
                                      error={processClicked && !detect}
                                    />
                                  )}
                                />
                              </TableCell>
                            </TableRow>
                          ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Grid>
              </Grid>
            </Container>
          </div>
        </Grid>
      </Grid>

      <PreviewModal
        open={open}
        setOpen={setOpen}
        handleClose={handleCloseModal}
        handleOpen={handleOpen}
        data={tableData}
        setCurrentIndex={setCurrentIndex}
        currentIndex={currentIndex}
      />
      <Backdrop
        sx={{ color: "#1a73e8", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={loading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <Notification notify={notify} setNotify={setNotify} />
      <ClearStatesConfirmationDialog
        onClose={() => handleClose()}
        onConfirm={handleConfirm}
        open={dialogState.open}
        title={dialogState.title}
        subTitle={dialogState.subTitle}
        type={dialogState.type}
      />
    </div>
  );
};

export default withAITracking(reactPlugin, BatchUpload, "BatchUpload");
