import React, { useEffect, useMemo, useState } from "react";
import { Grid, Button, Box } from "@mui/material";
import IconButton from "@mui/material/IconButton";
import { Refresh, Edit, Add } from "@mui/icons-material";
import { AgGridReact } from "ag-grid-react";
import convertToAgGridFormat from "../../components/utils/convertToAgGridFormat";
import { GetConfigurationId } from "../../components/utils/activeComponent";
import StaticModuleClass from "../../components/utils/staticModuleClass";
import { useSelector } from "react-redux";

const List = ({
  addNewClick,
  refreshClick,
  rows,
  columns,
  setCurrentTab,
  gridRef,
  setNotify,
  loading,
  setReqData,
  isEditable = true,
}) => {

  const defaultColDef = useMemo(
    () => ({
      resizable: true,
      sortable: true,
      // editable: true,
      //   floatingFilter: true,
      // filters: true,
      menuTabs: ["filterMenuTab", "generalMenuTab"],
    }),
    []
  );
  const accessDetails = useSelector((state) => state.accessRights);
  const segmentId = useSelector((state) => state.segmentID);
  const moduleId = useSelector((state) => state.moduleID);
  const [functionAccess, setFunctionAccess] = useState({ New: 0, Edit: 0 });
  const { agGridColumns, agGridRows } = convertToAgGridFormat(rows, columns);

  if (functionAccess.Edit === 0) {
    const editColumnIndex = agGridColumns.findIndex((column) => column.field === "edit");
    if (editColumnIndex > -1) {
      agGridColumns.splice(editColumnIndex, 1);
    }
  } else {
    agGridColumns[0] = {
      ...agGridColumns[0],
      width: 90, 
      sortable: false, 
      filter: false, 
      cellRenderer: (params) => (
        <IconButton
          onClick={() => {
            setReqData({
              sourceFileTypeID: params.data?.sourceFileTypeID,
              recData: params.data,
              fileTypeId: params.data?.fileTypeID,
            });
            setCurrentTab(1);
          }}
        >
          <Edit />
        </IconButton>
      ),
    };
  }


  useEffect(() => {
    const functionAccess = {
      New: GetConfigurationId(accessDetails, segmentId, moduleId,
        StaticModuleClass.DRF_CONFIGURATION_LIST_SECID, StaticModuleClass.DRF_CONFIGURATION_NEW_FUNID),
      Edit: GetConfigurationId(accessDetails, segmentId, moduleId,
        StaticModuleClass.DRF_CONFIGURATION_LIST_SECID, StaticModuleClass.DRF_CONFIGURATION_EDIT_FUNID),
    };
    setFunctionAccess(functionAccess);
    return () => {
      setNotify({
        isOpen: false,
      });
    };
  }, []);

  return (
    <Grid container>
      <Grid item xs={12} sm={12}>
        <Grid
          container
          alignItems="center"
          justifyContent="flex-end"
          className="mb-3"
        >
          <Box>
            {functionAccess.New != 0 && (
              <>
                <Button
                  variant="outlined"
                  color="primary"
                  endIcon={<Add />}
                  onClick={addNewClick}
                  sx={{ marginRight: 1 }}
                  className="button-styles !min-w-[150px]"
                >
                  Add New
                </Button>
              </>
            )}
            <Button
              variant="outlined"
              endIcon={<Refresh />}
              onClick={refreshClick}
              className="button-styles !min-w-[150px] !mr-2"
            >
              Refresh
            </Button>
          </Box>
        </Grid>

        <div className="mt-3">
          <Box className="ag-theme-alpine docVHgt relative">
            {/* <div className="absolute z-10 top-[6px] right-[1px]">
              <Button
                variant="outlined"
                color="primary"
                onClick={btnDownloadCSVClick}
                sx={{ marginRight: 1 }}
                className="button-styles !min-w-[150px]"
              >
                Export CSV
                <i className="fa-solid fa-file-csv fa-lg ml-2"></i>
              </Button>
              <Button
                variant="outlined"
                color="primary"
                onClick={btnDownloadXLClick}
                sx={{ marginRight: 1 }}
                className="button-styles !min-w-[150px]"
              >
                Export XL
                <i className="fa-regular fa-file-excel fa-lg ml-2"></i>
              </Button>
            </div> */}
            <AgGridReact
              ref={gridRef}
              rowData={agGridRows ?? []}
              columnDefs={agGridColumns}
              defaultColDef={defaultColDef}
              pagination={true}
              paginationPageSize={100}
              paginationPageSizeSelector={[100, 250, 500, 1000]}
              alwaysShowVerticalScroll={true}
              alwaysShowHorizontalScroll={true}
              domLayout="autoHeight"
              animateRows={true}
              rowGroupPanelShow="always"
              suppressCopyRowsToClipboard={true}
              loading={loading}
            />
          </Box>
        </div>
      </Grid>
    </Grid>
  );
};

export default List;
