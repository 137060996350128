import {
  Backdrop,
  CircularProgress,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
  Divider,
  Autocomplete,
  Container,
  Box,
  Button,
} from "@mui/material";
import React, { useContext, useMemo, useState } from "react";

import Breadcrumb from "../../components/Breadcrumb";
import { useFormik } from "formik";
import * as yup from "yup";
import { SavePatientInfo } from "../../apis/patientInfo";
import { LocationByServiceTypes } from "../../apis/patientInfo";
import MyContext from "../../components/MyContext";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Notification from "../../components/Notification";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import moment from "moment";
import Stack from "@mui/material/Stack";
import DeleteIcon from "@mui/icons-material/Delete";
import Alert from "@mui/material/Alert";
import { logTelemetry } from "../../logTelemetry";
import { useDispatch, useSelector } from "react-redux";
import { setActiveComponent } from "../../redux/actions";
import { setPatientInfoLookup } from "../../redux/actions/patientInfo";
import { setCosmosId } from "../../redux/actions/patientList";
const textFieldStyles = {
  width: "100%",
  margin: "12px 12px",
};

const PatientInfo = () => {
  const { data, mutateAsync, isLoading } = SavePatientInfo();
  const {
    data: dataServiceType,
    mutateAsync: mutateServiceTypeAsync,
    isLoading: proceedLoading,
  } = LocationByServiceTypes();

  const [personName, setPersonName] = React.useState([]);
  const [firstNameError, setfirstNameError] = useState(false);
  const [lastNameError, setlastNameError] = useState(false);
  const [dateOfBirthError, setdateOfBirthError] = useState(false);
  const [relationShifToInsure, setRelationShifToInsure] = useState(false);
  const dispatch = useDispatch();
  const [serviceTypesName, setserviceTypesName] = React.useState("");

  // const [insuranceDOS, setinsuranceDOS] = useState("");
  // let dataa = JSON.parse(sessionStorage?.getItem("captureData"));

  // let captureDetailsData = JSON.parse(
  //   sessionStorage?.getItem("captureDetailsData")
  // );
  let navigation = useNavigate();
  let captureDetailsData = useSelector((state) => state.captureDetailsData);
  let dataa = useSelector((state) => state.captureData);
  captureDetailsData = JSON.parse(captureDetailsData);
  dataa = JSON.parse(dataa);

  let ExistinsuranceDetails = dataa != null ? dataa.data : captureDetailsData;
  let localinsuranceDetailsObj = {};
  localinsuranceDetailsObj.id =
    dataa != null ? dataa.data.id : captureDetailsData.id;
  localinsuranceDetailsObj.recordSource =
    dataa != null ? dataa.data.recordSource : captureDetailsData.recordSource;
  localinsuranceDetailsObj.eligibilityServiceTypes =
    dataa != null ? "" : captureDetailsData?.eligibilityServiceTypes;
  localinsuranceDetailsObj.insuranceCardFiles =
    dataa != null
      ? dataa.data.insuranceCardFiles
      : captureDetailsData?.insuranceCardFiles;
  localinsuranceDetailsObj.subscriberId =
    dataa != null
      ? dataa.data.subscriberId
      : captureDetailsData?.patientInsurance?.subscriberId;
  localinsuranceDetailsObj.payerName =
    dataa != null
      ? dataa.data.payerName
      : captureDetailsData?.patientInsurance?.payerName;
  localinsuranceDetailsObj.orbitPayerId =
    dataa != null
      ? dataa.data.orbitPayerId
      : captureDetailsData?.patientInsurance?.orbitPayerId;
  localinsuranceDetailsObj.insuranceType =
    dataa != null
      ? dataa.data.insuranceType
      : captureDetailsData?.patientInsurance?.insuranceType;
  localinsuranceDetailsObj.payerId =
    dataa != null
      ? dataa.data.payerId
      : captureDetailsData?.patientInsurance?.payerId;
  localinsuranceDetailsObj.groupNumber =
    dataa != null
      ? dataa.data.groupNumber
      : captureDetailsData?.patientInsurance?.groupNumber;
  localinsuranceDetailsObj.planType =
    dataa != null
      ? dataa.data.planType
      : captureDetailsData?.patientInsurance?.planType;
  localinsuranceDetailsObj.effectiveDate =
    dataa != null
      ? dataa.data.effectiveDate
      : captureDetailsData?.patientInsurance?.effectiveDate;
  localinsuranceDetailsObj.rxBin =
    dataa != null
      ? dataa.data.rxBin
      : captureDetailsData?.patientInsurance?.rxBin;
  localinsuranceDetailsObj.rxId =
    dataa != null
      ? dataa.data.rxId
      : captureDetailsData?.patientInsurance?.rxId;
  localinsuranceDetailsObj.rxPcn =
    dataa != null
      ? dataa.data.rxPcn
      : captureDetailsData?.patientInsurance?.rxPcn;
  localinsuranceDetailsObj.rxId =
    dataa != null
      ? dataa.data.rxGrp
      : captureDetailsData?.patientInsurance?.rxGrp;

  localinsuranceDetailsObj.clearingHousePayerId =
    dataa != null
      ? dataa.data.clearingHousePayerId
      : captureDetailsData?.patientInsurance?.clearingHousePayerId;
  localinsuranceDetailsObj.clearingHousePayerName =
    dataa != null
      ? dataa.data.clearingHousePayerName
      : captureDetailsData?.patientInsurance?.clearingHousePayerName;

  localinsuranceDetailsObj.mobileNumber =
    dataa != null ? "" : captureDetailsData?.phoneNumber;
  localinsuranceDetailsObj.email =
    dataa != null ? "" : captureDetailsData?.emailId;
  localinsuranceDetailsObj.firstName =
    dataa != null ? "" : captureDetailsData?.firstName;
  localinsuranceDetailsObj.lastName =
    dataa != null ? "" : captureDetailsData?.lastName;
  localinsuranceDetailsObj.ssn =
    dataa != null ? dataa.data.ssn : captureDetailsData?.patientInsurance?.ssn;
  localinsuranceDetailsObj.dateOfBirth =
    dataa != null
      ? ""
      : captureDetailsData?.dob == undefined
      ? ""
      : moment(captureDetailsData?.dob).format("MM/DD/YYYY") == "Invalid date"
      ? ""
      : moment(captureDetailsData?.dob).format("MM/DD/YYYY");
  localinsuranceDetailsObj.dateOfServiceStr =
    dataa != null
      ? ""
      : captureDetailsData?.appointmentDate == undefined
      ? ""
      : moment(captureDetailsData?.appointmentDate).format("MM/DD/YYYY") ==
        "Invalid date"
      ? ""
      : moment(captureDetailsData?.appointmentDate).format("MM/DD/YYYY");
  localinsuranceDetailsObj.gender =
    dataa != null ? "" : captureDetailsData?.gender;
  localinsuranceDetailsObj.postalCode =
    dataa != null ? "" : captureDetailsData?.postalCode;
  localinsuranceDetailsObj.stateProvinceCode =
    dataa != null ? "" : captureDetailsData?.stateProvinceCode;
  localinsuranceDetailsObj.cityTown =
    dataa != null ? "" : captureDetailsData?.cityTown;
  localinsuranceDetailsObj.addressLine1 =
    dataa != null ? "" : captureDetailsData?.addressLine1;
  localinsuranceDetailsObj.dateOfServiceStr =
    localinsuranceDetailsObj.dateOfServiceStr == ""
      ? moment(new Date()).format("MM/DD/YYYY")
      : localinsuranceDetailsObj.dateOfServiceStr;

  localinsuranceDetailsObj.insuredFirstName =
    dataa != null
      ? ""
      : captureDetailsData?.patientInsurance?.subscriberFirstName;
  localinsuranceDetailsObj.insuredLastName =
    dataa != null
      ? ""
      : captureDetailsData?.patientInsurance?.subscriberLastName;
  localinsuranceDetailsObj.insuredDOB =
    dataa != null
      ? ""
      : captureDetailsData?.patientInsurance?.subscriberDob == undefined
      ? ""
      : moment(captureDetailsData?.patientInsurance?.subscriberDob).format(
          "MM/DD/YYYY"
        ) == "Invalid date"
      ? ""
      : moment(captureDetailsData?.patientInsurance?.subscriberDob).format(
          "MM/DD/YYYY"
        );
  localinsuranceDetailsObj.insuredGender =
    dataa != null ? "" : captureDetailsData?.patientInsurance?.subscriberGender;
  localinsuranceDetailsObj.insured =
    dataa != null ? "" : captureDetailsData?.patientInsurance?.relationship;
  localinsuranceDetailsObj.insured =
    localinsuranceDetailsObj.insured == undefined
      ? ""
      : localinsuranceDetailsObj.insured == null
      ? ""
      : localinsuranceDetailsObj.insured;
  localinsuranceDetailsObj.payerNameDescription =
    localinsuranceDetailsObj.payerName +
    " ( " +
    localinsuranceDetailsObj.payerId +
    " )";
  // localinsuranceDetailsObj.insuranceDOS=moment(new Date()).format("MM/DD/YYYY");
  const [isReadOnlyFalg, setisReadOnlyFalge] = useState(
    localinsuranceDetailsObj?.subscriberId?.length > 0 ? true : false
  );
  const [open, setOpen] = useState(false);
  const [notify, setNotify] = useState({
    isOpen: false,
    message: "",
    type: "",
  });

  // console.log(dataa);
  // const { details?.companyId, setdetails?.companyId } = useContext(MyContext);

  let maxLength = 10;

  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const setStatesObject = [];
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      },
    },
  };
  const new_value = {
    id: 2,
    firstname: "san",
    sex: "male",
  };

  let num_reg_ex =
    /^(?:-(?:[1-9](?:\d{0,2}(?:,\d{3})+|\d*))|(?:0|(?:[1-9](?:\d{0,2}(?:,\d{3})+|\d*))))(?:.\d+|)$/;
  const breadcrumbItems = [
    { label: "Insurance Verification List", link: "/patient-list" },
    { label: "Upload Image", link: "/patient-list/capture-driving-license" },
    {
      label: "Patient Information",
      link: "/patient-list/capture-driving-license/patient-info",
    },
  ];
  // let details = JSON.parse(sessionStorage?.getItem("details"));
  // let details = useSelector((state) => JSON.parse(state.loginDetails));
  const loginDetails = useSelector((state) => state.loginDetails);

  let details = useMemo(() => {
    if (loginDetails) {
      return JSON.parse(loginDetails);
    }
    return null;
  }, [loginDetails]);

  let locationDetails = JSON.parse(sessionStorage?.getItem("locationDetails"));
  let setLocationName =
    locationDetails != undefined
      ? locationDetails.locationName
      : details.CompanyName;
  let setlocationvalue =
    locationDetails != undefined
      ? locationDetails.locationvalue
      : details?.companyId;
  let locationData = details?.aILocations.map((i) => i.companyName);
  let aIVisitTypeList = details?.aIAllServiceTypeList;

  function formatPhoneNumber(phoneNumberString) {
    var cleaned = ("" + phoneNumberString).replace(/\D/g, "");
    var match = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/);
    if (match) {
      var intlCode = match[1] ? "+1 " : "";
      return [intlCode, "(", match[2], ") ", match[3], "-", match[4]].join("");
    }
    return null;
  }

  function getStyles(name, personName, theme) {
    return {
      fontWeight:
        personName.indexOf(name) === -1
          ? theme.typography.fontWeightRegular
          : theme.typography.fontWeightMedium,
    };
  }

  const onsubscriberIdChange = (event) => {
    localinsuranceDetailsObj.subscriberId = event.target.value;
  };
  const handleChangeVisit = (event) => {
    if (personName.indexOf(event.target.value) > -1) {
      return;
    }
    const {
      target: { value },
    } = event;

    setPersonName(
      // On autofill we get a stringified value.
      typeof value === "string" ? value.split(",") : value
    );
    var stringNames = "";
    for (var k = 0; k <= value.length; k++) {
      if (value[k] != null && value[k] != undefined && value[k] != "") {
        var lstDataObject = aIVisitTypeList?.filter(
          (i) => i.code.replace(" ", "") == value[k].replace(" ", "")
        );
        if (lstDataObject != undefined && lstDataObject.length > 0) {
          stringNames += lstDataObject[0].description + " , ";
        }
      }
    }
    setserviceTypesName(stringNames);
  };

  const handleAutocompleteChange = (event, key) => {
    const fullName = event.target.value.slice(-1).pop();
    const selectedPerson = aIVisitTypeList.find(
      (u) => u.description === fullName
    );
    if (
      selectedPerson &&
      !personName.some((person) => person.description === fullName)
    ) {
      setPersonName((prevArray) => [...prevArray, selectedPerson]);
    } else {
      const updatedArray = personName.filter((p) => p.description !== fullName);
      setPersonName((prevArray) => [...updatedArray]);
    }
  };

  const handleMobileInputChange = (e) => {
    const newValue = e.target.value;

    if (newValue.length <= maxLength) {
      setFieldValue("mobileNumber", newValue);
    }
  };

  const handleNumberInput = (event) => {
    if (
      event.key !== "Backspace" &&
      event.key !== "Delete" &&
      event.key !== "Tab" &&
      !num_reg_ex.test(event.key)
    ) {
      event.preventDefault();
    }
  };

  let schema = yup.object({
    firstName: yup.string().trim(),
    lastName: yup.string().trim(),
    gender: yup.string().trim(),
    dateOfBirth: yup.string(),
    dateOfServiceStr: yup.string(),
    mobileNumber: yup.string().trim(),
    ssn: yup.string().trim(),
    email: yup.string().email("E-Mail is not valid").trim(),
    insured: yup.string(),
    memberid: yup.string(),
    insuredFirstName: yup.string().when("insured", {
      is: (e) => e != "Self",
      then: (schema) => schema.required(),
      otherwise: (schema) => schema.notRequired().nullable(),
    }),
    insuredLastName: yup.string().when("insured", {
      is: (e) => e != "Self",
      then: (schema) => schema.required(),
      otherwise: (schema) => schema.notRequired().nullable(),
    }),
    insuredGender: yup.string().when("insured", {
      is: (e) => e != "Self",
      then: (schema) => schema.required(),
      otherwise: (schema) => schema.notRequired().nullable(),
    }),
    insuredDOB: yup.string().when("insured", {
      is: (e) => e != "Self",
      then: (schema) => schema.required(),
      otherwise: (schema) => schema.notRequired().nullable(),
    }),
  });

  const {
    values,
    handleSubmit,
    handleChange,
    errors,
    touched,
    resetForm,
    setFieldValue,
  } = useFormik({
    initialValues: {
      insured: localinsuranceDetailsObj.insured,
      mobileNumber: localinsuranceDetailsObj.mobileNumber,
      email: localinsuranceDetailsObj.email,
      firstName: localinsuranceDetailsObj.firstName,
      lastName: localinsuranceDetailsObj.lastName,
      dateOfBirth: localinsuranceDetailsObj.dateOfBirth,
      gender: localinsuranceDetailsObj.gender,
      insuredFirstName: localinsuranceDetailsObj.insuredFirstName,
      insuredLastName: localinsuranceDetailsObj.insuredLastName,
      insuredDOB: localinsuranceDetailsObj.insuredDOB,
      insuredGender: localinsuranceDetailsObj.insuredGender,
      dateOfServiceStr: localinsuranceDetailsObj.dateOfServiceStr,
      ssn: localinsuranceDetailsObj.ssn,
      memberid: localinsuranceDetailsObj.subscriberId,
    },
    validationSchema: schema,
  });

  const changeLocationServiceType = async () => {
    let valuess = {
      organizationID: details?.orgId,
      companyID:
        setlocationvalue == null ? details?.companyId : setlocationvalue,
      coCode: details?.companyCode,
      groupID: details?.groupID,
      timeZone: details?.timeZone,
      sessionId: details?.sesionId,
      loginUserId: `${details?.userId}`,
      loginUserName: details?.userName,
      auditTrail: 0,
      editTrail: 0,
      dataSource: "",
      processStatus: "",
      deliveryStatus: "",
      recordId: setlocationvalue,
    };
    try {
      await mutateServiceTypeAsync(valuess);
    } catch (exp) {
      logTelemetry(
        "Exception",
        "patientInfo",
        "changeLocationServiceType",
        `${exp?.message}`,
        exp
      );
    }
  };

  function btnBackClick() {
    dispatch(setActiveComponent("/patient-list/capture-driving-license"));
  }

  const SaveInfoclick = async () => {
    setfirstNameError(false);
    setlastNameError(false);
    setdateOfBirthError(false);
    setRelationShifToInsure(false);
    var i = 0;
    if (values.firstName == "") {
      i = i + 1;
      setfirstNameError(true);
    }
    if (values.lastName == "") {
      i = i + 1;
      setlastNameError(true);
    }
    if (values.dateOfBirth == "") {
      i = i + 1;
      setdateOfBirthError(true);
    }
    if (!values.insured) {
      i = i + 1;
      setRelationShifToInsure(true);
    }
    if (i > 0) {
      return;
    }
    let SubscriberInfo = {
      firstName: values?.insuredFirstName,
      lastName: values?.insuredLastName,
      middleName: "",
      dob: values?.insuredDOB,
      gender: values?.insuredGender,
      addressLine1: "",
      addressLine2: "",
      cityTown: "",
      stateProvinceCode: "",
      postalCode: "",
      countryCode: "",
      phoneNumber: "",
      phoneExtension: "",
      phoneType: null,
      email: "",
      ssn: values?.ssn,
    };
    var isSubmitClickData = false;
    if (
      localinsuranceDetailsObj.eligibilityServiceTypes != undefined &&
      localinsuranceDetailsObj.eligibilityServiceTypes != null &&
      localinsuranceDetailsObj.eligibilityServiceTypes != ""
    ) {
      var splitDataArray =
        localinsuranceDetailsObj.eligibilityServiceTypes.split(",");
      if (splitDataArray?.length - 1 != personName?.length) {
        localinsuranceDetailsObj.id = "";
        isSubmitClickData = true;
      } else {
        for (var k = 0; k <= personName?.length - 1; k++) {
          const isInArray = splitDataArray.includes(
            personName[k].code.replace(" ", "")
          );
          if (isInArray == false) {
            localinsuranceDetailsObj.id = "";
            isSubmitClickData = true;
            break;
          }
        }
      }
    } else {
      isSubmitClickData = true;
    }

    if (
      localinsuranceDetailsObj.dateOfServiceStr != undefined &&
      localinsuranceDetailsObj.dateOfServiceStr != null &&
      localinsuranceDetailsObj.dateOfServiceStr != ""
    ) {
      var dateOfService = new Date(localinsuranceDetailsObj.dateOfServiceStr);
      if (
        values.dateOfServiceStr != undefined &&
        values.dateOfServiceStr != null
      ) {
        var dateOfServiceLocal = new Date(values.dateOfServiceStr);

        if (dateOfServiceLocal < dateOfService) {
          localinsuranceDetailsObj.id = "";
          isSubmitClickData = true;
        } else if (dateOfServiceLocal > dateOfService) {
          localinsuranceDetailsObj.id = "";
          isSubmitClickData = true;
        }
      }
    } else {
      isSubmitClickData = true;
    }

    if (isSubmitClickData == false) {
      setNotify({
        isOpen: true,
        message:
          "This record is already processed same data with multiple times. Can not re-process again, please send data as a new/fresh request.",
        type: "error",
      });
      return;
    }

    if (values?.gender == null || values?.gender == undefined) {
      values.gender = "";
    }

    let SubscriberInfoNull = null;
    let serviceTypesobject = "";
    // var chkMHFlag=false;
    // if(setlocationvalue=="4249")
    // {
    // serviceTypesobject="98,30,96,MH";
    // chkMHFlag=true;
    // }
    for (var k = 0; k <= personName.length - 1; k++) {
      var locServiceType = personName[k].code.replace(" ", "");
      if (locServiceType != null && locServiceType != "") {
        if (serviceTypesobject.length == 0) {
          serviceTypesobject = locServiceType.replace(" ", "");
        } else {
          serviceTypesobject =
            serviceTypesobject + "," + locServiceType.replace(" ", "");
        }
      }
    }

    var dateOfServiceStr = "";
    if (
      values.dateOfServiceStr != undefined &&
      values.dateOfServiceStr != null &&
      values.dateOfServiceStr != null
    ) {
      dateOfServiceStr = new Date(values.dateOfServiceStr);
    } else {
      dateOfServiceStr = new Date();
    }
    let valuess = {
      recordSource: localinsuranceDetailsObj.recordSource,
      id:
        localinsuranceDetailsObj.id == undefined
          ? ""
          : localinsuranceDetailsObj.id == null
          ? ""
          : localinsuranceDetailsObj.id,
      serviceTypes: serviceTypesobject,
      commonParams: {
        organizationID: details?.orgId,
        companyID:
          setlocationvalue == null
            ? details?.companyId
            : setlocationvalue == ""
            ? details?.companyId
            : setlocationvalue,
        coCode: details?.companyCode,
        groupID: details?.groupID,
        timeZone: details?.timeZone,
        sessionId: details?.sesionId,
        loginUserId: `${details?.userId}`,
        loginUserName: details?.userName,
        auditTrail: 0,
        editTrail: 0,
        dataSource: "",
        processStatus: "",
        deliveryStatus: "",
      },
      PatientDto: {
        id:
          localinsuranceDetailsObj.id == undefined
            ? ""
            : localinsuranceDetailsObj.id == null
            ? ""
            : localinsuranceDetailsObj.id,
        externalId:
          localinsuranceDetailsObj.id == undefined
            ? ""
            : localinsuranceDetailsObj.id == null
            ? ""
            : localinsuranceDetailsObj.id,
        referenceId: "",
        serviceTypes: serviceTypesobject,
        orgId: 0,
        isModifyPayerId: 0,
        companyId: 0,
        batchId: "",
        ediProcessType: "1",
        tpImageSeqNbr: 0,
        tpImageSubSeqNbr: 0,
        registrationId: "",
        appointmentId: "",
        vaccinationId: "",
        requestId: 0,
        storeNumber: "",
        imageCaptureMethod: "",
        imageTypeDetails: "",
        imageCropMethod: "",
        locationNumber: setlocationvalue,
        patientInfo: {
          firstName: values?.firstName ? values.firstName : "",
          lastName: values?.lastName ? values?.lastName : "",
          middleName: "",
          gender: values?.gender ? values?.gender : "",
          dob: values?.dateOfBirth ? values?.dateOfBirth : "",
          addressLine1: "",
          cityTown: "",
          stateProvinceCode: "",
          postalCode: "",
          countryCode: "US",
          phoneNumber: values?.mobileNumber
            ? values?.mobileNumber
            : ""
                ?.replace("(", "")
                .replace(")", "")
                .replace("-", "")
                .replace(" ", ""),
          phoneExtension: "",
          phoneType: "Mobile",
          email: values?.email ? values?.email : "",
          ssn: values?.ssn ? values?.ssn : "",
        },
        firstVisit: {
          visitDateTime: dateOfServiceStr,
        },
        secondVisit: null,
        idCardFiles: null,
        insuranceCardFiles:
          localinsuranceDetailsObj.insuranceCardFiles == null
            ? null
            : [
                {
                  fileId:
                    localinsuranceDetailsObj.insuranceCardFiles[0]?.fileId,
                  fileName:
                    localinsuranceDetailsObj.insuranceCardFiles[0]?.fileName,
                  fileExt: null,
                  fileSize: 87119,
                  width: 0,
                  height: 0,
                  dpi: 300,
                  ocrQuality: 95,
                  cardFace: "Front",
                },
                {
                  fileId:
                    localinsuranceDetailsObj.insuranceCardFiles[1]?.fileId,
                  fileName:
                    localinsuranceDetailsObj.insuranceCardFiles[1]?.fileName,
                  fileExt: "jpg",
                  fileSize: 97334,
                  width: 0,
                  height: 0,
                  dpi: 300,
                  ocrQuality: 95,
                  cardFace: "Back",
                },
              ],
        patientInsurance: {
          subscriberId: values?.memberid,
          payerName: localinsuranceDetailsObj.payerName,
          onabortrbitPayerId: localinsuranceDetailsObj.orbitPayerId,
          insuranceType: localinsuranceDetailsObj.insuranceType,
          payerId: `${localinsuranceDetailsObj.payerId}`,
          planNumber: "",
          groupNumber: localinsuranceDetailsObj.groupNumber,
          planType: localinsuranceDetailsObj.PlanType,
          effectiveDate: localinsuranceDetailsObj.effectiveDate,
          clickoPayment: "",
          dataaeductible: 0,
          rxBin: localinsuranceDetailsObj.rxBin,
          rxId: localinsuranceDetailsObj.RxId,
          rxPcn: localinsuranceDetailsObj.RxPcn,
          rxGrp: localinsuranceDetailsObj.RxGrp,
          employerName: "",
          priority: "Primary",
          relationship: values.insured,
          subscriberInfo:
            values.insured == "Self" ? SubscriberInfoNull : SubscriberInfo,
        },
      },
    };
    valuess.PatientDto.insuranceCardFiles =
      localinsuranceDetailsObj.insuranceCardFiles?.length == 0
        ? null
        : valuess.PatientDto.insuranceCardFiles;
    if (!values.firstName) {
      setNotify({
        isOpen: true,
        message: "Enter first name",
        type: "error",
      });
    } else if (!values.ssn && !values.memberid) {
      setNotify({
        isOpen: true,
        message: "Enter SSN or Member Id",
        type: "error",
      });
    } else if (!values.lastName) {
      setNotify({
        isOpen: true,
        message: "Enter last name",
        type: "error",
      });
    } else if (!values.dateOfBirth) {
      setNotify({
        isOpen: true,
        message: "Enter date of birth",
        type: "error",
      });
    } else if (!values.insured) {
      setNotify({
        isOpen: true,
        message: "Select the patient relationship to insured",
        type: "error",
      });
    } else {
      try {
        await mutateAsync(valuess);
      } catch (exp) {
        logTelemetry(
          "Exception",
          "patientInfo",
          "saveInfoClick",
          `${exp?.message}`,
          exp
        );
      }
    }
  };

  const deleteServiceType = async (selVal) => {
    const updatedArray = personName.filter(
      (p) => p.code.replace(" ", "") != selVal.replace(" ", "")
    );
    setPersonName((prevArray) => [...updatedArray]);
  };
  useEffect(() => {
    if (dataServiceType) {
      if (dataServiceType?.status && dataServiceType?.status?.code != 200) {
        logTelemetry(
          "Failed",
          "patientInfo",
          "useEffect 730",
          `${dataServiceType?.status?.message}`
        );
        setNotify({
          isOpen: true,
          message: `${dataServiceType?.status?.message}`,
          type: "error",
        });
      }

      if (dataServiceType?.status && dataServiceType?.status?.code == 200) {
        if (dataServiceType?.data && dataServiceType?.data != null) {
          var stringNames = "";
          var stringCodes = "";
          setPersonName([]);
          for (var k = 0; k <= dataServiceType?.data.length - 1; k++) {
            const new_value = {
              description: dataServiceType?.data[k].description,
              code: dataServiceType?.data[k].code,
            };
            if (stringCodes.length == 0) {
              stringCodes = dataServiceType?.data[k].code;
            } else {
              stringCodes = stringCodes + "," + dataServiceType?.data[k].code;
            }
            setPersonName((prevState) => [...prevState, new_value]);
          }
          localinsuranceDetailsObj.eligibilityServiceTypes = stringCodes;
        }
      }
    }
  }, [dataServiceType]);

  useEffect(() => {
    if (data) {
      if (data?.status && data?.status?.code != 200) {
        logTelemetry(
          "Failed",
          "patientInfo",
          "useEffect 778",
          `${data?.status?.message}`
        );
        setNotify({
          isOpen: true,
          message: `${data?.status?.message}`,
          type: "error",
        });
      } else {
        if (data?.status && data?.status?.code == 200) {
          sessionStorage.setItem(
            "setCosmosKeyID",
            JSON.stringify(data.data.id)
          );

          dispatch(setCosmosId(data.data.id));
          dispatch(setActiveComponent("/patient-list/patient-details"));
          return;
        }
      }
    }
    if (data) {
      if (data?.response && data?.response?.status != 200) {
        logTelemetry(
          "Failed",
          "patientInfo",
          "useEffect 790",
          `${data?.response?.message}`
        );
        setNotify({
          isOpen: true,
          message: `${data?.response?.message}`,
          type: "error",
        });
      } else {
        dispatch(setPatientInfoLookup(data.data.id));
        dispatch(setActiveComponent("/PATIENT-LIST/PATIENT-DETAILS"));
      }
    }
    if (data == undefined) {
      if (
        localinsuranceDetailsObj.eligibilityServiceTypes != undefined &&
        localinsuranceDetailsObj.eligibilityServiceTypes != null &&
        localinsuranceDetailsObj.eligibilityServiceTypes != "" &&
        localinsuranceDetailsObj.eligibilityServiceTypes.length > 0
      ) {
        setPersonName([]);
        var splitDataArray =
          localinsuranceDetailsObj.eligibilityServiceTypes.split(",");
        var stringNames = "";
        for (var k = 0; k <= splitDataArray?.length; k++) {
          var lstDataObject = aIVisitTypeList?.filter(
            (i) => i.code.replace(" ", "") == splitDataArray[k]
          );
          if (lstDataObject?.length > 0) {
            const new_value = {
              description: lstDataObject[0].description,
              code: lstDataObject[0].code,
            };
            setPersonName((prevState) => [...prevState, new_value]);
          }
        }
      } else {
        changeLocationServiceType();
      }
    }
  }, [data]);

  return (
    // <Breadcrumb items={breadcrumbItems} isRedux={true} />
    <div>
      <Container maxWidth="xl">
        <Grid container>
          <Grid item={true} xs={12} sm={12}>
            <Grid container>
              <Grid
                item={true}
                xs={12}
                sm={12}
                className="flex justify-between !my-4"
              >
                <Typography variant="h6">Patient Information</Typography>
                <Box>
                  <>
                    <Button
                      className="button-styles !mr-2 !w-[120px]"
                      variant="outlined"
                      onClick={SaveInfoclick}
                    >
                      Submit
                    </Button>
                    <Button
                      className="button-styles !w-[120px]"
                      variant="outlined"
                      onClick={() => btnBackClick()}
                    >
                      Back
                    </Button>
                  </>
                </Box>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Card>
          <CardContent>
            <Grid container>
              {(firstNameError ||
                lastNameError ||
                dateOfBirthError ||
                relationShifToInsure) && (
                <Grid xs={12} sm={12}>
                  <Alert severity="error">Required Fields Missing !</Alert>
                </Grid>
              )}
              <Grid
                item
                fullWidth
                xs={12}
                sm={6}
                className="flex justify-center"
              >
                <TextField
                  InputProps={{
                    readOnly: true,
                  }}
                  id="outlined-password-input"
                  label="Location"
                  name="Location"
                  value={setLocationName}
                  className="m-0"
                  size="small"
                  style={textFieldStyles}
                />
              </Grid>
              <Grid
                item
                fullWidth
                xs={12}
                sm={6}
                className="flex justify-center"
              >
                <TextField
                  InputProps={{
                    readOnly: true,
                  }}
                  id="outlined-password-input"
                  label="Payer Name (Payer Code)"
                  name="Payer Name (Payer Code)"
                  value={localinsuranceDetailsObj?.payerNameDescription}
                  className="m-0"
                  size="small"
                  style={textFieldStyles}
                />
              </Grid>
              <Grid
                item
                fullWidth
                xs={12}
                sm={6}
                className="flex justify-center"
              >
                <TextField
                  InputProps={{
                    readOnly: isReadOnlyFalg,
                  }}
                  id="outlined-password-input"
                  label="Subscriber/ Member ID"
                  name="Subscriber/ Member ID"
                  value={values?.memberid}
                  onChange={handleChange("memberid")}
                  className="m-0"
                  size="small"
                  style={textFieldStyles}
                />
              </Grid>
              <Grid
                item
                fullWidth
                xs={12}
                sm={6}
                className="flex justify-center"
              >
                <TextField
                  id="outlined-ssn-input"
                  label="Social Security No"
                  onKeyDown={(e) => {
                    var val = e.target.value.replace(/\D/g, "");
                    var newVal = "";
                    if (val.length > 4) {
                      e.target.value = val;
                    }
                    if (val.length > 3 && val.length < 6) {
                      newVal += val.substr(0, 3) + "-";
                      val = val.substr(3);
                    }
                    if (val.length > 5) {
                      newVal += val.substr(0, 3) + "-";
                      newVal += val.substr(3, 2) + "-";
                      val = val.substr(5);
                    }
                    newVal += val;
                    e.target.value = newVal.substring(0, 11);
                    values.ssn = e.target.value;
                  }}
                  name="ssn"
                  className="m-0"
                  value={values.ssn}
                  onChange={handleChange("ssn")}
                  style={textFieldStyles}
                  size="small"
                />
              </Grid>
              <Grid
                item
                fullWidth
                xs={12}
                sm={6}
                className="flex justify-center"
              >
                <TextField
                  id="outlined-password-inpuDSt"
                  label="Date Of Service (MM/DD/YYYY)"
                  name="Date Of Service"
                  placeholder="MM/DD/YYYY"
                  value={values.dateOfServiceStr}
                  onChange={handleChange("dateOfServiceStr")}
                  className="m-0"
                  size="small"
                  style={textFieldStyles}
                />
              </Grid>
              <Grid
                item
                fullWidth
                xs={12}
                sm={6}
                className="flex justify-center"
              >
                {/* <Autocomplete
            disablePortal
            id="combo-box-demo"
            options={aIVisitTypeList}
            size="small"
            // sx={{ width: 160 }}
            style={textFieldStyles}
            fullWidth
            renderInput={(params) => <TextField {...params} label="Service Types" />}
          /> */}
                <Grid container>
                  <Grid
                    item
                    fullWidth
                    xs={12}
                    sm={12}
                    className="flex justify-center"
                  >
                    <Autocomplete
                      id="country-select-demo"
                      size="small"
                      fullWidth
                      onChange={(event, fullName) => {
                        if (fullName == null) {
                          return null;
                        } else {
                          const selectedPerson = aIVisitTypeList.find(
                            (u) =>
                              u.code.replace(" ", "") ==
                              fullName.code.replace(" ", "")
                          );
                          if (
                            selectedPerson &&
                            !personName.some(
                              (person) =>
                                person.code.replace(" ", "") ==
                                fullName.code.replace(" ", "")
                            )
                          ) {
                            setPersonName((prevArray) => [
                              ...prevArray,
                              selectedPerson,
                            ]);
                          } else {
                            // const updatedArray = personName.filter( p=> p.code.replace(" ","") != fullName.code.replace(" ",""))
                            // setPersonName(prevArray => [...updatedArray])
                          }
                          return null;
                        }
                      }}
                      options={aIVisitTypeList}
                      getOptionLabel={(option) =>
                        "(" + option.code + ") " + option.description
                      }
                      getOptionSelected={(option, value) =>
                        option.code === value.code
                      }
                      autoHighlight
                      style={textFieldStyles}
                      renderInput={(params) => (
                        <TextField {...params} label="Service Types" />
                      )}
                    />
                  </Grid>
                  <Grid
                    item
                    fullWidth
                    xs={12}
                    sm={12}
                    className="flex justify-center"
                  ></Grid>
                  <Stack
                    spacing={{ xs: 1, sm: 1, pl: "20px" }}
                    direction="row"
                    useFlexGap
                    flexWrap="wrap"
                  >
                    {personName?.map((i) => {
                      return (
                        <span
                          style={{
                            color: "#564f2a",
                            paddingLeft: "20px",
                            background: "aliceblue",
                          }}
                          sx={{ textAlign: "left", pl: "20px" }}
                        >
                          {i.description}{" "}
                          <DeleteIcon
                            color="primary"
                            onClick={() => {
                              deleteServiceType(i.code);
                            }}
                            sx={{ "&amp;:hover": { color: "blue" } }}
                          />
                        </span>
                      );
                    })}
                  </Stack>
                </Grid>
              </Grid>

              {/* Location :<Typography  sx={{ fontWeight: "bold",paddingTop:"2px",paddingLeft:"4px"}}  gutterBottom><u>{setLocationName}</u> </Typography> */}
            </Grid>
            <Grid container>
              <Typography sx={{ fontWeight: "bold" }} gutterBottom>
                Patient Details
              </Typography>
              <Divider />
              <Grid container>
                <Grid
                  item
                  fullWidth
                  xs={12}
                  sm={6}
                  className="flex justify-center"
                >
                  <TextField
                    id="outlined-password-input"
                    error={
                      firstNameError == true
                        ? values.firstName == ""
                          ? true
                          : false
                        : false
                    }
                    label="First Name"
                    name="First Name"
                    className="m-0"
                    value={values.firstName}
                    onChange={handleChange("firstName")}
                    style={textFieldStyles}
                    size="small"
                  />
                </Grid>
                <Grid
                  item
                  fullWidth
                  xs={12}
                  sm={6}
                  className="flex justify-center"
                >
                  <TextField
                    id="outlined-password-input"
                    label="Last Name"
                    name="Last Name"
                    className="m-0"
                    error={
                      lastNameError == true
                        ? values.lastName == ""
                          ? true
                          : false
                        : false
                    }
                    value={values.lastName}
                    onChange={handleChange("lastName")}
                    style={textFieldStyles}
                    size="small"
                  />
                </Grid>
                <Grid
                  item
                  fullWidth
                  xs={12}
                  sm={6}
                  className="flex justify-center"
                >
                  {/* <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DatePicker
              className="m-0"
              sx={{ margin: "12px" }}
              value={values.dateOfBirth}
              slotProps={{ textField: { fullWidth: true } }}
              placeholder="Date of Birth"
              label="Date of Birth"
              onChange={(e) => {
                setFieldValue("dateOfBirth", e);
              }}
              inputFormat="dd-MM-yyyy"
              renderInput={(params) => <TextField {...params} />}
            />
          </LocalizationProvider> */}
                  <TextField
                    id="outlined-password-input"
                    label="Date of Birth (MM/DD/YYYY)"
                    name="Date of Birth"
                    className="m-0"
                    value={values.dateOfBirth}
                    onChange={handleChange("dateOfBirth")}
                    error={
                      dateOfBirthError == true
                        ? values.dateOfBirth == ""
                          ? true
                          : false
                        : false
                    }
                    placeholder="MM/DD/YYYY"
                    size="small"
                    // error={errors.Location && touched.Location}
                    // className="m-0"
                    // onKeyDown={handlePrice}
                    style={textFieldStyles}
                  />
                </Grid>
                <Grid
                  item
                  fullWidth
                  xs={12}
                  sm={6}
                  className="flex justify-center"
                >
                  <FormControl style={textFieldStyles}>
                    <InputLabel id="demo-simple-select-label">
                      Gender
                    </InputLabel>
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      value={values.gender}
                      onChange={handleChange("gender")}
                      label="Gender"
                      size="small"
                    >
                      <MenuItem value={"Male"}>Male</MenuItem>
                      <MenuItem value={"Female"}>Female</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>

                <Grid
                  item
                  fullWidth
                  xs={12}
                  sm={6}
                  className="flex justify-center"
                >
                  {/* <TextField
            id="outlined-password-input"
            label="Mobile Number"
            name="Mobile Number"
            className="m-0"
            value={values.mobileNumber}
            handleChange={handleChange("mobileNumber")}
            style={textFieldStyles}
          /> */}
                  <TextField
                    id="outlined-password-input"
                    label="Mobile Number"
                    name="Mobile Number"
                    className="m-0"
                    value={values.mobileNumber}
                    onChange={handleMobileInputChange}
                    style={textFieldStyles}
                    onKeyDown={handleNumberInput}
                    size="small"
                  />
                </Grid>
                <Grid
                  item
                  fullWidth
                  xs={12}
                  sm={6}
                  className="flex justify-center"
                >
                  <TextField
                    id="outlined-password-input"
                    label="E-Mail Address"
                    name="E-Mail Address"
                    className="m-0"
                    value={values.email}
                    onChange={handleChange("email")}
                    style={textFieldStyles}
                    size="small"
                  />
                </Grid>

                <Grid item fullWidth xs={12} className="flex justify-center">
                  <FormControl style={textFieldStyles}>
                    <InputLabel id="demo-simple-select-label">
                      Patient Relationship To Insured
                    </InputLabel>
                    <Select
                      error={
                        relationShifToInsure == true
                          ? values.insured == ""
                            ? true
                            : false
                          : false
                      }
                      labelId="demo-simple-select-label"
                      size="small"
                      id="demo-simple-select"
                      value={values.insured}
                      label="Patient Relationship To Insured"
                      onChange={(e) => setFieldValue("insured", e.target.value)}
                    >
                      <MenuItem value={"Self"}>Self</MenuItem>
                      <MenuItem value={"Spouse"}>Spouse</MenuItem>
                      <MenuItem value={"Child"}>Child</MenuItem>
                      <MenuItem value={"OtherAdult"}>
                        Other Relationship
                      </MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
                {values.insured != "Self" && values.insured != "" ? (
                  <Grid container>
                    <Grid
                      item
                      fullWidth
                      xs={12}
                      sm={6}
                      className="flex justify-center"
                    >
                      <TextField
                        id="outlined-password-input"
                        label="Subscriber First Name"
                        name="First Name"
                        value={values.insuredFirstName}
                        onChange={handleChange("insuredFirstName")}
                        className="m-0"
                        size="small"
                        style={textFieldStyles}
                      />
                    </Grid>
                    <Grid
                      item
                      fullWidth
                      xs={12}
                      sm={6}
                      className="flex justify-center"
                    >
                      <TextField
                        id="outlined-password-input"
                        label="Subscriber Last Name"
                        name="Last Name"
                        className="m-0"
                        size="small"
                        value={values.insuredLastName}
                        onChange={handleChange("insuredLastName")}
                        style={textFieldStyles}
                      />
                    </Grid>
                    <Grid
                      item
                      fullWidth
                      xs={12}
                      sm={6}
                      className="flex justify-center"
                    >
                      <FormControl style={textFieldStyles}>
                        <InputLabel id="demo-simple-select-label">
                          Gender
                        </InputLabel>
                        <Select
                          labelId="demo-simple-select-label"
                          id="demo-simple-select"
                          // value={insuredState}
                          value={values.insuredGender}
                          onChange={handleChange("insuredGender")}
                          label="Subscriber Gender"
                          size="small"
                          // onChange={(e) => setInsuredState(e.target.value)}
                        >
                          <MenuItem value={"Male"}>Male</MenuItem>
                          <MenuItem value={"Female"}>Female</MenuItem>
                        </Select>
                      </FormControl>
                    </Grid>
                    <Grid
                      item
                      fullWidth
                      xs={12}
                      sm={6}
                      className="flex justify-center"
                    >
                      {/* <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                  className="m-0"
                  sx={{ margin: "12px" }}
                  value={values.insuredDOB}
                  slotProps={{ textField: { fullWidth: true } }}
                  placeholder="Date of Birth"
                  label="Date of Birth"
                  onChange={(e) => {
                    setFieldValue("insuredDOB", e);
                  }}
                  inputFormat="dd-MM-yyyy"
                  renderInput={(params) => <TextField {...params} />}
                />
              </LocalizationProvider> */}
                      <TextField
                        id="outlined-password-input"
                        label="Subscriber Date of Birth (MM/DD/YYYY)"
                        name="Date of Birth"
                        className="m-0"
                        value={values.insuredDOB}
                        onChange={handleChange("insuredDOB")}
                        placeholder="MM/DD/YYYY"
                        size="small"
                        // error={errors.Location && touched.Location}
                        // className="m-0"
                        // onKeyDown={handlePrice}
                        style={textFieldStyles}
                      />
                    </Grid>
                  </Grid>
                ) : null}
              </Grid>{" "}
            </Grid>
          </CardContent>
        </Card>
      </Container>
      <Backdrop
        sx={{ color: "#1a73e8", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={isLoading || proceedLoading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <Notification notify={notify} setNotify={setNotify} />
    </div>
  );
};

export default PatientInfo;
