import { combineReducers } from "redux";

import {
  lookUpDataReducer,
  dIdReducer,
  filteredDataReducer,
  documentCombinedReducer,
  // setFormDataReducer,
  // setPageModelReducer,
  // setFilterModelReducer,
  // setSortModelReducer,
  // setFiltersAppliedReducer,
} from "./documentRecognizerReducer";
import { activeComponentReducer } from "./homeReducer";
import {
  // cosmosIdReducer,
  patientFilteredDataReducer,
  // commonReducer,
} from "./patientListReducer";
import {
  captureDataReducer,
  captureDetailsDataReducer,
  captureSMSDataReducer,
  ClaimStatusDataReducer,
} from "./patientDeatailsReducer";
// import { reportsReducer } from "./reportsReducer";
import { previousComponentReducer } from "./previousReducer";
import {
  loginDetailsReducer,
  loginTokenReducer,
  setAccessRights,
} from "./loginDeatailsReducer";
import ClaimStatusDetails from "../../pages/claimSubmit/ClaimStatus";
import { patientReducer } from "./patientDataReducer";
import { smsIdReducer } from "./smsIdReducer";
import { previousSegmentIdReducer, segmentIdReducer } from "./segmentIdReducer";
import { currentModuleIdReducer, moduleIdReducer, previousModuleIdReducer } from "./moduleIdReducer";
import { sectionIdReducer } from "./sectionIdReducer";
import { fromDateReducer, orderStatusDescriptionReducer, orderStatusIdReducer, orderSubStatusDescriptionReducer, orderSubStatusIdReducer, toDateReducer } from "./orderIdReducer";
import { isFromLocationReducer } from "./locationReducer";

const rootReducer = combineReducers({
  activeComponent: activeComponentReducer,
  // activeComponentData: documentCombinedReducer,
  // lookUpData: lookUpDataReducer,
  // dId: dIdReducer,
  // filteredData: filteredDataReducer,
  // cosmosId: cosmosIdReducer,
  // patientFilteredData: patientFilteredDataReducer,
  // reportsData: reportsReducer,
  previousComponent: previousComponentReducer,
  loginDetails: loginDetailsReducer,
  accessRights: setAccessRights,
  loginToken: loginTokenReducer,
  captureData: captureDataReducer,
  captureDetailsData: captureDetailsDataReducer,
  captureSMSData: captureSMSDataReducer,
  ClaimStatusDetails: ClaimStatusDataReducer,
  patientInfoRecordId: patientReducer,
  sentSMSID: smsIdReducer,
  segmentID: segmentIdReducer,
  moduleID: moduleIdReducer,
  sectionID: sectionIdReducer,
  currentModuleID: currentModuleIdReducer,
  previousModuleID: previousModuleIdReducer,
  previousSegmentID: previousSegmentIdReducer,
  orderStatusID:orderStatusIdReducer,
  orderStatusDescription:orderStatusDescriptionReducer,
  orderSubStatusID:orderSubStatusIdReducer,
  orderSubStatusDescription:orderSubStatusDescriptionReducer,
  documentSummaryFromDate:fromDateReducer,
  documentSummaryToDate:toDateReducer,
  // formData: setFormDataReducer,
  // pageModel: setPageModelReducer,
  // sortModel: setSortModelReducer,
  // filterModel: setFilterModelReducer,
  // filtersApplied: setFiltersAppliedReducer,
  // patientListData: commonReducer,
  isFromLocation: isFromLocationReducer,
});

export default rootReducer;
