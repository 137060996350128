import React, { useState, useCallback, useRef, useMemo } from "react";
import { useNavigate } from "react-router-dom";
import {
  Box,
  Grid,
  Button as MuiButton,
  Badge,
  InputBase,
  Divider,
  IconButton,
  Paper,
  Typography,
  Button,
} from "@mui/material";
import DialogBox from "../../components/DialogBox";
import Dialogs from "../../components/DocDialogs";
import FilterAltIcon from "@mui/icons-material/FilterAlt";
import { UpdateAllPatientList } from "../../apis/patientList";
import SearchIcon from "@mui/icons-material/Search";
import RefreshIcon from "@mui/icons-material/Refresh";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { useEffect } from "react";
import { useContext } from "react";
// import MyContext from "../../components/MyContext";
import Notification from "../../components/Notification";
import FilterListIcon from "@mui/icons-material/FilterList";
import ClearAllIcon from "@mui/icons-material/Clear";
import UploadIcon from "@mui/icons-material/Upload";
import Tooltip from "@mui/material/Tooltip";
import dayjs from "dayjs";
import { AgGridReact } from "ag-grid-react";
import { ChevronLeft, ChevronRight } from "@mui/icons-material";
import { logTelemetry } from "../../logTelemetry";
import ClearIcon from "@mui/icons-material/Clear";
import FirstPageIcon from "@mui/icons-material/FirstPage";
import LastPageIcon from "@mui/icons-material/LastPage";
import ClearStatesConfirmationDialog from "../importModule/ClearStatesConfirmationDialog";
import { DocumentContext } from "../../components/utils/DocumentContextProvider";
import { setActiveComponent } from "../../redux/actions";
import { useDispatch, useSelector } from "react-redux";
import {
  setCosmosId,
  setPatientFilteredData,
  setPatientSortModel,
  setFilters,
  setIsFilters,
  setPaginationModel,
  setPatientFilterModel,
} from "../../redux/actions/patientList";
import { resetPatientData } from "../../redux/actions/patientDetails";
import { persistor } from "../../redux/store";
import { GetConfigurationId, GetSectionRights } from "../../components/utils/activeComponent";
import formatDateStamp from "../../components/utils/getDateStamp";
import StaticModuleClass from "../../components/utils/staticModuleClass";
// import { useSelector } from "react-redux";
const spanPendingStyles = {
  color: "#8e2e2e",
  background: "#fce4e4",
  padding: "3px 8px",
  borderRadius: "4px",
};
const spanVerifiedStyles = {
  color: "#1e571e",
  background: "#e3fbe3",
  padding: "3px 8px",
  borderRadius: "4px",
};
const spanUnverifiedStyles = {
  color: "#5c5909",
  background: "#f0ec8d",
  padding: "3px 8px",
  borderRadius: "4px",
};
const spanDeliveredStyles = {
  color: "#08404d",
  background: "#b7ecf7",
  padding: "3px 8px",
  borderRadius: "4px",
};

const PatientList = () => {
  const dispatch = useDispatch();
  const accessDetails = useSelector((state) => state.accessRights);
  const segmentId = useSelector((state) => state.segmentID);
  const moduleId = useSelector((state) => state.moduleID);
  const currentComponentData = useSelector(
    (state) => state?.previousComponent?.currentComponentData || {}
  );

  const filters = useMemo(
    () => currentComponentData.filters || {},
    [currentComponentData.filters]
  );
  const paginationModel = useMemo(
    () => currentComponentData.paginationModel,
    [currentComponentData.paginationModel]
  );
  const isFilters = useMemo(
    () => currentComponentData.isFilters,
    [currentComponentData.isFilters]
  );
  const patientFilterModel = useMemo(
    () => currentComponentData.patientFilterModel,
    [currentComponentData.patientFilterModel]
  );
  const patientSortModel = useMemo(
    () => currentComponentData.patientSortModel,
    [currentComponentData.patientSortModel]
  );

  // let details = JSON.parse(sessionStorage?.getItem("details"));
  // setPatientSortModel
  // let details = useSelector((state) => JSON.parse(state.loginDetails));

  const loginDetails = useSelector((state) => state.loginDetails);

  let details = useMemo(() => {
    if (loginDetails) {
      return JSON.parse(loginDetails);
    }
    return null;
  }, [loginDetails]);

  const gridRef = useRef(null);
  let [open, setOpen] = useState(false);
  let [message, setMessage] = useState("");
  const [filterClick, setfilterClick] = useState(false);
  const [searchClick, setSearchClick] = useState(false);
  const [search, setSearch] = useState(false);
  const [searchImport, setsearchImport] = useState(false);
  const [sectionAccess, setSectionAccess] = useState({ List: false });
  const [functionAccess, setFunctionAccess] = useState({ Export: 0,ImportFile:0 });

  const initialFilters = {
    location: {
      companyId: "0",
      companyName: "All",
    },
    for: "14",
    daysOrMonths: "days",
    fromDate: dayjs().subtract(14, "day"),
    toDate: dayjs(),
    statusDate: {
      label: "Create Date",
      value: "CDT",
    },
    dateOfBirth: null,
    batchNumber: "",
    status: {
      label: "",
      value: null,
    },
    deliveryStatus: {
      label: "",
      value: null,
    },
  };

  // let [filters, setFilters] = useState(initialFilters);
  // let [isFilters, setIsFilters] = useState(false);

  let [isSearchFilters, setIsSearchFilters] = useState(false);
  let [SearchInputvalue, setSearchInputvalue] = useState("");

  let patientBackDataObj = JSON.parse(
    sessionStorage?.getItem("patientBackData")
  );
  // const [paginationModel, setPaginationModel] = useState({
  //   page: 0,
  //   pageSize: 100,
  // });
  const [loading, setLoading] = React.useState(false);
  // const [cosmsid, setCosmosid] = React.useState();

  const [array, setArray] = useState([]);
  const [wholeData, setWholeData] = useState(false);
  const [notify, setNotify] = useState({
    isOpen: false,
    message: "",
    type: "",
  });
  const [gridApi, setGridApi] = useState(null);

  let navigation = useNavigate();
  const { mutateAsync, data, isLoading } = UpdateAllPatientList();

  //neel

  const handleAutocompleteChange = (name, value) => {
    // if (name === "status") {
    //   const res = lookUpData?.subStatusList?.filter(
    //     (item) => item.code === value.code
    //   );
    //   res.unshift({
    //     description: "All",
    //     key: "0",
    //   });
    //   setSubStatusData(res);
    // }
    dispatch(setFilters({ ...filters, [name]: value }));
    // setFilters((prevData) => ({ ...prevData, [name]: value }));
  };

  const handleDateChange = (value, name) => {
    // const { name, value } = e.target;
    if (name === "fromDate") {
      dispatch(setFilters({ ...filters, [name]: value, for: "" }));
      // setFilters((prevData) => ({ ...prevData, [name]: value, for: "" }));
      return;
    }
    dispatch(setFilters({ ...filters, [name]: value }));
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    if (name === "for") {
      let fromDate;
      if (filters?.daysOrMonths === "days") {
        fromDate = dayjs(filters?.toDate)?.subtract(parseInt(value, 10), "day");
      } else if (filters?.daysOrMonths === "weeks") {
        fromDate = dayjs(filters?.toDate)?.subtract(
          parseInt(value, 10),
          "week"
        );
      } else {
        fromDate = dayjs(filters?.toDate)?.subtract(
          parseInt(value, 10),
          "month"
        );
      }
      // setFilters((prevData) => ({
      //   ...prevData,
      //   [name]: value,
      //   fromDate,
      // }));
      dispatch(setFilters({ ...filters, [name]: value, fromDate }));
    } else if (name == "Batch Number") {
      dispatch(setFilters({ ...filters, batchNumber: value }));
      // setFilters((prevData) => ({
      //   ...prevData,
      //   batchNumber: value,
      // }));
    } else {
      dispatch(setFilters({ ...filters, [name]: value }));
      // setFilters((prevData) => ({ ...prevData, [name]: value }));
      if (name === "daysOrMonths") {
        let fromDate;
        if (value === "days") {
          fromDate = dayjs(filters?.toDate).subtract(
            parseInt(filters?.for, 10),
            "day"
          );
        } else if (value === "weeks") {
          fromDate = dayjs(filters?.toDate).subtract(
            parseInt(filters?.for, 10),
            "week"
          );
        } else {
          fromDate = dayjs(filters?.toDate).subtract(
            parseInt(filters?.for, 10),
            "month"
          );
        }
        dispatch(setFilters({ ...filters, [name]: value, fromDate }));
        // setFilters((prevData) => ({
        //   ...prevData,
        //   [name]: value,
        //   fromDate,
        // }));
      }
    }
  };

  function handleClearValues() {
    setOpen(false);
    patientBackDataObj = null;
    sessionStorage?.removeItem("patientBackData");
    sessionStorage?.removeItem("setCosmosKeyID");
    // setIsFilters(false);
    // setFilters(initialFilters);
    setSearchInputvalue("");
    setMessage("");
    setSearchClick(false);
    setIsSearchFilters(false);
    setSearch(!search);
    handleClose1();
    dispatch(setPatientFilterModel(null));
    dispatch(setPatientSortModel(null));
    // dispatch(setIsSearchFilters(false));
    dispatch(setFilters(initialFilters));
    dispatch(setIsFilters(false));
  }

  async function getPatientList() {
    setLoading(true);
    // Define parameters for the API call

    let params = {
      commonParams: {
        organizationID: details?.orgId,
        companyID: details?.companyId,
        coCode: details?.companyCode,
        groupID: details?.groupID,
        timeZone: details?.timeZone,
        sessionId: details?.sesionId,
        loginUserId: `${details?.userId}`,
        loginUserName: details?.userName,
        AuditTrail: 0,
        editTrail: 0,
        dataSource: "",
      },
      searchImport: searchImport,
      arrayObj: array,
      processStatus: filters?.processStatus || "",
      deliveryStatus: filters?.deliveryStatus?.value || "",
      cosmosId: "",
      seachText: filters?.filter || message || "",
      dobFromDate: filters?.dateOfBirth || "",
      dobToDate: "",
      transactionFromDate:
        (filters.fromDate && dayjs(filters.fromDate).format("MM/DD/YYYY")) ??
        "01/01/2023",
      transactionToDate:
        (filters.toDate && dayjs(filters.toDate).format("MM/DD/YYYY")) ??
        "07/01/2024",
      batchNumber: filters?.batchNumber || "",
      locationName: (filters?.location?.companyName ?? filters?.location) || "",
      sortColumnName: "",
      sortColumnValue: "",
      pageNumber: paginationModel?.page + 1,
      pageSize: paginationModel?.pageSize,
      createdBy: filters?.createdBy || "",
      locationId: filters?.location?.companyId || "0",
    };
    // Make the API call
    try {
      const res = await mutateAsync(params);
      // Handle the response
      if (res?.status?.code == 200) {
        setWholeData(res?.data);

        setNotify({
          isOpen: true,
          message: `${res?.status?.message || "Data Retrieved Successfully"}`,
          type: "success",
        });
        // setLoading(false);
      } else if (res?.status?.code == 401) {
        setNotify({
          isOpen: true,
          message: `${res?.status?.message || "Your session has expired"}`,
          type: "error",
        });
        sessionStorage.clear();
        persistor.purge();
        window.location.href = "/login";

        // dispatch(setActiveComponent("/login"));
      } else if (res?.response?.status !== 200) {
        setLoading(false);
        logTelemetry(
          "Failed",
          "PatientList",
          "getPatientList",
          res?.status?.message
        );
        setNotify({
          isOpen: true,
          message: `${res?.response?.message}`,
          type: "error",
        });
      } else {
        setLoading(false);
        logTelemetry(
          "Failed",
          "PatientList",
          "getPatientList",
          res?.status?.message
        );
        setsearchImport(undefined);
      }
    } catch (error) {
      setLoading(false);
      logTelemetry(
        "Failed",
        "PatientList",
        "getPatientList",
        error?.message,
        error
      );
      setNotify({
        isOpen: true,
        message: `An error occurred: ${error.message}`,
        type: "error",
      });
    } finally {
      setLoading(false);
    }
  }

  useEffect(() => {
    const accessList = {
      List: GetSectionRights(accessDetails, segmentId, moduleId, StaticModuleClass.LIST_SECID),
    };
    setSectionAccess(accessList);
    const functionAccess = {
      Export: GetConfigurationId(accessDetails, segmentId, moduleId, StaticModuleClass.LIST_SECID, StaticModuleClass.INSURANCE_EXPORT_FUNID),
      ImportFile: GetConfigurationId(accessDetails, segmentId, moduleId, StaticModuleClass.LIST_SECID, StaticModuleClass.IMPORT_FILE_FUNID),
    };
    setFunctionAccess(functionAccess);
  }, []);

  useEffect(() => {
    getPatientList();
  }, [paginationModel?.page, search, paginationModel?.pageSize]);

  useEffect(() => {
    if (searchImport == true) {
      getPatientList();
    }
  }, [searchImport]);
  let agree = async () => {
    setOpen(false);
    // setFilters({});
  };
  let disagree = (vals) => {
    setOpen(false);
    getPatientList();
    //setFilters(vals);
  };

  function handleUploadClick() {
    // navigation("/patient-list/data-import-module");
    dispatch(setActiveComponent("/patient-list/data-import-module"));
  }

  const intial = {
    open: false,
    title: "",
    subTitle: "",
    type: "",
  };
  const [dialogState, setDialogState] = useState(intial);

  const columns = [
    {
      field: "Action",
      headerName: "View",
      width: 110,
      cellRenderer: (params) => {
        if (params.node.group) {
          return null; // Return null for grouped rows to hide the icon
        }
        return (
          <span style={{ paddingLeft: "6px" }}>
            <VisibilityIcon
              sx={{
                "&amp;:hover": {
                  color: "blue",
                  textDecoration: "underline",
                  cursor: "pointer",
                },
              }}
              style={{
                width: "20px",
                marginRight: "14px",
              }}
            />
          </span>
        );
      },
    },
    {
      field: "updateDate",
      headerName: "Create Date",
      width: 160,
      cellRenderer: (cellValues) => {
        let values = cellValues.value;
        if (cellValues.node.group) {
          return null; // Return null for grouped rows to hide the icon
        }
        return (
          <div>
            <p className="datagridWraptxt">
              {values == "" ? "" : dayjs(values).format("MM/DD/YYYY")}
            </p>
          </div>
        );
      },
    },
    {
      field: "dateOfBirth",
      headerName: "Name & DOB",
      width: 220,
      cellRenderer: (cellValues) => {
        let values = cellValues?.value;
        if (cellValues.node.group) {
          return null; // Return null for grouped rows to hide the icon
        }
        return (
          <div>
            <p className="text-sm text-slate-700 datagridWraptxt">{`${cellValues?.data?.firstName || ""
              } ${cellValues?.data?.lastName || ""}`}</p>
            <p className="text-xs text-slate-500 datagridWraptxt">
              {values == "" ? "" : dayjs(values).format("MM/DD/YYYY")}
            </p>
          </div>
        );
      },
    },
    {
      field: "primaryPhone",
      headerName: "Mobile & Email",
      width: 180,
      cellRenderer: (cellValues) => {
        return (
          <div>
            <p className="text-sm text-slate-700 hover:cursor-hand">
              {cellValues.value}
            </p>
            <p className="text-xs text-slate-500 hover:cursor-hand">
              {cellValues?.data?.primaryEmail == undefined
                ? ""
                : `${cellValues.data.primaryEmail}`}
            </p>
          </div>
        );
      },
    },
    {
      field: "locationName",
      headerName: "Location",
      width: 150,
      cellRenderer: (cellValues) => {
        return (
          <p className="text-sm text-slate-700 datagridWraptxt">
            {cellValues.value}
          </p>
        );
      },
    },
    {
      field: "payerName",
      headerName: "Payer Identified",
      width: 220,
      cellRenderer: (cellValues) => {
        return (
          <p className="text-sm text-slate-700 datagridWraptxt">
            {cellValues.value}
          </p>
        );
      },
    },
    {
      field: "insVerificationStatus",
      headerName: "Ins.Verification Status",
      width: 220,
      cellRenderer: (cellValues) => {
        // console.log(cellValues);
        return (
          <span
            className="text-sm text-slate-700 datagridWraptxt"
            style={
              cellValues?.value?.toLowerCase() === "failed"
                ? spanPendingStyles
                : cellValues?.value?.toLowerCase() === "active"
                  ? spanVerifiedStyles
                  : {}
            }>
            {`${cellValues?.data?.insVerificationStatus || ""}`}
          </span>
        );
      },
    },

    {
      field: "smsDeliveryStatus",
      headerName: "Delivery Status",
      width: 180,
      cellRenderer: (cellValues) => {
        return (
          <div className="datagridWraptxt">
            {`${cellValues?.data?.smsDeliveryStatus || ""}`}
          </div>
        );
      },
    },
    {
      field: "processStatus",
      headerName: "Process Status",
      width: 180,
      cellRenderer: (cellValues) => {
        return (
          <span
            className="text-sm text-slate-700 whitespace-nowrap"
            style={
              cellValues?.value?.toLowerCase() == "open"
                ? spanPendingStyles
                : cellValues?.value?.toLowerCase() == "completed"
                  ? spanVerifiedStyles
                  : cellValues?.value?.toLowerCase() == "card captured"
                    ? spanUnverifiedStyles
                    : cellValues?.value?.toLowerCase() == "queued for verification"
                      ? spanDeliveredStyles
                      : // : cellValues.value == "New"
                      //   ? spanNewStyles
                      null
            }>
            {cellValues.value}
          </span>
        );
        // cellValues.value
      },
    },
    {
      field: "recordSource",
      headerName: "Req Type",
      width: 140,
      cellRenderer: (cellValues) => {
        return (
          <div className="datagridWraptxt">
            {`${cellValues?.data?.recordSource || ""}`}
          </div>
        );
      },
    },
    {
      field: "batchCode",
      headerName: "Batch Number",
      width: 170,
      cellRenderer: (cellValues) => {
        return (
          <div className="datagridWraptxt">
            {`${cellValues?.data?.batchCode || ""}`}
          </div>
        );
      },
    },
    {
      field: "updateUser",
      headerName: "Created By",
      width: 150,
      cellRenderer: (cellValues) => {
        return (
          <div className="datagridWraptxt">
            {`${cellValues?.data?.updateUser || ""}`}
          </div>
        );
      },
    },
    {
      field: "dateofService",
      headerName: "Date of Service",
      width: 180,
      cellRenderer: (cellValues) => {
        let values = cellValues.value;
        if (cellValues.node.group) {
          return null; // Return null for grouped rows to hide the icon
        }
        return (
          <div>
            <p className="datagridWraptxt">
              {values == "" ? "" : dayjs(values).format("MM/DD/YYYY")}
            </p>
          </div>
        );
      },
    },
    {
      field: "cosmosKeyID",
      headerName: "Ref.Id",
      width: 300,
      cellRenderer: (cellValues) => {
        return (
          <div className="datagridWraptxt">
            {`${cellValues?.data?.cosmosKeyID || ""}`}
          </div>
        );
      },
    },
  ];

  const onGridReady = useCallback(
    (params) => {
      setGridApi(params.api);
      params.api.sizeColumnsToFit();
      params.api.addEventListener("firstDataRendered", () => {
        if (patientFilterModel) {
          params.api.setFilterModel(patientFilterModel);
        }
        if (patientSortModel) {
          // params.api.setSortModel(patientSortModel);
          gridRef?.current?.api?.applyColumnState({
            state: patientSortModel,
            defaultState: { sort: null },
          });
        }
      });
    },
    [patientFilterModel, patientSortModel]
  );

  const onCellClicked = (params) => {
    const currentFilterModel = params?.api?.getFilterModel();
    // const currentSortModel = params?.api?.getSortModel();
    let colState = gridRef?.current?.api?.getColumnState();
    let sortState = colState
      .filter(function (s) {
        return s.sort != null;
      })
      .map(function (s) {
        return { colId: s.colId, sort: s.sort, sortIndex: s.sortIndex };
      });
    dispatch(setPatientFilterModel(currentFilterModel));
    dispatch(setPatientSortModel(sortState));
    // setPatientSortModel(sortState);
    let currentPage = params.api.paginationGetCurrentPage(); // Gets the current page index
    let pageSize = params.api.paginationGetPageSize(); // Gets the number of rows per page
    let isFilterActive = params.api.isAnyFilterPresent(); // Check if a filter is applied

    let filteredData;

    if (isFilterActive) {
      filteredData = params.api
        .getRenderedNodes()
        .map((node) => node?.data?.cosmosKeyID);
    } else {
      let startIndex = currentPage * pageSize;
      let endIndex = startIndex + pageSize;

      // Get all rows data
      const allRowsData = [];
      params.api.forEachNode((node) => {
        allRowsData.push(node.data);
      });

      // Slice data based on pagination
      filteredData = allRowsData
        .slice(startIndex, endIndex)
        .map((node) => node?.cosmosKeyID);
    }
    if (params.colDef.field === "Action") {
      sessionStorage.setItem(
        "setCosmosKeyID",
        JSON.stringify(params.data.cosmosKeyID)
      );
      // navigation("/patient-list/patient-details", {
      //   state: { cosmosKeyID: params.data.cosmosKeyID, filteredData },
      // });

      dispatch(setPatientFilteredData(filteredData));
      dispatch(setCosmosId(params.data.cosmosKeyID));
      dispatch(setActiveComponent("/patient-list/patient-details"));
    }
  };

  const handlePageSizeChange = (event) => {
    const newPageSize = parseInt(event.target.value, 10);
    dispatch(setPaginationModel({ pageSize: newPageSize, page: 0 }));
  };

  const getUpdatedPaginationModel = (paginationModel, action, data) => {
    let newPage;

    switch (action) {
      case "first":
        newPage = 0; // Go to the first page
        break;
      case "last":
        newPage = Math.max(parseInt(data?.data?.noofPage ?? 1) - 1, 0); // Go to the last page
        break;
      default:
        newPage = Math.max(paginationModel.page + action, 0); // Increment or decrement the page based on action
        break;
    }

    return {
      ...paginationModel,
      page: newPage,
    };
  };

  const handlePageChange = (action) => {
    const updatedPaginationModel = getUpdatedPaginationModel(
      paginationModel,
      action,
      data
    );

    dispatch(setPaginationModel(updatedPaginationModel));
  };

  function btnExportXlClick() {
    if (gridApi) {
      const formattedDate = formatDateStamp();

      gridApi.exportDataAsExcel({
        fileName: `Insurance Verification List_${formattedDate}`,
      });
    } else {
      console.error("Grid API not available");
    }
  }

  function btnExportCsvClick() {
    if (gridRef.current) {
      const formattedDate = formatDateStamp();

      gridRef.current.api.exportDataAsCsv({
        fileName: `Insurance Verification List_${formattedDate}`,
      });
    } else {
      console.error("Grid reference is not available");
    }
  }

  const handleConfirm = (title) => {
    if (title === "Attention Required") {
      patientBackDataObj = null;
      sessionStorage?.removeItem("patientBackData");
      sessionStorage?.removeItem("setCosmosKeyID");
      // setIsFilters(false);
      // setFilters(initialFilters);
      setSearchInputvalue("");
      setMessage("");
      setSearchClick(false);
      setIsSearchFilters(false);
      setSearch(!search);
      handleClose1();
      // setPatientFilterModel(null);
      // setPatientSortModel(null);
      // dispatch(setPatientFilterModel(null));
      // dispatch(setPatientSortModel(null));
      // dispatch(setIsFilters(false));
      // dispatch(setFilters(initialFilters));
      dispatch(resetPatientData());
    }
  };

  function handleClose1() {
    setDialogState(intial);
  }

  function onFilterChanged() {
    const filterModel = gridRef?.current?.api?.getFilterModel();
    if (Object.keys(filterModel).length === 0) {
      setWholeData((prev) => ({
        ...prev,
        totalRows: parseInt(data?.data?.totalRows),
        noofPage: parseInt(data?.data?.noofPage),
      }));
      setPaginationModel((prev) => ({
        ...prev,
        page: 0,
      }));
    } else {
      const filteredRowCount = gridRef.current.api.getDisplayedRowCount();
      setWholeData((prev) => ({
        ...prev,
        totalRows: filteredRowCount,
        noofPage: 1,
      }));
      setPaginationModel((prev) => ({
        ...prev,
        page: 0,
      }));
    }
  }

  function setIsFilterss(args) {
    dispatch(setIsFilters(args));
  }

  return (
    <div className="">
      <Grid container>
        <Grid item={true} xs={12} sm={12}>
          <Grid container>
            <Grid
              item={true}
              xs={12}
              sm={12}
              className="mb-3 mt-3"
              style={{
                display: "flex",
                justifyContent: "space-between",
                margin: "1rem 0",
              }}>
              <Typography variant="h6">Insurance Verification List</Typography>
              {functionAccess.ImportFile !=0 && (
              <Box>
                <Button
                  variant="outlined"
                  color="primary"
                  onClick={() => handleUploadClick()}
                  className="button-styles">
                  Import File
                </Button>
              </Box>
               )}
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      {/* {isFilters || isSearchFilters ? ( */}

      <Grid container>
        <Grid item xs={12} sm={12}>
          <span>
            Showing records for the location: {filters?.location?.companyName}{" "}
            From: {dayjs(filters?.fromDate).format("MM/DD/YYYY")} To:{" "}
            {dayjs(filters?.toDate).format("MM/DD/YYYY")}. You may change
            Filters by clicking on filter Icon.
          </span>

          {/* {isFilters && isSearchFilters == false && (
              <span style={{ color: "blue" }}>
                Displaying rows with filters applied. You may change or clear
                filters by clicking on filter.
              </span>
            )}
            {isSearchFilters && isFilters == false && (
              <span style={{ color: "blue" }}>
                Displaying rows with Search applied. You may change or clear
                filters by clicking on filter.
              </span>
            )}
            {isSearchFilters && isFilters && (
              <span style={{ color: "blue" }}>
                Displaying rows with Search/filters are applied. You may change
                or clear filters by clicking on filter.
              </span>
            )} */}

          <Tooltip title="Apply Filter">
            <FilterListIcon
              onClick={() => {
                setfilterClick();
                setOpen(true);
                setfilterClick(false);
              }}
              color="primary"
              className="secondaryIcon"
            />
          </Tooltip>
          <Tooltip title="Clear Filters">
            <ClearAllIcon
              placeholder="Clear All"
              onClick={() => {
                if (isFilters || isSearchFilters) {
                  setDialogState({
                    open: true,
                    title: "Attention Required",
                    subTitle:
                      "Are you sure you want to clear all the filters ? ",
                    type: "double",
                  });
                }

                // patientBackDataObj = null;
                // sessionStorage?.removeItem("patientBackData");
                // sessionStorage?.removeItem("setCosmosKeyID");
                // setIsFilters(false);
                // setIsSearchFilters(false);
                // setFilters(initialFilters);
                // setSearchInputvalue("");
                // setMessage("");
                // setSearchClick(false);
                // setSearch(!search);
              }}
              color="primary"
              className="secondaryIcon"
            />
          </Tooltip>
        </Grid>
      </Grid>

      {/* ) : (
      <span style={{ color: "blue" }}>
        Showing records for the location: {filters.location.companyName} From:{" "}
        {dayjs(filters.fromDate).format("MM/DD/YYYY")} To:{" "}
        {dayjs(filters.toDate).format("MM/DD/YYYY")}.You may change Filters by
        clicking on filter Icon.
      </span> */}
      {/* )} */}
      <Grid gap={2} className="w-full flex justify-between mb-2 mt-4 md:mt-0">
        <div className="w-full">
          {/* <div className="flex">
            <input
            type="text"
            id="first_name"
            className="search-input bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-tl-lg rounded-bl-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
            placeholder="Search by Mobile number (or) email"
            />
            <button className="border-2 border-l-0 px-4 rounded-r-md  search-button">
              Search
              </button>
              </div> */}
          <Paper
            component="form"
            sx={{ p: "2px 4px", display: "flex", alignItems: "center" }}>
            {message && (
              <IconButton
                sx={{ p: "10px" }}
                aria-label="clear"
                onClick={() => setMessage("")}>
                <ClearIcon />
              </IconButton>
            )}
            <InputBase
              sx={{ ml: 1, flex: 1 }}
              value={message}
              // error={true}
              name="searchName"
              id="searchName"
              placeholder="Search by name or  mobile number or email"
              onChange={(e) => setMessage(e.target.value)}
              onKeyDown={(event) => {
                if (event.key === "Enter") {
                  patientBackDataObj = null;
                  sessionStorage?.removeItem("patientBackData");
                  sessionStorage?.removeItem("setCosmosKeyID");
                  // setIsFilters(false);
                  // setFilters(initialFilters);
                  dispatch(setFilters({ ...initialFilters }));
                  dispatch(setIsFilters(false));
                  setSearchClick(true);
                  setSearch(!search);
                  setSearchClick(false);
                  setIsSearchFilters(true);
                  event.preventDefault();
                }
              }}
            />
            <Divider sx={{ height: 28, m: 0.5 }} orientation="vertical" />
            <IconButton
              type="button"
              sx={{ p: "10px" }}
              aria-label="search"
              className="search-icon"
              onClick={() => {
                patientBackDataObj = null;
                sessionStorage?.removeItem("patientBackData");
                sessionStorage?.removeItem("setCosmosKeyID");
                dispatch(setFilters({ ...initialFilters }));
                dispatch(setIsFilters(false));
                setSearchClick(true);
                setIsSearchFilters(true);
                setSearch(!search);
                setSearchClick(false);
              }}
              onBlur={() => setSearchClick(false)}>
              <SearchIcon />
            </IconButton>
          </Paper>
        </div>
        <div>
          <Tooltip title="Refresh">
            <MuiButton
              onClick={() => {
                if (isFilters || isSearchFilters) {
                  setDialogState({
                    open: true,
                    title: "Attention Required",
                    subTitle:
                      "Are you sure you want to clear all the filters ? ",
                    type: "double",
                  });
                } else {
                  handleConfirm("Attention Required");
                }
              }}
              className={"filterButton"}>
              <RefreshIcon />
            </MuiButton>
          </Tooltip>
        </div>
        <Tooltip title="Apply Filter">
          <div
            onClick={() => {
              patientBackDataObj = null;
              sessionStorage?.removeItem("patientBackData");
              sessionStorage?.removeItem("setCosmosKeyID");
              setfilterClick();
              setOpen(true);
              setfilterClick(false);
            }}
            onBlur={() => setfilterClick(false)}
            className="fltr-hgt">
            {isFilters ? (
              <Badge variant="dot" color="error">
                <MuiButton className={"filterButton"}>
                  <FilterAltIcon />
                </MuiButton>
              </Badge>
            ) : (
              <MuiButton className={"filterButton"}>
                <FilterAltIcon />
              </MuiButton>
            )}
          </div>
        </Tooltip>
      </Grid>
      <div className="mt-3">
      {sectionAccess.List && (
          <Box className="ag-theme-alpine insVHgt relative customPagination">
            {functionAccess.Export !=0 && (
              <div className="absolute z-10 top-[6px] right-[-2px]">
                <MuiButton
                  variant="outlined"
                  color="primary"
                  onClick={btnExportCsvClick}
                  sx={{ marginRight: 1 }}
                  className="button-styles">
                  export csv
                  <i className="fa-solid fa-file-csv fa-lg ml-2"></i>
                </MuiButton>
                <MuiButton
                  variant="outlined"
                  color="primary"
                  onClick={btnExportXlClick}
                  sx={{ marginRight: 1 }}
                  className="button-styles">
                  export xl
                  <i className="fa-regular fa-file-excel fa-lg ml-2"></i>
                </MuiButton>
              </div>
            )}
            <AgGridReact
              loading={loading}
              columnDefs={columns}
              rowData={data?.data?.listPatientData || []}
              pagination={true}
              defaultColDef={{
                filter: true,
                sortable: true,
                resizable: true,
                // Add 'rowGroup' here to enable grouping on specific columns
                enableRowGroup: true,
              }}
              gridOptions={{
                groupHideOpenParents: true,
              }}
              groupDisplayType="multipleColumns"
              onGridReady={onGridReady}
              paginationPageSize={paginationModel?.pageSize}
              paginationPageSizeSelector={[100, 250, 500, 1000]}
              paginationNumberFormatter={(params) =>
                `[${params.value.toLocaleString()}]`
              }
              rowCount={data?.data?.totalRows?.length || 0}
              loadingOverlayComponentParams={{ loading: isLoading }}
              rowGroupPanelShow="always" // Ensure it's enabled for grouping panel to show
              suppressRowClickSelection={true}
              suppressCopyRowsToClipboard={true}
              alwaysShowVerticalScroll={true}
              domLayout="autoHeight"
              animateRows={true}
              ref={gridRef}
              onCellClicked={onCellClicked}
              onFilterChanged={onFilterChanged}
            />

            <div className="flex items-center justify-between absolute bottom-1.5 right-4">
              <div className="flex items-center">
                <label htmlFor="selectRows" style={{ marginRight: 8 }}>
                  Page Size:
                </label>
                <select
                  name="selectRows"
                  id="selectRows"
                  value={paginationModel?.pageSize}
                  onChange={(e) => handlePageSizeChange(e)}
                  style={{
                    padding: "4px 8px",
                    borderRadius: 4,
                    border: "1px solid #ccc",
                    backgroundColor: "white",
                  }}>
                  {[100, 250, 500, 1000].map((size) => (
                    <option key={size} value={size}>
                      {size}
                    </option>
                  ))}
                </select>
              </div>
              {/* Record Display */}
              <div style={{ margin: "0 16px" }}>
                {`${paginationModel?.page * paginationModel?.pageSize + 1
                  } to ${Math.min(
                    (paginationModel?.page + 1) * paginationModel?.pageSize,
                    wholeData?.totalRows ?? 0
                  )} of ${wholeData?.totalRows ?? 0}`}
              </div>

              {/* Pagination Controls */}
              <div className="flex items-center">
                <IconButton
                  disabled={paginationModel?.page === 0}
                  onClick={() => handlePageChange("first")} // Go to first page
                >
                  <FirstPageIcon />
                </IconButton>
                <IconButton
                  disabled={paginationModel?.page === 0}
                  onClick={() => handlePageChange(-1)}>
                  <ChevronLeft />
                </IconButton>
                <span style={{ margin: "0 16px" }}>
                  Page {paginationModel?.page + 1} of{" "}
                  {parseInt(wholeData?.noofPage ?? 0)}
                </span>
                <IconButton
                  disabled={
                    paginationModel?.page >= parseInt(wholeData?.noofPage) - 1
                  }
                  onClick={() => handlePageChange(1)}>
                  <ChevronRight />
                </IconButton>
                <IconButton
                  disabled={
                    paginationModel?.page >= parseInt(wholeData?.noofPage) - 1
                  }
                  onClick={() => handlePageChange("last")} // Go to last page
                >
                  <LastPageIcon />
                </IconButton>
              </div>
            </div>
          </Box>
        )}
      </div>
      {open ? (
        <Dialogs
          open={true}
          cancel={agree}
          submit={disagree}
          // setFilters={setFilters}
          filters={filters}
          setOpen={setOpen}
          //lookUpData={lookUpData}
          formData={filters}
          //setFormData={setFormData}
          handleAutocompleteChange={handleAutocompleteChange}
          handleInputChange={handleInputChange}
          handleDateChange={handleDateChange}
          handleSubmit={disagree}
          //subStatusData={subStatusData}
          handleClearValues={handleClearValues}
          isFrom="patientList"
          setIsFilters={setIsFilterss}
        />
      ) : null}
      {/* {open ? (
        <DialogBox
          open={true}
          cancel={agree}
          submit={disagree}
          // page={page}
          setFilters={setFilters}
          filters={filters}
          setIsFilters={setIsFilters}
          setOpen={setOpen}
          isFrom="patientLit"
        />
      ) : null} */}
      <Notification notify={notify} setNotify={setNotify} />
      <ClearStatesConfirmationDialog
        onClose={() => handleClose1()}
        onConfirm={handleConfirm}
        open={dialogState.open}
        title={dialogState.title}
        subTitle={dialogState.subTitle}
        type={dialogState.type}
      />
    </div>
  );
};

export default PatientList;


