import React, { useState, useEffect } from "react";
import {
  Card,
  CardContent,
  Typography,
  Divider,
  Box,
  Grid,
} from "@mui/material";
import { Masonry } from "@mui/lab";
import ActivityLog from "./ActivityLog";
import LocationModal from "./ChildWindow/LocationModal";
import ContactsModal from "./ChildWindow/ContactsModal";
import LocationCityIcon from "@mui/icons-material/LocationCity";
import PublicIcon from "@mui/icons-material/Public";
import MarkunreadMailboxIcon from "@mui/icons-material/MarkunreadMailbox";
import { GetSectionRights } from "../../components/utils/activeComponent";
import { useDispatch, useSelector } from "react-redux";
import StaticModuleClass from "../../components/utils/staticModuleClass";

const data = {
  locationData: {
    locationCode: "14542",
    organizationCode: "devclp",
    locationType: "Satellite",
    recordStatus: "Active",
    locationName: "Aetna",
    timeZone: "Eastern",
    email: "test@gmail.com",
    address: {
      country: "United States",
      addressLine: "1 Main St",
      postalCode: "08831",
      city: "MONROE TOWNSHIP",
      state: "New Jersey",
    },
    contact: {
      primaryPhone: {
        number: "(123) 123-1231",
        ext: "",
        type: "Office",
      },
      secondaryPhone: {
        number: "(123) 123-1231",
        ext: "",
      },
      otherPhone: {
        number: "",
        ext: "",
      },
    },
  },
  appointment: {
    date: "28 AUG",
    time: "11:00AM - 11:30AM",
    doctor: "Dr. Raymond Gibbons",
    status: "Up Coming",
  },
  paymentMethod: {
    insurancePrimary: {
      name: "Peterson James Davbhs",
      insurance: "orbit Health Insurance",
      number: "9876543210",
      contact: "(843) 848-3483",
      email: "helpdesk@test.com",
    },
    insuranceSecondary: {
      name: "Peterson James Davbhs",
      insurance: "orbit Health Insurance",
      number: "9876543210",
      contact: "(843) 848-3483",
      email: "helpdesk@test.com",
    },
  },
  provider: {
    primary: {
      name: "Printo Thomas",
      phone: "868 345 2341",
      email: "PrintoThomas@gmail.com",
      address: "77 Milltown Rd, East Brunswick, New Jersey - 08816",
    },
    secondary: {
      name: "Dr. Secondary",
      phone: "868 345 2341",
      email: "Secondaryprovider@gmail.com",
      address: "77 Milltown Rd, East Brunswick, New Jersey - 08816",
    },
  },
  contacts: {
    recordData: {
      recordView: "Active",
      recordStatus: "Active",
    },
    contactData: {
      title: "Mr.",
      firstName: "John",
      middleInitial: "A",
      lastName: "Doe",
      suffix: "Jr.",
      primaryPhone: {
        number: "(123) 456-7890",
        ext: "101",
      },
      secondaryPhone: {
        number: "(987) 654-3210",
        ext: "202",
      },
      otherPhone: {
        number: "(555) 555-5555",
        ext: "",
      },
      primaryEmail: "john.doe@example.com",
      secondaryEmail: "doe.john@workmail.com",
      contactType: "Business",
      code: "JD123",
    },
  },
};
const styles = {
  formContainer: {
    fontFamily: "Roboto, sans-serif",
    // border: "1px solid #ddd",
    borderRadius: "8px",
    padding: "16px",
    maxWidth: "600px",
    margin: "0 auto",
    // backgroundColor: "",
  },
  section: {
    marginBottom: "24px",
  },
  sectionTitle: {
    fontSize: "1rem",
    fontWeight: 500,
    color: "#333",
    borderBottom: "1px solid #ddd",
    paddingBottom: "4px",
    // marginBottom: "16px",
  },
  row: {
    display: "flex",
    justifyContent: "space-between",
    padding: "8px 0",
    // borderBottom: "1px solid #eee",
    fontWeight: 400,
    fontSize: "0.875rem",
    lineHeight: 1.43,
    letterSpacing: "0.01071em",
  },
  row1: {
    display: "flex",
    alignItems: "center",
    paddingTop: 1,
  },
  label: {
    fontWeight: 500,
    color: "#555",
    // fontSize: "0.7rem",
    fontWeight: 400,
    fontSize: "0.875rem",
    lineHeight: 1.43,
    letterSpacing: "0.01071em",
  },
  value: {
    color: "#333",
    fontWeight: 400,
    fontSize: "0.875rem",
    lineHeight: 1.43,
    letterSpacing: "0.01071em",
  },
  card: {
    border: "1px solid #e0e0e0",
    borderRadius: "8px",
    backgroundColor: "white",
    padding: "16px",
    width: "100%",
    maxWidth: "400px",
    boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
    fontFamily: "Roboto, sans-serif",
  },
  title: {
    fontWeight: 500,
  },
  subtitle: {
    fontSize: "1rem",
    fontWeight: 400,
    margin: "8px 0",
  },
  body: {
    fontSize: "0.8rem",
    fontWeight: 300,
    margin: "4px 0",
    color: "#424242",
  },
  status: {
    fontSize: "0.75rem",
    fontWeight: 400,
    color: "lightblue",
  },
  flexBetween: {
    display: "flex",
    justifyContent: "space-between",
    // marginBottom: "8px",
  },
  divider: {
    margin: "16px 0",
    borderBottom: "1px solid #e0e0e0",
  },
  icon: {
    marginRight: "8px",
    fontSize: "1.1rem",
  },
  title: { fontSize: "1.25rem", color: "#20c2ec" },
  key: { fontSize: "0.875rem", mr: 2, flexBasis: "50%" },
  subHeading: { fontSize: "1rem", fontWeight: "bold", padding: "4px 0" },
  value: { fontSize: "0.875rem", flexBasis: "50%" },
  container: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    mb: 1,
  },
};

const {
  locationCode,
  organizationCode,
  locationType,
  recordStatus,
  locationName,
  timeZone,
  email,
  address,
  contact,
} = data.locationData;
const { recordData, contactData } = data.contacts;

const ProfileDashboard = () => {
  const accessDetails = useSelector((state) => state.accessRights);
  const segmentId = useSelector((state) => state.segmentID);
  const moduleId = useSelector((state) => state.moduleID);
  const [showModal, setShowModal] = useState(false);
  const [showContactModel, setShowContactModal] = useState(false);
  const [sectionAccess, setSectionAccess] = useState({
    Information: false,
    Location: false,
    ActivityLog: false,
    Notes: false,
    LocationSettings: false,
    Addresses: false,
  });

  useEffect(() => {
    const accessList = {
      Information: GetSectionRights(accessDetails, segmentId, moduleId, StaticModuleClass.INFORMATION_SECID),
      Location: GetSectionRights(accessDetails, segmentId, moduleId, StaticModuleClass.LOCATION_SECID),
      Contacts: GetSectionRights(accessDetails, segmentId, moduleId, StaticModuleClass.CONTACTS_SECID),
      ActivityLog: GetSectionRights(accessDetails, segmentId, moduleId, StaticModuleClass.ACTIVITY_LOG_SECID),
      Notes: GetSectionRights(accessDetails, segmentId, moduleId, StaticModuleClass.NOTES_SECID),
      LocationSettings: GetSectionRights(
        accessDetails,
        segmentId,
        moduleId,
        StaticModuleClass.LOCATION_SETTINGS_SECID
      ),
      Addresses: GetSectionRights(accessDetails, segmentId, moduleId, StaticModuleClass.ADDRESSES_SECID),
    };
    setSectionAccess(accessList);
  }, []);

  const handleAutocompleteChange = (name, value) => {};

  return (
    <Box>
      <Masonry columns={{ xs: 1, sm: 2, md: 3 }} spacing={1}>
        {/* Personal Details */}
        {sectionAccess.Location && (
          <Card sx={{ height: "auto" }}>
            <CardContent>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <Typography sx={styles.title}>Location Information</Typography>
                <Box onClick={() => setShowModal(true)}>
                  <i className="fas fa-edit cursor-pointer"></i>
                </Box>
              </Box>
              <Divider style={{ margin: "4px 0" }} />
              <div style={styles.flexBetween}>
                <Typography sx={styles.subtitle}>
                  {locationName} ({locationType})
                </Typography>
                <Typography sx={styles.status}>{recordStatus}</Typography>
              </div>

              <div style={styles.flexBetween}>
                <Typography variant="body2">
                  Location Code: {locationCode}
                </Typography>
                <br></br>
                <Typography variant="body2">
                  Organization Code: {organizationCode}
                </Typography>
              </div>

              <Divider sx={styles.divider} />

              {/* Contact Info */}
              <Typography sx={styles.subtitle}>Contact Information</Typography>
              <Typography variant="body2">{email}</Typography>
              <Typography variant="body2">
                {contact.primaryPhone.number} ({contact.primaryPhone.type})
              </Typography>

              <Divider sx={styles.divider} />

              {/* Address Section */}
              <Typography sx={styles.title}>Address</Typography>
              <Typography variant="body2">
                {address.addressLine}, {address.city}, {address.state},{" "}
                {address.postalCode}, {address.country}
              </Typography>

              <Divider sx={styles.divider} />

              {/* Timezone */}
              <Typography variant="body2">
                <strong>Time Zone: </strong>
                {timeZone}
              </Typography>
            </CardContent>
          </Card>
        )}

        {/* Contacts */}
        <Card sx={{ height: "auto" }}>
          <CardContent>
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <Typography sx={styles.title}>Contacts</Typography>
              <Box onClick={() => setShowContactModal(true)}>
                <i className="fas fa-edit cursor-pointer"></i>
              </Box>
            </Box>
            <Divider style={{ margin: "4px 0" }} />
            {/* Contact 1 */}
            <Grid container justifyContent="space-between">
              <Typography variant="body1">
                <strong>John Doe</strong>
                <br />
                Relation: Father
              </Typography>
              <Typography variant="body1">Emergency</Typography>
            </Grid>
            <Typography variant="body2" color="textSecondary">
              M: 123 456 7890
              <br />
              johndoe@gmail.com
            </Typography>

            <Divider style={{ margin: "4px 0" }} />

            {/* Contact 2 */}
            <Grid container justifyContent="space-between">
              <Typography variant="body1">
                <strong>Jane Smith</strong>
                <br />
                Relation: Grandmother
              </Typography>
              <Typography variant="body1">Care Giver</Typography>
            </Grid>
            <Typography variant="body2" color="textSecondary">
              M: 987 654 3210, T: 112 233 4455
              <br />
              Work: 5555
              <br />
              janesmith@gmail.com
            </Typography>

            <Divider style={{ margin: "4px 0" }} />

            {/* Contact 3 */}
            <Grid container justifyContent="space-between">
              <Typography variant="body1">
                <strong>Michael Johnson</strong>
                <br />
                Relation: Father
              </Typography>
              <Typography variant="body1">Guarantor</Typography>
            </Grid>
            <Typography variant="body2" color="textSecondary">
              M: 123 456 7890, T: 998 877 6655
              <br />
              Work: 2222
              <br />
              michaeljohnson@gmail.com
            </Typography>
          </CardContent>
        </Card>
      </Masonry>

      {sectionAccess.ActivityLog && (
        <Card sx={{ height: 400, width: "100%", marginBottom: "0.5rem" }}>
          <CardContent>
            <Typography
              variant="h6"
              component="p"
              sx={{ ...styles.title, marginBottom: "4px" }}
              className="my-2"
            >
              Activity Log
            </Typography>
            <ActivityLog />
          </CardContent>
        </Card>
      )}
      {sectionAccess.Notes && (
        <Card sx={{ height: 450, width: "100%" }}>
          <CardContent>
            <Box>
              <Typography
                variant="h6"
                component="p"
                className="my-2"
                sx={{ ...styles.title, marginBottom: "4px" }}
              >
                Notes
              </Typography>
            </Box>
            <ActivityLog />
          </CardContent>
        </Card>
      )}
      <LocationModal open={showModal} handleClose={() => setShowModal(false)} />
      <ContactsModal
        open={showContactModel}
        handleAutocompleteChange={handleAutocompleteChange}
        handleClose={() => setShowContactModal(false)}
      />
    </Box>
  );
};

export default ProfileDashboard;

{
  /* {sectionAccess.Information && (
          <Card sx={{ height: 390 }}>
            <CardContent>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <Typography sx={styles.title}>Information</Typography>
                <Box onClick={() => setShowModal(true)}>
                  <i className="fas fa-edit"></i>
                </Box>
              </Box>
              <Divider style={{ margin: "2px 0 2px 0" }} />
              <Typography sx={styles.subHeading} mr={1}>
                Record Data
              </Typography>
              <div style={styles.container}>
                <Typography sx={styles.key}>Location Code:</Typography>
                <Typography sx={styles.value}>LOC12345</Typography>
              </div>

              <div style={styles.container}>
                <Typography sx={styles.key}>Organization Code:</Typography>
                <Typography sx={styles.value}>ORG99999</Typography>
              </div>

              <div style={styles.container}>
                <Typography sx={styles.key}>Record Status:</Typography>
                <Typography sx={styles.value}>Active</Typography>
              </div>

              <div style={styles.container}>
                <Typography sx={styles.key}>Location Type:</Typography>
                <Typography sx={styles.value}>Central</Typography>
              </div>
              <Divider style={{ padding: "2px 0 2px 0" }} />
              <Typography sx={styles.subHeading} mr={1}>
                Location Data
              </Typography>
              <div style={styles.container}>
                <Typography sx={styles.key}>Location Name:</Typography>
                <Typography sx={styles.value}>TEST XP</Typography>
              </div>
              <div style={styles.container}>
                <Typography sx={styles.key}>Time Zone:</Typography>
                <Typography sx={styles.value}>Eastern</Typography>
              </div>

              <Divider style={{ padding: "2px 0 2px 0" }} />
              <Typography sx={styles.subHeading} mr={1}>
                Address and Contact
              </Typography>
              <Box sx={styles.row1}>
                <LocationCityIcon sx={styles.icon} />
                <Typography variant="body2">
                  {address.addressLine}, {address.addressLine}
                </Typography>
              </Box>

              <Box sx={styles.row1}>
                <PublicIcon sx={styles.icon} />
                <Typography variant="body2">
                  {address.city}, {address.state}, {address.country}
                </Typography>
              </Box>

              <Typography
                variant="body2"
                sx={{ display: "flex", alignItems: "center", mt: 1 }}
              >
                <MarkunreadMailboxIcon sx={styles.icon} />
                <Typography variant="body2">
                  Postal Code: {address.postalCode}
                </Typography>
              </Typography>
            </CardContent>
          </Card>
        )} */
}

{
  /* Appointment */
}
{
  /* {sectionAccess.Contacts && (
          <Card sx={{ height: "auto" }}>
            <CardContent>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <Typography sx={styles.title}>Contact Information</Typography>
                <Box onClick={() => setShowModal(true)}>
                  <i className="fas fa-edit"></i>
                </Box>
              </Box>
              <Divider style={{ margin: "4px 0" }} />
              <Typography style={styles.row}>Mr.John Doe Jr.</Typography>

              <Typography style={styles.row}>+1 2345678901(primary)</Typography>
              <Typography style={styles.row}>
                +44 20 7946 0958(secondary)
              </Typography>

              <Typography style={styles.row}>
                john.doe@example.com(primary)
              </Typography>
              <Typography style={styles.row}>
                doe.john@workmail.com(secondary)
              </Typography>
              <Typography style={styles.row}>Code: XYZ123</Typography>
            </CardContent>
          </Card>
        )} */
}
{
  /* {sectionAccess.Addresses && (
          <Card sx={{ height: 250 }}>
            <CardContent>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <Typography sx={styles.title}>Address</Typography>
                <Box onClick={() => setShowModal(true)}>
                  <i className="fas fa-edit"></i>
                </Box>
              </Box>
              <Divider sx={{ margin: "4px 0" }} />

              <Box sx={styles.row1}>
                <LocationCityIcon sx={styles.icon} />
                <Typography variant="body2">
                  {address.addressLine}, {address.addressLine}
                </Typography>
              </Box>

              <Box sx={styles.row1}>
                <PublicIcon sx={styles.icon} />
                <Typography variant="body2">
                  {address.city}, {address.state}, {address.country}
                </Typography>
              </Box>

              <Typography
                variant="body2"
                sx={{ display: "flex", alignItems: "center", mt: 1 }}
              >
                <MarkunreadMailboxIcon sx={styles.icon} />
                <Typography variant="body2">
                  Postal Code: {address.postalCode}
                </Typography>
              </Typography>
            </CardContent>
          </Card>
        )} */
}

{
  /* //Location Settings */
}
{
  /* {sectionAccess.LocationSettings && (
          <Card sx={{ height: "auto" }}>
            <CardContent>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <Typography sx={styles.title}>Location Settings</Typography>
                <Box onClick={() => setShowModal(true)}>
                  <i className="fas fa-edit"></i>
                </Box>
              </Box>
              <Divider style={{ margin: "4px 0" }} />
              <div style={styles.container}>
                <Typography sx={styles.key}>Location Name:</Typography>
                <Typography>Sample Location</Typography>
              </div>

              <div style={styles.container}>
                <Typography sx={styles.key}>Location Code:</Typography>
                <Typography sx={styles.value}>LOC12345</Typography>
              </div>

              <div style={styles.container}>
                <Typography sx={styles.key}>Organization Code:</Typography>
                <Typography sx={styles.value}>ORG67890</Typography>
              </div>

              <div style={styles.container}>
                <Typography sx={styles.key}>Address:</Typography>
                <Typography sx={styles.value}>
                  123 Sample St, Sample City, SC - 12345, Sample Country
                </Typography>
              </div>

              <div style={styles.container}>
                <Typography sx={styles.key}>Time Zone:</Typography>
                <Typography sx={styles.value}>GMT+0</Typography>
              </div>

              <Divider sx={styles.divider} />
            </CardContent>
          </Card>
        )} */
}
{
  /* Payment Method */
}
{
  /* <Card sx={{ height: "auto" }}>
          <CardContent>
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <Typography sx={styles.title}>Payment Details</Typography>
              <Box onClick={() => setShowModal(true)}>
                <i className="fas fa-edit"></i>
              </Box>
            </Box>
            <Divider style={{ margin: "16px 0" }} />
            <Typography variant="body2">
              <strong>Primary Insurance</strong>
            </Typography>
            <Typography variant="body2">
              {data.paymentMethod.insurancePrimary.name}
            </Typography>
            <Typography variant="body2">
              {data.paymentMethod.insurancePrimary.insurance}
            </Typography>
            <Typography variant="body2">
              <a href="#">{data.paymentMethod.insurancePrimary.number}</a>
            </Typography>
            <Typography variant="body2">
              {data.paymentMethod.insurancePrimary.contact}
            </Typography>
            <Typography variant="body2">
              {data.paymentMethod.insurancePrimary.email}
            </Typography>

            <Divider style={{ margin: "16px 0" }} />
            <Typography variant="body2">
              <strong>Secondary Insurance</strong>
            </Typography>
            <Typography variant="body2">
              {data.paymentMethod.insuranceSecondary.name}
            </Typography>
            <Typography variant="body2">
              {data.paymentMethod.insuranceSecondary.insurance}
            </Typography>
            <Typography variant="body2">
              <a href="#">{data.paymentMethod.insuranceSecondary.number}</a>
            </Typography>
            <Typography variant="body2">
              {data.paymentMethod.insuranceSecondary.contact}
            </Typography>
            <Typography variant="body2">
              {data.paymentMethod.insuranceSecondary.email}
            </Typography>
          </CardContent>
        </Card> */
}
