import React, { useEffect, useState } from "react";

// Redux related Import
import { useDispatch, useSelector } from "react-redux";

import { useNavigate } from "react-router-dom"; // Make sure you are using react-router-dom v6
import Header from "../../components/Header";
import PatientList from "../patient/PatientList";
import ErrorPage from "../../components/ErrorPage";
import PatientDetails from "../patient/PatientDetails";
import PatientInfo from "../capture/PatientInfo";
import CaptureDriverLicense from "../capture/CaptureDriverLicense";
import SentSMS from "../sendSMS/SentSMS";
import SendSMS from "../sendSMS/SendSMS";
import InsuranceDiscoverInfo from "../discoverInsurance/InsuranceDiscoverIno";
import ClaimSubmitDetails from "../claimSubmit/ClaimSubmit";
import ClaimStatusDetails from "../claimSubmit/ClaimStatus";
import DataImportModule from "../importModule/DataImportModule";
import CustomReportsModule from "../reports/CustomReportsModule";
import DocumentVerificationPage from "../documentVerification/DocumentVerification";
import SingleDocumentPage from "../documentVerification/SingleDocument";
import BatchUploadPage from "../documentVerification/BatchUpload";
import ReportsList from "../reports/ReportsList";
import TransationSummaryListObj from "../transactionSummary/TransationSummaryList";
import ClaimDynamicSubmit from "../claimSubmit/ClaimSubmitDynamic";
import TransationSummary from "../transactionSummary/TransationSummay";
import CMSMaster from "../cms1500/CMSMaster";
import DocumentSummaryPage from "../documentSummary/DocumentSummary";
import ConfigurationList from "../docAiConfiguration/ConfigurationList";
import TaskSummaryPage from "../taskSummary/TaskSummary";
import LocationPage from "../location/Location";
import StaffPage from "../staff/StaffList";
import LocationDetailsPage from "../location/LocationDetails";
import { store } from "../../redux/store";
import { setPreviousAndCurrentComponent } from "../../redux/actions/previousComponent";

const componentMap = {
  "/PATIENT-LIST": <PatientList />,
  "/ERROR-PAGE": <ErrorPage />,
  "/PATIENT-LIST/PATIENT-DETAILS": <PatientDetails />,
  "/PATIENT-LIST/CAPTURE-DRIVING-LICENSE/PATIENT-INFO": <PatientInfo />,
  "/PATIENT-LIST/CAPTURE-DRIVING-LICENSE": <CaptureDriverLicense />,
  "/PATIENT-LIST/SEND-SMS-OR-EMAIL": <SendSMS />,
  "/PATIENT-LIST/SEND-SMS-OR-EMAIL/SMS-FORM": <SentSMS />,
  "/PATIENT-LIST/DISCOVER-INSURANCE": <InsuranceDiscoverInfo />,
  "/PATIENT-LIST/CLAIM-SUBMIT": <ClaimSubmitDetails />,
  "/PATIENT-LIST/CLAIM-STATUS": <ClaimStatusDetails />,
  "/PATIENT-LIST/DATA-IMPORT-MODULE": <DataImportModule />,
  "/PATIENT-LIST/CUSTOM-REPORTS": <CustomReportsModule />,
  "/PATIENT-LIST/DOCUMENT-VERIFICATION": <DocumentVerificationPage />,
  "/PATIENT-LIST/DOCUMENT-VERIFICATION/SINGLE-DOCUMENT": <SingleDocumentPage />,
  "/PATIENT-LIST/DOCUMENT-VERIFICATION/BATCH-UPLOAD": <BatchUploadPage />,
  "/PATIENT-LIST/REPORTS": <ReportsList />,
  "/TRANSACTIONSUMMARY/TRANSACTION-LIST": <TransationSummaryListObj />,
  "/PATIENT-LIST/CLAIM-SUBMITDYNAMIC": <ClaimDynamicSubmit />,
  "/PATIENT-LIST/TRANSATION-SUMMARY": <TransationSummary />,
  "/CMS": <CMSMaster />,
  "/DOCUMENT-SUMMARY": <DocumentSummaryPage />,
  "/DOCAICONFIGURATION": <ConfigurationList />,
  "/TASK-SUMMARY": <TaskSummaryPage />,
  "/LOCATION": <LocationPage />,
  "/STAFF": <StaffPage />,
  "/LOCATION-DETAILS": <LocationDetailsPage />,
};

const Home = () => {
  // const activeComponent = useSelector((state) => state.activeComponent);

  const activeComponent = useSelector((state) => state.activeComponent);
  const previousComponent = useSelector(
    (state) => state.previousComponent.previousComponent
  );
  const currentComponent = useSelector(
    (state) => state.previousComponent.currentComponent
  );

  const [content, setContent] = useState("");
  const navigate = useNavigate(); // react-router-dom v6 navigate hook
  const dispatch = useDispatch();

  useEffect(() => {
    const token = sessionStorage.getItem('token');
    if (!token || token === null) {
      window.location.href = "/login";
    }
  }, []);

  useEffect(() => {
    if (currentComponent !== activeComponent) {
      const previousComponent = store.getState()?.previousComponent?.currentComponentData;
      dispatch(setPreviousAndCurrentComponent(currentComponent, activeComponent));
    }
    const content = componentMap[activeComponent?.toUpperCase()] || <PatientList />;
    setContent(content);
  }, [activeComponent]);

  return (
    <div>
      {/* Header component which triggers handleComponentChange */}
      <Header />
      <div className="px-6 mx-auto relative top-[70px] w-full">
        <div>{content}</div>
      </div>
    </div>
  );
};

export default Home;
