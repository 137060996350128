import Constants from "../constants";

export const locationReducer = (state = null, action) => {
  switch (action.type) {
    case Constants.STORE_LOCATION_DATA:
      return action.payload;
    default:
      return state;
  }
};

export const isFromLocationReducer = (state = true, action) => {
  switch (action.type) {
    case Constants.STORE_ISFROM_LOCATION:
      return action.payload;
    default:
      return state;
  }
};
