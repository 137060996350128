import React, { useContext, useEffect, useMemo, useRef, useState } from "react";
import {
  Container,
  Grid,
  Typography,
  TextField,
  IconButton,
  Button,
  Backdrop,
  CircularProgress,
  Divider,
} from "@mui/material";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { getReportsListApi } from "../../apis/customReports";
import MyContext from "../../components/MyContext";
import Notification from "../../components/Notification";
// import StaticTabs from ".";
import PropTypes from "prop-types";
import { Tabs, Tab, Box } from "@mui/material";
import CustomReportsModule from "./CustomReportsModule";
import List from "./List";
import ClearStatesConfirmationDialog from "../importModule/ClearStatesConfirmationDialog";
import { logTelemetry } from "../../logTelemetry";
import { TabContext } from "../../components/utils/TabProvider";
import { useSelector } from "react-redux";
import formatDateStamp from "../../components/utils/getDateStamp";

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 1 }}>
          <>{children}</>
        </Box>
      )}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

const ReportsList = () => {
  const { setGlobalReportsData, globalreportsData } = useContext(TabContext);
  const gridRef = useRef();
  const { mutateAsync } = getReportsListApi();
  // let details = useSelector((state) => JSON.parse(state.loginDetails));

  const loginDetails = useSelector((state) => state.loginDetails);

  let details = useMemo(() => {
    if (loginDetails) {
      return JSON.parse(loginDetails);
    }
    return null;
  }, [loginDetails]);

  // const { contextValue } = useContext(MyContext);
  const [gridApi, setGridApi] = useState(null);

  // const [payload, setPayload] = useState(null);
  const [loading, setLoading] = useState(false);
  const [columns, setColumns] = useState([]);
  const [rows, setRows] = useState([]);
  const [fieldsToSearch, setFieldsToSearch] = useState([]);
  const [reportName, setReportName] = useState(null);
  const [reportId, setReportId] = useState(0);
  const [notify, setNotify] = useState({
    isOpen: false,
    message: "",
    type: "error",
  });

  const intial = {
    open: false,
    title: "",
    subTitle: "",
    type: "",
  };
  const [dialogState, setDialogState] = useState(intial);

  const handleClose = () => {
    setDialogState(intial);
  };

  const addReportClick = () => {
    // console.log("Add Report");
    setNotify({
      isOpen: true,
      message: "This Feature is Coming soon",
      type: "success",
    });
  };

  function btnDownloadXLClick() {
    if (gridApi) {
      const formattedDate = formatDateStamp();

      gridApi.exportDataAsExcel({
        fileName: `ReportsList_${formattedDate}`,
      });
    } else {
      console.error("Grid API not available");
    }
  }

  function btnDownloadCSVClick() {
    if (gridRef.current) {
      const formattedDate = formatDateStamp();

      gridRef.current.api.exportDataAsCsv({
        fileName: `ReportsList_${formattedDate}`,
      });
    } else {
      console.error("Grid reference is not available");
    }
  }

  const refreshClick = () => {
    getReportsList();
  };

  useEffect(() => {
    getReportsList();
  }, []);

  function transformObj(originalObj) {
    const columns = [
      {
        field: "Action",
        headerName: "View",
        Width: 40,
      },
    ];
    for (const key in originalObj) {
      if (originalObj.hasOwnProperty(key)) {
        const splitValue = originalObj[key].split("^");
        const column = {
          field: key,
          headerName: splitValue[1],
          width: Number(splitValue[3]),
        };
        columns.push(column);
      }
    }
    // console.log(columns, " columns");
    // debugger;
    return columns;
  }

  async function getReportsList() {
    setLoading(true);
    try {
      // console.clear();
      const payload = {
        commonParams: {
          organizationID: details?.orgId,
          companyID: details?.companyId,
          coCode: details?.companyCode,
          groupID: details?.groupID,
          timeZone: details?.timeZone,
          sessionId: details?.sesionId,
          loginUserId: `${details?.userId}`,
          loginUserName: details?.userName,
          AuditTrail: 0,
          editTrail: 0,
          dataSource: "",
        },
        ConfigId: 91886,
        ModuleId: 70140,
        SectionId: 60542,
      };
      const res = await mutateAsync(payload);
      if (res?.status?.code === 200) {
        setNotify({
          isOpen: true,
          message: `${res?.status?.message}`,
          type: "success",
        });
        const cols = transformObj(res?.data?.headers?.[0]);
        setColumns(cols);
        setFieldsToSearch(cols);
        const rows = res?.data?.searchValues?.map((itm, index) => ({
          ...itm,
          id: index + 1,
        }));
        setRows(!rows ? [] : rows);
        // setFilteredRows(rows);
        setLoading(false);
        // console.log(res, " Result ");
        // data.headers data.searchValues
        //test123 to push Hari
      } else {
        logTelemetry(
          "failed",
          "ReportsList",
          "getReportsList",
          `${res?.status?.message}`
        );
        setLoading(false);
      }
    } catch (e) {
      logTelemetry("failed", "ReportsList", "getReportsList", e.message, e);
      console.error(e, "error");
      setLoading(false);
      setNotify({
        isOpen: true,
        message: "getReportsList" + e.message,
        type: "error",
      });
    }
  }

  const [currentTab, setCurrentTab] = useState(0);
  function onRowClick(e, params) {
    e.stopPropagation(); // Prevent the row click event
    // console.log(params);
    // debugger;
    setReportId(params.row.C1);
    //setReportName(params.row.C6 ? params.row.C6 : params.row.C2.split("^")[0]);
    setReportName(
      params.row.C2.split("^")[0] ? params.row.C2.split("^")[0] : params.row.C6
    );
    setCurrentTab(1);
  }

  function onAdGridRowClick(params) {
    // debugger;
    setReportId(params.data.C1);
    setReportName(
      params.data.C2.split("^")[0]
        ? params.data.C2.split("^")[0]
        : params.data.C6
    );
    setCurrentTab(1);
  }

  const handleTabChange = (event, newValue) => {
    // console.log(newValue, " newValue ");
    if (newValue === 2) {
      setNotify({
        isOpen: true,
        message:
          "This tab is restricted. You can access it only after clicking 'Execute' and selecting a record from the data.",
        type: "warning",
      });
      return;
    }
    if (reportId == 0 && newValue == 1) {
      setNotify({
        isOpen: true,
        message: "Click on the view icon to see the desired report.",
        type: "warning",
      });
      return;
    }
    if (reportId == 0 && newValue == 2) {
      setNotify({
        isOpen: true,
        message: "Please select the desired report.",
        type: "warning",
      });
      return;
    }
    setCurrentTab(newValue);
  };

  function setCurrentTabb(id, flag) {
    if (flag) {
      setCurrentTab(id);
    }
  }

  const tabs = [
    {
      label: "Reports List",
      component: (
        <List
          columns={columns}
          rows={rows}
          gridRef={gridRef}
          addReportClick={addReportClick}
          refreshClick={refreshClick}
          setNotify={setNotify}
          onAdGridRowClick={onAdGridRowClick}
          setGridApi={setGridApi}
          btnDownloadCSVClick={btnDownloadCSVClick}
          btnDownloadXLClick={btnDownloadXLClick}
        />
      ),
    },
    {
      label: "Report Screen",
      component: (
        <CustomReportsModule
          reportId={reportId}
          loading={loading}
          reportName={reportName}
          setNotify={setNotify}
          setLoading={setLoading}
          setDialogState={setDialogState}
          currentTab={currentTab}
          setCurrentTab={setCurrentTabb}
          setPayload={setGlobalReportsData}
          payload={globalreportsData}
        />
      ),
    },
    {
      label: "Sub Report Screen",
      component: (
        <CustomReportsModule
          reportId={reportId}
          loading={loading}
          reportName={reportName}
          setNotify={setNotify}
          setLoading={setLoading}
          setDialogState={setDialogState}
          currentTab={currentTab}
          setCurrentTab={setCurrentTab}
          setPayload={setGlobalReportsData}
          payload={globalreportsData}
        />
      ),
    },
  ];

  return (
    <div>
      <div>
        <Box className="my-2 mt-3">
          <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
            <Tabs
              value={currentTab}
              onChange={handleTabChange}
              aria-label="basic tabs example"
            >
              {tabs.map((tab, index) => (
                <Tab
                  key={index}
                  label={tab.label}
                  sx={{
                    fontWeight: "bold",
                    textTransform: "capitalize",
                  }}
                />
              ))}
            </Tabs>
          </Box>
        </Box>
        {tabs.map((tab, index) => (
          <CustomTabPanel key={index} value={currentTab} index={index}>
            {tab.component}
          </CustomTabPanel>
        ))}
      </div>

      <Backdrop
        sx={{ color: "#1a73e8", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={loading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <Notification notify={notify} setNotify={setNotify} />
      <ClearStatesConfirmationDialog
        onClose={handleClose}
        onConfirm={handleClose}
        open={dialogState.open}
        title={dialogState.title}
        subTitle={dialogState.subTitle}
        type={dialogState.type}
      />
    </div>
  );
};

export default ReportsList;
