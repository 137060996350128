import Constants from '../constants';

export const moduleIdReducer = (state = null, action) => {
    switch (action.type) {
        case Constants.MODULE_ID:
            return action.payload;
        default:
            return state;
    }
};
export const currentModuleIdReducer = (state = null, action) => {
    switch (action.type) {
        case Constants.CURRENT_MODULE_ID:
            return action.payload;
        default:
            return state;
    }
};
export const previousModuleIdReducer = (state = null, action) => {
    switch (action.type) {
        case Constants.PREVIOUS_MODULE_ID:
            return action.payload;
        default:
            return state;
    }
};