import React from "react";
import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Grid,
  TextField,
  Box,
  Typography,
  FormControl,
  Autocomplete,
} from "@mui/material";

import CloseIcon from "@mui/icons-material/Close";

const ContactsModal = ({
  open,
  handleClose,
  formData,
  handleAutocompleteChange,
}) => {
  const recordView = [
    { label: "Active", value: "ACT" },
    { label: "Inactive", value: "IACT" },
    { label: "All", value: "ALL" },
  ];

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="customized-dialog-title"
    >
      <DialogTitle id="customized-dialog-title">
        Edit Contact Info
        <CloseIcon
          aria-label="close"
          className="button-stylesDialog cursor-pointer windowClose"
          onClick={handleClose}
        />
      </DialogTitle>
      <DialogContent dividers>
        <Box>
          {/* Record Data */}
          <Typography
            variant="h6"
            gutterBottom
            sx={{ fontSize: "1rem", fontWeight: "bold", paddingY: 1 }}
          >
            Record Data
          </Typography>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <FormControl fullWidth>
                <Autocomplete
                  disablePortal
                  autoSelect={false}
                  disableClearable
                  options={recordView}
                  getOptionLabel={(option) => option.label}
                  isOptionEqualToValue={(option, value) =>
                    option.label === value.label
                  }
                  value={formData?.recordView}
                  onChange={(e, value) =>
                    handleAutocompleteChange("recordView", value)
                  }
                  size="small"
                  renderInput={(params) => (
                    <TextField {...params} label="Status" />
                  )}
                />
              </FormControl>
            </Grid>
            <Grid item xs={6}>
              <TextField
                fullWidth
                label="Contact Type"
                value="Emergency"
                variant="outlined"
                size="small"
                disabled
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                fullWidth
                label="Record Status"
                value="Active"
                variant="outlined"
                size="small"
                disabled
              />
            </Grid>
            <Grid item xs={6}></Grid>
          </Grid>

          {/* Contact */}
          <Typography
            variant="h6"
            gutterBottom
            sx={{ fontSize: "1rem", fontWeight: "bold", paddingY: 1 }}
          >
            Contact Data
          </Typography>
          <Grid container spacing={2} className="!mb-3">
            <Grid item xs={6}>
              <TextField
                fullWidth
                label="Title"
                value="Mr."
                variant="outlined"
                size="small"
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                fullWidth
                label="Suffix"
                value="Junior"
                variant="outlined"
                size="small"
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                fullWidth
                label="First Name"
                value="Neel"
                variant="outlined"
                size="small"
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                fullWidth
                label="Last Name"
                value="Manikanta"
                variant="outlined"
                size="small"
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                fullWidth
                label="Primary Phone"
                value="(770) 929-9033"
                variant="outlined"
                size="small"
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                fullWidth
                label="Primary Email"
                value="test@orbithc.com"
                variant="outlined"
                size="small"
              />
            </Grid>
          </Grid>
        </Box>
      </DialogContent>
      <DialogActions sx={{ m: 0, p: 2 }}>
        <Button
          onClick={handleClose}
          className="button-styles !min-w-[100px]"
          color="primary"
        >
          Update Data
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ContactsModal;
