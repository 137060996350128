import React, { useEffect, useMemo, useState } from "react";
import {
  Autocomplete,
  TextField,
  Button,
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { useSelector } from "react-redux";

const AutocompleteModal = ({
  open,
  setOpen,
  value,
  setValue,
  options,
  selectedOption,
  handleOpen,
  setRolefFun,
  setAssigneFun,
  ddlDocumentTypeChange,
  handleClose,
  isFromMore,
  roleList,
  assignList,
  assigne,
  role,
  handleSaveClick,
}) => {
  const loginDetails = useSelector((state) => state.loginDetails);

  const details = useMemo(() => {
    if (loginDetails) {
      return JSON.parse(loginDetails);
    }
    return null;
  }, [loginDetails]);

  const createdByData = useMemo(() => {
    if (details?.aIStaffList && details.aIStaffList[0]?.code !== "0") {
      return [{ description: "All", code: "0" }, ...details.aIStaffList];
    }
    return details?.aIStaffList || [];
  }, [details]);
  return (
    <div>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        sx={{
          "& .MuiDialog-paper": {
            width: "550px",
            maxWidth: "90vw",
          },
        }}
      >
        <DialogTitle id="customized-dialog-title">
          <CloseIcon
            aria-label="close"
            onClick={() => handleClose("close")}
            className="button-stylesDialog cursor-pointer windowClose"
          />
        </DialogTitle>

        {isFromMore ? (
          <>
            <DialogContent dividers>
              <Autocomplete
                options={roleList || []}
                value={role}
                getOptionLabel={(option) => option.code}
                isOptionEqualToValue={(option, value) =>
                  option.code === value.code
                }
                onChange={setRolefFun}
                disableClearable
                name="Role"
                id="Role"
                className="!my-3"
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Role"
                    variant="outlined"
                    name="Role"
                    id="Role"
                    size="small"
                  />
                )}
              />
              <Autocomplete
                options={assignList || []}
                value={assigne}
                getOptionLabel={(option) => option.description}
                isOptionEqualToValue={(option, value) =>
                  option.description === value.description
                }
                onChange={setAssigneFun}
                disableClearable
                name="Assign To"
                id="Assign To"
                className="!mt-6 !mb-3"
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Assign To"
                    variant="outlined"
                    name="Assign To"
                    id="Assign To"
                    size="small"
                  />
                )}
              />
            </DialogContent>
            <DialogActions sx={{ m: 0, p: 2 }}>
              <Button
                variant="outlined"
                className="button-styles"
                onClick={() => handleSaveClick()}
              >
                Save
              </Button>
            </DialogActions>
          </>
        ) : (
          <>
            <DialogContent dividers>
              <Autocomplete
                options={options || []}
                value={selectedOption}
                getOptionLabel={(option) => option.name}
                isOptionEqualToValue={(option, value) =>
                  option.name === value.name
                }
                onChange={ddlDocumentTypeChange}
                disableClearable
                name="Select File Type"
                id="Select File Type"
                className="!my-3"
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Select File Type"
                    variant="outlined"
                    name="Select File Type1"
                    id="Select File Type1"
                    size="small"
                  />
                )}
              />
            </DialogContent>
            <DialogActions sx={{ m: 0, p: 2 }}>
              <Button
                variant="outlined"
                className="button-styles"
                onClick={handleClose}
              >
                Download
              </Button>
            </DialogActions>
          </>
        )}
      </Dialog>
    </div>
  );
};

export default AutocompleteModal;
