import React, { useEffect, useState } from "react";
import {
  Backdrop,
  CircularProgress,
  FormControl,
  Grid,
  Button,
  IconButton,
  InputAdornment,
  InputLabel,
  OutlinedInput,
  TextField,
  Typography,
  FormControlLabel,
  Checkbox,
} from "@mui/material";

import orbitLogin from "../images/orbitLogin.jpg";
import { useNavigate } from "react-router-dom";
import { useFormik } from "formik";
import * as yup from "yup";
import { UserLogin } from "../apis/login";
import Loader from "../components/Loader";
import LoaderComp from "../components/LoaderComp";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import axios from "axios";
import { toast } from "react-toastify";
import { baseurl } from "../apis/Urls";
import { LoginAPI } from "../apis/Urls";
import Notification from "../components/Notification";
import { useSearchParams } from "react-router-dom";
import { appVersion } from "../versionValue";
import { fetchGeoData } from "../apis/login";
import { handleLoginSubmit } from "./login/loginHandler";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { useDispatch } from "react-redux";
import {
  setLoginDetailsLookup,
  setLoginTokenLookup,
  setAccessRights,
} from "../redux/actions/loginDetails";
import { persistor } from "../redux/store";
import Constants from "../redux/constants";
import { setActiveComponent } from "../redux/actions";

import { GetModuleRoute } from "../../src/components/utils/activeComponent";
import { setSegmentId } from "../redux/actions/segmentId";
import { setModuleId } from "../redux/actions/moduleId";
const textfieldStyles = {
  marginTop: "15px",
};

const imageStyles = {
  height: "100vh",
  objectFit: "cover",
};

const heading = {
  color: "#20c2ec",
  fontSize: "24px",
  fontWeight: "700",
};

const Login = () => {
  const dispatch = useDispatch();
  const [openConfirmDialog, setOpenConfirmDialog] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [sessionOutMessage, setSessionOutMessagee] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [rememberMe, setRememberMe] = useState(false);
  const [notify, setNotify] = useState({
    isOpen: false,
    message: "",
    type: "error",
  });

  const handleClickShowPassword = () => setShowPassword((show) => !show);
  const { data, mutateAsync, isLoading } = UserLogin();
  const [searchParams] = useSearchParams();
  var seluserName = "";
  var selpasswordVaible = "";
  var url = window.location.href;
  var queryString = url ? url.split("?")[1] : "";
  queryString = queryString ? queryString.split("&") : "";
  seluserName = queryString
    ? queryString.length > 0
      ? queryString[0]
      : ""
    : "";
  selpasswordVaible = queryString
    ? queryString.length > 1
      ? queryString[1]
      : ""
    : "";

  useEffect(() => {

    persistor.purge();
    const sessionExpiredMessage = sessionStorage.getItem(
      "sessionExpiredMessage"
    );
    if (sessionExpiredMessage) {
      setSessionOutMessagee(sessionExpiredMessage);
      sessionStorage.removeItem("sessionExpiredMessage");
    }

    // Check if credentials are stored in localStorage
    const storedUsername = localStorage.getItem("userName");
    const storedCompanyCode = localStorage.getItem("companyCode");
    const storedRememberMe = localStorage.getItem("rememberMe") === "true";

    if (storedUsername && storedCompanyCode && storedRememberMe) {
      setFieldValue("userName", storedUsername);
      setFieldValue("companyCode", storedCompanyCode);
      setRememberMe(storedRememberMe);
    }
  }, []);

  useEffect(() => {
    if (data && data?.status?.code !== 200) {
      if (data && data?.status?.code === -9999) {
        setErrorMessage(data?.status?.message);
        setOpenConfirmDialog(true);
      } else if (data && data?.status?.code == 500) {
        setNotify({
          isOpen: true,
          message: `Internal Server Error`,
          type: "error",
        });
      } else if (data && data?.status?.code == undefined) {
        setNotify({
          isOpen: true,
          message: `Internal Server Error. please contact support team`,
          type: "error",
        });
      } else {
        setNotify({
          isOpen: true,
          message: `${data?.status?.message}`,
          type: "error",
        });
      }
    }
    if (data && data?.status?.code === 200) {
      sessionStorage.setItem(
        "token",
        JSON.stringify(data?.data?.organizationSetting)
      );
      sessionStorage.setItem(
        "companyCode",
        JSON.stringify(data.data.companyCode)
      );
      sessionStorage.setItem("userName", JSON.stringify(data.data.userName));
      sessionStorage.setItem("orgId", JSON.stringify(data.data.orgId));
      sessionStorage.setItem("companyId", JSON.stringify(data.data.companyId));
      const segs = data?.data?.segs;
      dispatch(setAccessRights(JSON.stringify(segs)));
      const details = { ...data.data, segs: null };
      dispatch(setLoginDetailsLookup(JSON.stringify(details)));
      // dispatch({ type: Constants.CLEAR_ACTIVE_COMPONENT });
      dispatch(setActiveComponent("/PATIENT-LIST"));
      if (
        data.data != null &&
        (data.data?.aiAccessRights?.tscs == 1 ||
          data.data?.aiAccessRights?.tsrte == 1 ||
          data.data?.aiAccessRights?.tsera == 1 ||
          data.data?.aiAccessRights?.tscsr == 1)
      ) {
        window.location.href = "/home";
      } else if (url.toLowerCase().includes("login")) {
        window.location.href = "/home";
      }

      const segmentIns = segs.find((s) => s.segId === 80009);
      const moduleInsuranceDetails = segmentIns?.mods.find(
        (m) => m.modId === 70180
      );

      const segmentReg = segs.find((s) => s.segId === 80021);
      const moduleregDetails = segmentReg?.mods.find((m) => m.modId === 70179);
      dispatch(setSegmentId(80021));
      dispatch(setModuleId(70179));
      if (moduleregDetails != undefined && moduleregDetails != null) {
        const newComponentInsList = GetModuleRoute(70179);
        // dispatch(setSegmentId(80021));
        // dispatch(setPreviousSegmentId(80021));
        // dispatch(setModuleId(70179));
        // dispatch(setPreviousModuleId(70179));
        dispatch(setActiveComponent(newComponentInsList));
        // window.location.href = (newComponentInsList);
      } else if (
        moduleInsuranceDetails != undefined &&
        moduleInsuranceDetails != null
      ) {
        const newComponentInsList = GetModuleRoute(70180);
        window.location.href = newComponentInsList;
      }
      window.location.href = "/home";
    }
  }, [data]);

  let schema = yup.object({
    companyCode: yup.string().trim(),
    userName: yup.string().trim(),
    password: yup.mixed(),
  });

  function rememberMeFunc() {
    if (rememberMe) {
      // Store credentials in localStorage if "Remember Me" is checked
      localStorage.setItem("companyCode", values.companyCode);
      localStorage.setItem("userName", values.userName);
      localStorage.setItem("rememberMe", rememberMe);
    } else {
      // Clear localStorage if "Remember Me" is not checked
      localStorage.removeItem("username");
      localStorage.removeItem("companyCode");
      localStorage.removeItem("rememberMe");
    }
  }

  const { values, handleSubmit, handleChange, errors, touched, setFieldValue } =
    useFormik({
      initialValues: {
        companyCode: seluserName,
        userName: selpasswordVaible,
        password: "",
      },
      validationSchema: schema,

      onSubmit: async (values) => {
        // try {
        //   setLoading(true);
        //   const response = await axios.post(`${baseurl}${LoginAPI}`, values);
        //   setLoading(false);
        //   if (response.data.Status.Code == 200) {
        //     sessionStorage.setItem(
        //       "token",
        //       JSON.stringify(response.data.Data.OrganizationSetting)
        //     );
        //     sessionStorage.setItem("details", JSON.stringify(response.data.Data));
        //     navigation("/patient-list");
        //     return response.data.Data;
        //   } else {
        //     toast.error(`${response.data.Status.Message}`);
        //   }
        // } catch (error) {
        //   toast.error(error);
        // }
        if (values.companyCode == "") {
          setNotify({
            isOpen: true,
            message: "Enter company code",
            type: "error",
          });
        } else if (values.userName == "") {
          setNotify({
            isOpen: true,
            message: "Enter username",
            type: "error",
          });
        } else if (values.password == "") {
          setNotify({
            isOpen: true,
            message: "Enter password",
            type: "error",
          });
        } else {
          await handleLoginSubmit(
            mutateAsync,
            values.companyCode,
            values.userName,
            values.password,
            "L",
            "N",
            rememberMeFunc
          );
        }
      },
    });

  const handleForceLogin = async () => {
    await handleLoginSubmit(
      mutateAsync,
      values.companyCode,
      values.userName,
      values.password,
      "L",
      "Y",
      rememberMeFunc
    );
  };

  const handleCloseConfirmDialog = () => {
    setOpenConfirmDialog(false);
  };

  return (
    <>
      <Grid container style={{ height: "100vh" }}>
        <Grid
          item
          sm={6}
          className="hidden sm:block flex justify-center items-center"
        >
          <div className="relative">
            <img
              src={orbitLogin}
              alt="logo"
              style={imageStyles}
              className="LoginBackgroundImage"
            />
            <div className="overflow-text ">
              Meet the <span style={{ color: "#09c1ea" }}>smarter</span> way to
              capture verify and process insurance
            </div>
          </div>
        </Grid>
        <Grid item sm={6} gap={4} className="flex justify-center items-center">
          <div className="md:w-80 mx-6 logintaleWindow">
            {sessionOutMessage && (
              <div className="text-[16px] text-center mb-4 text-[#ff0000]">
                {sessionOutMessage}
              </div>
            )}

            <div className="mb-2 flex justify-between items-center">
              <div className="mb-1">
                <p style={heading}>Sign in to Orbit</p>
              </div>
              <div>
                <Typography
                  variant="caption"
                  display="block"
                  className="!font-semibold"
                >
                  Version {appVersion}
                </Typography>
              </div>
            </div>

            <form onSubmit={handleSubmit}>
              <div>
                <TextField
                  style={textfieldStyles}
                  className="loginTextfields"
                  fullWidth
                  id="outlined-basic"
                  autoFocus
                  label="Company code"
                  autoComplete="off"
                  variant="outlined"
                  size="small"
                  value={values.companyCode}
                  onChange={handleChange("companyCode")}
                  // required
                  error={touched.companyCode && errors.companyCode}
                />
                <TextField
                  className="loginTextfields"
                  fullWidth
                  id="outlined-basic-text"
                  label="User name"
                  variant="outlined"
                  size="small"
                  autoComplete="off"
                  style={textfieldStyles}
                  value={values.userName}
                  onChange={handleChange("userName")}
                  error={touched.userName && errors.userName}
                // required
                />
                <FormControl
                  fullWidth
                  variant="outlined"
                  style={textfieldStyles}
                >
                  <InputLabel
                    htmlFor="outlined-adornment-password"
                    size="small"
                  >
                    Password
                  </InputLabel>
                  <OutlinedInput
                    value={values.password}
                    onChange={handleChange("password")}
                    error={touched.password && errors.password}
                    id="outlined-adornment-password"
                    autoComplete="off"
                    size="small"
                    type={showPassword ? "text" : "password"}
                    endAdornment={
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={handleClickShowPassword}
                          // onMouseDown={handleMouseDownPassword}
                          edge="end"
                        >
                          {showPassword ? <Visibility /> : <VisibilityOff />}
                        </IconButton>
                      </InputAdornment>
                    }
                    label="Password"
                  />
                </FormControl>
              </div>
              {/* <div className="flex justify-end">
              <a
                href="/"
                className="text-sm mt-2 font-normal forgot-password-styles"
              >
                Forgot password?
              </a>
            </div> */}

              <div className="mt-6">
                <button
                  className="login-button"
                  type="submit"
                  disabled={isLoading}
                >
                  Sign in
                </button>
              </div>
            </form>
            <div>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={rememberMe}
                    onChange={(e) => setRememberMe(e.target.checked)}
                    sx={{
                      paddingRight: "4px",
                    }}
                    color="primary"
                  />
                }
                label="Remember Me"
              />
            </div>
          </div>
        </Grid>
        <Backdrop
          sx={{ color: "#1a73e8", zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={isLoading}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
        <Notification notify={notify} setNotify={setNotify} />
      </Grid>

      <Dialog
        open={openConfirmDialog}
        onClose={handleCloseConfirmDialog}
        aria-labelledby="customized-dialog-title"
        sx={{
          "& .MuiDialog-paper": {
            width: "640px",
            maxWidth: "90vw",
          },
        }}
      >
        <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title">
          Session
        </DialogTitle>
        <DialogContent dividers>
          <Typography gutterBottom>{errorMessage}</Typography>
        </DialogContent>

        <DialogActions>
          <Button
            onClick={handleForceLogin}
            className="button-styles custom-label"
            variant="outlined"
            color="primary"
            autoFocus
          >
            Yes
          </Button>
          <Button
            onClick={handleCloseConfirmDialog}
            className="button-styles custom-label"
            variant="outlined"
            color="primary"
          >
            No
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default Login;
