import Constants from '../constants';

export const segmentIdReducer = (state = null, action) => {
    switch (action.type) {
        case Constants.SEGMENT_ID:
            return action.payload;
        default:
            return state;
    }
};
export const currentSegmentIdReducer = (state = null, action) => {
    switch (action.type) {
        case Constants.CURRENT_SEGMENT_ID:
            return action.payload;
        default:
            return state;
    }
};
export const previousSegmentIdReducer = (state = null, action) => {
    switch (action.type) {
        case Constants.PREVIOUS_SEGMENT_ID:
            return action.payload;
        default:
            return state;
    }
};