import { useDispatch } from "react-redux";
import { setAccessRights } from "../../redux/actions/loginDetails"; // Assuming you have an action creator for setting access rights

const moduleMapping = {
  70180: "/PATIENT-LIST",
  70182: "/PATIENT-LIST/CAPTURE-DRIVING-LICENSE",
  70183: "/PATIENT-LIST/SEND-SMS-OR-EMAIL",
  70181: "/PATIENT-LIST/DISCOVER-INSURANCE",
  70007: "/LOCATION",
  70029: "/STAFF",
  70176: "/PATIENT-LIST/TRANSATION-SUMMARY",
  70140: "/PATIENT-LIST/REPORTS",
  70075: "/TASK-SUMMARY",
  70112: "/DOCUMENT-SUMMARY",
  70175: "/PATIENT-LIST/DOCUMENT-VERIFICATION",
  70179: "/PATIENT-LIST/DOCUMENT-VERIFICATION",
  70142: "/PATIENT-LIST/DATA-IMPORT-MODULE",
  70177: "/PATIENT-LIST/CLAIM-SUBMIT",
  70178: "/PATIENT-LIST/CLAIM-STATUS",
  70184: "/DOCAICONFIGURATION",
};

export const GetModuleRoute = (modId) => {
  const route = Object.entries(moduleMapping).find(
    ([key, value]) => parseInt(key) === modId
  );
  return route ? route[1] : null;
};

// export const GetConfigurationId = (data) => {
//   if (data?.modId && data?.secs) {
//     const section = data.secs.find((section) =>
//       section.funcs.some((func) => func.funcId)
//     );
//     if (section) {
//       const func = section.funcs.find((func) => func.funcId);
//       if (func) {
//         if (func.confId) {
//           return func.confId;
//         }
//       }
//     }
//   }
//   return 0;
// };

// export const GetConfigurationId = (data, segId, modId, secId,funcId) => {
//   debugger;
//   let exists = 0;
//   if (data) {
//     data = JSON.parse(data);
//     if (data && segId && modId && secId) {
//       const segment = data.find((seg) => seg.segId === segId);
//       if (segment) {
//         const module = segment.mods.find((mod) => mod.modId === modId);
//         if (module) {
//           const section = module.secs.find((sec) => sec.secId === secId);
//           if (section) {
//             const funcExists = section.funcs.find((func) => func.funcId === funcId);
//             if (funcExists) {
//               exists = funcId; 
//             }
//           }
//         }
//       }
//     }
//   }
//   return exists;
// };
export const GetConfigurationId = (data, segId, modId, secId,funcId) => {

  if (!data || !segId || !modId || !secId) return 0;
  try {
    const parsedData = JSON.parse(data);
    const segment = parsedData.find((seg) => seg.segId === segId);
    const module = segment?.mods.find((mod) => mod.modId === modId);
    const section = module?.secs.find((sec) => sec.secId === secId);
    const functions = section?.funcs.find((func) => func.funcId === funcId);
    if (functions) {
      return functions.confId || 0; 
    } else {
      return 0; 
    }
  } catch (error) {
    console.error("Error parsing data:", error);
    return 0;
  }
};


export const GetSectionRights = (data, segId, modId, secId) => {
  let exists = false;
  if (data) {
    data = JSON.parse(data);
    if (data && segId && modId && secId) {
      const segment = data.find((seg) => seg.segId === segId);

      if (segment) {
        const module = segment.mods.find((mod) => mod.modId === modId);

        if (module) {
          exists = module.secs.some((sec) => sec.secId === secId);
        }
      }
    }
  }

  return exists;
};


