import React, { useMemo } from "react";
import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Grid,
  TextField,
  Box,
  Typography,
  FormControl,
  Autocomplete,
} from "@mui/material";

import CloseIcon from "@mui/icons-material/Close";
import { useSelector } from "react-redux";

const FilterModel = ({
  open,
  btnClear,
  btnClose,
  btnApply,
  formData,
  handleAutocompleteChange,
}) => {
  const loginDetails = useSelector((state) => state.loginDetails);

  const details = useMemo(() => {
    if (loginDetails) {
      return JSON.parse(loginDetails);
    }
    return null;
  }, [loginDetails]);

  // Create a new array with "All" option added only once
  const locationDataWithAll = useMemo(() => {
    const locations = details?.aILocations || [];
    return [{ companyName: "All", companyId: "0" }, ...locations];
  }, [details?.aILocations]);

  const filterBy = [
    { label: "Last Name", value: "LM" },
    { label: "Name", value: "NM" },
    { label: "Code", value: "CD" },
    { label: "Date of Birth", value: "DOB" },
  ];

  const statusData = [
    { label: "Active", value: "ACT" },
    { label: "Inactive", value: "IACT" },
    { label: "All", value: "ALL" },
  ];

  return (
    <Dialog
      open={open}
      onClose={btnClose}
      aria-labelledby="customized-dialog-title"
      sx={{
        "& .MuiDialog-paper": {
          width: "540px",
          maxWidth: "90vw",
          minHeight: "450px",
        },
      }}
    >
      <DialogTitle id="customized-dialog-title">
        Apply Filters
        <CloseIcon
          aria-label="close"
          className="button-stylesDialog cursor-pointer windowClose"
          onClick={btnClose}
        />
      </DialogTitle>
      <DialogContent dividers>
        <Box>
          <Grid container spacing={3}>
            <Grid item xs={12} className="!mt-4">
              <FormControl fullWidth>
                <Autocomplete
                  disablePortal
                  autoSelect={false}
                  disableClearable
                  options={locationDataWithAll}
                  getOptionLabel={(option) => option.companyName}
                  isOptionEqualToValue={(option, value) =>
                    option.companyName === value.companyName
                  }
                  value={formData?.location}
                  onChange={(e, value) =>
                    handleAutocompleteChange("location", value)
                  }
                  size="small"
                  renderInput={(params) => (
                    <TextField {...params} label="Location" />
                  )}
                  ListboxProps={{ style: { maxHeight: 220 } }}
                />
              </FormControl>
            </Grid>
            <Grid item xs={6}>
              <FormControl fullWidth>
                <Autocomplete
                  disablePortal
                  autoSelect={false}
                  disableClearable
                  options={filterBy}
                  getOptionLabel={(option) => option.label}
                  isOptionEqualToValue={(option, value) =>
                    option.label === value.label
                  }
                  value={formData?.filterBy}
                  onChange={(e, value) =>
                    handleAutocompleteChange("filterBy", value)
                  }
                  size="small"
                  renderInput={(params) => (
                    <TextField {...params} label="Filter By" />
                  )}
                />
              </FormControl>
            </Grid>
            <Grid item xs={6}>
              <TextField
                fullWidth
                label="Search Value"
                value=""
                variant="outlined"
                size="small"
                onChange={(e, value) =>
                  handleAutocompleteChange("searchValue", value)
                }
              />
            </Grid>
            <Grid item xs={6}>
              <FormControl fullWidth>
                <Autocomplete
                  disablePortal
                  autoSelect={false}
                  disableClearable
                  options={statusData}
                  getOptionLabel={(option) => option.label}
                  isOptionEqualToValue={(option, value) =>
                    option.label === value.label
                  }
                  value={formData?.statusData}
                  onChange={(e, value) =>
                    handleAutocompleteChange("statusData", value)
                  }
                  size="small"
                  renderInput={(params) => (
                    <TextField {...params} label="Status" />
                  )}
                />
              </FormControl>
            </Grid>
          </Grid>
        </Box>
      </DialogContent>
      <DialogActions sx={{ m: 0, p: 2 }}>
        <Button
          onClick={btnClose}
          className="button-styles !min-w-[100px]"
          color="primary"
        >
          Close
        </Button>
        <Button
          onClick={btnClear}
          className="button-styles !min-w-[100px]"
          color="primary"
        >
          Clear
        </Button>
        <Button
          onClick={btnApply}
          className="button-styles !min-w-[100px]"
          color="primary"
        >
          Apply
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default FilterModel;
