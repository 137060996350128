import React, { useState, useRef, useEffect, useMemo } from "react";
import {
  Grid,
  Box,
  Button,
  Card,
  CardContent,
  Autocomplete,
  TextField,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
  IconButton,
  Backdrop,
  CircularProgress,
  FormControl,
  RadioGroup,
  FormControlLabel,
  Radio,
  Tooltip,
  Popper,
  Divider,
} from "@mui/material";
import { TIFFViewer } from "react-tiff";
// import "react-tiff/dist/index.css";
import { ExpandMore, ArrowBackOutlined } from "@mui/icons-material";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import ClearStatesConfirmationDialog from "../importModule/ClearStatesConfirmationDialog";
import { useNavigate, useLocation } from "react-router-dom";
import {
  uploadSelectedFileAPI,
  RecognizeDocumentApi,
  DocumentValidationApi,
  DocumentVerificationApi,
  GetDocumentByIdApi,
  QuestionsPostApi,
  UpdateDocJsonAPI,
} from "../../apis/documentVerification";
// import MyContext from "../../components/MyContext";
import EditIcon from "@mui/icons-material/Edit";
import Notification from "../../components/Notification";
import { logTelemetry } from "../../logTelemetry";
import FullScreenDialog from "./FullScreenDialog";
import MyModal from "./MyModal";
import { styled } from "@mui/system";
import getUserRole from "../../components/utils/getUserRole";
import { useDispatch, useSelector } from "react-redux";
import { setActiveComponent } from "../../redux/actions/index";
import Constants from "../../redux/constants";
import {
  setdId,
  setFilteredData,
  setLookUpInfo,
} from "../../redux/actions/documentRecognizer";
import sessionMessage from "../../components/utils/sessionMessage";
import getErrorMessage from "../../components/utils/getErrorMessage";
import { baseurl } from "../../apis/Urls";

// Custom Popper component for the dropdown

const initialState = {
  lookUpData: null, // or [] based on your data structure
  dId: null,
  filteredData: [],
};

const SingleDocument = () => {
  const dispatch = useDispatch();
  const { lookUpData, filteredData, dId } = useSelector(
    (state) => state?.previousComponent?.previousComponentData
  );
  // const lookUpData = useSelector((state) => state.lookUpData);
  // const filteredData = useSelector((state) => state.filteredData);
  // const dId = useSelector((state) => state.dId);
  const location = useLocation();
  const documentTypeModel = true;
  const loginDetails = useSelector((state) => state.loginDetails);

  // let details = JSON.parse(sessionStorage?.getItem("details"));
  // let details = useSelector((state) => JSON.parse(state.loginDetails));

  let details = useMemo(() => {
    if (loginDetails) {
      return JSON.parse(loginDetails);
    }
    return null;
  }, [loginDetails]);
  // console.log(details.companyRoleName, " loginDetails ");

  const roleFlag = details.companyRoleName === "DocumentAI Demo User";
  let isEdit = false;
  let options = [];
  // if (location.pathname == "/home") {
  //   isEdit = lookUpData && dId && filteredData;                                                                                                                                                                                                                                                                                                                ;
  //   options = (lookUpData &&
  //     isEdit ? lookUpData : lookUpData
  //   )?.map((option) => ({
  //     ...option,
  //     name: option?.description,
  //     value: typeof option?.id !== "string" ? `${option?.id}` : option?.id,
  //   }));
  // }
  if (location.pathname === "/home") {
    isEdit = lookUpData && dId && Array.isArray(filteredData);
    // debugger;
    // options = Array.isArray(lookUpData) ?
    //   lookUpData.map((option) => ({
    //     ...option,
    //     name: option?.description,
    //     value: typeof option?.id !== "string" ? `${option?.id}` : option?.id,
    //   })) : [];

    options = (isEdit ? lookUpData : lookUpData?.inputTypeList)?.map(
      (option) => ({
        ...option,
        name: option.description,
        value: typeof option.id !== "string" ? `${option.id}` : option.id,
      })
    );
  } else {
    isEdit = Object.keys(location?.state).length === 3;
    options = (
      isEdit ? location?.state?.lookUpData : location.state.inputTypeList
    )?.map((option) => ({
      ...option,
      name: option.description,
      value: typeof option.id !== "string" ? `${option.id}` : option.id,
    }));
  }
  let intialAccData = [
    { title: "Extract Details", details: "" },
    { title: "Prompt Details", details: "" },
    { title: "Final Details", details: "" },
    { title: "Document Details", details: "" },
    { title: "Raw Data", details: "" },
    { title: "Validate Details", details: "" },
    // { title: "Verify Details", details: "" },
  ];
  if (roleFlag) {
    intialAccData = [{ title: "Final Details", details: "" }];
  }
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const { mutateAsync: uploadFileApiCall } = uploadSelectedFileAPI();
  const { mutateAsync: RecognizeDocumentApiCall } = RecognizeDocumentApi();
  const { mutateAsync: GetDocumentByIdApiCall } = GetDocumentByIdApi();
  const { mutateAsync: QuestionsPostApiCall } = QuestionsPostApi();
  const { mutateAsync: UpdateDocJsonAPICall } = UpdateDocJsonAPI();
  const { mutateAsync: DocumentVerificationApiCall } =
    DocumentVerificationApi();
  const { mutateAsync: DocumentValidationApiCall } = DocumentValidationApi();
  const [fileName, setFileName] = useState("");
  const [fileType, setFileType] = useState("");
  const [validateData, setValidateData] = useState({});
  const [expanded, setExpanded] = useState([]);
  const [tableData, setTableData] = useState([]);
  const [questionInput, setQuestionInput] = useState("");
  const [finalData, setFinalData] = useState({});
  const [faqs, setFaqs] = useState([]);
  // const [documentId, setDocumentId] = useState(
  //   isEdit ? location?.state?.dId : "0"
  // );
  // const [listOfDids, setListOfDids] = useState(
  //   isEdit ? location?.state?.filteredData : []
  // );
  const [kvExe, setKvExe] = useState("");
  const [llmExe, setLlmExe] = useState("");
  const [validate, setValidate] = useState("");
  const [verify, setVerify] = useState("");

  const handleInputChangeLatest = (setter) => (e) => {
    const value = event.target.value;
    if (value === "0" || value === "1" || value === "") {
      setter(value); // Allow only 0, 1, or empty for clearing
    }
  };
  const [documentId, setDocumentId] = useState(isEdit ? dId : "0");
  const [listOfDids, setListOfDids] = useState(isEdit ? filteredData : []);
  const [accordionData, setAccordionData] = useState(intialAccData);
  const [fileSize, setFileSize] = useState("");
  const [fileData, setFileData] = useState("");
  const [notify, setNotify] = useState({
    isOpen: false,
    message: "",
    type: "error",
  });

  const [selectedFile, setSelectedFile] = useState("");
  const [extractData, setExtractData] = useState([]);
  const [promptData, setPromptData] = useState({});
  const [detectData, setDetectData] = useState({});
  const [finalJson, setFinalJson] = useState({});
  const [wholeRes, setWholeRes] = useState(null);
  const [documentData, setDocumentData] = useState({});
  const [isDetectClicked, setIsDetectClicked] = useState(false);
  const [isEditFinal, setIsEditFinal] = useState(false);
  const [selectedOption, setSelectedOption] = useState(null);
  const [firstApiData, setFirstApiData] = useState(null);
  const [firstApiCalled, setFirstApiCalled] = useState(!!isEdit);
  const [pdfURL, setPdfURL] = useState("");
  const [isPDF, setIsPDF] = useState(false);
  const [loading, setLoading] = useState(false);
  const [modelOpen, setModelOpen] = useState(false);
  const [inputJSON, setInputJSON] = useState("");
  const [parsedJSON, setParsedJSON] = useState([]);
  const [tempObj, setTempObj] = useState({});
  const imageContainerRef = useRef(null);
  const inputRef = useRef(null);
  const [selectedModel, setSelectedModel] = useState("Old"); //"Old"
  // const [currentIndex, setCurrentIndex] = useState(
  //   isEdit ? location?.state?.filteredData.indexOf(location?.state?.dId) : 0
  // ); // Current index in the list

  const [currentIndex, setCurrentIndex] = useState(
    isEdit ? filteredData.indexOf(dId) : 0
  );

  const radioModelChange = (event) => {
    setSelectedModel(event.target.value);
  };
  let zoomLevel = 1.0;
  const intial = {
    open: false,
    title: "",
    subTitle: "",
    type: "",
  };
  const [dialogState, setDialogState] = useState(intial);
  const [iPatientInsurance, setIPatientInsurance] = useState({
    PatientFirstName: "",
    PatientLastName: "",
    PatientDob: "",
    PatientGender: "",
    AddressLine1: "",
    CityTown: "",
    PostalCode: "",
    StateProvinceCode: "",
    SubscriberId: "",
    PayerName: "",
    PayerId: "",
    SecndSubscriberId: "",
    SecndPayerName: "",
    SecndPayerId: "",
  });

  const [selectedView, setSelectedView] = useState("viewraw"); // Default to "view" or "edit" as needed
  const [backUp, setbackUp] = useState(); // Default to "view" or "edit" as needed
  const updateFirstThreeItems = (data, sections) => {
    return data.map((item, index) =>
      [0, 1, 2].includes(index)
        ? { ...item, details: generateSectionsData(sections, 2) }
        : item
    );
  };

  const handleRadioChange = (event) => {
    const val = event.target.value;
    if (val === "viewmodified" && wholeRes && wholeRes?.modifiedDetectJSON) {
      const sdfads = JSON.parse(wholeRes?.modifiedDetectJSON);
      setAccordionData((prev) =>
        updateFirstThreeItems([...prev], sdfads.Sections)
      );
    } else if (val === "viewraw") {
      const tt = JSON.parse(wholeRes?.detectJSON);
      setAccordionData((prev) => updateFirstThreeItems([...prev], tt.Sections));
    }
    setSelectedView(event.target.value);
  };

  const handleClose = () => {
    setDialogState(intial);
  };

  useEffect(() => {
    // Update the current document ID based on the current index
    if (isEdit) {
      setDocumentId(listOfDids[currentIndex] || "0");
      getDocumentData(listOfDids[currentIndex] || "0", false);
    }
  }, [currentIndex, listOfDids]);

  const handleNext = () => {
    if (currentIndex < listOfDids.length - 1) {
      setCurrentIndex(currentIndex + 1);
      setSelectedView("viewraw");
    }
  };

  const handlePrevious = () => {
    if (currentIndex > 0) {
      setCurrentIndex(currentIndex - 1); // aur changes
      setSelectedView("viewraw");
    }
  };

  const [fieldValues, setFieldValues] = useState({});

  function updatedAccordionData(details, SectionName, fieldName, newValue) {
    // debugger;
    return details.map((section) => {
      if (section.SectionName === SectionName) {
        return {
          ...section,
          Fields: section.Fields.map((field) => {
            if (field.fieldName === fieldName) {
              return {
                ...field,
                FieldFinalValue: newValue, // Update the field value
              };
            }
            return field;
          }),
        };
      }
      // debugger;
      return section;
    });
  }

  const transformData = (inputArray) => {
    if (inputArray.length > 0) {
      const result = [];
      let currentIndex = null;
      let currentObj = {};

      inputArray?.forEach((item) => {
        // Extract the base field name and index from fieldName (e.g., "Test_Name_1")
        const match = item.fieldName.match(/(.+)_(\d+)/); // Match "Test_Name" and "1"
        if (!match) return;

        const [, baseField, index] = match;

        // If the index changes, save the current object and start a new one
        if (currentIndex !== index) {
          if (Object.keys(currentObj).length > 0) {
            result.push(currentObj);
          }
          currentIndex = index;
          currentObj = {};
        }

        // Assign the key-value pair to the current object
        currentObj[baseField] = item.FieldFinalValue || "";
      });

      // Push the last object after the loop
      if (Object.keys(currentObj).length > 0) {
        result.push(currentObj);
      }

      return result;
    }
  };

  function updatedSubAccordionData(
    details,
    SectionName,
    fieldName,
    newValue,
    a,
    bb,
    ccc,
    dd
  ) {
    // debugger;
    return [...details].map((section) => {
      if (section.SectionName === SectionName) {
        return {
          ...section,
          Fields: section.Fields.map((field) => {
            if (field.fieldName === fieldName) {
              return {
                ...field,
                FieldFinalValue: field.FieldFinalValue.map((subField) => {
                  if (subField.fieldName === ccc) {
                    return {
                      ...subField,
                      FieldFinalValue: newValue,
                    };
                  } else {
                    return { ...subField };
                  }
                }), // Update the field value
              };
            }
            return field;
          }),
        };
      }
      // debugger;
      return section;
    });
  }

  function handleInputChange(
    SectionName,
    Fields,
    fieldName,
    fieldIndex,
    value,
    index,
    name
  ) {
    setAccordionData((prevData) =>
      prevData.map((item, index) => {
        if (index === 2) {
          return {
            ...item,
            details: updatedAccordionData(
              item.details,
              SectionName,
              fieldName,
              value
            ),
          };
        }
        return item; // Return all other items unchanged
      })
    );
  }

  function handleSubInputChange(
    SectionName,
    Fields,
    fieldName,
    fieldIndex,
    value,
    indexx,
    name,
    name1,
    name2
  ) {
    setAccordionData((prevData) =>
      prevData.map((item, index) => {
        if (index === 2) {
          let returned = updatedSubAccordionData(
            item.details,
            SectionName,
            fieldName,
            value,
            Fields,
            index,
            indexx
          );
          return {
            ...item,
            details: returned,
          };
        }
        return item; // Return all other items unchanged
      })
    );
  }

  async function getDocumentData(dID, isSkip) {
    setLoading(true);
    const payload = {
      Id: parseInt(dID ?? documentId),
      configId: "91234",
      commonParams: {
        organizationID: details?.orgId,
        companyID: details?.companyId,
        coCode: details?.companyCode,
        groupID: details?.groupID,
        timeZone: details?.timeZone,
        sessionId: details?.sesionId,
        loginUserId: `${details?.userId}`,
        loginUserName: details?.userName,
        AuditTrail: 0,
        editTrail: 0,
        dataSource: "",
      },
    };
    try {
      const res = await GetDocumentByIdApiCall(payload);
      if (res.status.code === 200) {
        const resultObj = res?.data;
        setWholeRes(resultObj);
        if (isSkip) {
          setDocumentData(resultObj);
        } else {
          setDocumentData(resultObj);
          let promiseResult = await getFileExtension(resultObj?.fileStream);
          setFileType(promiseResult);
          if (promiseResult === "application/pdf") {
            const blobResponse = await fetch(resultObj?.fileStream);
            const arrayBuffer = await blobResponse.arrayBuffer();
            const blob = new Blob([arrayBuffer], {
              type: "application/pdf",
            });
            const data = window.URL.createObjectURL(blob);
            setPdfURL(data);
            setIsPDF(true);
            // setTimeout(() => {
            //   window.URL.revokeObjectURL(data);
            // }, 1000);
          } else {
            setIsPDF(false);
            // if (promiseResult.includes()) {
            const fileUrl = resultObj?.fileStream;
            //   setFileData(fileUrl);
            // } else {
            setFileData(fileUrl);
            // }
          }
          if (resultObj.documentAction === "1") {
            setExtractData(res?.data);
            const parsed = JSON.parse(resultObj?.extractJSON || "{}");
            const parsedfinal = JSON.parse(resultObj?.detectJSON || "{}");
            setAccordionData((prev) =>
              intialAccData.map((item, index) =>
                index === 3
                  ? { ...item, details: parsed?.rawText }
                  : [0, 2].includes(index)
                  ? {
                      ...item,
                      details: generateSectionsData(
                        parsedfinal.Sections,
                        index
                      ),
                    }
                  : index === 4
                  ? { ...item, details: generateDetails(parsed) }
                  : item
              )
            );
            setExpanded([0]);
          } else if (resultObj?.documentAction === "2") {
            const parsed = JSON.parse(resultObj.promptJSON || "{}");
            const parsedfinal = JSON.parse(resultObj?.detectJSON || "{}");
            setAccordionData((prev) =>
              intialAccData.map((item, index) =>
                [1, 2].includes(index)
                  ? {
                      ...item,
                      details: generateSectionsData(
                        parsedfinal.Sections,
                        index
                      ),
                    }
                  : item
              )
            );
            setFaqs(parsed?.data);
            setExpanded([1]);
          } else {
            const parsed = JSON.parse(resultObj.promptJSON || "{}");
            const extractJson = JSON.parse(resultObj?.extractJSON || "{}");
            setFinalJson(extractJson);
            const parsedfinal = JSON.parse(resultObj?.detectJSON || "{}");
            updateAccordionData(parsed, parsedfinal, extractJson, resultObj);
            setFaqs(parsed?.data);
            setFieldValues(
              parsedfinal?.Sections?.reduce((acc, section) => {
                acc[section?.SectionName] = section?.Fields?.reduce(
                  (fieldAcc, field) => {
                    fieldAcc[field?.FieldName] = field?.FieldFinalValue;
                    return fieldAcc;
                  },
                  {}
                );
                return acc;
              }, {})
            );
            setFinalData(parsedfinal);
            // let data = parsedfinal?.Sections.reduce((acc, section) => {
            //   return acc.concat(section.Fields);
            // }, []);
            // const values = updateInsObj(data);
            // const { SubscriberId, PayerName, PayerId } = values;
            // setIPatientInsurance(values);
            // debugger;
            setExpanded(
              Object.keys(parsedfinal).length > 0 ? (roleFlag ? [0] : [2]) : []
            );
            setLoading(false);
            setIsDetectClicked(true);
          }
          const val = options?.filter(
            (option) => option.value === resultObj?.fileTypeCode
          );
          setLoading(false);
          setFileName(resultObj.documentName);
          if (val?.length > 0) {
            setSelectedOption(val?.[0]);
          }
        }
      } else {
        setLoading(false);
        logTelemetry(
          "Failed",
          "SingleDocument",
          "getDocumentData",
          res?.status?.message ?? res?.response?.data?.title
        );
        setDialogState({
          open: true,
          title: "fail",
          subTitle: res.status.message ?? "Second API is also fail",
          type: "single",
        });
        if (res?.status?.message?.includes("session")) {
          setTimeout(() => {
            window?.sessionStorage?.clear();
            window?.localStorage?.clear();
            window.location.href = "/login";
          }, 1500);
        }
      }
    } catch (e) {
      setLoading(false);
      logTelemetry("Failed", "SingleDocument", "getDocumentData", e.message, e);
      setNotify({
        isOpen: true,
        message: e.message,
        type: "error",
      });
      setDialogState({
        open: true,
        title: "Error",
        subTitle: "getDocumentData : " + e.message,
        type: "single",
      });
    }
  }

  async function getFileExtension(url) {
    //getting document type by using magic numbers
    try {
      const response = await fetch(url);

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      // Read the first few bytes
      const arrayBuffer = await response.arrayBuffer();
      const uintArray = new Uint8Array(arrayBuffer);

      // Determine the MIME type based on magic numbers
      let typeOfFile = "application/octet-stream"; // Default MIME type
      if (uintArray[0] === 0xff && uintArray[1] === 0xd8) {
        typeOfFile = "image/jpeg"; // JPEG
      } else if (
        uintArray[0] === 0x89 &&
        uintArray[1] === 0x50 &&
        uintArray[2] === 0x4e &&
        uintArray[3] === 0x47
      ) {
        typeOfFile = "image/png"; // PNG
      } else if (
        uintArray[0] === 0x25 &&
        uintArray[1] === 0x50 &&
        uintArray[2] === 0x44 &&
        uintArray[3] === 0x46
      ) {
        typeOfFile = "application/pdf"; // PDF
      } else if (
        (uintArray[0] === 0x4d &&
          uintArray[1] === 0x4d &&
          uintArray[2] === 0x00 &&
          uintArray[3] === 0x2a) || // TIFF Big Endian
        (uintArray[0] === 0x49 &&
          uintArray[1] === 0x49 &&
          uintArray[2] === 0x2a &&
          uintArray[3] === 0x00) // TIFF Little Endian
      ) {
        typeOfFile = "image/tiff"; // TIFF
      }
      return typeOfFile;
    } catch (error) {
      console.error("Error fetching the file:", error);
    }
  }

  function handleModalClose(e, reason) {
    if (reason && reason == "backdropClick" && "escapeKeyDown") return;
    setModelOpen(false);
  }
  const handleInputChangee = (event) => {
    setInputJSON(event.target.value);
  };

  const handleBtnBackClick = () => {
    dispatch(setActiveComponent("/patient-list/document-verification"));
    // dispatch(setLookUpInfo(null));
    // dispatch(setdId(null));
    // dispatch(setFilteredData(null));
  };

  const handleContinue = () => {
    try {
      const parsedData = JSON.parse(inputJSON);
      // console.log("Parsed JSON:", parsedData);
      setParsedJSON(parsedData);
      setSelectedOption(tempObj);
      handleModalClose();
    } catch (e) {
      // setNotify(`Invalid JSON: ${e.message}`);
      setNotify({
        isOpen: true,
        message: `Invalid JSON: ${e.message}`,
        type: "warning",
      });
    }
  };

  // Function to update accordion data
  function updateAccordionData(parsed, parsedfinal, extractJson, resultObj) {
    try {
      let updatedData = [];
      if (roleFlag) {
        updatedData = [
          {
            title: "Final Details",
            details: generateSectionsData(parsedfinal.Sections, 2),
          },
        ];
      } else {
        updatedData = accordionData.map((item, index) => {
          const transactionSummaryExists = !!resultObj?.transactionSummary;
          const rawDataIndex = transactionSummaryExists ? 4 : 3;
          const documentDetailsIndex = transactionSummaryExists ? 6 : 5;

          if (index === 3 && transactionSummaryExists) {
            return {
              title: "Transaction Summary",
              details: JSON.parse(resultObj?.transactionSummary),
            };
          } else if ([0, 1, 2].includes(index)) {
            return {
              ...item,
              details: generateSectionsData(parsedfinal?.Sections, index),
            };
          } else if (index === rawDataIndex) {
            return { title: "Raw Data", details: parsed?.ocrResults?.rawText };
          } else if (index === documentDetailsIndex) {
            return {
              title: "Document Details",
              details: generateDetails(extractJson),
            };
          } else {
            return item;
          }
        });
      }

      const newTitle = "Validated Insurance Details";
      const newDetails = resultObj.validateJSON
        ? JSON.stringify(JSON.parse(resultObj.validateJSON), undefined, 4)
        : null;

      // Check if the object with the title already exists in updatedData
      const existingIndex = updatedData.findIndex(
        (item) => item.title === newTitle
      );

      if (resultObj.validateJSON) {
        // If valid JSON exists, add/update the item
        if (existingIndex === -1) {
          // If not found, push new item
          updatedData.push({
            title: newTitle,
            details: newDetails,
          });
        } else {
          // If it exists, move the item to the end of the array
          const [existingItem] = updatedData.splice(existingIndex, 1); // Remove the existing item
          updatedData.push({
            ...existingItem,
            details: newDetails, // Optionally update the details if needed
          });
        }
      } else {
        // If no valid JSON, remove the item with the given title
        if (existingIndex !== -1) {
          updatedData.splice(existingIndex, 1); // Remove the item from the array
        }
      }

      /////////////////////////////////
      // Variables for verifyJSON case
      const verifyTitle = "Eligibility Details";
      const verifyDetails = resultObj.verifyJSON
        ? JSON.stringify(JSON.parse(resultObj.verifyJSON), undefined, 4)
        : null;

      // Check if the object with the title already exists in updatedData
      const existingVerifyIndex = updatedData.findIndex(
        (item) => item.title === verifyTitle
      );

      if (resultObj.verifyJSON) {
        // If verifyJSON exists, add/update the item
        if (existingVerifyIndex === -1) {
          // If not found, push new item
          updatedData.push({
            title: verifyTitle,
            details: verifyDetails,
          });
        } else {
          // If it exists, move the item to the end of the array and update details
          const [existingItem] = updatedData.splice(existingVerifyIndex, 1); // Remove the existing item
          updatedData.push({
            ...existingItem,
            details: verifyDetails, // Optionally update the details if needed
          });
        }
      } else {
        // If no verifyJSON, remove the item with the given title
        if (existingVerifyIndex !== -1) {
          updatedData.splice(existingVerifyIndex, 1); // Remove the item from the array
        }
      }

      setAccordionData(updatedData);
    } catch (e) {
      setDialogState({
        open: true,
        title: "Error",
        subTitle: `updateAccordionData : ` + e.message,
        type: "single",
      });
    }
  }

  // console.log(accordionData, " accordionData ");

  useEffect(() => {
    return () => {
      // Revoke the object URL when the component unmounts
      window?.URL?.revokeObjectURL(pdfURL);
    };
  }, [pdfURL]);

  // Call the function with the necessary parameters
  // updateAccordionData(parsed, parsedfinal, extractJson, resultObj);

  const handleConfirm = (title) => {
    try {
      setDialogState(intial);
      if (title === "Warning") {
        setFileName("");
        setFileType("");
        setFileData("");
        setPdfURL("");
        setIsPDF(false);
        setFirstApiData(null);
        setFirstApiCalled(false);
        setAccordionData(intialAccData); // Assuming 'initialAccData' is the initial accordion state
        setParsedJSON([]);
        setInputJSON("");
        setExpanded([]);
        setSelectedOption(null);
        setIsDetectClicked(false);
        setDocumentId("0");
        setSelectedModel("Old");
      }
    } catch (e) {
      setDialogState({
        open: true,
        title: "Error",
        subTitle: `handleConfirm : ` + e.message,
        type: "single",
      });
    }
  };

  const handleButtonClick = (buttonFunction, param) => {
    try {
      if (selectedOption && selectedOption.id > 0) {
        if (!firstApiCalled) {
          setLoading(true);
          callPreSignApi()
            .then((res) => {
              if (res?.signData?.status?.code === 200) {
                // setLoading(false);
                // setFirstApiCalled(res?.signData);
                setFirstApiData(res?.signData);
                buttonFunction(res?.signData?.data?.fileId);
              } else {
                // debugger;
                setLoading(false);
                setDialogState({
                  open: true,
                  title: "Error",
                  subTitle: `${
                    res?.signData?.status?.message ??
                    res?.status?.message ??
                    "Connection string parsing error-PreSign Error"
                  }`,
                  type: "single",
                });
              }
            })
            .catch((e) => {
              setDialogState({
                open: true,
                title: "Error",
                subTitle: `callPreSignApi : ${
                  e.message ?? "Connection string parsing error-PreSign Error"
                }`,
                type: "single",
              });
              setLoading(false);
            });
        } else {
          buttonFunction(
            isEdit ? documentData?.blobid : firstApiData?.data?.fileId,
            param
          );
        }
      } else {
        setNotify({
          isOpen: true,
          message: `Please Select Valid File type to continue`,
          type: "warning",
        });
      }
    } catch (e) {
      setDialogState({
        open: true,
        title: "Error",
        subTitle: `handleButtonClick : ` + e.message,
        type: "single",
      });
    }
  };

  async function callPreSignApi() {
    try {
      const values = {
        mimeType: fileType,
        fileName: fileName,
        locationNumber: details?.companyId,
        file: selectedFile,
      };
      const res = await uploadFileApiCall(values);
      if (res?.signData?.status?.code == 200) {
        if (res?.response?.status === 201 || res?.response?.status === 200) {
          setFirstApiCalled(true);
          setFirstApiData(res?.signData);
          return res;
        } else {
          setDialogState({
            open: true,
            title: "Something is Breaking with PreSign",
            subTitle: res?.status?.message ?? res?.response?.data?.title,
            type: "single",
          });
          return res;
        }
      } else {
        setLoading(true);
        logTelemetry(
          "Failed",
          "SingleDocument",
          "callPreSignApi",
          res?.status?.message ?? res?.response?.data?.title
        );
        return res;
      }
    } catch (error) {
      logTelemetry(
        "Failed",
        "SingleDocument",
        "callPreSignApi",
        error.message,
        error
      );
      console.error("Error calling the first API:", error);
      setDialogState({
        open: true,
        title: "Error",
        subTitle: `callPreSignApi : ${error?.message}`,
        type: "single",
      });
      setLoading(false);
    }
  }

  const handleFileSelect = (event) => {
    event.stopPropagation();
    const file = event.target.files[0];

    if (!file) return; // Guard clause to handle empty file selection

    setFileName(file.name);
    setFileSize(file.size);
    setSelectedFile(file);

    const reader = new FileReader();

    reader.onloadend = (e) => {
      const arrayBuffer = e.target.result;
      const uintArray = new Uint8Array(arrayBuffer);

      // Determine the MIME type based on magic numbers
      let typeOfFile = "application/octet-stream"; // Default MIME type
      if (uintArray[0] === 0xff && uintArray[1] === 0xd8) {
        typeOfFile = "image/jpeg"; // JPEG
      } else if (
        uintArray[0] === 0x89 &&
        uintArray[1] === 0x50 &&
        uintArray[2] === 0x4e &&
        uintArray[3] === 0x47
      ) {
        typeOfFile = "image/png"; // PNG
      } else if (
        uintArray[0] === 0x25 &&
        uintArray[1] === 0x50 &&
        uintArray[2] === 0x44 &&
        uintArray[3] === 0x46
      ) {
        typeOfFile = "application/pdf"; // PDF
      } else if (
        (uintArray[0] === 0x4d &&
          uintArray[1] === 0x4d &&
          uintArray[2] === 0x00 &&
          uintArray[3] === 0x2a) || // TIFF Big Endian
        (uintArray[0] === 0x49 &&
          uintArray[1] === 0x49 &&
          uintArray[2] === 0x2a &&
          uintArray[3] === 0x00) // TIFF Little Endian
      ) {
        typeOfFile = "image/tiff"; // TIFF
      }
      if (typeOfFile === "application/octet-stream") {
        setNotify({
          isOpen: true,
          message: "This type is not acceptable,please contact support team",
          type: "warning",
        });
        setFileName("");
        setFileSize("");
        setSelectedFile("");
        return;
      }

      // Handle file based on detected MIME type
      if (typeOfFile === "application/pdf") {
        setIsPDF(true);
        const newBlob = new Blob([file], { type: "application/pdf" });
        const data = window.URL.createObjectURL(newBlob);
        setPdfURL(data);
      } else {
        setIsPDF(false);
        // Handle TIFF files or other types
        if (typeOfFile === "image/tiff") {
          const fileUrl = window.URL.createObjectURL(file);
          setFileData(fileUrl);
        } else if (typeOfFile.startsWith("image/")) {
          const fileUrl = URL.createObjectURL(file);
          setFileData(fileUrl);
        } else {
          setFileData(e.target.result); // Handle other file types
        }
      }

      // Set the detected MIME type
      setFileType(typeOfFile);
    };

    // Read the file as ArrayBuffer to check magic numbers
    reader.readAsArrayBuffer(file);
    event.target.value = null; // Clear the file input for future selections
  };

  const btnClearClick = () => {
    if (fileName) {
      setDialogState({
        open: true,
        title: "Warning",
        subTitle:
          "Clear : Are you sure, you want to clear all data from right side and remove document on left side ?",
        type: "double",
      });
    }
  };

  const ZoomImage = (selVal) => {
    try {
      if (imageContainerRef.current) {
        if (selVal === 0) {
          zoomLevel = Math.max(0.1, zoomLevel - 0.1); // Prevent zooming out too much
        }
        if (selVal === 1) {
          zoomLevel += 0.1;
        }
        imageContainerRef.current.style.transform = `scale(${zoomLevel})`;
      }
    } catch (error) {
      console.error("ZoomImage error:", error);
    }
  };

  const FitPage = () => {
    if (imageContainerRef.current) {
      zoomLevel = 1.0;
      imageContainerRef.current.style.transform = "scale(1.0)";
    }
  };

  function updateInsObj(updates) {
    const updatedInsurance = { ...iPatientInsurance };

    updates?.forEach((update) => {
      const { FieldDBMappingId, FieldFinalValue } = update;
      switch (FieldDBMappingId) {
        case "27814":
          updatedInsurance.PatientFirstName = FieldFinalValue;
          break;
        case "27816":
          updatedInsurance.PatientLastName = FieldFinalValue;
          break;
        case "27813":
          updatedInsurance.PatientDob = FieldFinalValue;
          break;
        case "27815":
          updatedInsurance.PatientGender = FieldFinalValue;
          break;
        case "27809":
          updatedInsurance.AddressLine1 = FieldFinalValue;
          break;
        case "27810":
          updatedInsurance.CityTown = FieldFinalValue;
          break;
        case "27819":
          updatedInsurance.PostalCode = FieldFinalValue;
          break;
        case "27818":
          updatedInsurance.StateProvinceCode = FieldFinalValue;
          break;
        case "27828":
          updatedInsurance.SubscriberId = FieldFinalValue;
          break;
        case "27824":
          updatedInsurance.PayerName = FieldFinalValue;
          break;
        case "27823": // 0 t0 23
          updatedInsurance.PayerId = FieldFinalValue;
          break;
        case "27837":
          updatedInsurance.SecndSubscriberId = FieldFinalValue;
          break;
        case "27836":
          updatedInsurance.SecndPayerName = FieldFinalValue;
          break;
        case "27835":
          updatedInsurance.SecndPayerId = FieldFinalValue;
          break;
        default:
          break;
      }
    });
    return updatedInsurance;
  }

  const handleBoxClick = () => {
    if (inputRef.current) {
      inputRef.current.click();
    }
  };

  async function updateJsonFun(count, newData) {
    // console.log(newData);
    // debugger;
    // return;
    // NOTE :- save - 1  submit - 2
    setLoading(true);
    const payload = {
      configId: "91234",
      docId: wholeRes.documentID,
      selectedFileaveTyp: count, //? save - 1  submit - 2
      commonParams: {
        organizationID: details?.orgId,
        companyID: details?.companyId,
        coCode: details?.companyCode,
        groupID: details?.groupID,
        timeZone: details?.timeZone,
        sessionId: details?.sesionId,
        loginUserId: `${details?.userId}`,
        loginUserName: details?.userName,
        AuditTrail: 0,
        editTrail: 0,
        dataSource: "",
      },
      action: wholeRes.documentAction,
      extractJson: "",
      promptJson: "",
      finalJson: "",
      // finalJson: JSON.stringify(wholeRes.detectJSON),
      modifiedFinalJson: JSON.stringify({
        ...JSON.parse(wholeRes.detectJSON),
        Sections: newData ? newData : accordionData[2].details,
      }),
      validateJson: "",
      verifyJson: "",
      transactionSummary: "",
      changeHistoryJSON: "", //??
    };
    try {
      const res = await UpdateDocJsonAPICall(payload);
      if (res?.status?.code === 200) {
        getDocumentData(dId);
        // debugger;
        // setLoading(false);
        setIsEditFinal(false);
        setSelectedView("viewraw");
        setDialogState({
          open: true,
          title: "Success",
          subTitle: res?.status?.message,
          type: "single",
        });
      } else {
        logTelemetry(
          "Failed",
          "SingleDocument",
          "updateJsonFun",
          res?.status?.message ?? res?.response?.data?.title
        );
        setLoading(false);
        setDialogState({
          open: true,
          title: "Error!",
          subTitle: getErrorMessage(res?.status?.message ?? res?.title),
          type: "single",
        });
      }
    } catch (e) {
      logTelemetry("Failed", "SingleDocument", "updateJsonFun", e.message, e);
      console.log(e.message);
      setDialogState({
        open: true,
        title: "Error!",
        subTitle: "updateJsonFun : " + e?.message,
        type: "single",
      });
      setLoading(false);
    }
  }

  function btnSaveSubmitClick(flag) {
    // flag 1 means save 2 means submit
    try {
      let mandatoryFields;
      const newData = [...accordionData];

      newData[2].details = newData[2].details.map((section) => {
        // Extract mandatory fields
        // console.log(section);
        // debugger;
        mandatoryFields = section.Fields.filter(
          (field) =>
            field?.IsMandatory?.toLowerCase() === "true" &&
            (!field?.FieldFinalValue || field?.FieldFinalValue.trim() === "")
        ).map((it) => it.FieldName);

        // Transform Fields
        const updatedFields = section.Fields.map((field) =>
          field.FieldContentType === "ARRAY"
            ? {
                ...field,
                FieldFinalValue: JSON.stringify(
                  transformData(field?.FieldFinalValue)
                ),
              }
            : field
        );

        return {
          ...section,
          Fields: updatedFields,
          // mandatoryFields, // Add this to the section if needed
        };
      });

      // Optional: Log or handle the mandatory fields
      // console.log("Updated Data:", newData);
      // console.log(
      //   "Mandatory Fields from all sections:",
      //   newData[2].details.flatMap((section) => section.mandatoryFields || [])
      // );
      // debugger;
      // return;
      // mandatoryFields
      if (flag == 2 && mandatoryFields.length > 0) {
        setNotify({
          isOpen: true,
          message: `Mandatory Fields are missing ${mandatoryFields?.join(
            ", "
          )}`,
          type: "warning",
        });
        return;
      }
      updateJsonFun(flag, newData[2].details);
    } catch (e) {
      setNotify({
        isOpen: true,
        message: e.message,
        type: "warning",
      });
    }
    // }
  }

  function renderDetails(details, index) {
    return (
      <Box>
        {index == 2 && !roleFlag && selectedView == "edit" && (
          <>
            <Box className="flex justify-end pb-3 space-x-2">
              <Button
                variant="outlined"
                className="button-styles !min-w-[100px]"
                color="primary"
                onClick={() => btnSaveSubmitClick(1)}
              >
                Save
              </Button>
              <Button
                variant="outlined"
                className="button-styles !min-w-[100px]"
                color="primary"
                onClick={() => {
                  getDocumentData(dId);
                }}
              >
                Cancel
              </Button>
            </Box>

            <Divider className="!mb-3" />
          </>
        )}

        {details?.map((section, sectionIndex) => (
          <Box key={sectionIndex}>
            <Typography variant="body1" gutterBottom className="!font-bold">
              {section.SectionName}
            </Typography>
            {section.Fields?.map((field, fieldIndex) => (
              <Grid
                container
                spacing={1}
                key={fieldIndex}
                className="!items-center"
              >
                <Grid item xs={5}>
                  <Typography
                    variant="subtitle1"
                    color="textSecondary"
                    textAlign="end"
                  >
                    {field.fieldName}
                    {field.IsMandatory ? "*" : ""} :
                  </Typography>
                </Grid>
                <Grid item xs={7} className="!pl-1">
                  {/* {console.log(
                    typeof field.FieldFinalValue,
                    " field.FieldFinalValue  ",
                    field.FieldFinalValue
                  )} */}
                  {selectedView == "edit" && index == 2 ? (
                    <>
                      {Array.isArray(field?.FieldFinalValue) ? (
                        ""
                      ) : (
                        <TextField
                          value={field.FieldFinalValue}
                          fullWidth
                          onChange={(e) =>
                            handleInputChange(
                              section.SectionName,
                              section.Fields,
                              field.fieldName,
                              fieldIndex,
                              e.target.value
                            )
                          }
                          size="small"
                          className="docEditCtrl"
                        />
                      )}
                    </>
                  ) : (
                    <div
                      style={{
                        wordBreak: "break-word",
                        whiteSpace: "pre-wrap",
                      }}
                    >
                      <Typography variant="subtitle1" className="!font-medium">
                        {Array.isArray(field.FieldFinalValue)
                          ? JSON.stringify(
                              transformData(field.FieldFinalValue),
                              null,
                              2
                            )
                          : field.FieldFinalValue}
                      </Typography>
                    </div>
                  )}
                </Grid>
                {Array.isArray(field.FieldFinalValue) &&
                  selectedView == "edit" &&
                  field?.FieldFinalValue?.map((d, ind) => (
                    <>
                      <Grid item xs={5}>
                        <Typography
                          variant="subtitle1"
                          color="textSecondary"
                          textAlign="end"
                        >
                          {d.fieldName} :
                        </Typography>
                      </Grid>
                      <Grid item xs={7}>
                        <TextField
                          value={d.FieldFinalValue}
                          fullWidth
                          onChange={(e) =>
                            handleSubInputChange(
                              section.SectionName,
                              section.Fields,
                              field.fieldName,
                              fieldIndex,
                              e.target.value,
                              d.fieldName,
                              ind,
                              d
                            )
                          }
                          size="small"
                          className="docEditCtrl"
                        />
                      </Grid>
                    </>
                  ))}
              </Grid>
            ))}
          </Box>
        ))}
      </Box>
    );
  }

  function arrayToKeyValueFlattened(array) {
    const result = {};
    array.forEach((item, index) => {
      const suffix = `_${index + 1}`; // Generate unique suffix
      Object.entries(item).forEach(([key, value]) => {
        result[`${key}${suffix}`] = value; // Append suffix to key
      });
    });
    return result;
  }

  function convertToKeyValuePairs(input) {
    const result = [];
    // Iterate through the object
    Object.entries(input).forEach(([key, value]) => {
      // Remove the numeric suffix from the key (e.g., "Test_Name_1" becomes "Test_Name")
      // const fieldName = key.replace(/_\d+$/, "");
      // Push the key-value pair into the result array
      result.push({
        // ...input,
        fieldName: key,
        FieldFinalValue: value,
      });
    });
    return result;
  }

  function generateSectionsData(Sections, indx) {
    return Sections?.map((section) => ({
      SectionName: section?.SectionName,
      Fields: section?.Fields?.map((item) => {
        const val =
          indx === 0
            ? item.FieldExtractValue
            : indx === 1
            ? item.FieldPromptValue
            : item.FieldFinalValue;
        // console.log(item, " hari here");IsMandatory
        if (
          (val?.includes("[") && val?.includes("]")) ||
          item.FieldContentType == "ARRAY"
        ) {
          try {
            // Parse the raw string into an array if it contains JSON array brackets
            const parsedVal = JSON.parse(val);

            // Convert the parsed array to key-value pairs
            const res = arrayToKeyValueFlattened(parsedVal);

            // Convert the flattened result to key-value pairs in the desired format
            const arr = convertToKeyValuePairs(res);

            // Return the modified object with key-value pairs in FieldFinalValue
            return {
              ...item,
              fieldName: item.FieldName,
              FieldFinalValue: arr, // Return the transformed array
            };
          } catch (error) {
            console.error("Failed to parse JSON:", error.message, val);
          }
        }

        return {
          ...item,
          fieldName: item.FieldName,
          FieldFinalValue: val,
        };
      }),
    }));
  }

  function btnViewtableClick() {
    setOpen(true);
  }

  const handleCloseModal = () => {
    setOpen(false);
  };

  function createNestedObject(arrayLength) {
    if (arrayLength <= 0) {
      return { arr: [], obj: {} }; // Handle invalid input
    }
    const arr = Array.from({ length: arrayLength }, (_, i) => i + 1); // Create array from 1 to arrayLength
    const obj = {};
    for (let i = 1; i <= arrayLength; i++) {
      obj[i] = [];
    }
    return { arr, obj }; // Return both array and object in a single object
  }

  function generateDetails(Sections, indx) {
    setTableData(
      Sections?.tablesExtracted?.length ? Sections?.tablesExtracted : []
    );
    if (Sections == null) Sections = [];
    const { pages, keyValuePairs } = Sections;
    const { arr, obj } = createNestedObject(pages);
    keyValuePairs?.forEach((msg) => {
      arr?.forEach((num) => {
        if (num === msg.key.boundingRegions[0].pageNumber) {
          obj[num].push(msg);
        }
      });
    });
    // debugger;
    return (
      <Box>
        {Object.entries(obj).map(([k, v], index) => (
          <Box key={index}>
            <Typography variant="body1" gutterBottom className="!font-bold">
              Page : {k}
            </Typography>
            {v?.map((item, index) => (
              <Grid container spacing={1} key={index + 1}>
                <Grid item xs={5}>
                  <Typography
                    variant="subtitle1"
                    color="textSecondary"
                    textAlign="end"
                  >
                    {item?.key?.content}
                  </Typography>
                </Grid>
                <Grid item xs={7}>
                  <Typography variant="subtitle1" className="!font-medium">
                    {item?.value?.content}
                  </Typography>
                </Grid>
              </Grid>
            ))}
          </Box>
        ))}
        {Sections?.tablesExtracted?.length > 0 && (
          <Button
            variant="outlined"
            // endIcon={<ArrowBackOutlined />}
            className="button-styles btnHeight !min-w-[100px] !mr-4 !mb-3"
            onClick={() => btnViewtableClick()}
          >
            View Table
          </Button>
        )}
      </Box>
    );
  }

  const QuestionAnswerList = (data) => (
    <Box sx={{ p: 2 }}>
      {data?.map((item, index) => (
        <Card key={index} className="mb-4" sx={{ backgroundColor: "#efefef" }}>
          <CardContent>
            <Typography variant="body1" sx={{ color: "red" }}>
              Q{index + 1}: {item?.question?.join(" ")}
            </Typography>
            <Typography variant="body1" sx={{ color: "green", mt: 1 }}>
              Answer: {item?.answer}
            </Typography>
          </CardContent>
        </Card>
      ))}
    </Box>
  );

  function btnDebugClick() {}

  async function btnExtractClick(fileId) {
    // debugger;
    if (!loading) {
      setLoading(true);
    }
    // console.clear();
    try {
      const payload = createPayloadObject(fileId, "1");
      if (payload.docInfo.InputJsonTyp === "0") {
        try {
          if (
            !inputJSON ||
            typeof inputJSON !== "string" ||
            !JSON.parse(inputJSON)
          ) {
            throw new Error();
          }
        } catch (error) {
          setNotify({
            isOpen: true,
            message: `Invalid JSON provided. Please ensure the input is valid and non-empty.`,
            type: "warning",
          });
          setLoading(false);
          return;
        }
      }
      const res = await RecognizeDocumentApiCall({
        ...payload,
        URL: selectedModel,
      });
      if (res?.status?.code === 200) {
        const message = res?.status?.message;
        const messages = message ? message.split("~") : [];
        const formattedMessage =
          messages.length > 1
            ? messages.map((msg, index) => (
                <React.Fragment key={index}>
                  {msg}
                  {index < messages.length - 1 && <br />}
                </React.Fragment>
              ))
            : message;

        setDialogState({
          open: true,
          title: "Success",
          subTitle: formattedMessage,
          type: "single",
        });
        setExtractData(res?.data);
        // console.log(res?.data);
        const parsed = JSON.parse(res?.data?.extractJson);
        const parsedfinal = JSON.parse(res?.data?.finalJson);
        // debugger;
        if (res?.data?.id) {
          setDocumentId(res?.data?.id);
          if (documentId === "0") {
            getDocumentData(res?.data?.id, true);
          }
        }
        setAccordionData((prev) => {
          const transactionSummaryExists = res?.data?.transactionSummary !== "";
          const baseIndex = transactionSummaryExists ? 4 : 3;

          return prev.map((item, index) =>
            index === 3 && transactionSummaryExists
              ? {
                  title: "Transaction Summary",
                  details: JSON.parse(res.data.transactionSummary),
                }
              : index === baseIndex
              ? { ...item, details: parsed?.rawText }
              : [0, 2].includes(index)
              ? {
                  ...item,
                  details: generateSectionsData(parsedfinal.Sections, index),
                }
              : index === baseIndex + 1
              ? { ...item, details: generateDetails(parsed) }
              : item
          );
        });

        setExpanded([0]);
        setLoading(false);
      } else {
        logTelemetry(
          "Failed",
          "SingleDocument",
          "btnExtractClick",
          res?.status?.message ?? res?.response?.data?.title
        );
        setLoading(false);
        // debugger;
        setDialogState({
          open: true,
          title: "Error!",
          subTitle: res?.status?.message ?? res?.response?.data?.title,
          type: "single",
        });
        if (res?.status?.message?.includes("session")) {
          setTimeout(() => {
            window?.sessionStorage?.clear();
            window?.localStorage?.clear();
            sessionMessage(res?.status?.message);
            window.location.href = "/login";
          }, 1500);
        }
      }
    } catch (e) {
      logTelemetry("Failed", "SingleDocument", "btnExtractClick", e.message, e);
      setLoading(false);
      setDialogState({
        open: true,
        title: "Error",
        subTitle: "btnExtractClick : " + e?.message,
        type: "single",
      });
    }
  }
  async function btnPromptClick(fileId) {
    // console.clear();
    if (!loading) {
      setLoading(true);
    }
    try {
      const payload = createPayloadObject(fileId, "2");
      if (payload.docInfo.InputJsonTyp === "0") {
        try {
          if (
            !inputJSON ||
            typeof inputJSON !== "string" ||
            !JSON.parse(inputJSON)
          ) {
            throw new Error();
          }
        } catch (error) {
          setNotify({
            isOpen: true,
            message: `Invalid JSON provided. Please ensure the input is valid and non-empty.`,
            type: "warning",
          });
          setLoading(false);
          return;
        }
      }
      const res = await RecognizeDocumentApiCall({
        ...payload,
        URL: selectedModel,
      });
      if (res?.status?.code === 200) {
        const message = res?.status?.message;
        const messages = message ? message.split("~") : [];
        const formattedMessage =
          messages.length > 1
            ? messages.map((msg, index) => (
                <React.Fragment key={index}>
                  {msg}
                  {index < messages.length - 1 && <br />}
                </React.Fragment>
              ))
            : message;
        setDialogState({
          open: true,
          title: "Success",
          subTitle: formattedMessage,
          type: "single",
        });
        setPromptData(res?.data);
        const parsed = JSON.parse(res?.data?.promptJson);
        const parsedfinal = JSON.parse(res?.data?.finalJson);
        if (res?.data?.id) {
          setDocumentId(res?.data?.id);
          if (documentId === "0") {
            getDocumentData(res?.data?.id, true);
          }
        }
        setAccordionData((prev) => {
          const transactionSummaryExists = res?.data?.transactionSummary !== "";
          return prev.map((item, index) =>
            [1, 2].includes(index)
              ? {
                  ...item,
                  details: generateSectionsData(parsedfinal.Sections, index),
                }
              : index === 3 && transactionSummaryExists
              ? {
                  title: "Transaction Summary",
                  details: JSON.parse(res?.data?.transactionSummary),
                }
              : item
          );
        });
        setFaqs(parsed?.data);
        setExpanded([1]);
        setLoading(false);
      } else {
        logTelemetry(
          "failed",
          "SingleDocument",
          "btnPromptClick",
          `${res?.status?.message ?? res?.response?.data?.title}`
        );
        setLoading(false);
        setDialogState({
          open: true,
          title: "Error!",
          subTitle: getErrorMessage(
            res?.status?.message ?? res?.response?.data?.title
          ),
          type: "single",
        });
      }
    } catch (e) {
      console.log(e.message);
      logTelemetry("Failed", "SingleDocument", "btnPromptClick", e.message, e);
      setLoading(false);
      setDialogState({
        open: true,
        title: "Error",
        subTitle: "btnPromptClick : " + e?.message,
        type: "single",
      });
    }
  }

  async function btnDetectClick(fileId) {
    if (!loading) {
      setLoading(true);
    }
    try {
      const payload = createPayloadObject(fileId, "3");

      if (payload.docInfo.InputJsonTyp === "0") {
        try {
          if (
            !inputJSON ||
            typeof inputJSON !== "string" ||
            !JSON.parse(inputJSON)
          ) {
            throw new Error();
          }
        } catch (error) {
          setNotify({
            isOpen: true,
            message: `Invalid JSON provided. Please ensure the input is valid and non-empty.`,
            type: "warning",
          });
          setLoading(false);
          return;
        }
      }

      const res = await RecognizeDocumentApiCall({
        ...payload,
        URL: selectedModel,
      });

      if (res?.status?.code === 200) {
        const message = res?.status?.message;
        const messages = message ? message.split("~") : [];
        const formattedMessage =
          messages.length > 1
            ? messages.map((msg, index) => (
                <React.Fragment key={index}>
                  {msg}
                  {index < messages.length - 1 && <br />}
                </React.Fragment>
              ))
            : message;
        setDialogState({
          open: true,
          title: "Success",
          subTitle: formattedMessage,
          type: "single",
        });
        setDetectData(res?.data);
        let parsed = null;
        let extractJson = null;
        let parsedfinal = null;

        if (res?.data?.finalJson !== "") {
          parsedfinal = JSON.parse(res?.data?.finalJson);
        }

        if (res?.data?.extractJson !== "") {
          extractJson = JSON.parse(res?.data?.extractJson);
        }

        if (res?.data?.promptJson !== "") {
          parsed = JSON.parse(res?.data?.promptJson);
        }
        if (res?.data?.id) {
          setDocumentId(res?.data?.id);
          if (documentId === "0") {
            getDocumentData(res?.data?.id, true);
          }
        }

        setAccordionData((prev) => {
          if (!roleFlag) {
            return prev.map((item, index) => {
              const transactionSummaryExists = !!res?.data?.transactionSummary;

              if (index === 3 && transactionSummaryExists) {
                return {
                  title: "Transaction Summary",
                  details: JSON.parse(res?.data?.transactionSummary),
                };
              } else if ([0, 1, 2].includes(index)) {
                return {
                  ...item,
                  details: generateSectionsData(parsedfinal.Sections, index),
                };
              } else if (index === (transactionSummaryExists ? 4 : 3)) {
                return { ...item, details: parsed?.ocrResults?.rawText };
              } else if (index === (transactionSummaryExists ? 5 : 4)) {
                return { ...item, details: generateDetails(extractJson) };
              } else {
                return item;
              }
            });
          } else {
            // return prev.map((item, index) => {
            //   if (index === 3) {
            //     return { ...item, details: parsed?.ocrResults?.rawText };
            //   } else if ([0, 1, 2].includes(index)) {
            return [
              // ...prev,
              {
                title: "Final Details",
                details: generateSectionsData(parsedfinal.Sections, 2),
              },
            ];
            // } else if (index === 4) {
            //   return { ...item, details: generateDetails(extractJson) };
            // } else {
            //   return item;
            // }
            // });
          }
        });

        setFaqs(parsed?.data);
        setFieldValues(
          parsedfinal?.Sections?.reduce((acc, section) => {
            acc[section?.SectionName] = section?.Fields?.reduce(
              (fieldAcc, field) => {
                fieldAcc[field?.FieldName] = field?.FieldFinalValue;
                return fieldAcc;
              },
              {}
            );
            return acc;
          }, {})
        );
        setFinalData(parsedfinal);
        setExpanded(roleFlag ? [0] : [2]);
        setLoading(false);
        setIsDetectClicked(true);
      } else {
        logTelemetry(
          "Failure",
          "SingleDocument",
          "btnDetectClick",
          `${res?.status?.message ?? res?.response?.data?.title}`
        );
        setLoading(false);
        setDialogState({
          open: true,
          title: "Error!",
          subTitle: getErrorMessage(
            res?.status?.message ?? res?.response?.data?.title
          ),
          type: "single",
        });
      }
    } catch (e) {
      console.log(e.message);
      logTelemetry("Failed", "SingleDocument", "btnDetectClick", e.message, e);
      setLoading(false);
      setDialogState({
        open: true,
        title: "Error",
        subTitle: "btnDetectClick : " + e?.message,
        type: "single",
      });
    }
  }
  async function btnValidateClick() {
    // if (!isDetectClicked) {
    //   setDialogState({
    //     open: true,
    //     title: "Warning!",
    //     subTitle: `Click on Detect before Validate`,
    //     type: "single",
    //   });
    // } else {
    let data = finalData?.Sections?.reduce((acc, section) => {
      return acc.concat(section.Fields);
    }, []);
    const values = updateInsObj(data);
    const {
      SubscriberId,
      PayerName,
      PayerId,
      SecndPayerId,
      SecndSubscriberId,
      SecndPayerName,
    } = values;
    setIPatientInsurance(values);
    if (!SubscriberId) {
      setNotify({
        isOpen: true,
        message: "Subscriber ID cannot be empty.",
        type: "warning",
      });
      return;
    } else if (!PayerName) {
      setNotify({
        isOpen: true,
        message: "Payer Name cannot be empty.",
        type: "warning",
      });
      return;
    }
    try {
      setLoading(true);
      const payload = {
        commonParams: {
          organizationID: details?.orgId,
          companyID: details?.companyId,
          coCode: details?.companyCode,
          groupID: details?.groupID,
          timeZone: details?.timeZone,
          sessionId: details?.sesionId,
          loginUserId: `${details?.userId}`,
          loginUserName: details?.userName,
          AuditTrail: 0,
          editTrail: 0,
          dataSource: "",
        },
        DocId: documentId,
        SubscriberId,
        PayerId,
        PayerName,
        SecndPayerId,
        SecndSubscriberId,
        SecndPayerName,
        InsuranceType: "",
        NetworkType: "",
      };
      const res = await DocumentValidationApiCall(payload);
      if (res?.status?.code === 200) {
        let prevAccData = [...accordionData];
        const newTitle = "Validated Insurance Details";
        const newDetails = JSON.stringify(res?.data, undefined, 4);
        const existingIndex = prevAccData.findIndex(
          (item) => item.title === newTitle
        );
        if (existingIndex !== -1) {
          prevAccData[existingIndex].details = newDetails;
        } else {
          prevAccData.push({
            title: newTitle,
            details: newDetails,
          });
        }
        // debugger;
        setValidateData(res?.data);
        setAccordionData(prevAccData);
        setExpanded(roleFlag ? [2] : [5]);
        setLoading(false);
      } else {
        logTelemetry(
          "failed",
          "SingleDocument",
          "btnValidateClick",
          `${res?.status?.message}`
        );
        setLoading(false);
        setDialogState({
          open: true,
          title: "Note",
          subTitle: getErrorMessage(res?.status?.message),
          type: "single",
        });
      }
    } catch (e) {
      console.log(e.message);
      logTelemetry(
        "Failed",
        "SingleDocument",
        "btnValidateClick",
        e.message,
        e
      );
      setLoading(false);
      setDialogState({
        open: true,
        title: "Error",
        subTitle: "btnValidateClick : " + e?.message,
        type: "single",
      });
    }
    // }
  }
  const getFieldValue = (data, fieldName) => {
    const field = data?.find((item) => item.FieldName.includes(fieldName));
    return (
      field?.FieldFinalValue ||
      field?.FieldPromptValue ||
      field?.FieldExtractValue ||
      ""
    );
  };

  async function btnVerifyClick() {
    // if (!isDetectClicked) {
    //   setDialogState({
    //     open: true,
    //     title: "Warning!",
    //     subTitle: `Click on Detect before Verify`,
    //     type: "single",
    //   });
    // } else {
    let allFields = finalData?.Sections?.reduce((acc, section) => {
      return acc.concat(section.Fields);
    }, []);
    const values = updateInsObj(allFields);
    // debugger;
    const {
      SubscriberId,
      PayerName,
      PayerId,
      PatientFirstName,
      PatientLastName,
      PatientDob,
      PatientGender,
      PostalCode,
      CityTown,
      AddressLine1,
      AddressLine2 = "",
      StateProvinceCode = "",
      CountryCode = "",
      SecndPayerId,
      SecndSubscriberId,
      SecndPayerName,
    } = values;
    setIPatientInsurance(values);
    if (!(validateData?.subscriberId ?? SubscriberId ?? "")) {
      setNotify({
        isOpen: true,
        message: "Subscriber ID cannot be empty.",
        type: "warning",
      });
      return;
    } else if (!(validateData?.payerName ?? PayerName ?? "")) {
      setNotify({
        isOpen: true,
        message: "Payer Name cannot be empty.",
        type: "warning",
      });
      return;
    } else if (!PatientFirstName) {
      setNotify({
        isOpen: true,
        message: "Patient First Name cannot be empty.",
        type: "warning",
      });
      return;
    } else if (!PatientLastName) {
      setNotify({
        isOpen: true,
        message: "Patient Last Name cannot be empty.",
        type: "warning",
      });
      return;
    } else if (!PatientDob) {
      setNotify({
        isOpen: true,
        message: "Patient Date of Birth cannot be empty.",
        type: "warning",
      });
      return;
    }
    try {
      setLoading(true);
      const payload = {
        commonParams: {
          organizationID: details?.orgId,
          companyID: details?.companyId,
          coCode: details?.companyCode,
          groupID: details?.groupID,
          timeZone: details?.timeZone,
          sessionId: details?.sesionId,
          loginUserId: `${details?.userId}`,
          loginUserName: details?.userName,
          AuditTrail: 0,
          editTrail: 0,
          dataSource: "",
        },
        DocId: documentId,
        PatientFirstName,
        PatientLastName,
        PatientDob,
        PatientGender,
        AddressLine1,
        AddressLine2,
        CityTown,
        StateProvinceCode,
        PostalCode,
        CountryCode,
        SecndPayerId: validateData?.SecndPayerId ? SecndPayerId : "",
        SecndSubscriberId: validateData?.SecndSubscriberId
          ? SecndSubscriberId
          : "",
        SecndPayerName: validateData?.SecndPayerName ? SecndPayerName : "",
        SubscriberId: validateData?.subscriberId ?? SubscriberId ?? "", // from response of validate
        PayerId: validateData?.payerId ?? PayerId ?? "", // from response of validate
        PayerName: validateData?.payerName ?? PayerName ?? "", // from response of validate
        Relationship: "Self", //hardcode
        Priority: "Primary", //hardcode
      };
      const res = await DocumentVerificationApiCall(payload);
      if (res?.status?.code === 200) {
        let prevAccData = [...accordionData];
        const newTitle = "Eligibility Details";
        const newDetails = JSON.stringify(res?.data, undefined, 4);
        const existingIndex = prevAccData.findIndex(
          (item) => item.title === newTitle
        );
        if (existingIndex !== -1) {
          prevAccData[existingIndex].details = newDetails;
        } else {
          prevAccData.push({
            title: newTitle,
            details: newDetails,
          });
        }
        // debugger;
        setAccordionData(prevAccData);
        setLoading(false);
        setExpanded(roleFlag ? [2] : [6]);
      } else {
        logTelemetry(
          "Fail",
          "SingleDocument",
          "btnVerifyClick",
          `${res?.status?.message}`
        );
        setLoading(false);
        setDialogState({
          open: true,
          title: "Warning!",
          subTitle: getErrorMessage(res?.status?.message),
          type: "single",
        });
      }
    } catch (e) {
      console.log(e.message);
      logTelemetry(
        "Failed",
        "SingleDocument",
        "btnVerifyClick",
        error.message,
        error
      );
      setLoading(false);
      setDialogState({
        open: true,
        title: "Error",
        subTitle: "btnVerifyClick : " + e?.message,
        type: "single",
      });
    }
  }
  // }

  const ddlDocumentTypeChange = (newValue, e) => {
    // debugger;
    try {
      if (newValue?.code?.toLowerCase() === "other") {
        setModelOpen(true);
        setTempObj(newValue);
        return;
      } else {
        setSelectedOption(newValue);
        setTempObj({});
        setParsedJSON([]);
        setInputJSON("");
      }
    } catch (e) {
      console.log(e.message);
      setDialogState({
        open: true,
        title: "Error!",
        subTitle: "ddlDocumentTypeChange : " + e?.message,
        type: "single",
      });
    }
  };

  const handleAccordionChange = (panel) => (event, isExpanded) => {
    if (isExpanded) {
      setExpanded([...expanded, panel]);
    } else {
      setExpanded(expanded.filter((item) => item !== panel));
    }
  };

  function createPayloadObject(fileId, apiProccTyp) {
    // console.log(firstApiData);
    // debugger;
    return {
      id: typeof documentId === "string" ? documentId : `${documentId}`,
      configId: "91234",
      RunKve: kvExe ? kvExe : "",
      RunLlm: llmExe ? llmExe : "",
      RunValidate: validate ? validate : "",
      RunVerify: verify ? verify : "",

      // Model: kvExe && llmExe ? `${kvExe}^${llmExe}` : "",

      docInfo: {
        DocId: fileId || "", //presign id something like this a14d556cdd164bfc96697dd7bfcb8cd2.pd
        DocName: fileName || "",
        DocSize: fileSize || "",
        DocType: fileType || "",
        ApiProccTyp: apiProccTyp || "1",
        InputJsonTyp: selectedOption.code,
      },
      DocSecs: Array.isArray(parsedJSON)
        ? parsedJSON
        : JSON.stringify(parsedJSON),
      commonParams: {
        organizationID: details?.orgId,
        companyID: details?.companyId,
        coCode: details?.companyCode,
        groupID: details?.groupID,
        timeZone: details?.timeZone,
        sessionId: details?.sesionId,
        loginUserId: `${details?.userId}`,
        loginUserName: details?.userName,
        AuditTrail: 0,
        editTrail: 0,
        dataSource: "",
      },
    };
  }

  function inputQuestionChange(e) {
    setQuestionInput(e.target.value);
  }

  async function btnSubmitClick(fileId) {
    const splitDatadata = questionInput;
    if (splitDatadata.trim() === "") {
      setNotify({
        isOpen: true,
        message: "Please enter your question in a clear and proper format.",
        type: "warning",
      });
      return;
    }

    const payload = {
      ConfigId: "91234",
      commonParams: {
        organizationID: details?.orgId,
        companyID: details?.companyId,
        coCode: details?.companyCode,
        groupID: details?.groupID,
        timeZone: details?.timeZone,
        sessionId: details?.sesionId,
        loginUserId: `${details?.userId}`,
        loginUserName: details?.userName,
        AuditTrail: 0,
        editTrail: 0,
        dataSource: "",
      },
      FileId: isEdit
        ? documentData?.blobid
        : !fileId
        ? firstApiData?.data?.fileId
        : fileId,
      CallTyp: "2",
      FieldQs: [],
    };
    // debugger;
    const splitQuestionAndArray = splitDatadata.split("~|");
    for (let i = 0; i < splitQuestionAndArray.length; i++) {
      if (splitQuestionAndArray[i].trim() !== "") {
        const checkQuestionAndArray = splitQuestionAndArray[i].split("[");
        if (checkQuestionAndArray.length > 1) {
          const splitArrayPipe = checkQuestionAndArray[1]
            .replace("]", "")
            .replace(/\s+/g, " ")
            .trim();
          const checkQuestionAndPipeArray = splitArrayPipe.split("|");
          const arrayanotherList = {
            FieldSubQs: checkQuestionAndPipeArray,
            Answer: "",
          };
          payload.FieldQs.push(arrayanotherList);
        } else {
          payload.FieldQs.push(
            splitQuestionAndArray[i].replace(/\s+/g, " ").trim()
          );
        }
      }
    }
    setLoading(true);
    try {
      const res = await QuestionsPostApiCall(payload);
      if (res?.status?.code === 200) {
        setQuestionInput("");
        const newFaq = res?.data?.fieldQs.map((faq) => ({
          question: faq.fieldSubQs,
          answer: faq.answer,
        }));
        setFaqs([...faqs, ...newFaq]);
        setLoading(false);
        setDialogState({
          open: true,
          title: "Success",
          subTitle: res?.status?.message,
          type: "single",
        });
      } else {
        logTelemetry(
          "Failed",
          "SingleDocument",
          "btnSubmitClick",
          res?.status?.message ?? res?.response?.data?.title
        );
        setLoading(false);
        setDialogState({
          open: true,
          title: "Error!",
          subTitle: getErrorMessage(res?.status?.message ?? res?.title),
          type: "single",
        });
      }
    } catch (e) {
      logTelemetry("Failed", "SingleDocument", "btnSubmitClick", e.message, e);
      console.log(e.message);
      setDialogState({
        open: true,
        title: "Error!",
        subTitle: "btnSubmitClick : " + e?.message,
        type: "single",
      });
    }
  }

  return (
    <div>
      <Grid container>
        <Grid item xs={12} sm={12} className="mb-2 mt-3">
          <div>
            <Grid
              container
              alignItems="center"
              justifyContent="space-between"
              className="mt-3"
            >
              {documentId === "0" ? (
                <Typography variant="h6" className="!mb-3">
                  New Upload
                </Typography>
              ) : (
                <Box
                  display="flex"
                  alignItems="center"
                  gap={3}
                  className="mb-1"
                  style={{
                    border: "1px solid #ddd",
                    maxWidth: "90%",
                    padding: "8px 20px",
                    borderRadius: "4px",
                    background: "#efefef",
                    marginBottom: "10px",
                  }}
                >
                  <Typography variant="body1">
                    ID: <b>{documentData?.documentID}</b>
                  </Typography>
                  <Typography variant="body1">
                    Status: <b>{documentData?.documentStatus}</b>
                  </Typography>
                  <Typography variant="body1">
                    SubStatus: <b>{documentData?.documentSubStatus}</b>
                  </Typography>
                  <Typography variant="body1">Class: {}</Typography>
                </Box>
              )}
              <Box>
                {isEdit && (
                  <Box className="float-left flex-auto !mr-[10px]">
                    <Tooltip title="Previous Document" placement="top" arrow>
                      <Button
                        onClick={handlePrevious}
                        disabled={currentIndex === 0}
                        className="button-styles !py-[5px]"
                      >
                        &nbsp;
                        <i className="fa-solid fa-angle-left fa-lg"></i>
                      </Button>
                    </Tooltip>
                    &nbsp;
                    {currentIndex + 1} Of {listOfDids.length}
                    <Tooltip title="Next Document" placement="top" arrow>
                      <Button
                        onClick={handleNext}
                        disabled={currentIndex === listOfDids.length - 1}
                        className="button-styles !ml-2 !py-[5px]"
                      >
                        &nbsp;
                        <i className="fa-solid fa-angle-right fa-lg"></i>
                      </Button>
                    </Tooltip>
                  </Box>
                )}
                <Button
                  variant="outlined"
                  endIcon={<ArrowBackOutlined />}
                  className="button-styles !min-w-[100px] !mr-4 !mb-3"
                  onClick={() => {
                    handleBtnBackClick();
                  }}
                >
                  Back
                </Button>
              </Box>
            </Grid>
            <Grid
              container
              alignItems="top"
              justifyContent="space-between"
              className="pb-4"
              spacing={2}
            >
              {/* Left side: File selector */}
              <Grid item xs={6.8}>
                <Box
                  sx={{
                    border: fileName ? "1px solid #ccc" : "2px dashed #ccc",
                    height: "84vh",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    cursor: "pointer",
                    backgroundColor: "#fff",
                    flexDirection: "column",
                    position: "relative",
                    overflow: "hidden",
                  }}
                  className={
                    !fileName ? "singleDocument-leftside" : "singleDocument"
                  }
                  onClick={() => {
                    if (!fileName) handleBoxClick();
                  }}
                >
                  <input
                    type="file"
                    id="file-input"
                    style={{ display: "none" }}
                    onChange={(e) => handleFileSelect(e)}
                    // accept=".pdf,.jpeg,.jpg,.png,.tiff,.tif"
                    ref={inputRef}
                  />
                  {!fileName && (
                    <label>
                      <Typography
                        variant="h6"
                        component="div"
                        className="fileInputLabel"
                      >
                        Click to select a document file...
                      </Typography>
                    </label>
                  )}

                  {isPDF ? (
                    <iframe
                      id="showPDFICON1"
                      src={pdfURL}
                      type="application/pdf"
                      width="100%"
                      height="100%"
                    >
                      <p>
                        Your browser does not support PDFs.{" "}
                        <a href={pdfURL}>Download the PDF</a>.
                      </p>
                    </iframe>
                  ) : (
                    fileData && (
                      <>
                        <div
                          className="w-full h-[60px] z-10 bg-white"
                          style={{ borderBottom: "1px solid #ddd" }}
                        >
                          <div
                            id="hideIsZoomIcons"
                            className="zoom_ctrl posi_initial z-10"
                            style={{
                              display: "flex",
                              flexDirection: "row",
                            }}
                          >
                            <IconButton
                              color="primary"
                              aria-label="zoomout"
                              onClick={() => ZoomImage(0)}
                            >
                              <RemoveIcon
                                title="Zoom Out"
                                style={{ cursor: "pointer" }}
                              />
                            </IconButton>
                            <IconButton
                              color="primary"
                              aria-label="zoomin"
                              onClick={() => ZoomImage(1)}
                            >
                              <AddIcon
                                title="Zoom In"
                                style={{ cursor: "pointer" }}
                              />
                            </IconButton>
                            <Button
                              variant="outlined"
                              // color="secondary"
                              onClick={FitPage}
                              style={{ cursor: "pointer" }}
                              className="button-styles btnHeight"
                            >
                              Fit Page
                            </Button>
                          </div>
                        </div>

                        <Box
                          sx={{
                            width: "100%",
                            height: "84vh",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            backgroundColor: "#fff",
                            flexDirection: "column",
                            position: "relative",
                            overflow: "auto",
                          }}
                        >
                          <div
                            ref={imageContainerRef}
                            className="file-upload-container "
                            style={{
                              position: "relative",
                              width: "100%",
                              height: "100%",
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                              transformOrigin: "center top",
                            }}
                          >
                            {fileType?.toLowerCase()?.includes("tif") ? (
                              <TIFFViewer key={fileData} tiff={fileData} />
                            ) : (
                              <img
                                id="showPDFICON"
                                src={fileData}
                                alt="Uploaded File Preview"
                                style={{
                                  maxWidth: "100%",
                                  maxHeight: "100%",
                                  objectFit: "cover",
                                  position: "absolute",
                                }}
                              />
                            )}
                          </div>
                        </Box>
                      </>
                    )
                  )}
                  {fileName && (
                    <Typography
                      variant="subtitle1"
                      component="div"
                      className="!my-2 !font-bold"
                    >
                      {fileName}
                    </Typography>
                  )}
                </Box>
              </Grid>

              {/* Right side: Buttons, Card with Autocomplete, and Accordions */}
              <Grid item xs={5.2}>
                <Card
                  sx={{
                    minWidth: 300,
                    minHeight: "84vh",
                    overflow: "auto",
                  }}
                >
                  {fileName && (
                    <CardContent className="singleDocHgt">
                      <Grid
                        container
                        className="flex flex-col justify-center items-end mb-4"
                      >
                        {/* <Grid
                          container
                          gap={1}
                          direction="row"
                          justifyContent="space-between"
                          alignItems="center"
                        > */}
                        <Grid
                          item={true}
                          xs={12}
                          sm={12}
                          className="flex justify-between items-center"
                        >
                          <FormControl>
                            <RadioGroup
                              row
                              aria-labelledby="demo-row-radio-buttons-group-label"
                              name="row-radio-buttons-group"
                              value={selectedModel}
                              onChange={radioModelChange}
                            >
                              {documentTypeModel ? (
                                <>
                                  <FormControlLabel
                                    value="Old"
                                    id="Old"
                                    name="Old"
                                    control={
                                      <Radio name="Radio1" id="Radio1" />
                                    }
                                    label="Old"
                                  />
                                  <FormControlLabel
                                    value="New"
                                    name="New"
                                    id="New"
                                    control={
                                      <Radio name="Radio2" id="Radio2" />
                                    }
                                    label="New"
                                  />
                                </>
                              ) : null}
                            </RadioGroup>
                          </FormControl>

                          <Box className="flex" gap={0.5}>
                            {!isEdit && (
                              <Button
                                variant="outlined"
                                className="button-styles !min-w-[100px]"
                                color="primary"
                                onClick={() => btnClearClick()}
                              >
                                Clear
                              </Button>
                            )}
                            {/* {roleFlag ? null : (
                              <>
                                <Button
                                  variant="outlined"
                                  className="button-styles !min-w-[100px]"
                                  color="primary"
                                  onClick={() =>
                                    handleButtonClick(btnExtractClick)
                                  }
                                >
                                  Extract
                                </Button>
                                <Button
                                  variant="outlined"
                                  className="button-styles !min-w-[100px]"
                                  color="primary"
                                  onClick={() =>
                                    handleButtonClick(btnPromptClick)
                                  }
                                >
                                  Prompt
                                </Button>
                              </>
                            )} */}
                            <Button
                              variant="outlined"
                              className="button-styles !min-w-[100px]"
                              color="primary"
                              onClick={() => handleButtonClick(btnDetectClick)}
                            >
                              Detect
                            </Button>
                          </Box>
                        </Grid>
                        <Grid
                          item={true}
                          xs={12}
                          sm={12}
                          className="flex justify-between items-center"
                        >
                          <Box
                            className="flex justify-between"
                            gap={0.5}
                            width={410}
                          >
                            {/* {baseurl.includes("tst") ? ( */}
                            <>
                              <TextField
                                label="KV_EXE"
                                value={kvExe}
                                onChange={handleInputChangeLatest(setKvExe)}
                                inputProps={{
                                  maxLength: 1, // Limit input to a single character
                                }}
                                variant="outlined"
                                size="small"
                              />
                              <TextField
                                label="LLM_EXE"
                                value={llmExe}
                                onChange={handleInputChangeLatest(setLlmExe)}
                                inputProps={{
                                  maxLength: 1,
                                }}
                                variant="outlined"
                                size="small"
                              />
                              <TextField
                                label="Validate"
                                value={validate}
                                onChange={handleInputChangeLatest(setValidate)}
                                inputProps={{
                                  maxLength: 1,
                                }}
                                variant="outlined"
                                size="small"
                              />
                              <TextField
                                label="Verify"
                                value={verify}
                                onChange={handleInputChangeLatest(setVerify)}
                                inputProps={{
                                  maxLength: 1,
                                }}
                                variant="outlined"
                                size="small"
                              />
                            </>
                            {/* ) : null} */}
                          </Box>

                          <Box className="flex !ml-auto" gap={0.5}>
                            {/* <Button
                              variant="outlined"
                              className="button-styles !min-w-[100px]"
                              color="primary"
                              onClick={() => btnDebugClick()}
                            >
                              Debug
                            </Button> */}
                            {selectedView == "edit" && (
                              <Button
                                variant="outlined"
                                className="button-styles !min-w-[100px]"
                                color="primary"
                                onClick={() => btnSaveSubmitClick(2)}
                              >
                                Submit
                              </Button>
                            )}
                            {/* <Button
                              variant="outlined"
                              className="button-styles !min-w-[100px]"
                              color="primary"
                              onClick={() => btnValidateClick()}
                            >
                              Validate
                            </Button>
                            <Button
                              variant="outlined"
                              className="button-styles !min-w-[100px]"
                              color="primary"
                              onClick={() => btnVerifyClick()}
                            >
                              Verify
                            </Button> */}
                          </Box>
                        </Grid>
                      </Grid>

                      <div>
                        <Autocomplete
                          options={options || []}
                          value={selectedOption}
                          getOptionLabel={(option) => option.name}
                          isOptionEqualToValue={(option, value) =>
                            option.name === value.name
                          }
                          onChange={(e, newValue) =>
                            ddlDocumentTypeChange(newValue, e)
                          }
                          disableClearable
                          name="Select File Type"
                          id="Select File Type"
                          renderOption={(props, option, { index }) => (
                            <li {...props} key={`${option}-${index}`}>
                              {option.name}
                            </li>
                          )}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              label="Select File Type"
                              variant="outlined"
                              name="Select File Type1"
                              id="Select File Type1"
                              size="small"
                            />
                          )}
                          // open={true}
                          // CustomListbox
                          // ListboxComponent={CustomListbox}
                          sx={{ marginBottom: 2 }}
                        />
                      </div>
                      <div>
                        {accordionData?.map((item, index) => (
                          <Accordion
                            key={index}
                            expanded={expanded.includes(index)}
                            onChange={handleAccordionChange(index)}
                          >
                            <div className="acc">
                              <AccordionSummary
                                expandIcon={<ExpandMore />}
                                sx={{
                                  backgroundColor: "#efefef",
                                  color: "#000",
                                }}
                              >
                                <Typography>{item.title}</Typography>
                                {index === 2 &&
                                  expanded.includes(index) &&
                                  item?.details?.length > 0 && (
                                    <RadioGroup
                                      row
                                      aria-label="view-edit"
                                      name={`viewEditGroup-${index}`}
                                      value={selectedView}
                                      onChange={handleRadioChange}
                                      sx={{
                                        marginLeft: "auto",
                                        display: "flex",
                                        gap: "10px",
                                      }}
                                      onClick={(event) =>
                                        event.stopPropagation()
                                      } // Prevents accordion toggle
                                    >
                                      <FormControlLabel
                                        value="viewraw"
                                        control={<Radio />}
                                        label="View Raw"
                                      />
                                      {wholeRes &&
                                        wholeRes?.modifiedDetectJSON && (
                                          <FormControlLabel
                                            value="viewmodified"
                                            control={<Radio />}
                                            label="View Modified"
                                          />
                                        )}
                                      <FormControlLabel
                                        value="edit"
                                        control={<Radio />}
                                        label="Modify"
                                      />
                                    </RadioGroup>
                                  )}
                              </AccordionSummary>
                            </div>
                            <AccordionDetails key={index + documentId}>
                              {typeof item.details === "string" ? (
                                <Typography
                                  component="pre"
                                  style={{ whiteSpace: "pre-wrap" }}
                                >
                                  {item.details}
                                </Typography>
                              ) : Array.isArray(item.details) && index < 3 ? (
                                renderDetails(item.details, index)
                              ) : typeof item?.details === "object" &&
                                !React.isValidElement(item?.details) ? (
                                <textarea
                                  className="!w-full resize-none bg-white h-[400px]"
                                  disabled
                                  key={item.details.Document_Tokens}
                                  value={JSON.stringify(item?.details, null, 2)}
                                ></textarea>
                              ) : (
                                <>{item.details}</>
                              )}
                            </AccordionDetails>
                          </Accordion>
                        ))}
                      </div>

                      {roleFlag ? null : (
                        <Accordion>
                          <div className="acc">
                            <AccordionSummary
                              expandIcon={<ExpandMore />}
                              sx={{
                                backgroundColor: "#efefef",
                                color: "#000",
                              }}
                            >
                              <Typography>Q&A Section</Typography>
                            </AccordionSummary>
                          </div>
                          <AccordionDetails>
                            <>
                              <TextField
                                multiline
                                name="questionInput" // Ensure this is a meaningful name attribute
                                id="questionInput" // Ensure this is a meaningful id attribute
                                fullWidth
                                label="Enter multiple question here..."
                                rows={4} // Initial number of rows displayed
                                value={questionInput}
                                onChange={inputQuestionChange}
                              />
                              <Box
                                display="flex"
                                justifyContent="flex-end"
                                mt={2}
                              >
                                <Button
                                  variant="outlined"
                                  color="secondary"
                                  className="button-styles btnHeight"
                                  onClick={() =>
                                    handleButtonClick(btnSubmitClick)
                                  }
                                >
                                  Submit
                                </Button>
                              </Box>
                              {QuestionAnswerList(faqs)}
                            </>
                          </AccordionDetails>
                        </Accordion>
                      )}
                    </CardContent>
                  )}
                </Card>
              </Grid>
            </Grid>
          </div>
        </Grid>
      </Grid>
      <MyModal
        open={modelOpen}
        handleClose={handleModalClose}
        inputJSON={inputJSON}
        setInputJSON={setInputJSON}
        handleInputChange={handleInputChangee}
        handleContinue={handleContinue}
      />
      <FullScreenDialog
        open={open}
        data={tableData}
        setOpen={setOpen}
        handleClickOpen={btnViewtableClick}
        handleClose={handleCloseModal}
      />
      <Backdrop
        sx={{ color: "#1a73e8", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={loading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <Notification notify={notify} setNotify={setNotify} />
      <ClearStatesConfirmationDialog
        onClose={handleClose}
        onConfirm={handleConfirm}
        open={dialogState.open}
        title={dialogState.title}
        subTitle={dialogState.subTitle}
        type={dialogState.type}
      />
    </div>
  );
};

export default SingleDocument;
