import Constants from '../constants';

export const sectionIdReducer = (state = null, action) => {
    switch (action.type) {
        case Constants.SECTION_ID:
            return action.payload;
        default:
            return state;
    }
};

// export const previousSegmentIdReducer = (state = null, action) => {
//     switch (action.type) {
//         case Constants.PREVIOUS_SEGMENT_ID:
//             return action.payload;
//         default:
//             return state;
//     }
// };