import {
  Autocomplete,
  Backdrop,
  CircularProgress,
  Divider,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Container,
  Button,
  Typography,
  Box,
} from "@mui/material";
import React, { useEffect, useMemo, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

import Breadcrumb from "../../components/Breadcrumb";
import { useFormik } from "formik";
import * as yup from "yup";
import { toast } from "react-toastify";
import axios from "axios";
import dayjs from "dayjs";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import MyContext from "../../components/MyContext";
import { useContext } from "react";
import { baseurl } from "../../apis/Urls";
import { SendSMSAPI } from "../../apis/Urls";
import { instance } from "../../apis/axios";
import { SavePatientDetails } from "../../apis/sendSMS";
import Notification from "../../components/Notification";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import moment from "moment";
import Alert from "@mui/material/Alert";
import { sentSMSId } from "../../redux/actions/sentSMSId";
const textFieldStyles = {
  width: "100%",
  margin: "12px 12px",
};
import { logTelemetry } from "../../logTelemetry";
import { useDispatch, useSelector } from "react-redux";
import { setActiveComponent } from "../../redux/actions";
import {
  GetConfigurationId,
  GetSectionRights,
} from "../../components/utils/activeComponent";
import StaticModuleClass from "../../components/utils/staticModuleClass";
import { setModuleId, setPreviousModuleId } from "../../redux/actions/moduleId";
import {
  setPreviousSegmentId,
  setSegmentId,
} from "../../redux/actions/segmentId";

const SendSMS = () => {
  const previousComponent = useSelector(
    (state) => state.previousComponent.previousComponent
  );
  const dispatch = useDispatch();
  const accessDetails = useSelector((state) => state.accessRights);
  const segmentId = useSelector((state) => state.segmentID);
  const moduleId = useSelector((state) => state.moduleID);
  const previousSegmentId = useSelector((state) => state.previousSegmentID);
  const previousModuleId = useSelector((state) => state.previousModuleID);

  const navigate = useNavigate();
  const [numberCheck, setNumberCheck] = useState(true);
  // const { details?.companyId, setdetails?.companyId } = useContext(MyContext);
  const { data, mutateAsync, isLoading, isError } = SavePatientDetails();
  const [emailError, setemailError] = useState(false);
  const [mobileNumberError, setmobileNumberError] = useState(false);
  const [sectionAccess, setSectionAccess] = useState({ SendSMSOrEmail: false });
  const [functionAccess, setFunctionAccess] = useState({ Send: 0 });

  const [notify, setNotify] = useState({
    isOpen: false,
    message: "",
    type: "",
  });

  let num_reg_ex =
    /^(?:-(?:[1-9](?:\d{0,2}(?:,\d{3})+|\d*))|(?:0|(?:[1-9](?:\d{0,2}(?:,\d{3})+|\d*))))(?:.\d+|)$/;
  const [loading, setLoading] = useState(false);
  let navigation = useNavigate();
  const maxLength = 10;

  const breadcrumbItems = [
    { label: "Insurance Verification List", link: "/patient-list" },
    { label: "Send SMS", link: "/patient-list/send-sms-or-email" },
  ];

  const loginDetails = useSelector((state) => state.loginDetails);

  let details = useMemo(() => {
    if (loginDetails) {
      return JSON.parse(loginDetails);
    }
    return null;
  }, [loginDetails]);

  // const loginInitialDetails = useSelector((state) =>
  //   JSON.parse(state.loginDetails)
  // );
  // let details = loginInitialDetails;
  let locationData = details?.aILocations.map((i) => i.companyName);

  let companyId =
    details?.companyId != undefined ? details?.companyId : details.CompanyId;
  companyId = details?.aILocations.filter((i) => i.companyId == companyId);
  let captureSMSData = JSON.parse(useSelector((state) => state.captureSMSData));
  let captureSMSDataDetailsObj = {};
  captureSMSDataDetailsObj.firstName =
    captureSMSData != null ? captureSMSData.firstName : "";
  captureSMSDataDetailsObj.dob =
    captureSMSData != null
      ? moment(captureSMSData.dob).format("MM/DD/YYYY") == "Invalid date"
        ? ""
        : moment(captureSMSData.dob).format("MM/DD/YYYY")
      : "";
  captureSMSDataDetailsObj.lastName =
    captureSMSData != null ? captureSMSData.lastName : "";
  captureSMSDataDetailsObj.gender =
    captureSMSData != null ? captureSMSData.gender : "";
  captureSMSDataDetailsObj.mobileNumber =
    captureSMSData != null ? captureSMSData.phoneNumber : "";
  captureSMSDataDetailsObj.email =
    captureSMSData != null ? captureSMSData.emailId : "";

  function formatPhoneNumber(phoneNumberString) {
    var cleaned = ("" + phoneNumberString).replace(/\D/g, "");
    var match = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/);
    if (match) {
      var intlCode = match[1] ? "+1 " : "";
      return [intlCode, "(", match[2], ") ", match[3], "-", match[4]].join("");
    }
    return null;
  }

  const handleNumberInput = (event) => {
    if (
      event.key !== "Backspace" &&
      event.key !== "Delete" &&
      event.key !== "Tab" &&
      !num_reg_ex.test(event.key)
    ) {
      event.preventDefault();
    }
  };

  let schema = yup.object({
    location: yup.string().trim(),
    mobileNumber: yup.string().trim(),
    email: yup.string().trim(),
    firstName: yup.string().trim(),
    lastName: yup.string().trim(),
    dateOfBirth: yup.string(),
    gender: yup.string().nullable(),
  });
  const {
    values,
    handleSubmit,
    handleChange,
    errors,
    touched,
    resetForm,
    setFieldValue,
  } = useFormik({
    initialValues: {
      location: details?.companyId,
      mobileNumber:
        captureSMSDataDetailsObj?.mobileNumber == null
          ? ""
          : captureSMSDataDetailsObj?.mobileNumber,
      email:
        captureSMSDataDetailsObj?.email == null
          ? ""
          : captureSMSDataDetailsObj?.email,
      firstName:
        captureSMSDataDetailsObj?.firstName == null
          ? ""
          : captureSMSDataDetailsObj?.firstName,
      lastName:
        captureSMSDataDetailsObj?.lastName == null
          ? ""
          : captureSMSDataDetailsObj?.lastName,
      dateOfBirth:
        captureSMSDataDetailsObj?.dob == null
          ? ""
          : captureSMSDataDetailsObj?.dob,
      gender:
        captureSMSDataDetailsObj?.gender == null
          ? ""
          : captureSMSDataDetailsObj?.gender,
    },
    validationSchema: schema,
    onSubmit: async (values) => {
      setemailError(false);

      setmobileNumberError(false);
      if (
        values.email != "" &&
        values.email != null &&
        values.email != undefined
      ) {
        // Regex pattern for email validation
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        if (!emailRegex.test(values.email)) {
          setemailError(true);
          setNotify({
            isOpen: true,
            message: `Invalid email address`,
            type: "error",
          });
          return;
        } else {
          setemailError(false);
        }
      }

      if (!values.mobileNumber && !values.email) {
        // toast.error("Mobile or Email is required");
        setemailError(true);
        setmobileNumberError(true);
        // setNotify({
        //   isOpen: true,
        //   message: "Mobile or Email is required",
        //   type: 'error',
        // });
      } else {
        try {
          await mutateAsync({
            commonParams: {
              organizationID: details?.orgId,
              companyID: details?.companyId,
              coCode: details?.coCode,
              groupID: details?.groupID,
              timeZone: details?.timeZone,
              sessionId: details?.sesionId,
              loginUserId: `${details?.userId}`,
              loginUserName: details?.userName,
              AuditTrail: 0,
              editTrail: 0,
              dataSource: "",
              processStatus: "",
              deliveryStatus: "",
            },
            patientDto: {
              PatientInfo: {
                cosmosKeyId: "",
                status: "",
                dataSource: "",
                auditTrailLevel: 0,
                editTrailLevel: 0,
                locationNumber: "",
                processStatus: "Pending",
                deliveryStatus: "Delivered",
                firstName: values.firstName,
                lastName: values.lastName,
                dob: values.dateOfBirth,
                // dob: values.dateOfBirth == null ? "" : values.dateOfBirth,
                email: values.email,
                phoneNumber: values?.mobileNumber
                  ?.replace("(", "")
                  .replace(")", "")
                  .replace("-", "")
                  .replace(" ", ""),
                gender: values.gender,
              },
            },
          });
        } catch (e) {
          logTelemetry("Failed", "SendSMS", "onSubmit", e?.message, e);
        }
      }
    },
  });

  const handleEmailInputChange = (e) => {
    const newValue = e.target.value;

    setFieldValue("email", newValue);
    if (newValue != undefined && newValue != null && newValue != "") {
      setmobileNumberError(false);
    }
  };

  const handleMobileInputChange = (e) => {
    const newValue = e.target.value;

    if (newValue.length <= maxLength) {
      setFieldValue("mobileNumber", newValue);
      // setFieldValue("mobileNumber", formatPhoneNumber(newValue));
    }
    if (newValue != undefined && newValue != null && newValue != "") {
      setemailError(false);
    }

    // const newValue = e.target.value;
    // if (newValue) {
    //   const previousValue = values?.mobileNumber;
    //   if (newValue?.length === previousValue?.length - 1) {
    //     setFieldValue("mobileNumber", formatPhoneNumber(newValue));
    //   } else if (newValue?.length <= maxLength) {
    //     setFieldValue("mobileNumber", formatPhoneNumber(newValue));
    //   }
    // }
  };

  useEffect(() => {
    const accessList = {
      SendSMSOrEmail: GetSectionRights(accessDetails, segmentId, moduleId, StaticModuleClass.SEND_SMS_OR_EMAIL_SECID),
    };
    setSectionAccess(accessList);
    const functionAccess = {
      Send: GetConfigurationId(accessDetails, segmentId, moduleId, StaticModuleClass.SEND_SMS_OR_EMAIL_SECID, StaticModuleClass.SEND_SMS_OR_EMAIL_SEND_FUNID),
    };
    setFunctionAccess(functionAccess);
  }, []);

  useEffect(() => {
    if (data) {
      if (data?.status && data?.status?.code != 200) {
        // dispatch(sentSMSId(JSON.stringify(1)));
        // dispatch(setActiveComponent("/PATIENT-LIST/SEND-SMS-OR-EMAIL/SMS-FORM"));
        logTelemetry(
          "Failed",
          "SendSMS",
          "onSubmit(useEffect)",
          data?.status?.message
        );
        setNotify({
          isOpen: true,
          message: `${data?.status?.message}`,
          type: "error",
        });
      } else if (data?.status && data?.status?.code == 200) {
        dispatch(sentSMSId(JSON.stringify(data?.data?.id)));
        dispatch(
          setActiveComponent("/PATIENT-LIST/SEND-SMS-OR-EMAIL/SMS-FORM")
        );
      }
    }
    if (data) {
      if (data?.response && data?.response?.status != 200) {
        logTelemetry(
          "Failed",
          "SendSMS",
          "onSubmit(useEffect)",
          data?.response?.message
        );
        setNotify({
          isOpen: true,
          message: `${data?.response?.message}`,
          type: "error",
        });
      }
    }
    sessionStorage?.removeItem("captureSMSData");
  }, [data]);

  function btnBackClick() {
    dispatch(setModuleId(StaticModuleClass.INSURANCE_MODID));
    dispatch(setSegmentId(StaticModuleClass.INTAKE_SEGID));
    dispatch(setActiveComponent("/patient-list"));
  }

  return (
    <div>
      {/* <Breadcrumb 
      items={breadcrumbItems} 
      isRedux={true} 
      segId={StaticModuleClass.INTAKE_SEGID} 
      moduleId={StaticModuleClass.INSURANCE_MODID} /> */}

      {sectionAccess.SendSMSOrEmail && (
        <Container maxWidth="xl">
          <Grid container>
            <Grid item={true} xs={12} sm={12}>
              <Grid container>
                <Grid
                  item={true}
                  xs={12}
                  sm={12}
                  className="flex justify-between !my-4"
                >
                  <Typography variant="h6">Send SMS</Typography>

                  {functionAccess.Send != 0 && (
                    <Box>
                      <>
                        <Button
                          className="button-styles !w-[120px] !mr-2"
                          variant="outlined"
                          onClick={handleSubmit}
                          disable={loading}
                        >
                          Send
                        </Button>
                        <Button
                          className="button-styles !w-[120px]"
                          variant="outlined"
                          onClick={() => btnBackClick()}
                          disable={loading}
                        >
                          Back
                        </Button>
                      </>
                    </Box>
                  )}
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Card>
            <CardContent>
              <Grid container>
                <Grid item xs={12} sm={6} className="flex justify-center">
                  <Autocomplete
                    disablePortal
                    id="combo-box-demo"
                    options={locationData}
                    size="small"
                    value={companyId[0]?.companyName}
                    onChange={(e) =>
                      setFieldValue("companyName", e.target.innerHTML)
                    }
                    // sx={{ width: 160 }}
                    style={textFieldStyles}
                    renderInput={(params) => (
                      <TextField {...params} label="Location" fullWidth />
                    )}
                  />
                </Grid>
                {emailError && mobileNumberError && (
                  <Grid item xs={12} sm={12}>
                    <Alert severity="error">
                      Required Fields Missing (Mobile or Email is required) !
                    </Alert>
                  </Grid>
                )}
                <Grid
                  item
                  xs={12}
                  sm={12}
                  className="my-4"
                  display={{ xs: "none", sm: "block" }}
                >
                  <Divider variant="middle" />
                </Grid>
                <Grid item xs={12} sm={12} className="">
                  <p className="text-slate-600 font-medium text-sm my-2">
                    Mobile or Email is required*
                  </p>
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={6}
                  className="flex justify-center items-center mx-4"
                >
                  <TextField
                    id="outlined-mobile-input"
                    label="Mobile Number"
                    name="Mobile Number"
                    error={
                      mobileNumberError == true
                        ? values.mobileNumber == ""
                          ? true
                          : false
                        : false
                    }
                    className="m-0"
                    value={values.mobileNumber}
                    onChange={handleMobileInputChange}
                    style={textFieldStyles}
                    size="small"
                    onKeyDown={handleNumberInput}
                    tabIndex={0}
                    // InputLabelProps={{ shrink: values.mobileNumber == null ? true : false }}
                  />
                </Grid>

                <Grid item xs={12} sm={6} className="flex justify-center">
                  <TextField
                    id="outlined-email-input"
                    label="E-Mail Address"
                    name="E-Mail Address"
                    error={
                      emailError == true
                        ? values.email == ""
                          ? true
                          : false
                        : false
                    }
                    size="small"
                    className="m-0"
                    value={values.email}
                    onChange={handleEmailInputChange}
                    style={textFieldStyles}
                    tabIndex={1}
                  />
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={12}
                  className="my-4"
                  display={{ xs: "none", sm: "block" }}
                >
                  <Divider variant="middle" />
                </Grid>
                <Grid item xs={12} sm={12} className="">
                  <p className="text-slate-600 font-medium text-sm my-2">
                    Demographics info (Optional)
                  </p>
                </Grid>
                <Grid item xs={12} sm={6} className="flex justify-center">
                  <TextField
                    id="outlined-first-input"
                    label="First Name"
                    name="First Name"
                    className="m-0"
                    size="small"
                    fullWidth
                    value={values.firstName}
                    onChange={handleChange("firstName")}
                    style={textFieldStyles}
                    tabIndex={3}
                  />
                </Grid>

                <Grid item xs={12} sm={6} className="flex justify-center">
                  <TextField
                    id="outlined-last-input"
                    label="Last Name"
                    name="Last Name"
                    className="m-0"
                    value={values.lastName}
                    size="small"
                    fullWidth
                    onChange={handleChange("lastName")}
                    style={textFieldStyles}
                    tabIndex={4}
                  />
                </Grid>
                <Grid item xs={12} sm={6} className="flex justify-center">
                  <TextField
                    id="outlined-dob-input"
                    label="Date of Birth (MM/DD/YYYY)"
                    size="small"
                    name="Date of Birth"
                    className="m-0"
                    value={values.dateOfBirth}
                    onChange={handleChange("dateOfBirth")}
                    placeholder="MM/DD/YYYY"
                    // error={errors.Location && touched.Location}
                    // className="m-0"
                    // onKeyDown={handlePrice}
                    fullWidth
                    style={textFieldStyles}
                    tabIndex={5}
                  />
                </Grid>
                <Grid item xs={12} sm={6} className="flex justify-center">
                  <FormControl style={textFieldStyles} fullWidth>
                    <InputLabel id="gender-select-label" size="small">
                      Gender
                    </InputLabel>
                    <Select
                      labelId="gender-select-label"
                      id="gender-select"
                      name="gender"
                      size="small"
                      value={values.gender}
                      label="Gender"
                      tabIndex={6}
                      onChange={handleChange("gender")}
                    >
                      <MenuItem value={"Male"}>Male</MenuItem>
                      <MenuItem value={"Female"}>Female</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Container>
      )}
      <Backdrop
        sx={{ color: "#1a73e8", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={isLoading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <Notification notify={notify} setNotify={setNotify} />
    </div>
  );
};

export default SendSMS;
