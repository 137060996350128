class StaticModuleClass {
  constructor() { }

  //#region INSURANCE VERIFICATION 
  static INTAKE_SEGID= 80009;
  static INSURANCE_MODID = 70180;
  static LIST_SECID = 60682;
  static IMPORT_FILE_FUNID = 50669;
  static INSURANCE_EXPORT_FUNID = 50080;
  //#endregion
  //#region CAPTURE or Upload
  static CAPTURE_OR_UPLOAD_MODID = 70182;
  static CAPTURE_OR_UPLOAD_SECID = 60684;
  static UPLOAD_FUNID = 50567;
  //#endregion
  //#region SEND SMS or EMAIL
  static SEND_SMS_OR_EMAIL_MODID = 70183;
  static SEND_SMS_OR_EMAIL_SECID = 60685;
  static SEND_SMS_OR_EMAIL_SEND_FUNID = 50056;
  //#endregion
  //#region DISCOVER INSURANCE
  static DISCOVER_INSURANCE_MODID = 70181;
  static DISCOVER_INSURANCE_SECID = 60683;
  static INSURANCE_FUNID = 50654;
  //#endregion
 
  //#region CLAIMS
  static CLAIMS_MODID = 70177;
  static CLAIMS_SECID = 60686;
  static Claims_SEARCH_FUNID = 50670;
  static Claims_COPY_CLAIM_FUNID = 50671;
  static Claims_VOID_FUNID = 50547;
  //#endregion
  //#region CLAIM STATUS
  static CLAIMS_STATUS_MODID = 70178;
  static CLAIMS_STATUS_SECID = 60672;
  static CLAIMS_VERIFY_FUNID = 50672;
  //#endregion
  
  //DONE??//


  //#region ADMINISTRATION LOCATION
  static ADMINISTRATION_SEGID = 80007;
  static LOCATION_MODID = 70007;
  static RADIAL_MENU_SECID = 60088;
  static LOCATION_LIST_SECID = 60060;
  static LOCATION_SECID = 60061;
  static INFORMATION_SECID = 60042;
  static ADDRESSES_SECID = 60002;
  static CONTACTS_SECID = 60017;
  static LOCATION_SETTINGS_SECID = 60658;
  static WORKFLOW_SECID = 60603;
  static DOCUMENTS_SECID = 60543;
  static NOTES_SECID = 60222;
  static ACTIVITY_LOG_SECID = 60598;
  //#endregion

  //#region ADMINISTRATION STAFF
  static STAFF_MODID = 70029;
  static RADIAL_MENU_SECID = 60088;
  static STAFF_SECID = 60098;
  static STAFF_LIST_SECID = 60099;
  static PROFILE_SECID = 60087;
  static WORK_SCHEDULE_SECID = 60375;
  static SYSTEM_USER_SECID = 60384;
  static AFFILIATIONS_SECID = 60385;
  static NOTIFICATIONS_SECID = 60552;
  static STAFF_WORKFLOW_SECID = 60246;
  static STAFF_DOCUMENTS_SECID = 60526;
  static STAFF_NOTES_SECID = 60160;
  static STAFF_ACTIVITY_LOG_SECID = 60577;
  static WORKFLOW_NOTES_SECID = 60249;
  //#endregion

  //#region ADMINISTRATION DATA IMPORT WIZARD
  static DATA_IMPORT_WIZARD_MODID = 70142;
  static DATA_IMPORT_WIZARD_IMPORT_DATA_SECID = 60545;
  static DATA_IMPORT_VIEW_FUNID = 50061;
  static DATA_IMPORT_EDIT_FUNID = 50024;  //---->DISSCUSS
  static DATA_IMPORT_SAVE_FUNID = 50053;
  //#endregion

  //#region ADMINISTRATION DRF CONFIGURATION
  static DRF_CONFIGURATION_MODID = 70184;
  static DRF_CONFIGURATION_LIST_SECID = 60693;
  static DRF_CONFIGURATION_NEW_FUNID = 50032;
  static DRF_CONFIGURATION_EDIT_FUNID = 50024; //---->DISSCUSS
  static DRF_CONFIGURATION_DETAILS_SECID = 60694;
  static DRF_CONFIGURATION_SAVE_FUNID = 50053; //---->DISSCUSS
  //#endregion

  //#region REPORT
  static REPORTS_SEGID = 80005;
  //#endregion

  //#region REPORT TRANSACTION SUMMARY
  static TRANSACTION_SUMMARY_MODID = 70176;
  static REALTIME_ELIGIBILITY_REQUESTS_SECID = 60667;
  static CLAIM_SUBMISSION_SECID = 60668;
  static CLAIM_STATUS_REQUESTS_SECID = 60669;
  static ELECTRONIC_REMITTANCE_ADVICES_SECID = 60670;
  static TRANSACTION_SUMMARY_LIST_SECID = 60690;
  static TRANSACTION_SUMMARY_CLAIM_DETAILS_SECID = 60691;
  static TRANSACTION_SUMMARY_CLAIM_STATUS_SECID = 60692;


  static REALTIME_ELIGIBILITY_REQUESTS_VIEW_FUNID = 50061;
  static CLAIM_SUBMISSION_VIEW_FUNID = 50061;
  static CLAIM_STATUS_REQUESTS_VIEW_FUNID = 50061;
  static ELECTRONIC_REMITTANCE_ADVICES_VIEW_FUNID = 50061;
  static TRANSACTION_SUMMARY_LIST_VIEW_FUNID = 50061;
  static TRANSACTION_SUMMARY_CLAIM_DETAILS_VIEW_FUNID = 50061;
  static TRANSACTION_SUMMARY_CLAIM_STATUS_VIEW_FUNID = 50061;
  //#endregion

  //#region REPORT CUSTOM_REPORT
  static CUSTOM_REPORTS_MODID = 70140;
  static CUSTOM_REPORTS_RADIAL_MENU_SECID = 60088;
  static CUSTOM_REPORTS_NEW_FUNID = 50032; //---->DISSCUSS
  static CUSTOM_REPORTS_EDIT_FUNID = 50024; //---->DISSCUSS
  static CUSTOM_REPORTS_SECID = 60542;
  static CUSTOM_REPORTS_VIEW_FUNID = 50061; //---->DISSCUSS
  static CUSTOM_REPORTS_SAVE_FUNID = 50053;
  static EXPORT_FUNID = 50080;
  static EXECUTE_FUNID = 50585;
  //#endregion

  //#region  USER_APPS SEGMENT
  static USER_APPS_SEGID = 80010;
  static TASK_SUMMARY_MODID = 70075;
  static TASK_SUMMARY_RADIAL_MENU_SECID = 60088;
  static TASK_SUMMARY_ASSIGN_FUNID = 50005;
  static TASK_SUMMARY_UNASSIGN_FUNID = 50090;
  static TASK_SUMMARY_SEND_FUNID = 50056; //---->DISSCUSS
  static TASK_SUMMARY_ADDTASK_FUNID = 50096;
  static TASK_SUMMARY_BY_COUNT_SECID = 60523;
  static TASK_SUMMARY_BY_COUNT_DETAILS_SECID = 60337;
  static TASK_SUMMARY_BY_CHART_SECID = 60524;
  static TASK_SUMMARY_BY_CHART_DETAILS_SECID = 60525;
  static TASK_SUMMARY_NOTES_SECID = 60554;
  //#endregion

  //#region  DOCUMENTS SEGMENT
  static DOCUMENTS_SEGID = 80021;
  static DOCUMENT_SUMMARY_MODID = 70112;
  static DOCUMENT_SUMMARY_SECID = 60440;
  static DOCUMENT_SUMMARY_VIEW_FUNID = 50061;
  static DOCUMENT_SUMMARY_BY_COUNT_SECID = 60568;
  static DOCUMENT_SUMMARY_BY_COUNT_VIEW_FUNID = 50061;
  static DOCUMENT_SUMMARY_BY_COUNT_DETAIL_SECID = 60569;
  static DOCUMENT_SUMMARY_BY_COUNT_DETAIL_VIEW_FUNID = 50061;
  static DOCUMENT_SUMMARY_BY_CHART_SECID = 60570;
  static DOCUMENT_SUMMARY_BY_CHART_VIEW_FUNID = 50061;
  static DOCUMENT_SUMMARY_BY_CHART_DETAIL_SECID = 60571;
  static DOCUMENT_SUMMARY_BY_CHART_DETAIL_VIEW_FUNID = 50061;
  //#endregion

  //#region  DOCUMENTS RECOGNIZER
  static DOCUMENT_RECOGNIZER_MODID = 70179;
  static DOCUMENT_RECOGNIZER_LIST_SECID = 60687;
  static DOCUMENT_RECOGNIZER_VERIFY_STATUS_FUNID = 50672;
  static DOCUMENT_RECOGNIZER_UPLOAD_BATCH_FUNID = 50673;
  static DOCUMENT_RECOGNIZER_UPLOAD_SINGLE_SECID = 60688;
  static DOCUMENT_RECOGNIZER_EXTRACT_BATCH_FUNID = 50662;
  static DOCUMENT_RECOGNIZER_PROMPT_FUNID = 50663;
  static DOCUMENT_RECOGNIZER_DETECT_FUNID = 50664;
  static DOCUMENT_RECOGNIZER_VALIDATE_FUNID = 50665;
  static DOCUMENT_RECOGNIZER_VERIFY_FUNID = 50087;
  static DOCUMENT_RECOGNIZER_EXTRACT_DETAILS_FUNID = 50674;
  static DOCUMENT_RECOGNIZER_PROMPT_DETAILS_FUNID = 50675;
  static DOCUMENT_RECOGNIZER_FINAL_DETAILS_FUNID = 50676;
  static DOCUMENT_RECOGNIZER_DOCUMENT_DETAILS_FUNID = 50677;
  static DOCUMENT_RECOGNIZER_RAW_DATA_FUNID = 50678;
  static DOCUMENT_RECOGNIZER_VALIDATE_DETAILS_FUNID = 50679;
  static DOCUMENT_RECOGNIZER_QNA_DETAILS_FUNID = 50680;
  static DOCUMENT_RECOGNIZER_UPLOAD_BATCH_SECID = 60689;
  static DOCUMENT_RECOGNIZER_PROCESS_FUNID = 50049;
  //#endregion DOCUMENTS
}
export default StaticModuleClass;
