import Constants from "../constants";

export const setModuleId = (data) => {
    return {
        type: Constants.MODULE_ID,
        payload: data,
    };
};

export const setPreviousModuleId = (data) => {
    return {
        type: Constants.PREVIOUS_MODULE_ID,
        payload: data,
    };
};