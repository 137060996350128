import React from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
} from "@mui/material";

function ClearStatesConfirmationDialog({
  open,
  onClose,
  onConfirm,
  title,
  subTitle,
  type,
}) {
  return (
    <Dialog
      open={open}
      onClose={onClose}
      aria-labelledby="customized-dialog-title"
      sx={{
        "& .MuiDialog-paper": {
          width: "520px",
          maxWidth: "90vw",
        },
      }}
    >
      <DialogTitle id="customized-dialog-title">{title}</DialogTitle>

      <DialogContent dividers>
        <Typography gutterBottom>{subTitle}</Typography>
      </DialogContent>

      <DialogActions sx={{ m: 0, p: 2 }}>
        {type == "double" ? (
          <>
            <Button
              onClick={() => onConfirm(title)}
              color="primary"
              className="button-styles"
            >
              Yes
            </Button>
            <Button
              onClick={onClose}
              color="primary"
              autoFocus
              className="button-styles"
            >
              No
            </Button>
          </>
        ) : (
          <Button
            onClick={() => onConfirm(title)}
            color="primary"
            autoFocus
            className="button-styles"
          >
            {title == "Error" ? "Close" : "Ok"}
          </Button>
        )}
      </DialogActions>
    </Dialog>
  );
}

export default ClearStatesConfirmationDialog;
