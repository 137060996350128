import React, {
  useState,
  useEffect,
  useContext,
  useCallback,
  useMemo,
  useRef,
} from "react";
import { Link, Route, Routes, useNavigate } from "react-router-dom";
import {
  Box,
  Grid,
  Button,
  Typography,
  Button as MuiButton,
  Backdrop,
  CircularProgress,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Autocomplete,
  TextField,
  Divider,
} from "@mui/material";
// import { GetLocationDetailsListApi } from "../../apis/LocationDetailsApis";
import MyContext from "../../components/MyContext";
import Notification from "../../components/Notification";
// import MyGridComponent from "./MyGridComponent.js";
import Dialogs from "../../components/DocSummaryDialog";
import dayjs from "dayjs";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
// import React, { useState, useRef, useEffect } from "react";
import { AgGridReact } from "ag-grid-react";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-alpine.css";
// import { Box, Button } from "@mui/material";
// import { AgGridReact } from "ag-grid-react";
import convertToAgGridFormat from "../../components/utils/convertToAgGridFormat";
// import { useAppInsightsContext } from "@microsoft/applicationinsights-react-js";
import useCommonParams from "../../components/utils/useCommonParams";
// import { DocumentContext } from "../../components/utils/DocumentContextProvider";
import { logTelemetry } from "../../logTelemetry";
import Documents from "./Documents";

import ActivityLog from "./ActivityLog";
import Addresses from "./Addresses";
import Notes from "./Notes";
import Contacts from "./Contacts";
import Workflow from "./Workflow";
import LocationDetailsPage from "./LocationDetailsPage";
import LocationSettings from "./LocationSettings";
import ProfileDashboard from "./ProfileDashboard";
import { useDispatch, useSelector } from "react-redux";
import { setActiveComponent } from "../../redux/actions/index";

// import CollapsibleTable from "./CollapsibleTable";
// import { list } from "./list";

const LocationDetails = () => {
  const dispatch = useDispatch();
  const commonParams = useCommonParams();
  // const appInsights = useAppInsightsContext();

  const filterFormInitialData = {
    statusDate: {
      label: "Create Date",
      value: "CDT",
    },
    for: "1",
    daysOrMonths: "days",
    fromDate: dayjs().subtract(1, "day"),
    toDate: dayjs(),
    LocationDetails: {
      companyName: "All",
      companyId: "0",
    },
    status: {
      description: "All",
      code: "0",
    },
    subStatus: {
      description: "All",
      type: "0",
    },
    assignedTo: {
      description: "All",
      code: "0",
    },
    process: {
      id: 0,
      description: "All",
    },
    subProcess: {
      description: "All",
      code: "1000",
    },
  };
  const [formData, setFormData] = useState(filterFormInitialData);
  const [filtersApplied, setFiltersApplied] = useState(false);
  const gridRef = useRef(null);
  const [rowData, setRowData] = useState([
    {
      name: "Alliance Spine And Pain Centers",
      county: "CHEROKEE",
      primaryPhone: "7709299033",
      secondaryPhone: "",
      code: "14578",
      organizationName: "CHIROPACTIC XP",
      organizationCode: "DEVCLP",
    },
    {
      name: "Aetna",
      county: "MIDDLESEX",
      primaryPhone: "1231231231",
      secondaryPhone: "",
      code: "14542",
      organizationName: "CHIROPACTIC XP",
      organizationCode: "DEVCLP",
    },
    {
      name: "CHIROPACTIC XP",
      county: "MIDDLESEX",
      primaryPhone: "5086772554",
      secondaryPhone: "",
      code: "14564",
      organizationName: "CHIROPACTIC XP",
      organizationCode: "DEVCLP",
    },
    {
      name: "NJ Milltown Health Provider",
      county: "MIDDLESEX",
      primaryPhone: "1234567890",
      secondaryPhone: "",
      code: "14313",
      organizationName: "CHIROPACTIC XP",
      organizationCode: "DEVCLP",
    },
    {
      name: "Insite Medical NJ PA",
      county: "CAMDEN",
      primaryPhone: "8334946724",
      secondaryPhone: "",
      code: "14311",
      organizationName: "CHIROPACTIC XP",
      organizationCode: "DEVCLP",
    },
    {
      name: "Houston Health Services",
      county: "HARRIS",
      primaryPhone: "6282946292",
      secondaryPhone: "",
      code: "14309",
      organizationName: "CHIROPACTIC XP",
      organizationCode: "DEVCLP",
    },
    {
      name: "BILLTEC Corp2",
      county: "HENNEPIN",
      primaryPhone: "9528974252",
      secondaryPhone: "",
      code: "14115",
      organizationName: "CHIROPACTIC XP",
      organizationCode: "DEVCLP",
    },
    {
      name: "NJ Milltown Health Provider",
      county: "MIDDLESEX",
      primaryPhone: "1234567890",
      secondaryPhone: "",
      code: "14313",
      organizationName: "CHIROPACTIC XP",
      organizationCode: "DEVCLP",
    },
    {
      name: "Insite Medical NJ PA",
      county: "CAMDEN",
      primaryPhone: "8334946724",
      secondaryPhone: "",
      code: "14311",
      organizationName: "CHIROPACTIC XP",
      organizationCode: "DEVCLP",
    },
    {
      name: "Houston Health Services",
      county: "HARRIS",
      primaryPhone: "6282946292",
      secondaryPhone: "",
      code: "14309",
      organizationName: "CHIROPACTIC XP",
      organizationCode: "DEVCLP",
    },
    {
      name: "BILLTEC Corp2",
      county: "HENNEPIN",
      primaryPhone: "9528974252",
      secondaryPhone: "",
      code: "14115",
      organizationName: "CHIROPACTIC XP",
      organizationCode: "DEVCLP",
    },
  ]);

  const [columnDefs] = useState([
    { headerName: "Name", field: "name", sortable: true, filter: true },
    { headerName: "County", field: "county", sortable: true, filter: true },
    {
      headerName: "Primary Phone",
      field: "primaryPhone",
      sortable: true,
      filter: true,
    },
    {
      headerName: "Secondary Phone",
      field: "secondaryPhone",
      sortable: true,
      filter: true,
    },
    { headerName: "Code", field: "code", sortable: true, filter: true },
    {
      headerName: "Organization Name",
      field: "organizationName",
      sortable: true,
      filter: true,
    },
    {
      headerName: "Organization Code",
      field: "organizationCode",
      sortable: true,
      filter: true,
    },
  ]);
  const defaultColDef = useMemo(
    () => ({
      resizable: true,
      sortable: true,
      // editable: true,
      //   floatingFilter: true,
      // filters: true,
      menuTabs: ["filterMenuTab", "generalMenuTab"],
    }),
    []
  );
  const navigate = useNavigate();
  // const { mutateAsync } = GetLocationDetailsListApi();
  // const { mutateAsync: lookUpsApi } = GetLookUpsForDSApi();
  // const { mutateAsync: GetExportElementsApi } = GetExportElements();
  // let details = JSON.parse(sessionStorage?.getItem("details"));
  // let details = useSelector((state) => JSON.parse(state.loginDetails));
  const loginDetails = useSelector((state) => state.loginDetails);
  const isFromLocation = useSelector((state) => state.isFromLocation);

  let details = useMemo(() => {
    if (loginDetails) {
      return JSON.parse(loginDetails);
    }
    return null;
  }, [loginDetails]);

  // const { contextValue } = useContext(MyContext);
  const [loading, setLoading] = useState(false);
  const [lookUpData, setLookUpData] = useState({});
  const [gridApi, setGridApi] = useState(null);
  const [notify, setNotify] = useState({
    isOpen: false,
    message: "",
    type: "error",
  });
  const [rows, setRows] = useState([]);
  // const [filtersApplied, setFiltersApplied] = useState(false);
  const [subStatusData, setSubStatusData] = useState([]);
  const [open, setOpen] = useState(false);
  let [filters, setFilters] = useState({});
  const agree = () => {
    setOpen(false);
    // setFilters({});
  };
  const disagree = () => {
    setOpen(false);
  };

  const onGridReady = useCallback((params) => {
    setGridApi(params.api);
    params.api.sizeColumnsToFit();
  }, []);

  const handleInputChange = (e) => {
    // debugger;
    const { name, value } = e.target;
    if (name === "for") {
      let fromDate;
      if (formData?.daysOrMonths === "days") {
        fromDate = formData?.toDate.subtract(parseInt(value, 10), "day");
      } else {
        fromDate = formData?.toDate.subtract(parseInt(value, 10), "month");
      }
      setFormData((prevData) => ({
        ...prevData,
        [name]: value,
        fromDate,
      }));
    } else if (name == "Batch Number") {
      setFormData((prevData) => ({
        ...prevData,
        batchNumber: value,
      }));
    } else {
      setFormData((prevData) => ({ ...prevData, [name]: value }));
      if (name === "daysOrMonths") {
        let fromDate;
        if (value === "days") {
          fromDate = formData?.toDate.subtract(
            parseInt(formData?.for, 10),
            "day"
          );
        } else {
          fromDate = formData?.toDate.subtract(
            parseInt(formData?.for, 10),
            "month"
          );
        }
        setFormData((prevData) => ({
          ...prevData,
          [name]: value,
          fromDate,
        }));
      }
    }
  };
  const handleDateChange = (value, name) => {
    // const { name, value } = e.target;
    if (name === "fromDate") {
      setFormData((prevData) => ({ ...prevData, [name]: value, for: "" }));
      return;
    }
    setFormData((prevData) => ({ ...prevData, [name]: value }));
  };
  const handleAutocompleteChange = (name, value) => {
    if (name === "status") {
      const res = lookUpData?.subStatusList?.filter(
        (item) => item.code === value.code
      );
      res.unshift({
        description: "All",
        key: "0",
      });
      setSubStatusData(res);
    }
    setFormData((prevData) => ({ ...prevData, [name]: value }));
  };

  useEffect(() => {
    // lookUpsApiCall();
    // getDocumentList(formData);
    // getLocationDetailsList();
  }, []);

  // const defaultColDef = {
  //   flex: 1,
  //   minWidth: 100,
  //   resizable: true,
  // };

  // const onGridReady = (params) => {
  //   gridRef.current = params.api;
  //   gridRef.current.sizeColumnsToFit();
  // };

  function btnExportXlClick() {
    if (gridApi) {
      gridApi.exportDataAsExcel({
        fileName: "LocationDetails List",
      });
    } else {
      console.error("Grid API not available");
    }
  }

  function btnExportCsvClick() {
    if (gridRef.current) {
      gridRef.current.api.exportDataAsCsv({
        fileName: "LocationDetails List",
      });
    } else {
      console.error("Grid reference is not available");
    }
  }

  async function getLocationDetailsList() {
    setLoading(true);
    try {
      const payload = {
        commonParams: {
          organizationID: details?.orgId,
          companyID: details?.companyId,
          coCode: details?.companyCode,
          groupID: details?.groupID,
          timeZone: details?.timeZone,
          sessionId: details?.sesionId,
          loginUserId: `${details?.userId}`,
          loginUserName: details?.userName,
          AuditTrail: 0,
          editTrail: 0,
          dataSource: "",
        },
      };
      const res = await mutateAsync(payload);
      if (res?.status?.code === 200) {
        setNotify({
          isOpen: true,
          message: `${res?.status?.message}`,
          type: "success",
        });
        let data = res?.data;
        const rows = data?.map((item, index) => ({
          ...item,
          index: index + 1,
        }));
        setRows(rows);
        setLoading(false);
      } else {
        logTelemetry(
          "failed",
          "LocationDetails",
          "getDocumentList",
          `${res?.status?.message}`
        );
        setLoading(false);
        setRows([]);
        setNotify({
          isOpen: true,
          message: `${res?.status?.message}`,
          type: "warning",
        });
      }
    } catch (e) {
      logTelemetry(
        "Failed",
        "LocationDetails",
        "getDocumentList",
        e.message,
        e
      );
      console.error(e, "error in catch");
      setLoading(false);
      setNotify({
        isOpen: true,
        message: e.message,
        type: "error",
      });
    }
  }

  function handleClearValues() {
    setFiltersApplied(false);
    setFormData(filterFormInitialData);
    setOpen(false);
    getDocumentList(filterFormInitialData);
  }
  // list

  async function lookUpsApiCall() {
    setLoading(true);
    try {
      // console.clear();
      const payload = {
        commonParams: {
          organizationID: details?.orgId,
          companyID: details?.companyId,
          coCode: details?.companyCode,
          groupID: details?.groupID,
          timeZone: details?.timeZone,
          sessionId: details?.sesionId,
          loginUserId: `${details?.userId}`,
          loginUserName: details?.userName,
          AuditTrail: 0,
          editTrail: 0,
          dataSource: "",
        },
        configId: "91596",
        moduleId: "0",
        SectionId: "0",
        RequestType: "0",
        RecordId: "0",
      };
      const res = await lookUpsApi(payload);
      // console.log(res, " Result ");
      if (res?.status?.code === 200) {
        // setNotify({
        //   isOpen: true,
        //   message: `${res?.status?.message}`,
        //   type: "success",
        // });
        res?.data?.statusList?.length > 0
          ? res?.data?.statusList?.unshift({
              description: "All",
              code: "0",
            })
          : [];
        setLookUpData(res?.data);
        setLoading(false);
      } else {
        setLoading(false);
        setNotify({
          isOpen: true,
          message: `${res?.status?.message}`,
          type: "warning",
        });
        logTelemetry(
          "failed",
          "LocationDetails",
          "lookUpsApiCall",
          `${res?.status?.message}`
        );
      }
    } catch (e) {
      logTelemetry("Failed", "LocationDetails", "lookUpsApiCall", e.message, e);
      console.error(e, "error");
      setLoading(false);
      setNotify({
        isOpen: true,
        message: e.message,
        type: "error",
      });
    }
  }

  // State to track the currently expanded accordion
  const [expanded, setExpanded] = useState("panel0");

  // List of accordion items
  const sections = [
    { name: "Addresses", component: Addresses },
    { name: "Contacts", component: Contacts },
    { name: "Workflow", component: Workflow },
    { name: "123", path: LocationSettings },
    { name: "LocationSettings", component: LocationSettings },
    { name: "Documents", component: Notes },
    { name: "Notes", component: Notes },
    { name: "ActivityLog", component: ActivityLog },
  ];

  // { name: "Addresses", path: "addresses" },
  // { name: "Contacts", path: "contacts" },
  // { name: "LocationSettings", path: "location-settings" },
  // { name: "123", path: "123" },
  // { name: "Workflow", path: "workflow" },
  // { name: "Documents", path: "documents" },
  // { name: "Notes", path: "notes" },
  // { name: "ActivityLog", path: "activitylog" },

  // Handler to control which accordion is expanded
  const handleChange = (panel, section) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
    if (isExpanded) {
      navigate(section.name.toLowerCase()); // Navigates to profile, settings, etc.
    }
  };

  const btnFilterClick = () => {
    setOpen(true);
  };

  function handleSubmit() {
    // console.log(formData);
    getDocumentList(formData);
    setFiltersApplied(true);
    setOpen(false);
  }

  function btnRefreshClick() {
    getDocumentList(filterFormInitialData);
    setFormData(filterFormInitialData);
    setFiltersApplied(false);
  }

  function btnExportXlClick() {
    if (gridApi) {
      gridApi.exportDataAsExcel({
        fileName: "LocationDetails List",
      });
    } else {
      console.error("Grid API not available");
    }
  }

  function btnExportCsvClick() {
    if (gridRef.current) {
      gridRef.current.api.exportDataAsCsv({
        fileName: "LocationDetails List",
      });
    } else {
      console.error("Grid reference is not available");
    }
  }

  const downloadCsv = (data) => {
    const convertToCSV = (objArray) => {
      const array = Array.isArray(objArray) ? objArray : JSON.parse(objArray);
      let str = "";

      // Get the headers
      const headers = Object.keys(array[0]);
      str += headers.join(",") + "\r\n";

      // Get the rows
      array.forEach((item) => {
        let line = "";
        headers.forEach((header, index) => {
          if (index > 0) line += ",";
          line += item[header];
        });
        str += line + "\r\n";
      });

      return str;
    };

    const csvData = convertToCSV(data);
    const blob = new Blob([csvData], { type: "text/csv;charset=utf-8;" });
    const link = document.createElement("a");

    if (link.download !== undefined) {
      const url = URL.createObjectURL(blob);
      link.setAttribute("href", url);
      link.setAttribute("download", "Export List.csv");
      link.style.visibility = "hidden";
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  };

  const btnExportListCsvClick = async () => {
    const docIds = gridRef.current?.api
      ?.getRenderedNodes()
      .map((node) => node.data.documentId)
      .join("^");
    // Define the payload for the API call
    const payload = {
      commonParams,
      ConfigId: "0",
      ModuleId: "0",
      SectionId: "0",
      RecordId: docIds,
      RequestType: "1", // Assuming different RequestType for CSV export
    };

    setLoading(true);

    try {
      let res = await GetExportElementsApi(payload);

      if (res?.status?.code === 200) {
        setNotify({
          isOpen: true,
          message: `${res?.status?.message}`,
          type: "success",
        });
        downloadCsv(res?.data?.searchValues); // Assuming downloadCsv function exists
        setLoading(false);
      } else {
        logTelemetry(
          "failed",
          "LocationDetails",
          "btnExportListCsvClick",
          `${res?.status?.message}`
        );
        setLoading(false);
        setNotify({
          isOpen: true,
          message: `${res?.status?.message}`,
          type: "warning",
        });
      }
    } catch (e) {
      console.error(e, "error");
      logTelemetry(
        "Failed",
        "LocationDetails",
        "exportListCsvClick",
        e.message,
        e
      );
      setLoading(false);
      setNotify({
        isOpen: true,
        message: e.message,
        type: "error",
      });
    }
  };

  const btnBackClick = () => {
    if (isFromLocation) {
      dispatch(setActiveComponent("/location"));
    } else {
      dispatch(setActiveComponent("/staff"));
    }
  };

  return (
    <>
      <Grid container>
        <Grid item={true} xs={12} sm={12}>
          <Grid container>
            <Grid
              item={true}
              xs={12}
              sm={12}
              className="!my-3 flex justify-between"
            >
              <Typography variant="h6">
                {isFromLocation ? "Location Details" : "Staff Details"}
              </Typography>
              <Box>
                <Button
                  variant="outlined"
                  color="primary"
                  onClick={btnBackClick}
                  className="button-styles !mr-2 !w-[120px]"
                >
                  Back
                </Button>
              </Box>
            </Grid>
          </Grid>
        </Grid>
      </Grid>

      <div>
        <ProfileDashboard />
      </div>

      {open ? (
        <Dialogs
          open={true}
          cancel={agree}
          submit={disagree}
          // page={page}
          setFilters={setFilters}
          filters={filters}
          // setIsFilters={setIsFilters}
          setOpen={setOpen}
          lookUpData={lookUpData}
          formData={formData}
          setFormData={setFormData}
          handleAutocompleteChange={handleAutocompleteChange}
          handleInputChange={handleInputChange}
          handleDateChange={handleDateChange}
          handleSubmit={handleSubmit}
          subStatusData={subStatusData}
          handleClearValues={handleClearValues}
        />
      ) : null}
      <Backdrop
        sx={{ color: "#1a73e8", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={loading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <Notification notify={notify} setNotify={setNotify} />
    </>
  );
};

export default LocationDetails;
