import React from "react";
import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Grid,
  TextField,
  Box,
  Typography,
} from "@mui/material";

import CloseIcon from "@mui/icons-material/Close";

const LocationModal = ({ open, handleClose }) => {
  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="customized-dialog-title"
    >
      <DialogTitle id="customized-dialog-title">
        Edit Location Info
        <CloseIcon
          aria-label="close"
          className="button-stylesDialog cursor-pointer windowClose"
          onClick={handleClose}
        />
      </DialogTitle>
      <DialogContent dividers>
        <Box>
          {/* Record Data */}
          <Typography
            variant="h6"
            gutterBottom
            sx={{ fontSize: "1rem", fontWeight: "bold", paddingY: 1 }}
          >
            Record Data
          </Typography>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <TextField
                fullWidth
                label="Location Code"
                value="14578"
                variant="outlined"
                size="small"
                disabled
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                fullWidth
                label="Organization Code"
                value="devclp"
                variant="outlined"
                size="small"
                disabled
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                fullWidth
                label="Record Status"
                value="Active"
                variant="outlined"
                size="small"
                disabled
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                fullWidth
                label="Location Type"
                value="Satellite"
                variant="outlined"
                size="small"
                disabled
              />
            </Grid>
          </Grid>

          {/* Location Data */}
          <Typography
            variant="h6"
            gutterBottom
            sx={{ fontSize: "1rem", fontWeight: "bold", paddingY: 1 }}
          >
            Location Data
          </Typography>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TextField
                fullWidth
                label="Location Name"
                value="Alliance Spine And Pain Centers"
                variant="outlined"
                size="small"
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                label="Time Zone"
                value="Eastern"
                variant="outlined"
                size="small"
                disabled
              />
            </Grid>
          </Grid>

          {/* Address & Contact */}
          <Typography
            variant="h6"
            gutterBottom
            sx={{ fontSize: "1rem", fontWeight: "bold", paddingY: 1 }}
          >
            Address & Contact
          </Typography>
          <Grid container spacing={2} className="!mb-3">
            <Grid item xs={6}>
              <TextField
                fullWidth
                label="Country"
                value="United States"
                variant="outlined"
                size="small"
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                fullWidth
                label="Address"
                value="134 Riverstone"
                variant="outlined"
                size="small"
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                fullWidth
                label="Postal Code"
                value="30114"
                variant="outlined"
                size="small"
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                fullWidth
                label="City or Town"
                value="Canton"
                variant="outlined"
                size="small"
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                fullWidth
                label="State or Province"
                value="Georgia"
                variant="outlined"
                size="small"
              />
            </Grid>
            <Grid item xs={6}></Grid>
            <Grid item xs={6}>
              <TextField
                fullWidth
                label="Primary Phone"
                value="(770) 929-9033"
                variant="outlined"
                size="small"
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                fullWidth
                label="Primary Email"
                value="test@orbithc.com"
                variant="outlined"
                size="small"
              />
            </Grid>
          </Grid>
        </Box>
      </DialogContent>
      <DialogActions sx={{ m: 0, p: 2 }}>
        <Button
          onClick={handleClose}
          className="button-styles !min-w-[100px]"
          color="primary"
        >
          Update Data
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default LocationModal;
