import {
  AccordionDetails,
  AccordionSummary,
  Accordion,
  Divider,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  Backdrop,
  CircularProgress,
  Container,
  IconButton,
  Button as MuiButton,
  Button,
  List,
  ListItem,
} from "@mui/material";
import { makeStyles } from "@material-ui/core";
import React, { useMemo, useState } from "react";
import RefreshIcon from "@mui/icons-material/Refresh";
import Breadcrumb from "../../components/Breadcrumb";
import { GetPatientDetails, GetURL } from "../../apis/patientDetails";
import { useContext } from "react";
import MyContext from "../../components/MyContext";
import { useLocation } from "react-router-dom";
import { useEffect } from "react";
import moment from "moment";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Notification from "../../components/Notification";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
import { ArrowBackOutlined } from "@mui/icons-material";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Stack from "@mui/material/Stack";
import ZoomOutMapSharpIcon from "@mui/icons-material/ZoomOutMapSharp";
import DownloadMapSharpIcon from "@mui/icons-material/Download";
import CloseIcon from "@mui/icons-material/Close";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Paper from "@mui/material/Paper";
import Draggable from "react-draggable";
import { useNavigate } from "react-router-dom";
import Tooltip from "@mui/material/Tooltip";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import { logTelemetry } from "../../logTelemetry";
import ViewDocument from "./ViewDocument";
import { setActiveComponent } from "../../redux/actions";
import { useDispatch, useSelector } from "react-redux";
import StaticModuleClass from "../../components/utils/staticModuleClass";
import {
  setcaptureData,
  setcaptureDetailsData,
  setcaptureSMSData,
} from "../../redux/actions/patientDetails";
import { setModuleId, setPreviousModuleId } from "../../redux/actions/moduleId";
import {
  setPreviousSegmentId,
  setSegmentId,
} from "../../redux/actions/segmentId";
const PatientDetails = ({ dataFromTSL, isFromList, setDataFromTSL }) => {
  const { cosmosId: cosmosStoreId, filteredData: patientFilteredData } =
    useSelector(
      (state) => state?.previousComponent?.previousComponentData || {}
    );
  // console.log({ cosmosStoreId, patientFilteredData });
  // const patientFilteredData = useSelector((state) => state.patientFilteredData);
  const { state } = useLocation();
  const [activeStep, setactiveStep] = useState(0);
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const dataaa = JSON.parse(queryParams.get("data"));
  const [expanded, setExpanded] = useState(false);
  // const { contextValue } = useContext(MyContext);
  const [loading, setLoading] = useState(false);
  // const loginInitialDetails = useSelector((state) =>
  //   JSON.parse(state.loginDetails)
  // );
  // let details = loginInitialDetails;

  const loginDetails = useSelector((state) => state.loginDetails);

  let details = useMemo(() => {
    if (loginDetails) {
      return JSON.parse(loginDetails);
    }
    return null;
  }, [loginDetails]);
  const dispatch = useDispatch();
  const { mutateAsync, data, isLoading } = GetPatientDetails();
  const { mutateAsync: mutateAsync1, isLoading1 } = GetURL();
  const [expandedsum, setExpandedsum] = useState(true);
  const [expandedsumMul, setexpandedsumMul] = useState(false);
  const segmentId = useSelector((state) => state.segmentID);
  const moduleId = useSelector((state) => state.moduleID);
  const previousSegmentId = useSelector((state) => state.previousSegmentID);
  const previousModuleId = useSelector((state) => state.previousModuleID);
  let aIVisitTypeList = details?.aIAllServiceTypeList;

  // const [isHideBreadCum, setisHideBreadCum] = useState(
  //   state ? true : dataaa ? true : false
  // );
  const [isHideBreadCum, setisHideBreadCum] = useState(
    patientFilteredData ? true : dataaa ? true : false
  );

  const [pdfURL, setPdfURL] = useState("");
  const [isPDF, setIsPDF] = useState(false);
  // const [currentIndex, setCurrentIndex] = useState(
  //   location?.state?.filteredData.indexOf(location?.state?.cosmosKeyID)
  // );
  // const [listOfDids, setListOfDids] = useState(
  //   location?.state?.filteredData ?? []
  // );
  const [currentIndex, setCurrentIndex] = useState(
    patientFilteredData ? patientFilteredData.indexOf(cosmosStoreId) : []
  );
  const [listOfDids, setListOfDids] = useState(patientFilteredData ?? []);

  const [detailsListedInsPayer, setdetailsListedInsPayer] = useState(false);

  const [frontImage, setfrontImage] = useState(false);
  const [backImage, setbackImage] = useState(false);
  const [steps, setsteps] = useState([]);
  const [stepServiceTypeCodes, setstepServiceTypeCodes] = useState([]);

  const [frontDLImage, setfrontDLImage] = useState(false);
  const [backDLImage, setbackDLImage] = useState(false);
  const [isViewDocument, setIsViewDocument] = useState(false);
  const [imageId, setImageId] = useState("");
  const [imageName, setImageName] = useState("");

  const [recordSource, setrecordSource] = useState("");
  const [procesStatus, setprocesStatus] = useState("");
  const navigate = useNavigate();
  const [notify, setNotify] = useState({
    isOpen: false,
    message: "",
    type: "",
  });
  // const useStyles = makeStyles(() => ({
  //   root: {
  //     "& .MuiStepIcon-active": { color: "red" },
  //     "& .MuiStepIcon-completed": { color: "green" },
  //     "& .Mui-disabled .MuiStepIcon-root": { color: "cyan" }
  //   }
  // }));

  // const ckeys = useStyles();
  // const [cosmosId, setCosmosId] = React.useState(
  //   state
  //     ? state.cosmosKeyID
  //     : dataaa
  //     ? dataaa
  //     : dataFromTSL?.providercontrolnumber?.orbitAssignedClaimId
  //     ? dataFromTSL?.providercontrolnumber?.orbitAssignedClaimId
  //     : null
  // );

  const [cosmosId, setCosmosId] = React.useState(
    cosmosStoreId
      ? cosmosStoreId
      : dataaa
      ? dataaa
      : dataFromTSL?.providercontrolnumber?.orbitAssignedClaimId
      ? dataFromTSL?.providercontrolnumber?.orbitAssignedClaimId
      : null
  );

  const [open, setOpen] = React.useState(false);
  const [open1, setOpen1] = React.useState(false);
  const handleClose1 = async () => {
    setOpen1(false);
  };
  const handleClose = async () => {
    setOpen(false);
  };
  function PaperComponent(props) {
    return (
      <Draggable
        open={'[class*="body"]'}
        cancel={'[class*="MuiDialogContent-root"]'}
      >
        <Paper {...props} />
      </Draggable>
    );
  }

  useEffect(() => {
    getPatientDetails(listOfDids[currentIndex]);
  }, [currentIndex, listOfDids]);

  const handleNext = () => {
    if (currentIndex < listOfDids.length - 1) {
      setCurrentIndex(currentIndex + 1);
    }
  };

  const handlePrevious = () => {
    if (currentIndex > 0) {
      setCurrentIndex(currentIndex - 1);
    }
  };

  const handleOpen = async (selVal) => {
    if (selVal == 0) {
      setfrontImage(true);
      setbackImage(false);
      setbackDLImage(false);
      setfrontDLImage(false);
    }
    if (selVal == 1) {
      setbackImage(true);
      setfrontImage(false);
      setbackDLImage(false);
      setfrontDLImage(false);
    }

    setOpen(true);
  };

  const handleDLOpen = async (selVal, id, name) => {
    if (selVal == 0) {
      setfrontDLImage(true);
      setbackImage(false);
      setfrontImage(false);
      setbackDLImage(false);
    }
    if (selVal == 1) {
      setbackDLImage(true);
      setfrontDLImage(false);
      setbackImage(false);
      setfrontImage(false);
    }

    if (selVal === 10) {
      setLoading(true);
      getDocumentData(id, 0);
      setImageId(id);
      setImageName(name);
      return;
    }

    setOpen(true);
  };

  const handleDownload = async (selVal) => {
    if (selVal == 0) {
      setfrontImage(true);
      setbackImage(false);
    }
    if (selVal == 1) {
      setbackImage(true);
      setfrontImage(false);
    }

    setOpen(true);
  };

  // let data = patientListData ? patientListData : dataaa ? dataaa : null;
  const values = {
    commonParams: {
      organizationID: details?.orgId,
      companyID: details?.companyId,
      coCode: details?.companyCode,
      groupID: details?.groupID,
      timeZone: details?.timeZone,
      sessionId: details?.sesionId,
      loginUserId: `${details?.userId}`,
      loginUserName: details?.userName,
      AuditTrail: 0,
      editTrail: 0,
      dataSource: "",
    },
    processStatus: "",
    PatientId: 0,
    deliveryStatus: "",
    cosmosId: cosmosId,
    seachText: "",
    dobFromDate: "",
    dobToDate: "",
    transactionFromDate: "",
    transactionToDate: "",
    batchNumber: "",
    locationName: "",
    sortColumnName: "",
    sortColumnValue: "",
    pageNumber: 1,
    pageSize: 50,
    createdBy: "",
    locationId: "0",
  };

  async function getPatientDetails(val) {
    try {
      if (val) {
        values.cosmosId = val;
      }
      await mutateAsync(values);
    } catch (e) {
      logTelemetry(
        "Failed",
        "PatientDetails",
        "getPatientDetails",
        e?.message,
        e
      );
    }
  }

  async function sendSMSData(val) {
    dispatch(setcaptureSMSData(JSON.stringify(data?.data)));
    dispatch(setModuleId(StaticModuleClass.SEND_SMS_OR_EMAIL_MODID));
    dispatch(setSegmentId(StaticModuleClass.INTAKE_SEGID));
    dispatch(setActiveComponent("/patient-list/send-sms-or-email"));
  }

  function CallInsuranceDetails() {
    try {
      if (
        data?.data?.requestInfo != null &&
        data?.data?.payerCorrectionsFlag == "Yes"
      ) {
        if (
          (data?.data?.patientInsurance?.subscriberId != "") &
          (data?.data?.requestInfo?.insurance?.subscriberId != "")
        ) {
          if (
            data?.data?.requestInfo?.insurance?.subscriberId?.toLowerCase() !=
            data?.data?.patientInsurance?.subscriberId?.toLowerCase()
          ) {
            return true;
          }
        }
        if (
          (data?.data?.patientInsurance?.groupNumber != "") &
          (data?.data?.requestInfo?.insurance?.groupNumber != "")
        ) {
          if (
            data?.data?.requestInfo?.insurance?.groupNumber?.toLowerCase() !=
            data?.data?.patientInsurance?.groupNumber?.toLowerCase()
          ) {
            return true;
          }
        }
      }
    } catch (ex) {}
    return false;
  }

  function displayeligibilityServiceTypes() {
    var stringNames = "";
    try {
      var splitDataArray = data?.data?.eligibilityServiceTypes?.split(",");

      for (var k = 0; k <= splitDataArray?.length; k++) {
        var lstDataObject = aIVisitTypeList?.filter(
          (i) => i.code.replace(" ", "") == splitDataArray[k]
        );
        if (lstDataObject?.length > 0) {
          stringNames =
            stringNames +
            lstDataObject[0].description +
            " (" +
            lstDataObject[0].code +
            ")" +
            " , ";
        }
      }
    } catch (ex) {
      return "";
    }
    return stringNames;
  }

  function CallstepsListFunction(setdrecordSource, dprocesStatus) {
    setactiveStep(0);
    for (var i = steps.length - 1; i >= 0; i--) {
      steps.splice(i, 1);
    }
    if (setdrecordSource != undefined && setdrecordSource != "") {
      if (
        setdrecordSource == "SMS" ||
        setdrecordSource == "SMS & Email" ||
        setdrecordSource == "Email"
      ) {
        if (dprocesStatus == "Viewed" || dprocesStatus == "Submitted") {
          steps.push({ color: "green", status: "Sent >> Viewed" });
          setactiveStep((prevActiveStep) => prevActiveStep + 1);
        } else {
          steps.push({ color: "green", status: setdrecordSource });
          setactiveStep((prevActiveStep) => prevActiveStep + 1);
        }
      } else {
        steps.push({ color: "green", status: setdrecordSource });
        setactiveStep((prevActiveStep) => prevActiveStep + 1);
      }

      if (data?.data?.insuranceCardFiles?.length > 0) {
        steps.push({ color: "green", status: "Card Captured " });
        setactiveStep((prevActiveStep) => prevActiveStep + 1);
      }

      if (data?.data?.firstName?.length > 0) {
        if (procesStatus == "Sent") {
          steps.push({ color: "green", status: "Sent >> Viewed" });
          setactiveStep((prevActiveStep) => prevActiveStep + 1);
        } else {
          steps.push({ color: "green", status: "Submitted" });
          setactiveStep((prevActiveStep) => prevActiveStep + 1);
        }
      }

      if (data?.data?.eligibilityStatus == "Active") {
        steps.push({ color: "green", status: "Verification Completed" });
        setactiveStep((prevActiveStep) => prevActiveStep + 1);
      } else if (data?.data?.eligibilityStatus == "InActive") {
        steps.push({ color: "green", status: "Verification Completed" });
        setactiveStep((prevActiveStep) => prevActiveStep + 1);
      } else if (data?.data?.eligibilityStatus != "") {
        steps.push({ color: "red", status: "Verification Failed" });
      }
    }
    return "";
  }

  function callbackGroundpayerSystem(selval) {
    try {
      if (data?.data?.payerCorrectionsFlag == "Yes") {
        if (selval == "16") {
          if (
            data.data.requestInfo != null &&
            (data?.data?.requestInfo?.demographics?.addressLine1 == "" ||
              data?.data?.requestInfo?.demographics?.addressLine1 == null) &&
            data?.data?.addressLine1 != ""
          ) {
            data.data.requestInfo.demographics.addressLine1 =
              data.data.addressLine1;
            data.data.requestInfo.demographics.addressLine2 =
              data.data.addressLine2;
            data.data.requestInfo.demographics.cityTown = data.data.cityTown;
            data.data.requestInfo.demographics.stateProvinceCode =
              data.data.stateProvinceCode;
            data.data.requestInfo.demographics.postalCode =
              data.data.postalCode;
            return "";
          } else {
            if (data.data.requestInfo == null) {
              data.data.requestInfo = { demographics: {} };
              data.data.requestInfo.demographics.addressLine1 =
                data.data.addressLine1;
              data.data.requestInfo.demographics.addressLine2 =
                data.data.addressLine2;
              data.data.requestInfo.demographics.cityTown = data.data.cityTown;
              data.data.requestInfo.demographics.stateProvinceCode =
                data.data.stateProvinceCode;
              data.data.requestInfo.demographics.postalCode =
                data.data.postalCode;
            }
          }
        }
        if (selval == "1" && data.data.requestInfo != null) {
          if (
            data?.data?.requestInfo?.demographics?.firstName?.toLowerCase() !=
            data?.data?.firstName?.toLowerCase()
          ) {
            return "#f8f8";
          }
        }
        if (selval == "2" && data.data.requestInfo != null) {
          if (
            data?.data?.requestInfo?.demographics?.lastName?.toLowerCase() !=
            data?.data?.lastName?.toLowerCase()
          ) {
            return "#f8f8";
          }
        }
        if (selval == "3" && data.data.requestInfo != null) {
          if (
            data?.data?.requestInfo?.demographics?.middleName?.toLowerCase() !=
            data?.data?.middleName?.toLowerCase()
          )
            return "#f8f8";
        }
        if (selval == "4" && data.data.requestInfo != null) {
          if (
            data?.data?.requestInfo?.demographics?.dob?.toLowerCase() !=
            data?.data?.dob?.toLowerCase()
          ) {
            return "#f8f8";
          }
        }
        if (selval == "5" && data.data.requestInfo != null) {
          if (
            data?.data?.requestInfo?.demographics?.gender?.toLowerCase() !=
            data?.data?.gender?.toLowerCase()
          ) {
            return "#f8f8";
          }
        }
        if (selval == "6" && data.data.requestInfo != null) {
          if (
            data?.data?.requestInfo?.insurance?.relationship?.toLowerCase() !=
            data?.data?.patientInsurance?.relationship?.toLowerCase()
          ) {
            return "#f8f8";
          }
        }
        if (selval == "7" && data.data.requestInfo != null) {
          if (
            data?.data?.requestInfo?.subscriber?.firstName?.toLowerCase() !=
            data?.data?.patientInsurance?.subscriberFirstName?.toLowerCase()
          ) {
            return "#f8f8";
          }
        }
        if (selval == "8" && data.data.requestInfo != null) {
          if (
            data?.data?.requestInfo?.subscriber?.lastNameName?.toLowerCase() !=
            data?.data?.patientInsurance?.subscriberLastName?.toLowerCase()
          ) {
            return "#f8f8";
          }
        }

        if (selval == "9" && data.data.requestInfo != null) {
          if (
            data?.data?.requestInfo?.subscriber?.dob?.toLowerCase() !=
            data?.data?.patientInsurance?.dob?.toLowerCase()
          ) {
            return "#f8f8";
          }
        }

        if (selval == "10" && data.data.requestInfo != null) {
          if (
            data?.data?.requestInfo?.subscriber?.gender?.toLowerCase() !=
            data?.data?.patientInsurance?.gender?.toLowerCase()
          ) {
            return "#f8f8";
          }
        }

        if (selval == "11" && data.data.requestInfo != null) {
          if (
            data?.data?.requestInfo?.insurance?.subscriberId?.toLowerCase() !=
            data?.data?.patientInsurance?.subscriberId?.toLowerCase()
          ) {
            return "#f8f8";
          }
        }

        if (selval == "12" && data.data.requestInfo != null) {
          if (
            data?.data?.requestInfo?.insurance?.groupNumber?.toLowerCase() !=
            data?.data?.patientInsurance?.groupNumber?.toLowerCase()
          ) {
            return "#f8f8";
          }
        }

        if (selval == "13" && data.data.requestInfo != null) {
          if (
            data?.data?.requestInfo?.insurance?.rteInsuranceType?.toLowerCase() !=
            data?.data?.patientInsurance?.rteInsuranceType?.toLowerCase()
          ) {
            return "#f8f8";
          }
        }

        if (selval == "14" && data.data.requestInfo != null) {
          if (
            data?.data?.requestInfo?.insurance?.rtePlanTypeDescription?.toLowerCase() !=
            data?.data?.patientInsurance?.rtePlanTypeDescription?.toLowerCase()
          ) {
            return "#f8f8";
          }
        }
      }
    } catch (ex) {
      return "";
    }
    return "";
  }

  function BindServiceTypesCodes(code) {
    try {
      var chkServiceCode = parseInt(code);
      if (isNaN(chkServiceCode) == true) {
        var lstDataObject = details?.aIAllServiceTypeList?.filter(
          (i) => i.code.replace(" ", "") == code
        );
        if (lstDataObject != undefined && lstDataObject.length > 0) {
          return lstDataObject[0].description;
        } else {
          return code;
        }
      } else {
        var lstDataObject = details?.aIAllServiceTypeList?.filter(
          (i) => i.code.replace(" ", "") == code
        );
        if (lstDataObject != undefined && lstDataObject.length > 0) {
          return lstDataObject[0].description;
        }
      }
    } catch (ex) {}
  }

  function BindInsuranceDetails(val, subType) {
    try {
      if (data?.data?.coverageBenefits?.length > 0) {
        const companyIdSetails = data?.data?.coverageBenefits?.filter(
          (i) => i.coverageCode == 30
        );

        if (companyIdSetails != null && companyIdSetails.length > 0) {
          if (subType == "PCP") {
            const DeductibleonList = companyIdSetails[0].benefits?.filter(
              (i) => i.code == "L"
            );
            if (DeductibleonList != null && DeductibleonList.length > 0) {
              return DeductibleonList[0].additionalInfo;
            }
          }
          if (val == 1) {
            const DeductibleonList = companyIdSetails[0].benefits?.filter(
              (i) => i.code == "C" && i.timeQualifierCode == 23
            );
            if (DeductibleonList != null && DeductibleonList.length > 0) {
              return "Calender year";
            }
            return "Plan year";
          }

          if (val == 2) {
            const CoInsuranceAmtList = companyIdSetails[0].benefits?.filter(
              (i) => i.code == "A" && i.inPlanNetworkIndicatorCode == "Y"
            );
            if (CoInsuranceAmtList != null && CoInsuranceAmtList.length > 0) {
              const CoInsuranceAmtListINDList = CoInsuranceAmtList?.filter(
                (i) => i.coverageTypeCode == "IND"
              );
              const CoInsuranceAmtListFAMList = CoInsuranceAmtList?.filter(
                (i) => i.coverageTypeCode == "FAM"
              );
              const CoInsuranceAmtListFAMListObject =
                CoInsuranceAmtList?.filter((i) => i.coverageTypeCode == null);
              if (
                CoInsuranceAmtListFAMListObject != null &&
                CoInsuranceAmtListFAMListObject.length > 0
              ) {
                return CoInsuranceAmtListFAMListObject[0].coverageAmt == null
                  ? ""
                  : CoInsuranceAmtListFAMListObject[0].coverageAmt;
              }
            }
          }

          if (val == 3) {
            const InNetworkDetailsList = companyIdSetails[0].benefits?.filter(
              (i) => i.inPlanNetworkIndicatorCode == "Y"
            );
            const companyIdSetailsPhy = data?.data?.coverageBenefits?.filter(
              (i) => i.coverageCode == 98
            );
            if (
              (InNetworkDetailsList != null &&
                InNetworkDetailsList.length > 0) ||
              (companyIdSetailsPhy != null && companyIdSetailsPhy.length > 0)
            ) {
              if (subType == 1) {
                const coInsuranceDataObj = InNetworkDetailsList?.filter(
                  (i) => i.code == "A"
                );
                if (
                  coInsuranceDataObj != null &&
                  coInsuranceDataObj.length > 0
                ) {
                  return coInsuranceDataObj[0].coveragePercentage == null
                    ? ""
                    : coInsuranceDataObj[0].coveragePercentage;
                } else {
                  if (
                    companyIdSetailsPhy != null &&
                    companyIdSetailsPhy.length > 0
                  ) {
                    const InNetworkDetailsListPhy =
                      companyIdSetailsPhy[0].benefits?.filter(
                        (i) => i.inPlanNetworkIndicatorCode == "Y"
                      );
                    if (
                      InNetworkDetailsListPhy != null &&
                      InNetworkDetailsListPhy.length > 0
                    ) {
                      const coInsuranceDataObj =
                        InNetworkDetailsListPhy?.filter((i) => i.code == "A");
                      if (
                        coInsuranceDataObj != null &&
                        coInsuranceDataObj.length > 0
                      ) {
                        return coInsuranceDataObj[0].coveragePercentage == null
                          ? ""
                          : coInsuranceDataObj[0].coveragePercentage;
                      }
                    }
                  }
                }
                return "";
              }

              if (subType == 2) {
                var strObjectVal = "pcp";

                const companyIdSetails98 = data?.data?.coverageBenefits?.filter(
                  (i) => i.coverageCode == 98
                );
                if (
                  companyIdSetails98 != undefined &&
                  companyIdSetails98.length > 0
                ) {
                  const InNetworkDetailsList98Y =
                    companyIdSetails98[0].benefits?.filter(
                      (i) => i.inPlanNetworkIndicatorCode == "Y"
                    );
                  if (
                    InNetworkDetailsList98Y != undefined &&
                    InNetworkDetailsList98Y.length > 0
                  ) {
                    var teleMedcinearray = InNetworkDetailsList98Y?.filter(
                      (o) =>
                        Object.keys(o).some((k) =>
                          o[k]
                            ?.toLowerCase()
                            .includes(strObjectVal.toLowerCase())
                        )
                    );

                    if (
                      teleMedcinearray != undefined &&
                      teleMedcinearray.length == 0
                    ) {
                      teleMedcinearray = InNetworkDetailsList98Y?.filter((o) =>
                        Object.keys(o).some((k) =>
                          o[k]?.toLowerCase().includes("primary care")
                        )
                      );
                    }

                    if (
                      teleMedcinearray != undefined &&
                      teleMedcinearray.length > 0
                    ) {
                      const coSpecilistArrayObj = teleMedcinearray?.filter(
                        (i) => i.code == "B"
                      );
                      if (
                        coSpecilistArrayObj != undefined &&
                        coSpecilistArrayObj.length > 0
                      ) {
                        return coSpecilistArrayObj[0]?.coverageAmt == null
                          ? ""
                          : coSpecilistArrayObj[0]?.coverageAmt;
                      }
                    } else {
                      const coSpecilistArrayObj =
                        InNetworkDetailsList98Y?.filter((i) => i.code == "B");
                      if (
                        coSpecilistArrayObj != undefined &&
                        coSpecilistArrayObj.length > 0
                      ) {
                        return coSpecilistArrayObj[0]?.coverageAmt == null
                          ? ""
                          : coSpecilistArrayObj[0]?.coverageAmt;
                      }
                    }
                  }
                }
              }
              if (subType == 3) {
                var strObjectVal = "specialist";
                var teleMedicinaeBenifitArray = InNetworkDetailsList?.filter(
                  (o) =>
                    Object.keys(o).some((k) =>
                      o[k]?.toLowerCase().includes(strObjectVal.toLowerCase())
                    )
                );
                const coDeductableDataObjFam =
                  teleMedicinaeBenifitArray?.filter((i) => i.code == "B");
                if (
                  coDeductableDataObjFam != undefined &&
                  coDeductableDataObjFam.length > 0
                ) {
                  return coDeductableDataObjFam[0]?.coverageAmt == null
                    ? ""
                    : coDeductableDataObjFam[0]?.coverageAmt;
                } else {
                  const companyIdSetails98 =
                    data?.data?.coverageBenefits?.filter(
                      (i) => i.coverageCode == 98
                    );
                  if (
                    companyIdSetails98 != undefined &&
                    companyIdSetails98.length > 0
                  ) {
                    var teleMedcinearray =
                      companyIdSetails98[0].benefits?.filter((o) =>
                        Object.keys(o).some((k) =>
                          o[k]
                            ?.toLowerCase()
                            .includes(strObjectVal.toLowerCase())
                        )
                      );
                    if (
                      teleMedcinearray != undefined &&
                      teleMedcinearray.length > 0
                    ) {
                      const coSpecilistArrayObj = teleMedcinearray?.filter(
                        (i) => i.code == "B"
                      );
                      if (
                        coSpecilistArrayObj != undefined &&
                        coSpecilistArrayObj.length > 0
                      ) {
                        return coSpecilistArrayObj[0]?.coverageAmt == null
                          ? ""
                          : coSpecilistArrayObj[0]?.coverageAmt;
                      }
                    }
                  }
                } // const  DeductibleonLi99st = companyIdSetails[0].benefits?.filter((i) => i.additionalInfo);
                return "";
              }
              if (subType == 4) {
                var strObjectVal = "telemedicine";
                var teleMedicinaeBenifitArray = InNetworkDetailsList?.filter(
                  (o) =>
                    Object.keys(o).some((k) =>
                      o[k]?.toLowerCase().includes(strObjectVal.toLowerCase())
                    )
                );
                if (
                  teleMedicinaeBenifitArray != undefined &&
                  teleMedicinaeBenifitArray.length > 0
                ) {
                  const coDeductableDataObjFam = teleMedcinearray?.filter(
                    (i) => i.code == "B"
                  );
                  return coDeductableDataObjFam[0]?.coverageAmt == null
                    ? ""
                    : coDeductableDataObjFam[0]?.coverageAmt;
                } else {
                  const companyIdSetails98 =
                    data?.data?.coverageBenefits?.filter(
                      (i) => i.coverageCode == 98
                    );
                  if (
                    companyIdSetails98 != undefined &&
                    companyIdSetails98.length > 0
                  ) {
                    var teleMedcinearray =
                      companyIdSetails98[0].benefits?.filter((o) =>
                        Object.keys(o).some((k) =>
                          o[k]
                            ?.toLowerCase()
                            .includes(strObjectVal.toLowerCase())
                        )
                      );
                    const coDeductableDataObjFam = teleMedcinearray?.filter(
                      (i) => i.code == "B"
                    );
                    if (
                      coDeductableDataObjFam != undefined &&
                      coDeductableDataObjFam.length > 0
                    ) {
                      return coDeductableDataObjFam[0]?.coverageAmt == null
                        ? ""
                        : coDeductableDataObjFam[0]?.coverageAmt;
                    }
                  }
                } // const  DeductibleonLi99st = companyIdSetails[0].benefits?.filter((i) => i.additionalInfo);
                return "";
              }
              if (subType == 5) {
                const companyIdMHSetails = data?.data?.coverageBenefits?.filter(
                  (i) => i.coverageCode == "MH"
                );
                const CoInsuranceAmtList =
                  companyIdMHSetails[0]?.benefits?.filter(
                    (i) => i.code == "B" && i.inPlanNetworkIndicatorCode == "Y"
                  );
                if (
                  CoInsuranceAmtList != null &&
                  CoInsuranceAmtList.length > 0
                ) {
                  var strObjectVal = "pcp";
                  var teleMedicinaeBenifitArray = CoInsuranceAmtList?.filter(
                    (o) =>
                      Object.keys(o).some((k) =>
                        o[k]?.toLowerCase().includes(strObjectVal.toLowerCase())
                      )
                  );
                  if (
                    teleMedicinaeBenifitArray != undefined &&
                    teleMedicinaeBenifitArray.length > 0
                  ) {
                    return teleMedicinaeBenifitArray[0]?.coverageAmt == null
                      ? ""
                      : teleMedicinaeBenifitArray[0]?.coverageAmt;
                  }
                }
              }

              if (subType == 6) {
                const coDeductableDataObj = InNetworkDetailsList?.filter(
                  (i) => i.code == "C"
                );
                const DeductibleAmtINDList = coDeductableDataObj?.filter(
                  (i) =>
                    i.coverageTypeCode == "IND" && i.timeQualifierCode != 29
                );
                if (
                  DeductibleAmtINDList != null &&
                  DeductibleAmtINDList.length > 0
                ) {
                  const DeductibleAmtINDTimeList = coDeductableDataObj?.filter(
                    (i) =>
                      i.coverageTypeCode == "IND" && i.timeQualifierCode == 29
                  );
                  if (
                    DeductibleAmtINDTimeList != null &&
                    DeductibleAmtINDTimeList.length > 0
                  )
                    return DeductibleAmtINDList[0].coverageAmt == null
                      ? ""
                      : DeductibleAmtINDList[0].coverageAmt +
                          " (" +
                          DeductibleAmtINDTimeList[0].coverageAmt +
                          " Remaining)";
                  else
                    return DeductibleAmtINDList[0].coverageAmt == null
                      ? ""
                      : DeductibleAmtINDList[0].coverageAmt;
                } else {
                  if (
                    companyIdSetailsPhy != null &&
                    companyIdSetailsPhy.length > 0
                  ) {
                    const InNetworkDetailsListPhy =
                      companyIdSetailsPhy[0].benefits?.filter(
                        (i) => i.inPlanNetworkIndicatorCode == "Y"
                      );
                    if (
                      InNetworkDetailsListPhy != null &&
                      InNetworkDetailsListPhy.length > 0
                    ) {
                      const coDeductableDataObj =
                        InNetworkDetailsListPhy?.filter((i) => i.code == "C");
                      const DeductibleAmtINDList = coDeductableDataObj?.filter(
                        (i) =>
                          i.coverageTypeCode == "IND" &&
                          i.timeQualifierCode != 29
                      );
                      if (
                        DeductibleAmtINDList != null &&
                        DeductibleAmtINDList.length > 0
                      ) {
                        const DeductibleAmtINDTimeList =
                          coDeductableDataObj?.filter(
                            (i) =>
                              i.coverageTypeCode == "IND" &&
                              i.timeQualifierCode == 29
                          );
                        if (
                          DeductibleAmtINDTimeList != null &&
                          DeductibleAmtINDTimeList.length > 0
                        )
                          return DeductibleAmtINDList[0].coverageAmt == null
                            ? ""
                            : DeductibleAmtINDList[0].coverageAmt +
                                " (" +
                                DeductibleAmtINDTimeList[0].coverageAmt +
                                " Remaining)";
                        else
                          return DeductibleAmtINDList[0].coverageAmt == null
                            ? ""
                            : DeductibleAmtINDList[0].coverageAmt;
                      }
                    }
                  }
                }
                return "";
              }
              if (subType == 7) {
                const coDeductableDataObjFam = InNetworkDetailsList?.filter(
                  (i) => i.code == "C"
                );
                const DeductibleAmtFAMList = coDeductableDataObjFam?.filter(
                  (i) =>
                    i.coverageTypeCode == "FAM" &&
                    i.timeQualifierCode != 29 &&
                    i.coverageAmt > 0
                );
                if (
                  DeductibleAmtFAMList != null &&
                  DeductibleAmtFAMList.length > 0
                ) {
                  const DeductibleAmtFAMimeList =
                    coDeductableDataObjFam?.filter(
                      (i) =>
                        i.coverageTypeCode == "FAM" &&
                        i.timeQualifierCode == 29 &&
                        i.coverageAmt > 0
                    );
                  if (
                    DeductibleAmtFAMimeList != null &&
                    DeductibleAmtFAMimeList.length > 0
                  )
                    return DeductibleAmtFAMList[0].coverageAmt == null
                      ? ""
                      : DeductibleAmtFAMList[0].coverageAmt +
                          " (" +
                          DeductibleAmtFAMimeList[0].coverageAmt +
                          " Remaining)";
                  else
                    return DeductibleAmtFAMList[0].coverageAmt == null
                      ? ""
                      : DeductibleAmtFAMList[0].coverageAmt;
                } else {
                  if (
                    companyIdSetailsPhy != null &&
                    companyIdSetailsPhy.length > 0
                  ) {
                    const InNetworkDetailsListPhy =
                      companyIdSetailsPhy[0].benefits?.filter(
                        (i) => i.inPlanNetworkIndicatorCode == "Y"
                      );
                    if (
                      InNetworkDetailsListPhy != null &&
                      InNetworkDetailsListPhy.length > 0
                    ) {
                      const coDeductableDataObj =
                        InNetworkDetailsListPhy?.filter((i) => i.code == "C");
                      const DeductibleAmtINDList = coDeductableDataObj?.filter(
                        (i) =>
                          i.coverageTypeCode == "FAM" &&
                          i.timeQualifierCode != 29
                      );
                      if (
                        DeductibleAmtINDList != null &&
                        DeductibleAmtINDList.length > 0
                      ) {
                        const DeductibleAmtINDTimeList =
                          coDeductableDataObj?.filter(
                            (i) =>
                              i.coverageTypeCode == "FAM" &&
                              i.timeQualifierCode == 29
                          );
                        if (
                          DeductibleAmtINDTimeList != null &&
                          DeductibleAmtINDTimeList.length > 0
                        )
                          return DeductibleAmtINDList[0].coverageAmt == null
                            ? ""
                            : DeductibleAmtINDList[0].coverageAmt +
                                " (" +
                                DeductibleAmtINDTimeList[0].coverageAmt +
                                " Remaining)";
                        else
                          return DeductibleAmtINDList[0].coverageAmt == null
                            ? ""
                            : DeductibleAmtINDList[0].coverageAmt;
                      }
                    }
                  }
                }
                return "";
              }
              if (subType == 8) {
                const coPocketDataObjFam = InNetworkDetailsList?.filter(
                  (i) => i.code == "G"
                );
                const DPocketAmtFAMList = coPocketDataObjFam?.filter(
                  (i) =>
                    i.coverageTypeCode == "IND" &&
                    i.timeQualifierCode != 29 &&
                    i.coverageAmt > 0
                );
                if (DPocketAmtFAMList != null && DPocketAmtFAMList.length > 0) {
                  const pocketRemainAmtFAMimeList = coPocketDataObjFam?.filter(
                    (i) =>
                      i.coverageTypeCode == "IND" &&
                      i.timeQualifierCode == 29 &&
                      i.coverageAmt > 0
                  );
                  if (
                    pocketRemainAmtFAMimeList != null &&
                    pocketRemainAmtFAMimeList.length > 0
                  )
                    return DPocketAmtFAMList[0].coverageAmt == null
                      ? ""
                      : DPocketAmtFAMList[0].coverageAmt +
                          " (" +
                          pocketRemainAmtFAMimeList[0].coverageAmt +
                          " Remaining)";
                  else
                    return DPocketAmtFAMList[0].coverageAmt == null
                      ? ""
                      : DPocketAmtFAMList[0].coverageAmt;
                } else {
                  return "";
                }
              }
              if (subType == 9) {
                const coPocketDataObjFam = InNetworkDetailsList?.filter(
                  (i) => i.code == "G"
                );
                const DPocketAmtFAMList = coPocketDataObjFam?.filter(
                  (i) =>
                    i.coverageTypeCode == "FAM" &&
                    i.timeQualifierCode != 29 &&
                    i.coverageAmt > 0
                );
                if (DPocketAmtFAMList != null && DPocketAmtFAMList.length > 0) {
                  const pocketRemainAmtFAMimeList = coPocketDataObjFam?.filter(
                    (i) =>
                      i.coverageTypeCode == "FAM" &&
                      i.timeQualifierCode == 29 &&
                      i.coverageAmt > 0
                  );
                  if (
                    pocketRemainAmtFAMimeList != null &&
                    pocketRemainAmtFAMimeList.length > 0
                  )
                    return DPocketAmtFAMList[0].coverageAmt == null
                      ? ""
                      : DPocketAmtFAMList[0].coverageAmt +
                          " (" +
                          pocketRemainAmtFAMimeList[0].coverageAmt +
                          " Remaining)";
                  else
                    return DPocketAmtFAMList[0].coverageAmt == null
                      ? ""
                      : DPocketAmtFAMList[0].coverageAmt;
                } else {
                  return "";
                }
              }
            }

            const DeductibleAmtList = companyIdSetails[0].benefits?.filter(
              (i) => i.code == "C" && i.inPlanNetworkIndicatorCode == "Y"
            );
            if (DeductibleAmtList != null && DeductibleAmtList.length > 0) {
            }
          }

          if (val == 4) {
            const InNetworkDetailsListN = companyIdSetails[0].benefits?.filter(
              (i) => i.inPlanNetworkIndicatorCode == "N"
            );
            if (
              InNetworkDetailsListN != null &&
              InNetworkDetailsListN.length > 0
            ) {
              if (subType == 1) {
                const coInsuranceDataObj = InNetworkDetailsListN?.filter(
                  (i) => i.code == "A"
                );
                if (
                  coInsuranceDataObj != null &&
                  coInsuranceDataObj.length > 0
                ) {
                  return coInsuranceDataObj[0].coveragePercentage == null
                    ? ""
                    : coInsuranceDataObj[0].coveragePercentage;
                }
                return "";
              }

              if (subType == 2) {
                var strObjectVal = "pcp";
                const companyIdSetails98 = data?.data?.coverageBenefits?.filter(
                  (i) => i.coverageCode == 98
                );
                if (
                  companyIdSetails98 != undefined &&
                  companyIdSetails98.length > 0
                ) {
                  const InNetworkDetailsList98N =
                    companyIdSetails98[0].benefits?.filter(
                      (i) => i.inPlanNetworkIndicatorCode == "N"
                    );
                  if (
                    InNetworkDetailsList98N != undefined &&
                    InNetworkDetailsList98N.length > 0
                  ) {
                    var teleMedcinearray = InNetworkDetailsList98N?.filter(
                      (o) =>
                        Object.keys(o).some((k) =>
                          o[k]
                            ?.toLowerCase()
                            .includes(strObjectVal.toLowerCase())
                        )
                    );
                    if (
                      teleMedcinearray != undefined &&
                      teleMedcinearray.length > 0
                    ) {
                      const coSpecilistArrayObj = teleMedcinearray?.filter(
                        (i) => i.code == "B"
                      );
                      if (
                        coSpecilistArrayObj != undefined &&
                        coSpecilistArrayObj.length > 0
                      ) {
                        return coSpecilistArrayObj[0]?.coverageAmt == null
                          ? ""
                          : coSpecilistArrayObj[0]?.coverageAmt;
                      }
                    } else {
                      const coSpecilistArrayObj =
                        InNetworkDetailsList98N?.filter((i) => i.code == "B");
                      if (
                        coSpecilistArrayObj != undefined &&
                        coSpecilistArrayObj.length > 0
                      ) {
                        return coSpecilistArrayObj[0]?.coverageAmt == null
                          ? ""
                          : coSpecilistArrayObj[0]?.coverageAmt;
                      }
                    }
                  }
                }
              }
              if (subType == 3) {
                var strObjectVal = "specialist";
                var teleMedicinaeBenifitArray = InNetworkDetailsListN?.filter(
                  (o) =>
                    Object.keys(o).some((k) =>
                      o[k]?.toLowerCase().includes(strObjectVal.toLowerCase())
                    )
                );
                const coDeductableDataObjFam =
                  teleMedicinaeBenifitArray?.filter((i) => i.code == "B");
                if (
                  coDeductableDataObjFam != undefined &&
                  coDeductableDataObjFam.length > 0
                ) {
                  return coDeductableDataObjFam[0]?.coverageAmt == null
                    ? ""
                    : coDeductableDataObjFam[0]?.coverageAmt;
                } else {
                  const companyIdSetails98 =
                    data?.data?.coverageBenefits?.filter(
                      (i) => i.coverageCode == 98
                    );
                  if (
                    companyIdSetails98 != undefined &&
                    companyIdSetails98.length > 0
                  ) {
                    const InNetworkDetailsListPecialKey98Y =
                      companyIdSetails98[0].benefits?.filter(
                        (i) => i.inPlanNetworkIndicatorCode == "N"
                      );
                    if (
                      InNetworkDetailsListPecialKey98Y != undefined &&
                      InNetworkDetailsListPecialKey98Y.length > 0
                    ) {
                      var teleMedcinearray =
                        InNetworkDetailsListPecialKey98Y?.filter((o) =>
                          Object.keys(o).some((k) =>
                            o[k]
                              ?.toLowerCase()
                              .includes(strObjectVal.toLowerCase())
                          )
                        );
                      if (
                        teleMedcinearray != undefined &&
                        teleMedcinearray.length > 0
                      ) {
                        const coSpecilistArrayObj = teleMedcinearray?.filter(
                          (i) => i.code == "B"
                        );
                        if (
                          coSpecilistArrayObj != undefined &&
                          coSpecilistArrayObj.length > 0
                        ) {
                          return coSpecilistArrayObj[0]?.coverageAmt == null
                            ? ""
                            : coSpecilistArrayObj[0]?.coverageAmt;
                        }
                      }
                    }
                  }
                } // const  DeductibleonLi99st = companyIdSetails[0].benefits?.filter((i) => i.additionalInfo);
                return "";
              }
              if (subType == 4) {
                var strObjectVal = "telemedicine";
                var teleMedicinaeBenifitArray = InNetworkDetailsListN?.filter(
                  (o) =>
                    Object.keys(o).some((k) =>
                      o[k]?.toLowerCase().includes(strObjectVal.toLowerCase())
                    )
                );
                if (
                  teleMedicinaeBenifitArray != undefined &&
                  teleMedicinaeBenifitArray.length > 0
                ) {
                  const coDeductableDataObjFam = teleMedcinearray?.filter(
                    (i) => i.code == "B"
                  );
                  return coDeductableDataObjFam[0]?.coverageAmt == null
                    ? ""
                    : coDeductableDataObjFam[0]?.coverageAmt;
                } else {
                  const companyIdSetails98 =
                    data?.data?.coverageBenefits?.filter(
                      (i) => i.coverageCode == 98
                    );
                  if (
                    teleMedcinearray != undefined &&
                    teleMedcinearray.length > 0
                  ) {
                    var teleMedcinearray =
                      companyIdSetails98[0].benefits?.filter((o) =>
                        Object.keys(o).some((k) =>
                          o[k]
                            ?.toLowerCase()
                            .includes(strObjectVal.toLowerCase())
                        )
                      );
                    const coDeductableDataObjFam = teleMedcinearray?.filter(
                      (i) => i.code == "B"
                    );
                    if (
                      coDeductableDataObjFam != undefined &&
                      coDeductableDataObjFam.length > 0
                    ) {
                      return coDeductableDataObjFam[0]?.coverageAmt == null
                        ? ""
                        : coDeductableDataObjFam[0]?.coverageAmt;
                    }
                  }
                } // const  DeductibleonLi99st = companyIdSetails[0].benefits?.filter((i) => i.additionalInfo);
                return "";
              }
              if (subType == 5) {
                const companyIdMHSetails = data?.data?.coverageBenefits?.filter(
                  (i) => i.coverageCode == "MH"
                );
                const CoInsuranceAmtList =
                  companyIdMHSetails[0]?.benefits?.filter(
                    (i) => i.code == "B" && i.inPlanNetworkIndicatorCode == "N"
                  );
                if (
                  CoInsuranceAmtList != null &&
                  CoInsuranceAmtList.length > 0
                ) {
                  var strObjectVal = "pcp";
                  var teleMedicinaeBenifitArray = CoInsuranceAmtList?.filter(
                    (o) =>
                      Object.keys(o).some((k) =>
                        o[k]?.toLowerCase().includes(strObjectVal.toLowerCase())
                      )
                  );
                  if (
                    teleMedicinaeBenifitArray != undefined &&
                    teleMedicinaeBenifitArray.length > 0
                  ) {
                    return teleMedicinaeBenifitArray[0]?.coverageAmt == null
                      ? ""
                      : teleMedicinaeBenifitArray[0]?.coverageAmt;
                  }
                }
              }

              if (subType == 6) {
                const coDeductableDataObj = InNetworkDetailsListN?.filter(
                  (i) => i.code == "C"
                );
                const DeductibleAmtINDList = coDeductableDataObj?.filter(
                  (i) =>
                    i.coverageTypeCode == "IND" &&
                    i.timeQualifierCode != 29 &&
                    i.coverageAmt > 0
                );
                if (
                  DeductibleAmtINDList != null &&
                  DeductibleAmtINDList.length > 0
                ) {
                  const DeductibleAmtINDTimeList = coDeductableDataObj?.filter(
                    (i) =>
                      i.coverageTypeCode == "IND" &&
                      i.timeQualifierCode == 29 &&
                      i.coverageAmt > 0
                  );
                  if (
                    DeductibleAmtINDTimeList != null &&
                    DeductibleAmtINDTimeList.length > 0
                  )
                    return DeductibleAmtINDList[0].coverageAmt == null
                      ? ""
                      : DeductibleAmtINDList[0].coverageAmt +
                          " (" +
                          DeductibleAmtINDTimeList[0].coverageAmt +
                          " Remaining)";
                  else
                    return DeductibleAmtINDList[0].coverageAmt == null
                      ? ""
                      : DeductibleAmtINDList[0].coverageAmt;
                }
                return "";
              }
              if (subType == 7) {
                const coDeductableDataObjFam = InNetworkDetailsListN?.filter(
                  (i) => i.code == "C"
                );
                const DeductibleAmtFAMList = coDeductableDataObjFam?.filter(
                  (i) =>
                    i.coverageTypeCode == "FAM" &&
                    i.timeQualifierCode != 29 &&
                    i.coverageAmt > 0
                );
                if (
                  DeductibleAmtFAMList != null &&
                  DeductibleAmtFAMList.length > 0
                ) {
                  const DeductibleAmtFAMimeList =
                    coDeductableDataObjFam?.filter(
                      (i) =>
                        i.coverageTypeCode == "FAM" &&
                        i.timeQualifierCode == 29 &&
                        i.coverageAmt > 0
                    );
                  if (
                    DeductibleAmtFAMimeList != null &&
                    DeductibleAmtFAMimeList.length > 0
                  )
                    return DeductibleAmtFAMList[0].coverageAmt == null
                      ? ""
                      : DeductibleAmtFAMList[0].coverageAmt +
                          " (" +
                          DeductibleAmtFAMimeList[0].coverageAmt +
                          " Remaining)";
                  else
                    return DeductibleAmtFAMList[0].coverageAmt == null
                      ? ""
                      : DeductibleAmtFAMList[0].coverageAmt;
                }
                return "";
              }
              if (subType == 8) {
                const coPocketDataObjFam = InNetworkDetailsListN?.filter(
                  (i) => i.code == "G"
                );
                const DPocketAmtFAMList = coPocketDataObjFam?.filter(
                  (i) =>
                    i.coverageTypeCode == "IND" &&
                    i.timeQualifierCode != 29 &&
                    i.coverageAmt > 0
                );
                if (DPocketAmtFAMList != null && DPocketAmtFAMList.length > 0) {
                  const pocketRemainAmtFAMimeList = coPocketDataObjFam?.filter(
                    (i) =>
                      i.coverageTypeCode == "IND" &&
                      i.timeQualifierCode == 29 &&
                      i.coverageAmt > 0
                  );
                  if (
                    pocketRemainAmtFAMimeList != null &&
                    pocketRemainAmtFAMimeList.length > 0
                  )
                    return DPocketAmtFAMList[0].coverageAmt == null
                      ? ""
                      : DPocketAmtFAMList[0].coverageAmt +
                          " (" +
                          pocketRemainAmtFAMimeList[0].coverageAmt +
                          " Remaining)";
                  else
                    return DPocketAmtFAMList[0].coverageAmt == null
                      ? ""
                      : DPocketAmtFAMList[0].coverageAmt;
                } else {
                  return "";
                }
              }
              if (subType == 9) {
                const coPocketDataObjFam = InNetworkDetailsListN?.filter(
                  (i) => i.code == "G"
                );
                const DPocketAmtFAMList = coPocketDataObjFam?.filter(
                  (i) =>
                    i.coverageTypeCode == "FAM" &&
                    i.timeQualifierCode != 29 &&
                    i.coverageAmt > 0
                );
                if (DPocketAmtFAMList != null && DPocketAmtFAMList.length > 0) {
                  const pocketRemainAmtFAMimeList = coPocketDataObjFam?.filter(
                    (i) =>
                      i.coverageTypeCode == "FAM" &&
                      i.timeQualifierCode == 29 &&
                      i.coverageAmt > 0
                  );
                  if (
                    pocketRemainAmtFAMimeList != null &&
                    pocketRemainAmtFAMimeList.length > 0
                  )
                    return DPocketAmtFAMList[0].coverageAmt == null
                      ? ""
                      : DPocketAmtFAMList[0].coverageAmt +
                          " (" +
                          pocketRemainAmtFAMimeList[0].coverageAmt +
                          " Remaining)";
                  else
                    return DPocketAmtFAMList[0].coverageAmt == null
                      ? ""
                      : DPocketAmtFAMList[0].coverageAmt;
                } else {
                  return "";
                }
              }
            }

            const DeductibleAmtList = companyIdSetails[0].benefits?.filter(
              (i) => i.code == "C" && i.inPlanNetworkIndicatorCode == "Y"
            );
            if (DeductibleAmtList != null && DeductibleAmtList.length > 0) {
            }
          }

          if (val == 5) {
            const inPlanNetworkIndicatorCodeListY =
              companyIdSetails[0].benefits?.filter(
                (i) => i.inPlanNetworkIndicatorCode == "Y"
              );
            const inPlanNetworkIndicatorCodeListN =
              companyIdSetails[0].benefits?.filter(
                (i) => i.inPlanNetworkIndicatorCode == "N"
              );
            if (
              inPlanNetworkIndicatorCodeListY != null &&
              inPlanNetworkIndicatorCodeListN != null &&
              inPlanNetworkIndicatorCodeListY.length > 0 &&
              inPlanNetworkIndicatorCodeListN.length > 0
            ) {
              return "In-Network / Out-Of-Network";
            } else if (
              inPlanNetworkIndicatorCodeListY != null &&
              inPlanNetworkIndicatorCodeListY.length > 0
            ) {
              return "In-Network";
            } else if (
              inPlanNetworkIndicatorCodeListN != null &&
              inPlanNetworkIndicatorCodeListN.length > 0
            ) {
              return "Out-Of-Network";
            }
            return "N/A";
          }
        }
      }
    } catch (ex) {}
  }

  function getCoverageBenifitListCoinSuranceY(selOthercoverageBenefits) {
    var InNetworkDetailsListPhy = null;

    const companyIdSetails = selOthercoverageBenefits?.filter(
      (i) => i.coverageCode == "98"
    );
    if (companyIdSetails != null && companyIdSetails.length > 0) {
      InNetworkDetailsListPhy = companyIdSetails[0].benefits?.filter(
        (i) => i.code == "A" && i.inPlanNetworkIndicatorCode == "Y"
      );
    }
    if (
      InNetworkDetailsListPhy != null &&
      InNetworkDetailsListPhy.length > 0 &&
      InNetworkDetailsListPhy[0].coveragePercentage != null &&
      InNetworkDetailsListPhy[0].coveragePercentage != ""
    ) {
      return InNetworkDetailsListPhy[0].coveragePercentage * 100 + "%";
    } else {
      // check Bz
      companyIdSetails = selOthercoverageBenefits?.filter(
        (i) => i.coverageCode == "BZ"
      );
      if (companyIdSetails != null && companyIdSetails.length > 0) {
        InNetworkDetailsListPhy = companyIdSetails[0].benefits?.filter(
          (i) => i.code == "A" && i.inPlanNetworkIndicatorCode == "Y"
        );
      }
      if (
        InNetworkDetailsListPhy != null &&
        InNetworkDetailsListPhy.length > 0 &&
        InNetworkDetailsListPhy[0].coveragePercentage != null &&
        InNetworkDetailsListPhy[0].coveragePercentage != ""
      ) {
        return InNetworkDetailsListPhy[0].coveragePercentage * 100 + "%";
      } else {
        // check Bz
        companyIdSetails = selOthercoverageBenefits?.filter(
          (i) => i.coverageCode == "By"
        );
        if (companyIdSetails != null && companyIdSetails.length > 0) {
          InNetworkDetailsListPhy = companyIdSetails[0].benefits?.filter(
            (i) => i.code == "A" && i.inPlanNetworkIndicatorCode == "Y"
          );
        }
        if (
          InNetworkDetailsListPhy != null &&
          InNetworkDetailsListPhy.length > 0 &&
          InNetworkDetailsListPhy[0].coveragePercentage != null &&
          InNetworkDetailsListPhy[0].coveragePercentage != ""
        ) {
          return InNetworkDetailsListPhy[0].coveragePercentage * 100 + "%";
        } else {
        }
      }
    }
  }

  function BindInsuranceOtherDetailsServiceName(
    val,
    subType,
    OthercoverageBenefitsList,
    chkServiceType
  ) {
    try {
      var coverageCode = 30;
      var coverageCode98 = 98;
      if (
        chkServiceType != undefined &&
        chkServiceType != "" &&
        chkServiceType != null
      ) {
        coverageCode = chkServiceType;
        coverageCode98 = chkServiceType;
      }
      const OthercoverageBenefits = OthercoverageBenefitsList?.coverageBenefits;
      if (OthercoverageBenefits?.length > 0) {
        const companyIdSetails = OthercoverageBenefits?.filter(
          (i) => i.coverageCode == coverageCode
        );
        if (companyIdSetails != null && companyIdSetails.length > 0) {
          const DeductibleonList = companyIdSetails[0].benefits?.filter(
            (i) => i.code == "1"
          );
          if (DeductibleonList != null && DeductibleonList.length > 0) {
            return DeductibleonList[0].title;
          }
          var DeductibleonDeactiveList = companyIdSetails[0].benefits?.filter(
            (i) => i.code == "6"
          );
          if (
            DeductibleonDeactiveList != null &&
            DeductibleonDeactiveList.length > 0
          ) {
            return DeductibleonDeactiveList[0].title;
          }
        }
      }
    } catch (ex) {}
  }

  function BindInsuranceOtherDetails(
    val,
    subType,
    OthercoverageBenefitsList,
    chkServiceType
  ) {
    try {
      var coverageCode = 30;
      var coverageCode98 = 98;
      if (
        chkServiceType != undefined &&
        chkServiceType != "" &&
        chkServiceType != null
      ) {
        coverageCode = chkServiceType;
        coverageCode98 = chkServiceType;
      }
      const OthercoverageBenefits = OthercoverageBenefitsList?.coverageBenefits;
      if (OthercoverageBenefits?.length > 0) {
        const companyIdSetails = OthercoverageBenefits?.filter(
          (i) => i.coverageCode == coverageCode
        );

        if (companyIdSetails != null && companyIdSetails.length > 0) {
          if (subType == "PCP") {
            const DeductibleonList = companyIdSetails[0].benefits?.filter(
              (i) => i.code == "L"
            );
            if (DeductibleonList != null && DeductibleonList.length > 0) {
              return DeductibleonList[0].additionalInfo;
            }
          }
          if (val == 1) {
            const DeductibleonList = companyIdSetails[0].benefits?.filter(
              (i) => i.code == "C" && i.timeQualifierCode == 23
            );
            if (DeductibleonList != null && DeductibleonList.length > 0) {
              return "Calender year";
            }
            return "Plan year";
          }

          if (val == 2) {
            const CoInsuranceAmtList = companyIdSetails[0].benefits?.filter(
              (i) => i.code == "A" && i.inPlanNetworkIndicatorCode == "Y"
            );
            if (CoInsuranceAmtList != null && CoInsuranceAmtList.length > 0) {
              const CoInsuranceAmtListINDList = CoInsuranceAmtList?.filter(
                (i) => i.coverageTypeCode == "IND"
              );
              const CoInsuranceAmtListFAMList = CoInsuranceAmtList?.filter(
                (i) => i.coverageTypeCode == "FAM"
              );
              const CoInsuranceAmtListFAMListObject =
                CoInsuranceAmtList?.filter((i) => i.coverageTypeCode == null);
              if (
                CoInsuranceAmtListFAMListObject != null &&
                CoInsuranceAmtListFAMListObject.length > 0
              ) {
                return CoInsuranceAmtListFAMListObject[0].coverageAmt == null
                  ? ""
                  : CoInsuranceAmtListFAMListObject[0].coverageAmt;
              }
            }
          }

          if (val == 3) {
            const InNetworkDetailsList = companyIdSetails[0].benefits?.filter(
              (i) =>
                i.inPlanNetworkIndicatorCode == "Y" ||
                i.inPlanNetworkIndicatorCode == "W"
            );
            const companyIdSetailsPhy = OthercoverageBenefits?.filter(
              (i) => i.coverageCode == coverageCode98
            );
            try {
              // companyIdSetailsPhy = OthercoverageBenefits?.filter((i) => i.coverageCode == "MH");
            } catch (err) {
              console.log(err.message);
            }
            if (
              (InNetworkDetailsList != null &&
                InNetworkDetailsList.length > 0) ||
              (companyIdSetailsPhy != null && companyIdSetailsPhy.length > 0)
            ) {
              if (subType == 1) {
                const coInsuranceDataObj = InNetworkDetailsList?.filter(
                  (i) => i.code == "A"
                );
                if (
                  coInsuranceDataObj != null &&
                  coInsuranceDataObj.length > 0
                ) {
                  if (
                    coInsuranceDataObj[0].coveragePercentage != null &&
                    coInsuranceDataObj[0].coveragePercentage != ""
                  ) {
                    return coInsuranceDataObj[0].coveragePercentage * 100 + "%";
                  }
                } else {
                  if (
                    companyIdSetailsPhy != null &&
                    companyIdSetailsPhy.length > 0
                  ) {
                    const InNetworkDetailsListPhy =
                      companyIdSetailsPhy[0].benefits?.filter(
                        (i) =>
                          i.inPlanNetworkIndicatorCode == "Y" ||
                          i.inPlanNetworkIndicatorCode == "W"
                      );
                    if (
                      InNetworkDetailsListPhy != null &&
                      InNetworkDetailsListPhy.length > 0
                    ) {
                      const coInsuranceDataObj =
                        InNetworkDetailsListPhy?.filter((i) => i.code == "A");
                      if (
                        coInsuranceDataObj != null &&
                        coInsuranceDataObj.length > 0
                      ) {
                        if (
                          coInsuranceDataObj[0].coveragePercentage != null &&
                          coInsuranceDataObj[0].coveragePercentage != ""
                        ) {
                          return (
                            coInsuranceDataObj[0].coveragePercentage * 100 + "%"
                          );
                        }
                      }
                    }
                  }
                }

                const companyIdSetails96 = OthercoverageBenefits?.filter(
                  (i) => i.coverageCode == "96"
                );
                if (
                  companyIdSetails96 != null &&
                  companyIdSetails96.length > 0
                ) {
                  const coInsuranceDataObj =
                    companyIdSetails96[0].benefits?.filter(
                      (i) =>
                        (i.inPlanNetworkIndicatorCode == "Y" ||
                          i.inPlanNetworkIndicatorCode == "W") &&
                        i.code == "A"
                    );

                  if (
                    coInsuranceDataObj != null &&
                    coInsuranceDataObj.length > 0
                  ) {
                    if (
                      coInsuranceDataObj[0].coveragePercentage != null &&
                      coInsuranceDataObj[0].coveragePercentage != ""
                    ) {
                      return (
                        coInsuranceDataObj[0].coveragePercentage * 100 + "%"
                      );
                    }
                  }
                }
                const companyIdSetailsBY = OthercoverageBenefits?.filter(
                  (i) => i.coverageCode == "BY"
                );
                if (
                  companyIdSetailsBY != null &&
                  companyIdSetailsBY.length > 0
                ) {
                  const coInsuranceDataObjBy =
                    companyIdSetailsBY[0].benefits?.filter(
                      (i) =>
                        (i.inPlanNetworkIndicatorCode == "Y" ||
                          i.inPlanNetworkIndicatorCode == "W") &&
                        i.code == "A"
                    );

                  if (
                    coInsuranceDataObjBy != null &&
                    coInsuranceDataObjBy.length > 0
                  ) {
                    if (
                      coInsuranceDataObjBy[0].coveragePercentage != null &&
                      coInsuranceDataObjBy[0].coveragePercentage != ""
                    ) {
                      return (
                        coInsuranceDataObjBy[0].coveragePercentage * 100 + "%"
                      );
                    }
                  }
                }

                const companyIdSetailsBZ = OthercoverageBenefits?.filter(
                  (i) => i.coverageCode == "BZ"
                );
                if (
                  companyIdSetailsBZ != null &&
                  companyIdSetailsBZ.length > 0
                ) {
                  const coInsuranceDataObjBz =
                    companyIdSetailsBZ[0].benefits?.filter(
                      (i) =>
                        (i.inPlanNetworkIndicatorCode == "Y" ||
                          i.inPlanNetworkIndicatorCode == "W") &&
                        i.code == "A"
                    );

                  if (
                    coInsuranceDataObjBz != null &&
                    coInsuranceDataObjBz.length > 0
                  ) {
                    if (
                      coInsuranceDataObjBz[0].coveragePercentage != null &&
                      coInsuranceDataObjBz[0].coveragePercentage != ""
                    ) {
                      return (
                        coInsuranceDataObjBz[0].coveragePercentage * 100 + "%"
                      );
                    }
                  }
                }
                return "";
              }

              if (subType == 2) {
                var strObjectVal = "pcp";
                const companyIdSetails98 = OthercoverageBenefits?.filter(
                  (i) => i.coverageCode == coverageCode98
                );

                if (
                  companyIdSetails98 != undefined &&
                  companyIdSetails98.length > 0
                ) {
                  const InNetworkDetailsList98Y =
                    companyIdSetails98[0].benefits?.filter(
                      (i) => i.inPlanNetworkIndicatorCode == "Y"
                    );
                  if (
                    InNetworkDetailsList98Y != undefined &&
                    InNetworkDetailsList98Y.length > 0
                  ) {
                    var teleMedcinearray = InNetworkDetailsList98Y?.filter(
                      (o) =>
                        Object.keys(o).some((k) =>
                          o[k]
                            ?.toLowerCase()
                            .includes(strObjectVal.toLowerCase())
                        )
                    );

                    if (
                      teleMedcinearray != undefined &&
                      teleMedcinearray.length == 0
                    ) {
                      teleMedcinearray = InNetworkDetailsList98Y?.filter((o) =>
                        Object.keys(o).some((k) =>
                          o[k]?.toLowerCase().includes("primary care")
                        )
                      );
                    }

                    if (
                      teleMedcinearray != undefined &&
                      teleMedcinearray.length > 0
                    ) {
                      const coSpecilistArrayObj = teleMedcinearray?.filter(
                        (i) => i.code == "B"
                      );
                      if (
                        coSpecilistArrayObj != undefined &&
                        coSpecilistArrayObj.length > 0
                      ) {
                        return coSpecilistArrayObj[0]?.coverageAmt == null
                          ? ""
                          : "$" + coSpecilistArrayObj[0]?.coverageAmt;
                      }
                    }
                    // else {
                    //   const coSpecilistArrayObj =
                    //     InNetworkDetailsList98Y?.filter((i) => i.code == "B");
                    //   if (
                    //     coSpecilistArrayObj != undefined &&
                    //     coSpecilistArrayObj.length > 0
                    //   ) {
                    //     strObjectVal = "specialist";
                    //     for (var k = 0; k < coSpecilistArrayObj.length; k++) {
                    //       var teleMedcinearray = coSpecilistArrayObj[
                    //         k
                    //       ]?.additionalInfo
                    //         ?.toLowerCase()
                    //         .includes(strObjectVal.toLowerCase());
                    //       if (teleMedcinearray == true) {
                    //       } else {
                    //         return "$" + coSpecilistArrayObj[k]?.coverageAmt;
                    //       }
                    //     }
                    //   }
                    // }
                  }
                }
              }
              if (subType == 3) {
                var strObjectVal = "specialist";
                var teleMedicinaeBenifitArray = InNetworkDetailsList?.filter(
                  (o) =>
                    Object.keys(o).some((k) =>
                      o[k]?.toLowerCase().includes(strObjectVal.toLowerCase())
                    )
                );
                const coDeductableDataObjFam =
                  teleMedicinaeBenifitArray?.filter((i) => i.code == "B");
                if (
                  coDeductableDataObjFam != undefined &&
                  coDeductableDataObjFam.length > 0
                ) {
                  return coDeductableDataObjFam[0]?.coverageAmt == null
                    ? ""
                    : "$" + coDeductableDataObjFam[0]?.coverageAmt;
                } else {
                  const companyIdSetails98 = OthercoverageBenefits?.filter(
                    (i) => i.coverageCode == coverageCode98
                  );
                  var teleMedcinearray = null;
                  const coInsuranceDataObj = null;
                  if (
                    companyIdSetails98 != null &&
                    companyIdSetails98.length > 0
                  ) {
                    coInsuranceDataObj = companyIdSetails98[0].benefits?.filter(
                      (i) =>
                        (i.inPlanNetworkIndicatorCode == "Y" ||
                          i.inPlanNetworkIndicatorCode == "W") &&
                        i.code == "B"
                    );
                  }

                  if (
                    coInsuranceDataObj != null &&
                    coInsuranceDataObj.length > 0
                  ) {
                    teleMedcinearray = coInsuranceDataObj?.filter((o) =>
                      Object.keys(o).some((k) =>
                        o[k]?.toLowerCase().includes(strObjectVal.toLowerCase())
                      )
                    );
                  } else {
                    const companyIdSetails96 = OthercoverageBenefits?.filter(
                      (i) => i.coverageCode == "96"
                    );
                    if (
                      companyIdSetails96 != null &&
                      companyIdSetails96.length > 0
                    ) {
                      const coInsuranceDataObj =
                        companyIdSetails96[0].benefits?.filter(
                          (i) =>
                            (i.inPlanNetworkIndicatorCode == "Y" ||
                              i.inPlanNetworkIndicatorCode == "W") &&
                            i.code == "B"
                        );
                      if (
                        coInsuranceDataObj != null &&
                        coInsuranceDataObj.length > 0
                      ) {
                        teleMedcinearray = coInsuranceDataObj?.filter((o) =>
                          Object.keys(o).some((k) =>
                            o[k]
                              ?.toLowerCase()
                              .includes(strObjectVal.toLowerCase())
                          )
                        );
                      }
                    }
                  }

                  if (
                    teleMedcinearray != undefined &&
                    teleMedcinearray.length > 0
                  ) {
                    const coSpecilistArrayObj = teleMedcinearray?.filter(
                      (i) => i.code == "B"
                    );
                    if (
                      coSpecilistArrayObj != undefined &&
                      coSpecilistArrayObj.length > 0
                    ) {
                      var collocSpecilistArrayObj =
                        coSpecilistArrayObj[0]?.coverageAmt == null
                          ? ""
                          : "$" + coSpecilistArrayObj[0]?.coverageAmt;
                      return "" + collocSpecilistArrayObj;
                    }
                  }
                } // const  DeductibleonLi99st = companyIdSetails[0].benefits?.filter((i) => i.additionalInfo);
              }
              if (subType == 4) {
                var strObjectVal = "telemedicine";
                var teleMedicinaeBenifitArray = InNetworkDetailsList?.filter(
                  (o) =>
                    Object.keys(o).some((k) =>
                      o[k]?.toLowerCase().includes(strObjectVal.toLowerCase())
                    )
                );

                if (
                  teleMedicinaeBenifitArray != undefined &&
                  teleMedicinaeBenifitArray.length == 0
                ) {
                  teleMedicinaeBenifitArray = InNetworkDetailsList?.filter(
                    (o) =>
                      Object.keys(o).some((k) =>
                        o[k]?.toLowerCase().includes("telehealth")
                      )
                  );
                }

                if (
                  teleMedicinaeBenifitArray != undefined &&
                  teleMedicinaeBenifitArray.length > 0
                ) {
                  const coDeductableDataObjFam =
                    teleMedicinaeBenifitArray?.filter((i) => i.code == "B");
                  return coDeductableDataObjFam[0]?.coverageAmt == null
                    ? ""
                    : "$" + coDeductableDataObjFam[0]?.coverageAmt;
                } else {
                  const companyIdSetails98 = OthercoverageBenefits?.filter(
                    (i) => i.coverageCode == coverageCode98
                  );
                  if (
                    companyIdSetails98 != undefined &&
                    companyIdSetails98.length > 0
                  ) {
                    var teleMedcinearray =
                      companyIdSetails98[0].benefits?.filter((o) =>
                        Object.keys(o).some((k) =>
                          o[k]
                            ?.toLowerCase()
                            .includes(strObjectVal.toLowerCase())
                        )
                      );
                    if (
                      teleMedcinearray != undefined &&
                      teleMedcinearray.length == 0
                    ) {
                      teleMedcinearray = companyIdSetails98[0].benefits?.filter(
                        (o) =>
                          Object.keys(o).some((k) =>
                            o[k]?.toLowerCase().includes("telehealth")
                          )
                      );
                    }

                    const coDeductableDataObjFam = teleMedcinearray?.filter(
                      (i) => i.code == "B"
                    );
                    if (
                      coDeductableDataObjFam != undefined &&
                      coDeductableDataObjFam.length > 0
                    ) {
                      return coDeductableDataObjFam[0]?.coverageAmt == null
                        ? ""
                        : "$" + coDeductableDataObjFam[0]?.coverageAmt;
                    }
                  }
                } // const  DeductibleonLi99st = companyIdSetails[0].benefits?.filter((i) => i.additionalInfo);
                return "";
              }
              if (subType == 5) {
                const companyIdMHSetails = OthercoverageBenefits?.filter(
                  (i) => i.coverageCode == "MH"
                );
                const CoInsuranceAmtList =
                  companyIdMHSetails[0]?.benefits?.filter(
                    (i) => i.code == "B" && i.inPlanNetworkIndicatorCode == "Y"
                  );
                if (
                  CoInsuranceAmtList != null &&
                  CoInsuranceAmtList.length > 0
                ) {
                  var strObjectVal = "pcp";
                  var teleMedicinaeBenifitArray = CoInsuranceAmtList?.filter(
                    (o) =>
                      Object.keys(o).some((k) =>
                        o[k]?.toLowerCase().includes(strObjectVal.toLowerCase())
                      )
                  );
                  if (
                    teleMedicinaeBenifitArray != undefined &&
                    teleMedicinaeBenifitArray.length > 0
                  ) {
                    return teleMedicinaeBenifitArray[0]?.coverageAmt == null
                      ? ""
                      : "$" + teleMedicinaeBenifitArray[0]?.coverageAmt;
                  }
                }
              }

              if (subType == 6) {
                const coDeductableDataObj = InNetworkDetailsList?.filter(
                  (i) => i.code == "C"
                );
                const DeductibleAmtINDList = coDeductableDataObj?.filter(
                  (i) =>
                    i.coverageTypeCode == "IND" &&
                    i.timeQualifierCode != 29 &&
                    i.timeQualifierCode == 23
                );
                if (
                  DeductibleAmtINDList != null &&
                  DeductibleAmtINDList.length > 0
                ) {
                  const DeductibleAmtINDTimeList = coDeductableDataObj?.filter(
                    (i) =>
                      i.coverageTypeCode == "IND" && i.timeQualifierCode == 29
                  );
                  if (
                    DeductibleAmtINDTimeList != null &&
                    DeductibleAmtINDTimeList.length > 0
                  )
                    return DeductibleAmtINDList[0].coverageAmt == null
                      ? ""
                      : "$" +
                          DeductibleAmtINDList[0].coverageAmt +
                          " ($" +
                          DeductibleAmtINDTimeList[0].coverageAmt +
                          " Remaining)";
                  else
                    return DeductibleAmtINDList[0].coverageAmt == null
                      ? ""
                      : "$" + DeductibleAmtINDList[0].coverageAmt;
                } else {
                  if (
                    companyIdSetailsPhy != null &&
                    companyIdSetailsPhy.length > 0
                  ) {
                    const InNetworkDetailsListPhy =
                      companyIdSetailsPhy[0].benefits?.filter(
                        (i) =>
                          i.inPlanNetworkIndicatorCode == "Y" ||
                          i.inPlanNetworkIndicatorCode == "W"
                      );
                    if (
                      InNetworkDetailsListPhy != null &&
                      InNetworkDetailsListPhy.length > 0
                    ) {
                      const coDeductableDataObj =
                        InNetworkDetailsListPhy?.filter((i) => i.code == "C");
                      const DeductibleAmtINDList = coDeductableDataObj?.filter(
                        (i) =>
                          i.coverageTypeCode == "IND" &&
                          i.timeQualifierCode != 29
                      );
                      if (
                        DeductibleAmtINDList != null &&
                        DeductibleAmtINDList.length > 0
                      ) {
                        const DeductibleAmtINDTimeList =
                          coDeductableDataObj?.filter(
                            (i) =>
                              i.coverageTypeCode == "IND" &&
                              i.timeQualifierCode == 29
                          );
                        if (
                          DeductibleAmtINDTimeList != null &&
                          DeductibleAmtINDTimeList.length > 0
                        )
                          return DeductibleAmtINDList[0].coverageAmt == null
                            ? ""
                            : "$" +
                                DeductibleAmtINDList[0].coverageAmt +
                                " ($" +
                                DeductibleAmtINDTimeList[0].coverageAmt +
                                " Remaining)";
                        else
                          return DeductibleAmtINDList[0].coverageAmt == null
                            ? ""
                            : "$" + DeductibleAmtINDList[0].coverageAmt;
                      }
                    }
                  }
                }
                return "";
              }
              if (subType == 7) {
                const coDeductableDataObjFam = InNetworkDetailsList?.filter(
                  (i) => i.code == "C"
                );
                const DeductibleAmtFAMList = coDeductableDataObjFam?.filter(
                  (i) =>
                    i.coverageTypeCode == "FAM" &&
                    i.timeQualifierCode != 29 &&
                    i.timeQualifierCode == 23 &&
                    i.coverageAmt != null &&
                    i.coverageAmt != ""
                );
                if (
                  DeductibleAmtFAMList != null &&
                  DeductibleAmtFAMList.length > 0
                ) {
                  const DeductibleAmtFAMimeList =
                    coDeductableDataObjFam?.filter(
                      (i) =>
                        i.coverageTypeCode == "FAM" &&
                        i.timeQualifierCode == 29 &&
                        i.coverageAmt != null &&
                        i.coverageAmt != ""
                    );
                  if (
                    DeductibleAmtFAMimeList != null &&
                    DeductibleAmtFAMimeList.length > 0
                  )
                    return DeductibleAmtFAMList[0].coverageAmt == null
                      ? ""
                      : "$" +
                          DeductibleAmtFAMList[0].coverageAmt +
                          " ($" +
                          DeductibleAmtFAMimeList[0].coverageAmt +
                          " Remaining)";
                  else
                    return DeductibleAmtFAMList[0].coverageAmt == null
                      ? ""
                      : "$" + DeductibleAmtFAMList[0].coverageAmt;
                } else {
                  if (
                    companyIdSetailsPhy != null &&
                    companyIdSetailsPhy.length > 0
                  ) {
                    const InNetworkDetailsListPhy =
                      companyIdSetailsPhy[0].benefits?.filter(
                        (i) => i.inPlanNetworkIndicatorCode == "Y"
                      );
                    if (
                      InNetworkDetailsListPhy != null &&
                      InNetworkDetailsListPhy.length > 0
                    ) {
                      const coDeductableDataObj =
                        InNetworkDetailsListPhy?.filter((i) => i.code == "C");
                      const DeductibleAmtINDList = coDeductableDataObj?.filter(
                        (i) =>
                          i.coverageTypeCode == "FAM" &&
                          i.timeQualifierCode != 29
                      );
                      if (
                        DeductibleAmtINDList != null &&
                        DeductibleAmtINDList.length > 0
                      ) {
                        const DeductibleAmtINDTimeList =
                          coDeductableDataObj?.filter(
                            (i) =>
                              i.coverageTypeCode == "FAM" &&
                              i.timeQualifierCode == 29
                          );
                        if (
                          DeductibleAmtINDTimeList != null &&
                          DeductibleAmtINDTimeList.length > 0
                        )
                          return DeductibleAmtINDList[0].coverageAmt == null
                            ? ""
                            : "$" +
                                DeductibleAmtINDList[0].coverageAmt +
                                " ($" +
                                DeductibleAmtINDTimeList[0].coverageAmt +
                                " Remaining)";
                        else
                          return DeductibleAmtINDList[0].coverageAmt == null
                            ? ""
                            : "$" + DeductibleAmtINDList[0].coverageAmt;
                      }
                    }
                  }
                }
                return "";
              }
              if (subType == 8) {
                if (
                  InNetworkDetailsList != null &&
                  InNetworkDetailsList.length > 0
                ) {
                  const coPocketDataObjFam = InNetworkDetailsList?.filter(
                    (i) => i.code == "G"
                  );
                  const DPocketAmtFAMList = coPocketDataObjFam?.filter(
                    (i) =>
                      i.coverageTypeCode == "IND" &&
                      i.timeQualifierCode != 29 &&
                      i.timeQualifierCode == 23 &&
                      i.coverageAmt != null
                  );
                  if (
                    DPocketAmtFAMList != null &&
                    DPocketAmtFAMList.length > 0
                  ) {
                    const pocketRemainAmtFAMimeList =
                      coPocketDataObjFam?.filter(
                        (i) =>
                          i.coverageTypeCode == "IND" &&
                          i.timeQualifierCode == 29 &&
                          i.coverageAmt != null
                      );
                    if (
                      pocketRemainAmtFAMimeList != null &&
                      pocketRemainAmtFAMimeList.length > 0
                    )
                      return DPocketAmtFAMList[0].coverageAmt == null
                        ? ""
                        : "$" +
                            DPocketAmtFAMList[0].coverageAmt +
                            " ($" +
                            pocketRemainAmtFAMimeList[0].coverageAmt +
                            " Remaining)";
                    else
                      return DPocketAmtFAMList[0].coverageAmt == null
                        ? ""
                        : "$" + DPocketAmtFAMList[0].coverageAmt;
                  } else {
                    return "";
                  }
                } else {
                  const companyIdSetails = OthercoverageBenefits?.filter(
                    (i) => i.coverageCode == "98"
                  );

                  const coPocketDataObjFam =
                    companyIdSetails[0]?.benefits?.filter(
                      (i) =>
                        i.code == "G" && i.inPlanNetworkIndicatorCode == "Y"
                    );
                  const DPocketAmtFAMList = coPocketDataObjFam?.filter(
                    (i) =>
                      i.coverageTypeCode == "IND" &&
                      i.timeQualifierCode != 29 &&
                      i.timeQualifierCode == 23 &&
                      i.coverageAmt != null
                  );
                  if (
                    DPocketAmtFAMList != null &&
                    DPocketAmtFAMList.length > 0
                  ) {
                    const pocketRemainAmtFAMimeList =
                      coPocketDataObjFam?.filter(
                        (i) =>
                          i.coverageTypeCode == "IND" &&
                          i.timeQualifierCode == 29 &&
                          i.coverageAmt != null
                      );
                    if (
                      pocketRemainAmtFAMimeList != null &&
                      pocketRemainAmtFAMimeList.length > 0
                    )
                      return DPocketAmtFAMList[0].coverageAmt == null
                        ? ""
                        : "$" +
                            DPocketAmtFAMList[0].coverageAmt +
                            " ($" +
                            pocketRemainAmtFAMimeList[0].coverageAmt +
                            " Remaining)";
                    else
                      return DPocketAmtFAMList[0].coverageAmt == null
                        ? ""
                        : "$" + DPocketAmtFAMList[0].coverageAmt;
                  } else {
                    return "";
                  }
                }
              }
              if (subType == 9) {
                if (
                  InNetworkDetailsList != null &&
                  InNetworkDetailsList.length > 0
                ) {
                  const coPocketDataObjFam = InNetworkDetailsList?.filter(
                    (i) => i.code == "G"
                  );
                  const DPocketAmtFAMList = coPocketDataObjFam?.filter(
                    (i) =>
                      i.coverageTypeCode == "FAM" &&
                      i.timeQualifierCode != 29 &&
                      i.timeQualifierCode == 23 &&
                      i.coverageAmt != null
                  );
                  if (
                    DPocketAmtFAMList != null &&
                    DPocketAmtFAMList.length > 0
                  ) {
                    const pocketRemainAmtFAMimeList =
                      coPocketDataObjFam?.filter(
                        (i) =>
                          i.coverageTypeCode == "FAM" &&
                          i.timeQualifierCode == 29 &&
                          i.coverageAmt != null
                      );
                    if (
                      pocketRemainAmtFAMimeList != null &&
                      pocketRemainAmtFAMimeList.length > 0
                    )
                      return DPocketAmtFAMList[0].coverageAmt == null
                        ? ""
                        : "$" +
                            DPocketAmtFAMList[0].coverageAmt +
                            " ($" +
                            pocketRemainAmtFAMimeList[0].coverageAmt +
                            " Remaining)";
                    else
                      return DPocketAmtFAMList[0].coverageAmt == null
                        ? ""
                        : "$" + DPocketAmtFAMList[0].coverageAmt;
                  } else {
                    return "";
                  }
                } else {
                  const companyIdSetails = OthercoverageBenefits?.filter(
                    (i) => i.coverageCode == "98"
                  );

                  const coPocketDataObjFam =
                    companyIdSetails[0]?.benefits?.filter(
                      (i) =>
                        i.code == "G" && i.inPlanNetworkIndicatorCode == "Y"
                    );
                  const DPocketAmtFAMList = coPocketDataObjFam?.filter(
                    (i) =>
                      i.coverageTypeCode == "FAM" &&
                      i.timeQualifierCode != 29 &&
                      i.timeQualifierCode == 23 &&
                      i.coverageAmt != null
                  );
                  if (
                    DPocketAmtFAMList != null &&
                    DPocketAmtFAMList.length > 0
                  ) {
                    const pocketRemainAmtFAMimeList =
                      coPocketDataObjFam?.filter(
                        (i) =>
                          i.coverageTypeCode == "FAM" &&
                          i.timeQualifierCode == 29 &&
                          i.coverageAmt != null
                      );
                    if (
                      pocketRemainAmtFAMimeList != null &&
                      pocketRemainAmtFAMimeList.length > 0
                    )
                      return DPocketAmtFAMList[0].coverageAmt == null
                        ? ""
                        : "$" +
                            DPocketAmtFAMList[0].coverageAmt +
                            " ($" +
                            pocketRemainAmtFAMimeList[0].coverageAmt +
                            " Remaining)";
                    else
                      return DPocketAmtFAMList[0].coverageAmt == null
                        ? ""
                        : "$" + DPocketAmtFAMList[0].coverageAmt;
                  } else {
                    return "";
                  }
                }
              }
            }

            const DeductibleAmtList = companyIdSetails[0].benefits?.filter(
              (i) => i.code == "C" && i.inPlanNetworkIndicatorCode == "Y"
            );
            if (DeductibleAmtList != null && DeductibleAmtList.length > 0) {
            }
          }

          if (val == 4) {
            const InNetworkDetailsListN = companyIdSetails[0].benefits?.filter(
              (i) =>
                i.inPlanNetworkIndicatorCode == "N" ||
                i.inPlanNetworkIndicatorCode == "W"
            );
            const companyIdSetailsPhy = OthercoverageBenefits?.filter(
              (i) => i.coverageCode == coverageCode98
            );
            // companyIdSetailsPhy = OthercoverageBenefits?.filter((i) => i.coverageCode == "MH");
            if (
              (InNetworkDetailsListN != null &&
                InNetworkDetailsListN.length > 0) ||
              (companyIdSetailsPhy != null && companyIdSetailsPhy.length > 0)
            ) {
              if (subType == 1) {
                const coInsuranceDataObj = InNetworkDetailsListN?.filter(
                  (i) => i.code == "A"
                );
                if (
                  coInsuranceDataObj != null &&
                  coInsuranceDataObj.length > 0
                ) {
                  if (
                    coInsuranceDataObj[0].coveragePercentage != null &&
                    coInsuranceDataObj[0].coveragePercentage != ""
                  ) {
                    return coInsuranceDataObj[0].coveragePercentage * 100 + "%";
                  }
                } else {
                  if (
                    companyIdSetailsPhy != null &&
                    companyIdSetailsPhy.length > 0
                  ) {
                    const InNetworkDetailsListPhy =
                      companyIdSetailsPhy[0].benefits?.filter(
                        (i) => i.inPlanNetworkIndicatorCode == "N"
                      );
                    if (
                      InNetworkDetailsListPhy != null &&
                      InNetworkDetailsListPhy.length > 0
                    ) {
                      const coInsuranceDataObj =
                        InNetworkDetailsListPhy?.filter((i) => i.code == "A");
                      if (
                        coInsuranceDataObj != null &&
                        coInsuranceDataObj.length > 0
                      ) {
                        if (
                          coInsuranceDataObj[0].coveragePercentage != null &&
                          coInsuranceDataObj[0].coveragePercentage != ""
                        ) {
                          return (
                            coInsuranceDataObj[0].coveragePercentage * 100 + "%"
                          );
                        }
                      }
                    }
                  }
                }

                const companyIdSetails96 = OthercoverageBenefits?.filter(
                  (i) => i.coverageCode == "96"
                );
                if (
                  companyIdSetails96 != null &&
                  companyIdSetails96.length > 0
                ) {
                  const coInsuranceDataObj =
                    companyIdSetails96[0].benefits?.filter(
                      (i) =>
                        (i.inPlanNetworkIndicatorCode == "N" ||
                          i.inPlanNetworkIndicatorCode == "W") &&
                        i.code == "A"
                    );

                  if (
                    coInsuranceDataObj != null &&
                    coInsuranceDataObj.length > 0
                  ) {
                    if (
                      coInsuranceDataObj[0].coveragePercentage != null &&
                      coInsuranceDataObj[0].coveragePercentage != ""
                    ) {
                      return (
                        coInsuranceDataObj[0].coveragePercentage * 100 + "%"
                      );
                    }
                  }
                }

                const companyIdSetailsBY = OthercoverageBenefits?.filter(
                  (i) => i.coverageCode == "BY"
                );
                if (
                  companyIdSetailsBY != null &&
                  companyIdSetailsBY.length > 0
                ) {
                  const coInsuranceDataObjBy =
                    companyIdSetailsBY[0].benefits?.filter(
                      (i) =>
                        (i.inPlanNetworkIndicatorCode == "N" ||
                          i.inPlanNetworkIndicatorCode == "W") &&
                        i.code == "A"
                    );

                  if (
                    coInsuranceDataObjBy != null &&
                    coInsuranceDataObjBy.length > 0
                  ) {
                    if (
                      coInsuranceDataObjBy[0].coveragePercentage != null &&
                      coInsuranceDataObjBy[0].coveragePercentage != ""
                    ) {
                      return (
                        coInsuranceDataObjBy[0].coveragePercentage * 100 + "%"
                      );
                    }
                  }
                }

                const companyIdSetailsBZ = OthercoverageBenefits?.filter(
                  (i) => i.coverageCode == "BZ"
                );
                if (
                  companyIdSetailsBZ != null &&
                  companyIdSetailsBZ.length > 0
                ) {
                  const coInsuranceDataObjBz =
                    companyIdSetailsBZ[0].benefits?.filter(
                      (i) =>
                        (i.inPlanNetworkIndicatorCode == "N" ||
                          i.inPlanNetworkIndicatorCode == "W") &&
                        i.code == "A"
                    );

                  if (
                    coInsuranceDataObjBz != null &&
                    coInsuranceDataObjBz.length > 0
                  ) {
                    if (
                      coInsuranceDataObjBz[0].coveragePercentage != null &&
                      coInsuranceDataObjBz[0].coveragePercentage != ""
                    ) {
                      return (
                        coInsuranceDataObjBz[0].coveragePercentage * 100 + "%"
                      );
                    }
                  }
                }

                return "";
              }

              if (subType == 2) {
                var strObjectVal = "pcp";
                const companyIdSetails98 = OthercoverageBenefits?.filter(
                  (i) => i.coverageCode == coverageCode98
                );
                if (
                  companyIdSetails98 != undefined &&
                  companyIdSetails98.length > 0
                ) {
                  const InNetworkDetailsList98N =
                    companyIdSetails98[0].benefits?.filter(
                      (i) =>
                        i.inPlanNetworkIndicatorCode == "N" ||
                        i.inPlanNetworkIndicatorCode == "W"
                    );
                  if (
                    InNetworkDetailsList98N != undefined &&
                    InNetworkDetailsList98N.length > 0
                  ) {
                    var teleMedcinearray = InNetworkDetailsList98N?.filter(
                      (o) =>
                        Object.keys(o).some((k) =>
                          o[k]
                            ?.toLowerCase()
                            .includes(strObjectVal.toLowerCase())
                        )
                    );
                    if (
                      teleMedcinearray != undefined &&
                      teleMedcinearray.length == 0
                    ) {
                      teleMedcinearray = InNetworkDetailsList98N?.filter((o) =>
                        Object.keys(o).some((k) =>
                          o[k]?.toLowerCase().includes("primary care")
                        )
                      );
                    }

                    if (
                      teleMedcinearray != undefined &&
                      teleMedcinearray.length > 0
                    ) {
                      const coSpecilistArrayObj = teleMedcinearray?.filter(
                        (i) => i.code == "B"
                      );
                      if (
                        coSpecilistArrayObj != undefined &&
                        coSpecilistArrayObj.length > 0
                      ) {
                        return coSpecilistArrayObj[0]?.coverageAmt == null
                          ? ""
                          : "$" + coSpecilistArrayObj[0]?.coverageAmt;
                      }
                    }
                  }
                }
              }
              if (subType == 3) {
                var strObjectVal = "specialist";
                var teleMedicinaeBenifitArray = InNetworkDetailsListN?.filter(
                  (o) =>
                    Object.keys(o).some((k) =>
                      o[k]?.toLowerCase().includes(strObjectVal.toLowerCase())
                    )
                );

                const coDeductableDataObjFam =
                  teleMedicinaeBenifitArray?.filter((i) => i.code == "B");
                if (
                  coDeductableDataObjFam != undefined &&
                  coDeductableDataObjFam.length > 0
                ) {
                  return coDeductableDataObjFam[0]?.coverageAmt == null
                    ? ""
                    : "$" + coDeductableDataObjFam[0]?.coverageAmt;
                } else {
                  const companyIdSetails98 = OthercoverageBenefits?.filter(
                    (i) => i.coverageCode == coverageCode98
                  );
                  var teleMedcinearray = null;
                  const coInsuranceDataObj = null;
                  if (
                    companyIdSetails98 != null &&
                    companyIdSetails98.length > 0
                  ) {
                    coInsuranceDataObj = companyIdSetails98[0].benefits?.filter(
                      (i) =>
                        i.inPlanNetworkIndicatorCode == "N" && i.code == "B"
                    );
                  }

                  if (
                    coInsuranceDataObj != null &&
                    coInsuranceDataObj.length > 0
                  ) {
                    teleMedcinearray = coInsuranceDataObj?.filter((o) =>
                      Object.keys(o).some((k) =>
                        o[k]?.toLowerCase().includes(strObjectVal.toLowerCase())
                      )
                    );
                  } else {
                    const companyIdSetails96 = OthercoverageBenefits?.filter(
                      (i) => i.coverageCode == "96"
                    );
                    if (
                      companyIdSetails96 != null &&
                      companyIdSetails96.length > 0
                    ) {
                      const coInsuranceDataObj =
                        companyIdSetails96[0].benefits?.filter(
                          (i) =>
                            i.inPlanNetworkIndicatorCode == "N" && i.code == "B"
                        );
                      if (
                        coInsuranceDataObj != null &&
                        coInsuranceDataObj.length > 0
                      ) {
                        teleMedcinearray = coInsuranceDataObj?.filter((o) =>
                          Object.keys(o).some((k) =>
                            o[k]
                              ?.toLowerCase()
                              .includes(strObjectVal.toLowerCase())
                          )
                        );
                      }
                    }
                  }

                  if (
                    teleMedcinearray != undefined &&
                    teleMedcinearray.length > 0
                  ) {
                    const coSpecilistArrayObj = teleMedcinearray?.filter(
                      (i) => i.code == "B"
                    );
                    if (
                      coSpecilistArrayObj != undefined &&
                      coSpecilistArrayObj.length > 0
                    ) {
                      var collocSpecilistArrayObj =
                        coSpecilistArrayObj[0]?.coverageAmt == null
                          ? ""
                          : "$" + coSpecilistArrayObj[0]?.coverageAmt;
                      return "" + collocSpecilistArrayObj;
                    }
                  }
                }
              }
              if (subType == 4) {
                var strObjectVal = "telemedicine";
                var teleMedicinaeBenifitArray = InNetworkDetailsListN?.filter(
                  (o) =>
                    Object.keys(o).some((k) =>
                      o[k]?.toLowerCase().includes(strObjectVal.toLowerCase())
                    )
                );

                if (
                  teleMedicinaeBenifitArray != undefined &&
                  teleMedicinaeBenifitArray.length == 0
                ) {
                  teleMedicinaeBenifitArray = InNetworkDetailsListN?.filter(
                    (o) =>
                      Object.keys(o).some((k) =>
                        o[k]?.toLowerCase().includes("telehealth")
                      )
                  );
                }

                if (
                  teleMedicinaeBenifitArray != undefined &&
                  teleMedicinaeBenifitArray.length > 0
                ) {
                  const coDeductableDataObjFam =
                    teleMedicinaeBenifitArray?.filter((i) => i.code == "B");
                  return coDeductableDataObjFam[0]?.coverageAmt == null
                    ? ""
                    : "$" + coDeductableDataObjFam[0]?.coverageAmt;
                } else {
                  const companyIdSetails98 = OthercoverageBenefits?.filter(
                    (i) => i.coverageCode == coverageCode98
                  );
                  if (
                    companyIdSetails98 != undefined &&
                    companyIdSetails98.length > 0
                  ) {
                    const InNetworkDetailsList98N =
                      companyIdSetails98[0].benefits?.filter(
                        (i) =>
                          i.inPlanNetworkIndicatorCode == "N" ||
                          i.inPlanNetworkIndicatorCode == "W"
                      );

                    var teleMedcinearray = InNetworkDetailsList98N?.filter(
                      (o) =>
                        Object.keys(o).some((k) =>
                          o[k]
                            ?.toLowerCase()
                            .includes(strObjectVal.toLowerCase())
                        )
                    );

                    if (
                      teleMedcinearray != undefined &&
                      teleMedcinearray.length == 0
                    ) {
                      teleMedcinearray = InNetworkDetailsList98N?.filter((o) =>
                        Object.keys(o).some((k) =>
                          o[k]?.toLowerCase().includes("telehealth")
                        )
                      );
                    }
                    const coDeductableDataObjFam = teleMedcinearray?.filter(
                      (i) => i.code == "B"
                    );
                    if (
                      coDeductableDataObjFam != undefined &&
                      coDeductableDataObjFam.length > 0
                    ) {
                      return coDeductableDataObjFam[0]?.coverageAmt == null
                        ? ""
                        : "$" + coDeductableDataObjFam[0]?.coverageAmt;
                    }
                  }
                } // const  DeductibleonLi99st = companyIdSetails[0].benefits?.filter((i) => i.additionalInfo);
                return "";
              }
              if (subType == 5) {
                const companyIdMHSetails = OthercoverageBenefits?.filter(
                  (i) => i.coverageCode == "MH"
                );
                const CoInsuranceAmtList =
                  companyIdMHSetails[0]?.benefits?.filter(
                    (i) => i.code == "B" && i.inPlanNetworkIndicatorCode == "N"
                  );
                if (
                  CoInsuranceAmtList != null &&
                  CoInsuranceAmtList.length > 0
                ) {
                  var strObjectVal = "pcp";
                  var teleMedicinaeBenifitArray = CoInsuranceAmtList?.filter(
                    (o) =>
                      Object.keys(o).some((k) =>
                        o[k]?.toLowerCase().includes(strObjectVal.toLowerCase())
                      )
                  );
                  if (
                    teleMedicinaeBenifitArray != undefined &&
                    teleMedicinaeBenifitArray.length > 0
                  ) {
                    return teleMedicinaeBenifitArray[0]?.coverageAmt == null
                      ? ""
                      : "$" + teleMedicinaeBenifitArray[0]?.coverageAmt;
                  }
                }
              }

              if (subType == 6) {
                const coDeductableDataObj = InNetworkDetailsListN?.filter(
                  (i) => i.code == "C"
                );
                const DeductibleAmtINDList = coDeductableDataObj?.filter(
                  (i) =>
                    i.coverageTypeCode == "IND" &&
                    i.timeQualifierCode != 29 &&
                    i.timeQualifierCode == 23 &&
                    i.coverageAmt != null &&
                    i.coverageAmt != ""
                );
                if (
                  DeductibleAmtINDList != null &&
                  DeductibleAmtINDList.length > 0
                ) {
                  const DeductibleAmtINDTimeList = coDeductableDataObj?.filter(
                    (i) =>
                      i.coverageTypeCode == "IND" &&
                      i.timeQualifierCode == 29 &&
                      i.coverageAmt != null &&
                      i.coverageAmt != ""
                  );
                  if (
                    DeductibleAmtINDTimeList != null &&
                    DeductibleAmtINDTimeList.length > 0
                  )
                    return DeductibleAmtINDList[0].coverageAmt == null
                      ? ""
                      : "$" +
                          DeductibleAmtINDList[0].coverageAmt +
                          " ($" +
                          DeductibleAmtINDTimeList[0].coverageAmt +
                          " Remaining)";
                  else
                    return DeductibleAmtINDList[0].coverageAmt == null
                      ? ""
                      : "$" + DeductibleAmtINDList[0].coverageAmt;
                }
                return "";
              }
              if (subType == 7) {
                const coDeductableDataObjFam = InNetworkDetailsListN?.filter(
                  (i) => i.code == "C"
                );
                const DeductibleAmtFAMList = coDeductableDataObjFam?.filter(
                  (i) =>
                    i.coverageTypeCode == "FAM" &&
                    i.timeQualifierCode != 29 &&
                    i.timeQualifierCode == 23 &&
                    i.coverageAmt != null &&
                    i.coverageAmt != ""
                );
                if (
                  DeductibleAmtFAMList != null &&
                  DeductibleAmtFAMList.length > 0
                ) {
                  const DeductibleAmtFAMimeList =
                    coDeductableDataObjFam?.filter(
                      (i) =>
                        i.coverageTypeCode == "FAM" &&
                        i.timeQualifierCode == 29 &&
                        i.coverageAmt != null &&
                        i.coverageAmt != ""
                    );
                  if (
                    DeductibleAmtFAMimeList != null &&
                    DeductibleAmtFAMimeList.length > 0
                  )
                    return DeductibleAmtFAMList[0].coverageAmt == null
                      ? ""
                      : "$" +
                          DeductibleAmtFAMList[0].coverageAmt +
                          " ($" +
                          DeductibleAmtFAMimeList[0].coverageAmt +
                          " Remaining)";
                  else
                    return DeductibleAmtFAMList[0].coverageAmt == null
                      ? ""
                      : "$" + DeductibleAmtFAMList[0].coverageAmt;
                }
                return "";
              }
              if (subType == 8) {
                if (
                  InNetworkDetailsListN != null &&
                  InNetworkDetailsListN.length > 0
                ) {
                  const coPocketDataObjFam = InNetworkDetailsListN?.filter(
                    (i) => i.code == "G"
                  );
                  const DPocketAmtFAMList = coPocketDataObjFam?.filter(
                    (i) =>
                      i.coverageTypeCode == "IND" &&
                      i.timeQualifierCode != 29 &&
                      i.timeQualifierCode == 23 &&
                      i.coverageAmt != null &&
                      i.coverageAmt != ""
                  );
                  if (
                    DPocketAmtFAMList != null &&
                    DPocketAmtFAMList.length > 0
                  ) {
                    const pocketRemainAmtFAMimeList =
                      coPocketDataObjFam?.filter(
                        (i) =>
                          i.coverageTypeCode == "IND" &&
                          i.timeQualifierCode == 29 &&
                          i.coverageAmt != null &&
                          i.coverageAmt != ""
                      );
                    if (
                      pocketRemainAmtFAMimeList != null &&
                      pocketRemainAmtFAMimeList.length > 0
                    )
                      return DPocketAmtFAMList[0].coverageAmt == null
                        ? ""
                        : "$" +
                            DPocketAmtFAMList[0].coverageAmt +
                            " ($" +
                            pocketRemainAmtFAMimeList[0].coverageAmt +
                            " Remaining)";
                    else
                      return DPocketAmtFAMList[0].coverageAmt == null
                        ? ""
                        : "$" + DPocketAmtFAMList[0].coverageAmt;
                  } else {
                    return "";
                  }
                } else {
                  const companyIdSetails = OthercoverageBenefits?.filter(
                    (i) => i.coverageCode == "98"
                  );

                  const coPocketDataObjFam =
                    companyIdSetails[0]?.benefits?.filter(
                      (i) =>
                        i.code == "G" && i.inPlanNetworkIndicatorCode == "N"
                    );

                  const DPocketAmtFAMList = coPocketDataObjFam?.filter(
                    (i) =>
                      i.coverageTypeCode == "IND" &&
                      i.timeQualifierCode != 29 &&
                      i.timeQualifierCode == 23 &&
                      i.coverageAmt != null &&
                      i.coverageAmt != ""
                  );
                  if (
                    DPocketAmtFAMList != null &&
                    DPocketAmtFAMList.length > 0
                  ) {
                    const pocketRemainAmtFAMimeList =
                      coPocketDataObjFam?.filter(
                        (i) =>
                          i.coverageTypeCode == "IND" &&
                          i.timeQualifierCode == 29 &&
                          i.coverageAmt != null &&
                          i.coverageAmt != ""
                      );
                    if (
                      pocketRemainAmtFAMimeList != null &&
                      pocketRemainAmtFAMimeList.length > 0
                    )
                      return DPocketAmtFAMList[0].coverageAmt == null
                        ? ""
                        : "$" +
                            DPocketAmtFAMList[0].coverageAmt +
                            " ($" +
                            pocketRemainAmtFAMimeList[0].coverageAmt +
                            " Remaining)";
                    else
                      return DPocketAmtFAMList[0].coverageAmt == null
                        ? ""
                        : "$" + DPocketAmtFAMList[0].coverageAmt;
                  } else {
                    return "";
                  }
                }
              }
              if (subType == 9) {
                if (
                  InNetworkDetailsListN != null &&
                  InNetworkDetailsListN.length > 0
                ) {
                  const coPocketDataObjFam = InNetworkDetailsListN?.filter(
                    (i) => i.code == "G"
                  );
                  const DPocketAmtFAMList = coPocketDataObjFam?.filter(
                    (i) =>
                      i.coverageTypeCode == "FAM" &&
                      i.timeQualifierCode != 29 &&
                      i.timeQualifierCode == 23 &&
                      i.coverageAmt != null &&
                      i.coverageAmt != ""
                  );
                  if (
                    DPocketAmtFAMList != null &&
                    DPocketAmtFAMList.length > 0
                  ) {
                    const pocketRemainAmtFAMimeList =
                      coPocketDataObjFam?.filter(
                        (i) =>
                          i.coverageTypeCode == "FAM" &&
                          i.timeQualifierCode == 29 &&
                          i.coverageAmt != null &&
                          i.coverageAmt != ""
                      );
                    if (
                      pocketRemainAmtFAMimeList != null &&
                      pocketRemainAmtFAMimeList.length > 0
                    )
                      return DPocketAmtFAMList[0].coverageAmt == null
                        ? ""
                        : "$" +
                            DPocketAmtFAMList[0].coverageAmt +
                            " ($" +
                            pocketRemainAmtFAMimeList[0].coverageAmt +
                            " Remaining)";
                    else
                      return DPocketAmtFAMList[0].coverageAmt == null
                        ? ""
                        : "$" + DPocketAmtFAMList[0].coverageAmt;
                  } else {
                    return "";
                  }
                } else {
                  const companyIdSetails = OthercoverageBenefits?.filter(
                    (i) => i.coverageCode == "98"
                  );

                  const coPocketDataObjFam =
                    companyIdSetails[0]?.benefits?.filter(
                      (i) =>
                        i.code == "G" && i.inPlanNetworkIndicatorCode == "N"
                    );
                  const DPocketAmtFAMList = coPocketDataObjFam?.filter(
                    (i) =>
                      i.coverageTypeCode == "FAM" &&
                      i.timeQualifierCode != 29 &&
                      i.timeQualifierCode == 23 &&
                      i.coverageAmt != null &&
                      i.coverageAmt != ""
                  );
                  if (
                    DPocketAmtFAMList != null &&
                    DPocketAmtFAMList.length > 0
                  ) {
                    const pocketRemainAmtFAMimeList =
                      coPocketDataObjFam?.filter(
                        (i) =>
                          i.coverageTypeCode == "FAM" &&
                          i.timeQualifierCode == 29 &&
                          i.coverageAmt != null &&
                          i.coverageAmt != ""
                      );
                    if (
                      pocketRemainAmtFAMimeList != null &&
                      pocketRemainAmtFAMimeList.length > 0
                    )
                      return DPocketAmtFAMList[0].coverageAmt == null
                        ? ""
                        : "$" +
                            DPocketAmtFAMList[0].coverageAmt +
                            " ($" +
                            pocketRemainAmtFAMimeList[0].coverageAmt +
                            " Remaining)";
                    else
                      return DPocketAmtFAMList[0].coverageAmt == null
                        ? ""
                        : "$" + DPocketAmtFAMList[0].coverageAmt;
                  } else {
                    return "";
                  }
                }
              }
            }

            const DeductibleAmtList = companyIdSetails[0].benefits?.filter(
              (i) => i.code == "C" && i.inPlanNetworkIndicatorCode == "Y"
            );
            if (DeductibleAmtList != null && DeductibleAmtList.length > 0) {
            }
          }

          if (val == 5) {
            const inPlanNetworkIndicatorCodeListY =
              companyIdSetails[0].benefits?.filter(
                (i) => i.inPlanNetworkIndicatorCode == "Y"
              );
            const inPlanNetworkIndicatorCodeListN =
              companyIdSetails[0].benefits?.filter(
                (i) => i.inPlanNetworkIndicatorCode == "N"
              );
            if (
              inPlanNetworkIndicatorCodeListY != null &&
              inPlanNetworkIndicatorCodeListN != null &&
              inPlanNetworkIndicatorCodeListY.length > 0 &&
              inPlanNetworkIndicatorCodeListN.length > 0
            ) {
              return "In-Network / Out-Of-Network";
            } else if (
              inPlanNetworkIndicatorCodeListY != null &&
              inPlanNetworkIndicatorCodeListY.length > 0
            ) {
              return "In-Network";
            } else if (
              inPlanNetworkIndicatorCodeListN != null &&
              inPlanNetworkIndicatorCodeListN.length > 0
            ) {
              return "Out-Of-Network";
            }
            return "N/A";
          }
        }
      }
    } catch (ex) {}
  }

  function downloadDoc(dID) {
    setLoading(true);
    getDocumentData(dID, 1);
  }

  function getFileExtension(filename) {
    const trimmedFilename = filename.replace(/\s+/g, "");
    const lastDotIndex = trimmedFilename.lastIndexOf(".");
    if (lastDotIndex === -1) {
      return "";
    }
    return trimmedFilename.substring(lastDotIndex + 1);
  }

  async function getDocumentData(dID, isFrom) {
    //1-download 0 for popup
    // setLoading(true);
    const payload = {
      Id: 0,
      configId: "0",
      recordId: dID,
      commonParams: {
        organizationID: details?.orgId,
        companyID: details?.companyId,
        coCode: details?.companyCode,
        groupID: details?.groupID,
        timeZone: details?.timeZone,
        sessionId: details?.sesionId,
        loginUserId: `${details?.userId}`,
        loginUserName: details?.userName,
        AuditTrail: 0,
        editTrail: 0,
        dataSource: "",
      },
    };
    try {
      const res = await mutateAsync1(payload);
      if (res.status.code === 200) {
        const resultObj = res?.data;

        const fileExtension = getFileExtension(resultObj?.fileId).toLowerCase();

        if (fileExtension === "pdf") {
          const blobResponse = await fetch(resultObj?.signedUrl);
          const arrayBuffer = await blobResponse.arrayBuffer();
          const blob = new Blob([arrayBuffer], { type: "application/pdf" });
          let res = window.URL.createObjectURL(blob);
          if (isFrom == 1) {
            const link = document.createElement("a");
            link.href = res;
            link.download = `${resultObj?.fileId}.pdf`; // Name of the file for download
            link.click(); // Trigger the download
          } else {
            setPdfURL(res);
            setIsPDF(true);
            setOpen1(true);
            setIsViewDocument(true);
          }
          setLoading(false);
        } else {
          if (isFrom == 1) {
            // For images (assuming the signedUrl points to the image file)
            const link = document.createElement("a");
            link.href = resultObj?.signedUrl;
            link.download = `${resultObj?.fileId}.${fileExtension}`; // Name of the image file
            link.click(); // Trigger the download
          } else {
            setPdfURL(resultObj?.signedUrl);
            setIsPDF(false);
            setOpen1(true);
            setIsViewDocument(true);
          }
          setLoading(false);
        }
      }
    } catch (e) {
      alert(e.message);
      setLoading(false);
      //   setDialogState({
      //     open: true,
      //     title: "Error",
      //     subTitle: "getDocumentData : " + e.message,
      //     type: "single",
      //   });
    }
  }

  const onVerifyHandler = async () => {
    let companyIdVal = data?.data?.locationNumber;
    if (
      companyIdVal == undefined ||
      companyIdVal == null ||
      companyIdVal == ""
    ) {
      companyIdVal = details?.companyId;
    }
    companyIdVal = details?.aILocations.filter(
      (i) => i.companyId == companyIdVal
    );
    var locatioObjectDetails = {
      locationName: companyIdVal[0]?.companyName,
      locationvalue: data?.data?.locationNumber,
    };

    sessionStorage.setItem(
      "locationDetails",
      JSON.stringify(locatioObjectDetails)
    );
    data.data.recordSource = recordSource;
    // sessionStorage.setItem("captureDetailsData", JSON.stringify(data?.data));
    // sessionStorage.setItem("captureData", null);
    dispatch(setcaptureDetailsData(JSON.stringify(data?.data)));
    dispatch(setcaptureData(null));
    // dispatch(setPatientFilteredData(filteredData));
    // dispatch(setCosmosId(params.data.cosmosKeyID));
    dispatch(
      setActiveComponent("/patient-list/capture-driving-license/patient-info")
    );
  };

  useEffect(() => {
    getPatientDetails();
  }, []);

  // console.log(data);
  useEffect(() => {
    if (data) {
      if (data?.status && data?.status?.code != 200) {
        logTelemetry(
          "Failed",
          "PatientDetails",
          "getPatientDetails",
          data?.status?.message
        );
        setNotify({
          isOpen: true,
          message: `${data?.status?.message}`,
          type: "error",
        });
      } else {
        var splitDataSource = data?.data?.processStatus;
        if (splitDataSource != undefined && splitDataSource != null) {
          var splitArray = splitDataSource.split("^");
          if (splitArray != undefined && splitArray != null) {
            if (splitArray.length > 0) {
              setrecordSource(splitArray[0]);

              if (splitArray.length > 1) {
                setprocesStatus(splitArray[1]);
                CallstepsListFunction(splitArray[0], splitArray[1]);
              } else {
                CallstepsListFunction(splitArray[0], "");
              }
            }
          }
        }

        var splitDataArray = data?.data?.eligibilityServiceTypes?.split(",");

        for (var k = 0; k <= splitDataArray?.length; k++) {
          if (
            splitDataArray[k] != undefined &&
            splitDataArray[k] != "98" &&
            splitDataArray[k] != "30" &&
            splitDataArray[k] != ""
          ) {
            stepServiceTypeCodes.push(splitDataArray[k]);
          }
        }
      }
    }
    if (data) {
      if (data?.response && data?.response?.status != 200) {
        setNotify({
          isOpen: true,
          message: `${data?.response?.message}`,
          type: "error",
        });
      }
    }
  }, [data]);

  const breadcrumbItems = [
    { label: "Insurance Verification List", link: "/patient-list" },
    {
      label: "Insurance Verification Details",
      link: "/patient-list/patient-details",
    },
  ];

  function changeBackground(e) {
    e.target.style.color = "blue";
  }
  function changeBackgroundleave(e) {
    e.target.style.color = "";
  }

  function btnBackClick() {
    dispatch(setModuleId(StaticModuleClass.INSURANCE_MODID));
    dispatch(setSegmentId(StaticModuleClass.INTAKE_SEGID));
    dispatch(setActiveComponent("/patient-list"));
  }

  function Chapters({ value }) {
    if (value != null && value != undefined && value != "") {
      const chapters = value.split("|");

      return (
        <List>
          {chapters.map((chapter, i) =>
            chapter.length > 0 ? (
              <ListItem
                style={{
                  fontSize: "small",
                  paddingLeft: "0px",
                  paddingTop: "0px",
                  paddingBottom: "0px",
                  paddingRight: "0px",
                }}
                key={i}
              >
                <FiberManualRecordIcon
                  style={{ fontSize: "small", paddingRight: "5px" }}
                ></FiberManualRecordIcon>
                {chapter}
              </ListItem>
            ) : (
              ""
            )
          )}
        </List>
      );
    } else {
      return "";
    }
  }

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };
  const handleChangesum = (panel) => (event, isExpandedsum) => {
    setExpandedsum(isExpandedsum ? panel : false);
  };

  const MultihandleChange = (panel) => (event, isExpandedsum) => {
    setexpandedsumMul(isExpandedsum ? panel : false);
  };

  function jsxGenerate(val) {
    let res = JSON.parse(val);
    let { Sections } = res;
    return (
      <Box>
        {Sections?.map((section, index) => (
          <Box key={index}>
            <Typography variant="body1" gutterBottom className="mt-2">
              {section.SectionName}
            </Typography>
            {section.Fields?.map((item, index) => (
              <Grid container className="my-2" key={index + 1}>
                <Grid xs={3} sx={{ pl: 2 }}>
                  {item.FieldName}
                </Grid>
                <Grid xs={3}>
                  <Grid container>
                    <Stack direction="row" spacing={1}>
                      <div>:</div>
                      <div>
                        <Typography
                          style={{ overflowWrap: "break-word" }}
                          variant="body1"
                        >
                          {item.FieldFinalValue}
                        </Typography>
                      </div>
                    </Stack>
                  </Grid>
                </Grid>
              </Grid>

              // <Grid container spacing={1} key={index + 1}>
              //   <Grid item xs={5}>
              //     <Typography
              //       variant="subtitle1"
              //       color="textSecondary"
              //       textAlign="end"
              //     >
              //       {item.FieldName} :
              //     </Typography>
              //   </Grid>
              //   <Grid item xs={7}>
              //     <Typography variant="subtitle1" className="!font-medium">
              //       {item.FieldFinalValue}
              //     </Typography>
              //   </Grid>
              // </Grid>
            ))}
          </Box>
        ))}
      </Box>
    );
  }

  return (
    <div>
      <Container maxWidth="xl">
        <Grid container>
          {isHideBreadCum == true && (
            <Grid
              container
              alignItems="center"
              justifyContent="space-between"
              xs={12}
              sm={12}
              className="mt-3 mb-2"
            >
              <Breadcrumb items={breadcrumbItems} isRedux={true} />

              <Box>
                {listOfDids.length === 1 ? (
                  ""
                ) : (
                  <Box className="float-left flex-auto !mr-[10px]">
                    <Tooltip title="Previous" placement="top" arrow>
                      <Button
                        onClick={handlePrevious}
                        disabled={currentIndex === 0}
                        className="button-styles !py-[5px]"
                      >
                        &nbsp;
                        <i className="fa-solid fa-angle-left fa-lg"></i>
                      </Button>
                    </Tooltip>{" "}
                    {currentIndex + 1} Of {listOfDids.length}{" "}
                    <Tooltip title="Next" placement="top" arrow>
                      <Button
                        onClick={handleNext}
                        disabled={currentIndex === listOfDids.length - 1}
                        className="button-styles !ml-2 !py-[5px]"
                      >
                        &nbsp;
                        <i className="fa-solid fa-angle-right fa-lg"></i>
                      </Button>
                    </Tooltip>
                  </Box>
                )}
                <Button
                  variant="outlined"
                  onClick={() => btnBackClick()}
                  className="button-styles !w-[120px]"
                >
                  Back
                </Button>
              </Box>
            </Grid>
          )}
          {isHideBreadCum == false && (
            <Grid
              container
              alignItems="center"
              justifyContent="space-between"
              xs={12}
              sm={12}
              className="mt-3 mb-2"
            >
              <Breadcrumb items={breadcrumbItems} isRedux={true} />
              <Box>
                <Button
                  variant="outlined"
                  onClick={() => btnBackClick()}
                  className="button-styles !w[120px]"
                >
                  Back
                </Button>
              </Box>
            </Grid>
          )}
          <Grid xs={2} sm={2}></Grid>
          <Grid className="mt-1  mb-2 flex items-center" xs={8} sm={8}>
            <Box className="w-full">
              <Stepper
                style={{ color: "green" }}
                activeStep={activeStep}
                alternativeLabel
              >
                {steps.map((label) => (
                  <Step key={label.status}>
                    <StepLabel>{label.status}</StepLabel>
                  </Step>
                ))}
              </Stepper>
            </Box>
          </Grid>
          <Grid xs={2} sm={2}></Grid>
        </Grid>

        <div className="w-full">
          <div className="">
            <Card>
              <CardContent>
                <Grid
                  container
                  direction="row"
                  justifyContent="space-between"
                  alignItems="center"
                  xs={12}
                  sm={12}
                  className="mb-3"
                >
                  <Typography className="!font-bold" gutterBottom>
                    Patient Details &nbsp;&nbsp;&nbsp;
                    <span style={{ cursor: "hand", paddingLeft: "20px" }}>
                      (Ref.Id :{" "}
                      <span
                        style={{
                          color: "blue",
                          cursor: "hand",
                          paddingLeft: "0px",
                        }}
                      >
                        {data?.data?.id}{" "}
                      </span>
                      )
                    </span>{" "}
                  </Typography>
                  {isHideBreadCum == true && (
                    <Box>
                      <Button
                        variant="outlined"
                        onClick={sendSMSData}
                        className="button-styles"
                      >
                        Send SMS or EMail
                      </Button>

                      <MuiButton
                        className={"filterButton !h-[36px] !ml-2"}
                        onClick={() =>
                          getPatientDetails(listOfDids[currentIndex])
                        }
                      >
                        <Tooltip title="Refresh">
                          <RefreshIcon />
                        </Tooltip>
                      </MuiButton>
                    </Box>
                  )}
                </Grid>
                <Divider />

                <Grid container className="my-2">
                  <Grid xs={2}>First Name</Grid>
                  <Grid xs={3}>
                    <Grid container>
                      <Stack direction="row" spacing={1}>
                        <div>:</div>
                        <div>
                          <Typography
                            style={{ overflowWrap: "break-word" }}
                            variant="body1"
                          >
                            {data?.data?.payerCorrectionsFlag == "Yes" ? (
                              <span>
                                {
                                  data?.data?.requestInfo?.demographics
                                    ?.firstName
                                }
                              </span>
                            ) : (
                              <span>
                                {data?.data?.firstName == ""
                                  ? ""
                                  : data?.data?.firstName}
                              </span>
                            )}
                          </Typography>
                        </div>
                      </Stack>
                    </Grid>
                  </Grid>
                  <Grid xs={2} sx={{ pl: 2 }}>
                    Address Line 1
                  </Grid>
                  <Grid xs={3}>
                    <Grid container>
                      <Stack direction="row" spacing={1}>
                        <div>:</div>
                        <div>
                          <Typography
                            style={{ overflowWrap: "break-word" }}
                            variant="body1"
                          >
                            {data?.data?.payerCorrectionsFlag == "Yes" ? (
                              <span>
                                {
                                  data?.data?.requestInfo?.demographics
                                    ?.addressLine1
                                }
                              </span>
                            ) : (
                              <span>
                                {data?.data?.addressLine1 == ""
                                  ? ""
                                  : data?.data?.addressLine1}
                              </span>
                            )}
                          </Typography>
                        </div>
                      </Stack>
                    </Grid>
                  </Grid>
                </Grid>

                <Grid container className="my-2">
                  <Grid xs={2}>Last Name</Grid>
                  <Grid xs={3}>
                    <Grid container>
                      <Stack direction="row" spacing={1}>
                        <div>:</div>
                        <div>
                          <Typography
                            style={{ overflowWrap: "break-word" }}
                            variant="body1"
                          >
                            {data?.data?.payerCorrectionsFlag == "Yes" ? (
                              <span>
                                {
                                  data?.data?.requestInfo?.demographics
                                    ?.lastName
                                }
                              </span>
                            ) : (
                              <span>
                                {data?.data?.lastName == ""
                                  ? ""
                                  : data?.data?.lastName}
                              </span>
                            )}
                          </Typography>
                        </div>
                      </Stack>
                    </Grid>
                  </Grid>
                  <Grid xs={2} sx={{ pl: 2 }}>
                    Address Line 2
                  </Grid>
                  <Grid xs={3}>
                    <Grid container>
                      <Stack direction="row" spacing={1}>
                        <div>:</div>
                        <div>
                          <Typography
                            style={{ overflowWrap: "break-word" }}
                            variant="body1"
                          >
                            {data?.data?.payerCorrectionsFlag == "Yes" ? (
                              <span>
                                {
                                  data?.data?.requestInfo?.demographics
                                    ?.addressLine2
                                }
                              </span>
                            ) : (
                              <span>
                                {data?.data?.addressLine2 == ""
                                  ? ""
                                  : data?.data?.addressLine2}
                              </span>
                            )}
                          </Typography>
                        </div>
                      </Stack>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid container className="my-2">
                  <Grid xs={2}>Date of Birth</Grid>
                  <Grid xs={3}>
                    <Grid container>
                      <Stack direction="row" spacing={1}>
                        <div>:</div>
                        <div>
                          <Typography
                            style={{ overflowWrap: "break-word" }}
                            variant="body1"
                          >
                            {data?.data?.payerCorrectionsFlag == "Yes" ? (
                              <span>
                                {moment(
                                  data?.data?.requestInfo?.demographics?.dob
                                ).format("MM/DD/YYYY") == "Invalid date"
                                  ? ""
                                  : moment(
                                      data?.data?.requestInfo?.demographics?.dob
                                    ).format("MM/DD/YYYY")}
                              </span>
                            ) : (
                              <span>
                                {" "}
                                {moment(data?.data?.dob).format("MM/DD/YYYY") ==
                                "Invalid date"
                                  ? ""
                                  : moment(data?.data?.dob).format(
                                      "MM/DD/YYYY"
                                    )}
                              </span>
                            )}
                          </Typography>
                        </div>
                      </Stack>
                    </Grid>
                  </Grid>
                  <Grid xs={2} sx={{ pl: 2 }}>
                    City or Town
                  </Grid>
                  <Grid xs={3}>
                    <Grid container>
                      <Stack direction="row" spacing={1}>
                        <div>:</div>
                        <div>
                          <Typography
                            style={{ overflowWrap: "break-word" }}
                            variant="body1"
                          >
                            {data?.data?.payerCorrectionsFlag == "Yes" ? (
                              <span>
                                {
                                  data?.data?.requestInfo?.demographics
                                    ?.cityTown
                                }
                              </span>
                            ) : (
                              <span>
                                {data?.data?.cityTown == ""
                                  ? ""
                                  : data?.data?.cityTown}
                              </span>
                            )}
                          </Typography>
                        </div>
                      </Stack>
                    </Grid>
                  </Grid>
                  <Grid xs={2}>
                    {data?.data?.idCardFiles?.length > 1 && (
                      <div>
                        <Tooltip title="Download">
                          <DownloadMapSharpIcon
                            className="justify-left"
                            onClick={() => {
                              const link = document.createElement("a");
                              link.download = "download";
                              link.href = data?.data?.idCardFiles[0]?.fileName;
                              link.click();
                            }}
                            color="primary"
                          />
                        </Tooltip>
                        <a
                          style={{
                            cursor: "pointer",
                            textDecoration: "underline",
                          }}
                          onMouseLeave={changeBackgroundleave}
                          onMouseOver={changeBackground}
                          onClick={() => {
                            handleDLOpen(0);
                          }}
                        >
                          {" "}
                          Identification-Front{" "}
                        </a>
                      </div>
                    )}
                  </Grid>
                </Grid>

                <Grid container className="my-2">
                  <Grid xs={2}>Gender</Grid>
                  <Grid xs={3}>
                    <Grid container>
                      <Stack direction="row" spacing={1}>
                        <div>:</div>
                        <div>
                          <Typography
                            style={{ overflowWrap: "break-word" }}
                            variant="body1"
                          >
                            {data?.data?.payerCorrectionsFlag == "Yes" ? (
                              <span>
                                {data?.data?.requestInfo?.demographics?.gender}
                              </span>
                            ) : (
                              <span>
                                {data?.data?.gender == ""
                                  ? ""
                                  : data?.data?.gender}
                              </span>
                            )}
                          </Typography>
                        </div>
                      </Stack>
                    </Grid>
                  </Grid>
                  <Grid xs={2} sx={{ pl: 2 }}>
                    State or Province
                  </Grid>
                  <Grid xs={3}>
                    <Grid container>
                      <Stack direction="row" spacing={1}>
                        <div>:</div>
                        <div>
                          <Typography
                            style={{ overflowWrap: "break-word" }}
                            variant="body1"
                          >
                            {data?.data?.payerCorrectionsFlag == "Yes" ? (
                              <span>
                                {
                                  data?.data?.requestInfo?.demographics
                                    ?.stateProvinceCode
                                }
                              </span>
                            ) : (
                              <span>
                                {data?.data?.stateProvinceCode == ""
                                  ? ""
                                  : data?.data?.stateProvinceCode}
                              </span>
                            )}
                          </Typography>
                        </div>
                      </Stack>
                    </Grid>
                  </Grid>
                  <Grid xs={2}>
                    {data?.data?.idCardFiles?.length > 1 && (
                      <div style={{ paddingLeft: "0px" }}>
                        <Tooltip title="Download">
                          <DownloadMapSharpIcon
                            className="justify-left"
                            onClick={() => {
                              const link = document.createElement("a");
                              link.download = "download";
                              link.href = data?.data?.idCardFiles[1]?.fileName;
                              link.click();
                            }}
                            color="primary"
                          />
                        </Tooltip>
                        <a
                          style={{
                            cursor: "pointer",
                            textDecoration: "underline",
                          }}
                          onMouseLeave={changeBackgroundleave}
                          onMouseOver={changeBackground}
                          onClick={() => {
                            handleDLOpen(1);
                          }}
                        >
                          {" "}
                          Identification-Back{" "}
                        </a>
                      </div>
                    )}
                    {data?.data?.idCardFiles?.length == 1 && (
                      <div>
                        <Tooltip title="Download">
                          <DownloadMapSharpIcon
                            className="justify-left"
                            onClick={() => {
                              const link = document.createElement("a");
                              link.download = "download";
                              link.href = data?.data?.idCardFiles[0]?.fileName;
                              link.click();
                            }}
                            color="primary"
                          />{" "}
                        </Tooltip>
                        <a
                          style={{
                            cursor: "pointer",
                            textDecoration: "underline",
                          }}
                          onMouseLeave={changeBackgroundleave}
                          onMouseOver={changeBackground}
                          onClick={() => {
                            handleDLOpen(0);
                          }}
                        >
                          {" "}
                          Identification-Front{" "}
                        </a>
                      </div>
                    )}
                  </Grid>
                </Grid>
                <Grid container className="my-2">
                  <Grid xs={2}>Mobile Number</Grid>
                  <Grid xs={3}>
                    <Grid container>
                      <Stack direction="row" spacing={1}>
                        <div>:</div>
                        <div>
                          <Typography
                            style={{ overflowWrap: "break-word" }}
                            variant="body1"
                          >
                            {data?.data?.phoneNumber == ""
                              ? ""
                              : data?.data?.phoneNumber}
                          </Typography>
                        </div>
                      </Stack>
                    </Grid>
                  </Grid>
                  <Grid xs={2} sx={{ pl: 2 }}>
                    Postal Code
                  </Grid>
                  <Grid xs={3}>
                    <Grid container>
                      <Stack direction="row" spacing={1}>
                        <div>:</div>
                        <div>
                          <Typography
                            style={{ overflowWrap: "break-word" }}
                            variant="body1"
                          >
                            {data?.data?.payerCorrectionsFlag == "Yes" ? (
                              <span>
                                {
                                  data?.data?.requestInfo?.demographics
                                    ?.postalCode
                                }
                              </span>
                            ) : (
                              <span>
                                {data?.data?.postalCode == ""
                                  ? ""
                                  : data?.data?.postalCode}
                              </span>
                            )}
                          </Typography>
                        </div>
                      </Stack>
                    </Grid>
                  </Grid>
                </Grid>

                <Grid container className="my-2">
                  <Grid xs={2}>Email</Grid>
                  <Grid xs={3}>
                    <Grid container>
                      <Stack direction="row" spacing={1}>
                        <div>:</div>
                        <div>
                          <Typography
                            style={{ overflowWrap: "break-word" }}
                            variant="body1"
                          >
                            {data?.data?.emailId == ""
                              ? ""
                              : data?.data?.emailId}
                          </Typography>
                        </div>
                      </Stack>
                    </Grid>
                  </Grid>

                  <Grid xs={2} sx={{ pl: 2 }}>
                    Patient relationship to subscriber
                  </Grid>
                  <Grid xs={3}>
                    <Grid container>
                      <Stack direction="row" spacing={1}>
                        <div>:</div>
                        <div>
                          <Typography
                            style={{ overflowWrap: "break-word" }}
                            variant="body1"
                          >
                            {data?.data?.payerCorrectionsFlag == "Yes" ? (
                              <span>
                                {
                                  data?.data?.requestInfo?.insurance
                                    ?.relationship
                                }
                              </span>
                            ) : (
                              <span>
                                {data?.data?.patientInsurance?.relationship}
                              </span>
                            )}
                          </Typography>
                        </div>
                      </Stack>
                    </Grid>
                  </Grid>
                </Grid>

                {data?.data?.payerCorrectionsFlag == "Yes" && (
                  <Card
                    sx={{ mt: 1 }}
                    xs={12}
                    sm={12}
                    style={{
                      backgroundColor: "#fcfbfb",
                      color: "#000",
                      border: "2px #edf2f6 solid",
                    }}
                  >
                    <CardContent>
                      <Typography gutterBottom>
                        Details listed in Payer System
                      </Typography>
                      <Divider />
                    </CardContent>
                    <Grid container sx={{ pl: 2, pb: 1, fontSize: "14px" }}>
                      <Grid xs={2}>First name</Grid>
                      <Grid xs={2}>Last Name</Grid>
                      <Grid xs={2}>Middle Name</Grid>
                      <Grid xs={2}>Date of Birth</Grid>
                      <Grid xs={1}>Gender</Grid>
                      <Grid xs={1}>Relationship</Grid>
                      <Grid xs={2}>Address</Grid>
                    </Grid>
                    <Divider />
                    <Grid container sx={{ pl: 2, pt: 1, pb: 1 }}>
                      <Grid xs={2}>
                        <div>
                          <Typography
                            style={{
                              overflowWrap: "break-word",
                              background: callbackGroundpayerSystem("1"),
                            }}
                            variant="body1"
                          >
                            {data?.data?.firstName == ""
                              ? ""
                              : data?.data?.firstName}
                          </Typography>
                        </div>
                      </Grid>
                      <Grid xs={2}>
                        <div>
                          <Typography
                            style={{
                              marginLeft: "10px",
                              overflowWrap: "break-word",
                              background: callbackGroundpayerSystem("2"),
                            }}
                            variant="body1"
                          >
                            {data?.data?.lastName == ""
                              ? ""
                              : data?.data?.lastName}
                          </Typography>
                        </div>
                      </Grid>
                      <Grid xs={2}>
                        <div>
                          <Typography
                            style={{
                              marginLeft: "10px",
                              overflowWrap: "break-word",
                              background: callbackGroundpayerSystem("3"),
                            }}
                            variant="body1"
                          >
                            {data?.data?.middleName == ""
                              ? ""
                              : data?.data?.middleName}
                          </Typography>
                        </div>
                      </Grid>

                      <Grid xs={2}>
                        <div>
                          <Typography
                            style={{
                              marginLeft: "10px",
                              overflowWrap: "break-word",
                              background: callbackGroundpayerSystem("4"),
                            }}
                            variant="body1"
                          >
                            {moment(data?.data?.dob).format("MM/DD/YYYY") ==
                            "Invalid date"
                              ? ""
                              : moment(data?.data?.dob).format("MM/DD/YYYY")}
                          </Typography>
                        </div>
                      </Grid>
                      <Grid xs={1}>
                        <div>
                          <Typography
                            style={{
                              marginLeft: "10px",
                              overflowWrap: "break-word",
                              background: callbackGroundpayerSystem("5"),
                            }}
                            variant="body1"
                          >
                            {data?.data?.gender == "" ? "" : data?.data?.gender}
                          </Typography>
                        </div>
                      </Grid>
                      <Grid xs={1}>
                        <div>
                          <Typography
                            style={{
                              marginLeft: "10px",
                              overflowWrap: "break-word",
                              background: callbackGroundpayerSystem("6"),
                            }}
                            variant="body1"
                          >
                            {data?.data?.patientInsurance?.relationship}
                          </Typography>
                        </div>
                      </Grid>
                      <Grid xs={2}>
                        <div>
                          <Typography
                            style={{
                              marginLeft: "10px",
                              overflowWrap: "break-word",
                              background: callbackGroundpayerSystem("16"),
                            }}
                            variant="body1"
                          >
                            {data?.data?.addressLine1 == ""
                              ? ""
                              : data?.data?.addressLine1}
                            {data?.data?.addressLine2 == "" ||
                            data?.data?.addressLine2 == null
                              ? ""
                              : "," + data?.data?.addressLine2}
                            {data?.data?.cityTown == ""
                              ? ""
                              : "," + data?.data?.cityTown}
                            {/* {data?.data?.stateProvinceCode == "" ? "" : ","+ data?.data?.stateProvinceCode}
                      {data?.data?.countryCode == "" ? "" : ","+ data?.data?.countryCode} */}
                            {data?.data?.postalCode == ""
                              ? ""
                              : "," + data?.data?.postalCode}
                          </Typography>
                        </div>
                      </Grid>
                    </Grid>
                  </Card>
                )}
              </CardContent>
            </Card>
            <br />
            {/* start */}
            {data?.data?.llmJson && (
              <Card>
                <CardContent>
                  <Grid
                    container
                    direction="row"
                    justifyContent="space-between"
                    alignItems="center"
                    xs={12}
                    sm={12}
                    // style={{
                    //   marginTop: "24px",
                    // }}
                  >
                    <Typography
                      className="!font-bold"
                      gutterBottom
                      style={{
                        marginTop: "8px",
                        marginBottom: "8px",
                      }}
                    >
                      Financial Details
                    </Typography>
                  </Grid>
                  <Divider
                    sx={{
                      marginBottom: "8px",
                    }}
                  />
                  <Grid container className="my-2">
                    <Grid xs={10}> {jsxGenerate(data?.data?.llmJson)}</Grid>
                    {/* <Grid xs={1}></Grid> */}
                    <Grid xs={2}>
                      <Box>
                        {JSON.parse(data?.data?.llmJson).documentllmId && (
                          <div>
                            <Tooltip title="Download Document">
                              <DownloadMapSharpIcon
                                className="justify-left"
                                sx={{
                                  cursor: "pointer",
                                }}
                                onClick={() => {
                                  downloadDoc(
                                    JSON.parse(data?.data?.llmJson)
                                      ?.documentllmId
                                  );
                                  // const link = document.createElement("a");
                                  // link.download = "download";
                                  // link.href = data?.data?.idCardFiles[0]?.fileName;
                                  // link.click();
                                }}
                                color="primary"
                              />
                            </Tooltip>
                            <a
                              style={{
                                cursor: "pointer",
                                textDecoration: "underline",
                              }}
                              onMouseLeave={changeBackgroundleave}
                              onMouseOver={changeBackground}
                              onClick={() => {
                                handleDLOpen(
                                  10,
                                  JSON.parse(data?.data?.llmJson)
                                    ?.documentllmId,
                                  JSON.parse(data?.data?.llmJson)?.Document
                                    ?.Name
                                );
                              }}
                            >
                              View Document
                            </a>
                          </div>
                        )}
                      </Box>
                    </Grid>
                  </Grid>
                </CardContent>
              </Card>
            )}
            {/* end */}

            {(data?.data?.patientInsurance?.relationship == "Self" &&
              (data?.data?.patientInsurance?.subscriberFirstName == "" ||
                data?.data?.patientInsurance?.subscriberFirstName == null)) ||
            data?.data?.patientInsurance?.relationship == "" ||
            data?.data?.patientInsurance?.relationship == null ? null : (
              <Card sx={{ mt: 2 }}>
                <CardContent>
                  <Typography sx={{ fontWeight: "bold" }} gutterBottom>
                    Subscriber Details
                  </Typography>
                  <Divider />

                  <Grid container className="my-2">
                    <Grid xs={2}>First Name</Grid>
                    <Grid xs={3}>
                      <Grid container>
                        <Stack direction="row" spacing={1}>
                          <div>:</div>
                          <div>
                            <Typography
                              style={{ overflowWrap: "break-word" }}
                              variant="body1"
                            >
                              {data?.data?.payerCorrectionsFlag == "Yes" ? (
                                <span>
                                  {
                                    data?.data?.requestInfo?.subscriber
                                      ?.firstName
                                  }
                                </span>
                              ) : (
                                <span>
                                  {
                                    data?.data?.patientInsurance
                                      ?.subscriberFirstName
                                  }
                                </span>
                              )}
                            </Typography>
                          </div>
                        </Stack>
                      </Grid>
                    </Grid>
                    <Grid xs={2} sx={{ pl: 2 }}>
                      Last Name
                    </Grid>
                    <Grid xs={3}>
                      <Grid container>
                        <Stack direction="row" spacing={1}>
                          <div>:</div>
                          <div>
                            <Typography
                              style={{ overflowWrap: "break-word" }}
                              variant="body1"
                            >
                              {data?.data?.payerCorrectionsFlag == "Yes" ? (
                                <span>
                                  {
                                    data?.data?.requestInfo?.subscriber
                                      ?.lastName
                                  }
                                </span>
                              ) : (
                                <span>
                                  {
                                    data?.data?.patientInsurance
                                      ?.subscriberLastName
                                  }
                                </span>
                              )}
                            </Typography>
                          </div>
                        </Stack>
                      </Grid>
                    </Grid>
                  </Grid>

                  <Grid container className="my-2">
                    <Grid xs={2}>Gender</Grid>
                    <Grid xs={3}>
                      <Grid container>
                        <Stack direction="row" spacing={1}>
                          <div>:</div>
                          <div>
                            <Typography
                              style={{ overflowWrap: "break-word" }}
                              variant="body1"
                            >
                              {data?.data?.payerCorrectionsFlag == "Yes" ? (
                                <span>
                                  {data?.data?.requestInfo?.subscriber?.gender}
                                </span>
                              ) : (
                                <span>
                                  {
                                    data?.data?.patientInsurance
                                      ?.subscriberGender
                                  }
                                </span>
                              )}
                            </Typography>
                          </div>
                        </Stack>
                      </Grid>
                    </Grid>
                    <Grid xs={2} sx={{ pl: 2 }}>
                      Date of Birth
                    </Grid>
                    <Grid xs={3}>
                      <Grid container>
                        <Stack direction="row" spacing={1}>
                          <div>:</div>
                          <div>
                            <Typography
                              style={{ overflowWrap: "break-word" }}
                              variant="body1"
                            >
                              {data?.data?.payerCorrectionsFlag == "Yes" ? (
                                <span>
                                  {moment(
                                    data?.data?.requestInfo?.subscriber?.dob
                                  ).format("MM/DD/YYYY") == "Invalid date"
                                    ? ""
                                    : data?.data?.requestInfo?.subscriber ==
                                      null
                                    ? ""
                                    : moment(
                                        data?.data?.requestInfo?.subscriber?.dob
                                      ).format("MM/DD/YYYY")}
                                </span>
                              ) : (
                                <span>
                                  {" "}
                                  {moment(
                                    data?.data?.patientInsurance?.subscriberDob
                                  ).format("MM/DD/YYYY") == "Invalid date"
                                    ? ""
                                    : moment(
                                        data?.data?.patientInsurance
                                          ?.subscriberDob
                                      ).format("MM/DD/YYYY")}
                                </span>
                              )}
                            </Typography>
                          </div>
                        </Stack>
                      </Grid>
                    </Grid>
                  </Grid>
                  {data?.data?.payerCorrectionsFlag == "Yes" && (
                    <Card
                      sx={{ mt: 1 }}
                      xs={12}
                      sm={12}
                      style={{
                        backgroundColor: "#fcfbfb",
                        color: "#000",
                        border: "2px #edf2f6 solid",
                      }}
                    >
                      <CardContent>
                        <Typography gutterBottom>
                          Details listed in Payer System
                        </Typography>
                        <Divider />
                      </CardContent>
                      <Grid container sx={{ pl: 2, pb: 1, fontSize: "14px" }}>
                        <Grid xs={2}>First name</Grid>{" "}
                        <Grid xs={2}>Last Name</Grid>
                        <Grid xs={2}>Middle Name</Grid>
                        <Grid xs={2}>Date of Birth</Grid>
                        <Grid xs={1}>Gender</Grid>
                      </Grid>
                      <Divider />
                      <Grid container sx={{ pl: 2, pt: 1, pb: 1 }}>
                        <Grid xs={2}>
                          <div>
                            <Typography
                              style={{
                                marginLeft: "10px",
                                overflowWrap: "break-word",
                                background: callbackGroundpayerSystem("7"),
                              }}
                              variant="body1"
                            >
                              {
                                data?.data?.patientInsurance
                                  ?.subscriberFirstName
                              }
                            </Typography>
                          </div>
                        </Grid>
                        <Grid xs={2}>
                          <div>
                            <Typography
                              style={{
                                marginLeft: "10px",
                                overflowWrap: "break-word",
                                background: callbackGroundpayerSystem("8"),
                              }}
                              variant="body1"
                            >
                              {data?.data?.patientInsurance?.subscriberLastName}
                            </Typography>
                          </div>
                        </Grid>
                        <Grid xs={2}>
                          <div>
                            <Typography
                              style={{
                                marginLeft: "10px",
                                overflowWrap: "break-word",
                                background: callbackGroundpayerSystem("9"),
                              }}
                              variant="body1"
                            >
                              {
                                data?.data?.patientInsurance
                                  ?.subscriberMiddleName
                              }
                            </Typography>
                          </div>
                        </Grid>
                        <Grid xs={2}>
                          {" "}
                          <div>
                            <Typography
                              style={{
                                marginLeft: "10px",
                                overflowWrap: "break-word",
                                background: callbackGroundpayerSystem("10"),
                              }}
                              variant="body1"
                            >
                              {moment(
                                data?.data?.patientInsurance?.subscriberDob
                              ).format("MM/DD/YYYY") == "Invalid date"
                                ? ""
                                : moment(
                                    data?.data?.patientInsurance?.subscriberDob
                                  ).format("MM/DD/YYYY")}
                            </Typography>
                          </div>
                        </Grid>
                        <Grid xs={1}>
                          <div>
                            <Typography
                              style={{ overflowWrap: "break-word" }}
                              variant="body1"
                            >
                              {data?.data?.patientInsurance?.subscriberGender}
                            </Typography>
                          </div>
                        </Grid>
                      </Grid>
                    </Card>
                  )}
                </CardContent>
              </Card>
            )}

            <Card sx={{ mt: 2 }}>
              <CardContent>
                <Grid
                  container
                  direction="row"
                  justifyContent="space-between"
                  alignItems="center"
                  xs={12}
                  sm={12}
                  className="mb-3"
                >
                  <Typography className="!font-bold" gutterBottom>
                    {data?.data?.patientInsurance?.priority} Insurance Details
                  </Typography>

                  <Box>
                    {details?.aiAccessRights?.captureUpload == 9 &&
                      data?.data?.patientInsurance == null &&
                      data?.data?.insuranceCardFiles?.length == 0 && (
                        <Button
                          variant="outlined"
                          navigate="/patient-list/capture-driving-license"
                          className="button-styles btnHeight !mr-2"
                        >
                          Capture or Upload
                        </Button>
                      )}

                    {details?.aiAccessRights?.verifyData == 1 &&
                      (data?.data?.patientInsurance != null ||
                        data?.data?.insuranceCardFiles?.length > 0) && (
                        <div>
                          {(data?.data?.patientInsurance?.payerName?.length >
                            0 ||
                            data?.data?.patientInsurance?.subscriberId?.length >
                              0) && (
                            <Button
                              variant="outlined"
                              onClick={onVerifyHandler}
                              className="button-styles btnHeight !min-w-[200px] !ml-2"
                            >
                              Verify
                            </Button>
                          )}
                        </div>
                      )}
                  </Box>
                </Grid>
                <Divider />
                <Grid container className="my-2">
                  <Grid xs={6}>
                    <Grid container className="my-2">
                      <Grid xs={4}>Payer Name</Grid>
                      <Grid xs={6}>
                        <Grid container>
                          <Stack direction="row" spacing={1}>
                            <div>:</div>
                            <div>
                              <Typography
                                style={{ overflowWrap: "break-word" }}
                                variant="body1"
                              >
                                {data?.data?.patientInsurance?.payerName}
                              </Typography>
                            </div>
                          </Stack>
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid container className="my-2">
                      <Grid xs={4}>Payer Id</Grid>
                      <Grid xs={6}>
                        <Grid container>
                          <Stack direction="row" spacing={1}>
                            <div>:</div>
                            <div>
                              <Typography
                                style={{ overflowWrap: "break-word" }}
                                variant="body1"
                              >
                                {data?.data?.patientInsurance?.payerId}
                              </Typography>
                            </div>
                          </Stack>
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid container className="my-2">
                      <Grid xs={4}>Subscriber/Member ID</Grid>
                      <Grid xs={6}>
                        <Grid container>
                          <Stack direction="row" spacing={1}>
                            <div>:</div>
                            <div>
                              <Typography
                                style={{ overflowWrap: "break-word" }}
                                variant="body1"
                              >
                                {data?.data?.payerCorrectionsFlag == "Yes" ? (
                                  <span>
                                    {
                                      data?.data?.requestInfo?.insurance
                                        ?.subscriberId
                                    }
                                  </span>
                                ) : (
                                  <span>
                                    {data?.data?.patientInsurance?.subscriberId}
                                  </span>
                                )}
                              </Typography>
                            </div>
                          </Stack>
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid container className="my-2">
                      <Grid xs={4}>Group Number</Grid>
                      <Grid xs={6}>
                        <Grid container>
                          <Stack direction="row" spacing={1}>
                            <div>:</div>
                            <div>
                              <Typography
                                style={{ overflowWrap: "break-word" }}
                                variant="body1"
                              >
                                {data?.data?.payerCorrectionsFlag == "Yes" ? (
                                  <span>
                                    {
                                      data?.data?.requestInfo?.insurance
                                        ?.groupNumber
                                    }
                                  </span>
                                ) : (
                                  <span>
                                    {data?.data?.patientInsurance?.groupNumber}
                                  </span>
                                )}
                              </Typography>
                            </div>
                          </Stack>
                        </Grid>
                      </Grid>
                    </Grid>

                    <Grid container className="my-2">
                      <Grid xs={4}>Insurance Type</Grid>
                      <Grid xs={6}>
                        <Grid container>
                          <Stack direction="row" spacing={1}>
                            <div>:</div>
                            <div>
                              <Typography
                                style={{ overflowWrap: "break-word" }}
                                variant="body1"
                              >
                                {data?.data?.patientInsurance?.insuranceType}
                              </Typography>
                            </div>
                          </Stack>
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid container className="my-2">
                      <Grid xs={4}>Insurance Card Type</Grid>
                      <Grid xs={6}>
                        <Grid container>
                          <Stack direction="row" spacing={1}>
                            <div>:</div>
                            <div>
                              <Typography
                                style={{ overflowWrap: "break-word" }}
                                variant="body1"
                              >
                                {
                                  data?.data?.patientInsurance
                                    ?.insuranceCardType
                                }
                              </Typography>
                            </div>
                          </Stack>
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid container className="my-2">
                      <Grid xs={4}>Network Type</Grid>
                      <Grid xs={6}>
                        <Grid container>
                          <Stack direction="row" spacing={1}>
                            <div>:</div>
                            <div>
                              <Typography
                                style={{ overflowWrap: "break-word" }}
                                variant="body1"
                              >
                                {data?.data?.patientInsurance?.insuranceNetwork}
                              </Typography>
                            </div>
                          </Stack>
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid container className="my-2">
                      <Grid xs={4}>Rx ID</Grid>
                      <Grid xs={6}>
                        <Grid container>
                          <Stack direction="row" spacing={1}>
                            <div>:</div>
                            <div>
                              <Typography
                                style={{ overflowWrap: "break-word" }}
                                variant="body1"
                              >
                                {data?.data?.patientInsurance?.rxId}
                              </Typography>
                            </div>
                          </Stack>
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid container className="my-2">
                      <Grid xs={4}>Rx Bin</Grid>
                      <Grid xs={6}>
                        <Grid container>
                          <Stack direction="row" spacing={1}>
                            <div>:</div>
                            <div>
                              <Typography
                                style={{ overflowWrap: "break-word" }}
                                variant="body1"
                              >
                                {data?.data?.patientInsurance?.rxBin}
                              </Typography>
                            </div>
                          </Stack>
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid container className="my-2">
                      <Grid xs={4}>Rx PCN</Grid>
                      <Grid xs={6}>
                        <Grid container>
                          <Stack direction="row" spacing={1}>
                            <div>:</div>
                            <div>
                              <Typography
                                style={{ overflowWrap: "break-word" }}
                                variant="body1"
                              >
                                {data?.data?.patientInsurance?.rxPcn}
                              </Typography>
                            </div>
                          </Stack>
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid container className="my-2">
                      <Grid xs={4}>Rx Group</Grid>
                      <Grid xs={6}>
                        <Grid container>
                          <Stack direction="row" spacing={1}>
                            <div>:</div>
                            <div>
                              <Typography
                                style={{ overflowWrap: "break-word" }}
                                variant="body1"
                              >
                                {data?.data?.patientInsurance?.rxGrp}
                              </Typography>
                            </div>
                          </Stack>
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid container className="my-2">
                      <Grid xs={4}>Date of service</Grid>
                      <Grid xs={6}>
                        <Grid container>
                          <Stack direction="row" spacing={1}>
                            <div>:</div>
                            <div>
                              <Typography
                                style={{ overflowWrap: "break-word" }}
                                variant="body1"
                              >
                                {moment(data?.data?.appointmentDate).format(
                                  "MM/DD/YYYY"
                                ) == "Invalid date"
                                  ? ""
                                  : moment(data?.data?.appointmentDate).format(
                                      "MM/DD/YYYY"
                                    )}
                              </Typography>
                            </div>
                          </Stack>
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid container className="my-2">
                      <Grid xs={4}>Create Date</Grid>
                      <Grid xs={6}>
                        <Grid container>
                          <Stack direction="row" spacing={1}>
                            <div>:</div>
                            <div>
                              <Typography
                                style={{ overflowWrap: "break-word" }}
                                variant="body1"
                              >
                                {moment(data?.data?.createDate).format(
                                  "MM/DD/YYYY"
                                ) == "Invalid date"
                                  ? ""
                                  : moment(data?.data?.createDate).format(
                                      "MM/DD/YYYY"
                                    )}
                              </Typography>
                            </div>
                          </Stack>
                        </Grid>
                      </Grid>
                    </Grid>

                    <Grid
                      container
                      className="my-2"
                      sx={{
                        background:
                          data?.data?.eligibilityStatus?.toLowerCase() ==
                          "inactive"
                            ? "#ff9090"
                            : data?.data?.eligibilityStatus?.toLowerCase() ==
                              "active"
                            ? "#D3F8D3"
                            : "#fff5a1",
                      }}
                    >
                      <Grid xs={4}>Insurance Verification Status</Grid>
                      <Grid xs={6}>
                        <Grid container>
                          <Stack direction="row" spacing={1}>
                            <div>:</div>
                            <div>
                              <Typography
                                style={{
                                  overflowWrap: "break-word",
                                  fontWeight: "bold",
                                }}
                                variant="body1"
                              >
                                {data?.data?.eligibilityStatus}
                              </Typography>
                            </div>
                          </Stack>
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid container className="my-2">
                      <Grid xs={4}> Clearing house name</Grid>
                      <Grid xs={6}>
                        <Grid container>
                          <Stack direction="row" spacing={1}>
                            <div>:</div>
                            <div>
                              <Typography
                                style={{ overflowWrap: "break-word" }}
                                variant="body1"
                              >
                                {data?.data?.patientInsurance?.clearingHouseName?.toLowerCase() ==
                                "wavehdc"
                                  ? "Experian Health"
                                  : data?.data?.patientInsurance
                                      ?.clearingHouseName}
                              </Typography>
                            </div>
                          </Stack>
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid container className="my-2">
                      <Grid xs={4}>CH Payer ID </Grid>
                      <Grid xs={6}>
                        <Grid container>
                          <Stack direction="row" spacing={1}>
                            <div>:</div>
                            <div>
                              <Typography
                                style={{ overflowWrap: "break-word" }}
                                variant="body1"
                              >
                                {
                                  data?.data?.patientInsurance
                                    ?.clearingHousePayerId
                                }
                              </Typography>
                            </div>
                          </Stack>
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid container className="my-2">
                      <Grid xs={4}>CH Payer Name </Grid>
                      <Grid xs={6}>
                        <Grid container>
                          <Stack direction="row" spacing={1}>
                            <div>:</div>
                            <div>
                              <Typography
                                style={{ overflowWrap: "break-word" }}
                                variant="body1"
                              >
                                {
                                  data?.data?.patientInsurance
                                    ?.clearingHousePayerName
                                }
                              </Typography>
                            </div>
                          </Stack>
                        </Grid>
                      </Grid>
                    </Grid>

                    {data?.data?.eligiblityLog?.responses?.length > 0 && (
                      <Grid
                        container
                        className="my-2"
                        style={{ background: "aqua" }}
                      >
                        <Grid xs={4}>Reason</Grid>
                        <Grid xs={6}>
                          <Grid container style={{ background: "aqua" }}>
                            <Stack direction="row" spacing={1}>
                              <div>:</div>
                              <div>
                                <Typography
                                  style={{ overflowWrap: "break-word" }}
                                  variant="body1"
                                >
                                  {data?.data?.eligiblityLog?.responses?.map(
                                    (k) => {
                                      return (
                                        <Grid key={k.description}>
                                          {k.description}
                                        </Grid>
                                      );
                                    }
                                  )}
                                </Typography>
                              </div>
                            </Stack>
                          </Grid>
                        </Grid>
                      </Grid>
                    )}
                    {data?.data?.eligibilityNotes?.length > 0 && (
                      <Grid container className="my-2">
                        <Grid xs={4}>Notes</Grid>
                        <Grid xs={6}>
                          <Grid container>
                            <Stack direction="row" spacing={1}>
                              <div>:</div>
                              <div>
                                <Typography
                                  style={{ overflowWrap: "break-word" }}
                                  variant="body1"
                                >
                                  {data?.data?.eligibilityNotes}
                                </Typography>
                              </div>
                            </Stack>
                          </Grid>
                        </Grid>
                      </Grid>
                    )}

                    {data?.data?.eligibilityServiceTypes?.length > 0 > 0 && (
                      <Grid
                        container
                        className="my-2"
                        style={{ background: "lightyellow" }}
                      >
                        <Grid xs={4}>Service Types</Grid>
                        <Grid xs={8}>
                          <Grid container style={{ background: "lightyellow" }}>
                            <Stack direction="row" spacing={1}>
                              <div>:</div>
                              <div>
                                <Typography
                                  style={{ overflowWrap: "break-word" }}
                                  variant="body1"
                                >
                                  {displayeligibilityServiceTypes()}
                                </Typography>
                              </div>
                            </Stack>
                          </Grid>
                        </Grid>
                      </Grid>
                    )}
                  </Grid>

                  <Grid xs={1}></Grid>
                  <Grid xs={4}>
                    {data?.data?.insuranceCardFiles?.length > 0 && (
                      <div className="w-full my-4">
                        <div>
                          {data?.data?.insuranceCardFiles?.map((j, index) => {
                            return (
                              <Grid
                                className="mb-2"
                                xs={12}
                                sm={12}
                                key={index}
                              >
                                <div className="rounded-sm h-52 mx-4 zoomContainer">
                                  <div className="h-full flex justify-center items-center image-container">
                                    <div
                                      className="truncate object-cover imagee"
                                      onClick={() => {
                                        handleOpen(index);
                                      }}
                                    >
                                      <img
                                        src={j.fileName}
                                        className="imageDetailsHeight"
                                      />
                                    </div>
                                  </div>
                                </div>
                                <div className="rounded-sm  mx-4 mb-2 imageIconsOver">
                                  <div className=" mx-4 my-2 imageIconOver1">
                                    <Tooltip title="Download">
                                      <DownloadMapSharpIcon
                                        className="justify-left"
                                        onClick={() => {
                                          const link =
                                            document.createElement("a");
                                          link.download = "download";
                                          link.href = j.fileName;
                                          link.click();
                                        }}
                                        color="primary"
                                      />
                                    </Tooltip>
                                    <Tooltip title="Zoom Image">
                                      <ZoomOutMapSharpIcon
                                        sx={{ float: "right" }}
                                        onClick={() => {
                                          handleOpen(index);
                                        }}
                                        color="primary"
                                      />
                                    </Tooltip>
                                  </div>
                                </div>
                              </Grid>
                            );
                          })}
                          {data?.data?.insuranceCardFiles?.length == 1 && (
                            <Grid
                              style={{ width: "50%", float: "left" }}
                              xs={12}
                              sm={6}
                            >
                              <div className="rounded-sm h-52 mx-6 md:mx-10">
                                <div className="h-full flex justify-center items-center image-container">
                                  <div className="truncate object-cover imagee"></div>
                                </div>
                              </div>
                            </Grid>
                          )}
                        </div>
                      </div>
                    )}
                  </Grid>
                </Grid>
                {data?.data?.payerCorrectionsFlag == "Yes" &&
                  CallInsuranceDetails(detailsListedInsPayer) && (
                    <Card
                      sx={{ mt: 1 }}
                      xs={12}
                      sm={12}
                      style={{
                        backgroundColor: "#fcfbfb",
                        color: "#000",
                        border: "2px #edf2f6 solid",
                      }}
                    >
                      <CardContent>
                        <Typography gutterBottom>
                          Details listed in Payer System
                        </Typography>
                        <Divider />
                      </CardContent>
                      <Grid container sx={{ pl: 2, pb: 1, fontSize: "14px" }}>
                        <Grid xs={3}>Subscriber/Member ID</Grid>{" "}
                        <Grid xs={2}>Group Number</Grid>
                        <Grid xs={2}>Plan Type</Grid>
                        <Grid xs={2}>Plan Name</Grid>
                      </Grid>
                      <Divider />
                      <Grid container sx={{ pl: 2, pt: 1, pb: 1 }}>
                        <Grid xs={3}>
                          <div>
                            <Typography
                              style={{
                                marginLeft: "10px",
                                overflowWrap: "break-word",
                                background: callbackGroundpayerSystem("11"),
                              }}
                              variant="body1"
                            >
                              {data?.data?.patientInsurance?.subscriberId}
                            </Typography>
                          </div>
                        </Grid>
                        <Grid xs={2}>
                          <div>
                            <Typography
                              style={{
                                marginLeft: "10px",
                                overflowWrap: "break-word",
                                background: callbackGroundpayerSystem("12"),
                              }}
                              variant="body1"
                            >
                              {data?.data?.patientInsurance?.groupNumber}
                            </Typography>
                          </div>
                        </Grid>
                        <Grid xs={2}>
                          <div>
                            <Typography
                              style={{
                                marginLeft: "10px",
                                overflowWrap: "break-word",
                                background: callbackGroundpayerSystem("13"),
                              }}
                              variant="body1"
                            >
                              {data?.data?.patientInsurance?.rteInsuranceType}
                            </Typography>
                          </div>
                        </Grid>
                        <Grid xs={5}>
                          <div>
                            <Typography
                              style={{
                                marginLeft: "10px",
                                overflowWrap: "break-word",
                                background: callbackGroundpayerSystem("14"),
                              }}
                              variant="body1"
                            >
                              {
                                data?.data?.patientInsurance
                                  ?.rtePlanTypeDescription
                              }
                            </Typography>
                          </div>
                        </Grid>
                      </Grid>
                    </Card>
                  )}
              </CardContent>
            </Card>
            {(data?.data?.patientInsurance?.pcpOnCard != null ||
              data?.data?.patientInsurance?.pcpInfo != null) && (
              <Card sx={{ mt: 2 }}>
                <CardContent>
                  <Typography sx={{ fontWeight: "bold" }} gutterBottom>
                    Primary Care Provider Details{" "}
                    <span
                      style={{
                        color: "blue",
                        cursor: "hand",
                        paddingLeft: "0px",
                      }}
                    >
                      ({data?.data?.patientInsurance?.pcpValidationStatus})
                    </span>
                  </Typography>
                  <Divider />
                  {data?.data?.patientInsurance?.pcpOnCard != null && (
                    <Grid>
                      <Grid container className="my-2">
                        <Grid xs={2}>Name</Grid>
                        <Grid xs={3}>
                          <Grid container>
                            <Stack direction="row" spacing={1}>
                              <div>:</div>
                              <div>
                                <Typography
                                  style={{ overflowWrap: "break-word" }}
                                  variant="body1"
                                >
                                  <span>
                                    {
                                      data?.data?.patientInsurance?.pcpOnCard
                                        ?.Name
                                    }
                                  </span>
                                </Typography>
                              </div>
                            </Stack>
                          </Grid>
                        </Grid>
                        <Grid xs={2} sx={{ pl: 2 }}>
                          Phone
                        </Grid>
                        <Grid xs={3}>
                          <Grid container>
                            <Stack direction="row" spacing={1}>
                              <div>:</div>
                              <div>
                                <Typography
                                  style={{ overflowWrap: "break-word" }}
                                  variant="body1"
                                >
                                  <span>
                                    {
                                      data?.data?.patientInsurance?.pcpOnCard
                                        ?.phone
                                    }
                                  </span>
                                </Typography>
                              </div>
                            </Stack>
                          </Grid>
                        </Grid>
                      </Grid>

                      <Grid container className="my-2">
                        <Grid xs={2}>Address</Grid>
                        <Grid xs={3}>
                          <Grid container>
                            <Stack direction="row" spacing={1}>
                              <div>:</div>
                              <div>
                                <Typography
                                  style={{ overflowWrap: "break-word" }}
                                  variant="body1"
                                >
                                  <span>
                                    {
                                      data?.data?.patientInsurance?.pcpOnCard
                                        ?.address
                                    }
                                  </span>
                                </Typography>
                              </div>
                            </Stack>
                          </Grid>
                        </Grid>
                        <Grid xs={2} sx={{ pl: 2 }}></Grid>
                        <Grid xs={3}>
                          <Grid container></Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  )}
                  <Card
                    sx={{ mt: 1 }}
                    xs={12}
                    sm={12}
                    style={{
                      backgroundColor: "#fcfbfb",
                      color: "#000",
                      border: "2px #edf2f6 solid",
                    }}
                  >
                    <CardContent>
                      <Typography gutterBottom>
                        Details listed in Payer System
                      </Typography>
                      <Divider />
                    </CardContent>
                    <Grid container sx={{ pl: 2, pb: 1, fontSize: "14px" }}>
                      <Grid xs={2}>Name</Grid> <Grid xs={1}>NPI</Grid>
                      <Grid xs={1}>Phone</Grid>
                      <Grid xs={1}>Start Date</Grid>
                      <Grid xs={1}>End Date</Grid>
                      <Grid xs={4}>Address</Grid>
                    </Grid>
                    <Divider />
                    <Grid container sx={{ pl: 2, pt: 1, pb: 1 }}>
                      <Grid xs={2}>
                        <div>
                          <Typography
                            style={{
                              marginRight: "3px",
                              marginLeft: "2px",
                              overflowWrap: "break-word",
                              background: callbackGroundpayerSystem("7"),
                            }}
                            variant="body1"
                          >
                            {" "}
                            <span>
                              {data?.data?.patientInsurance?.pcpInfo?.name}
                            </span>
                          </Typography>
                        </div>
                      </Grid>
                      <Grid xs={1}>
                        <div>
                          <Typography
                            style={{
                              marginRight: "3px",
                              marginLeft: "2px",
                              overflowWrap: "break-word",
                              background: callbackGroundpayerSystem("8"),
                            }}
                            variant="body1"
                          >
                            {" "}
                            <span>
                              {data?.data?.patientInsurance?.pcpInfo?.npi}
                            </span>
                          </Typography>
                        </div>
                      </Grid>
                      <Grid xs={1}>
                        <div>
                          <Typography
                            style={{
                              marginRight: "3px",
                              marginLeft: "2px",
                              overflowWrap: "break-word",
                              background: callbackGroundpayerSystem("9"),
                            }}
                            variant="body1"
                          >
                            {" "}
                            {data?.data?.patientInsurance?.pcpInfo?.phone}
                          </Typography>
                        </div>
                      </Grid>
                      <Grid xs={1}>
                        <div>
                          <Typography
                            style={{
                              marginRight: "3px",
                              marginLeft: "2px",
                              overflowWrap: "break-word",
                              background: callbackGroundpayerSystem("9"),
                            }}
                            variant="body1"
                          >
                            <span>
                              {" "}
                              {moment(
                                data?.data?.patientInsurance?.pcpInfo
                                  ?.effectiveStartDate
                              ).format("MM/DD/YYYY") == "Invalid date"
                                ? ""
                                : moment(
                                    data?.data?.patientInsurance?.pcpInfo
                                      ?.effectiveStartDate
                                  ).format("MM/DD/YYYY")}
                            </span>
                          </Typography>
                        </div>
                      </Grid>
                      <Grid xs={1}>
                        <div>
                          <Typography
                            style={{
                              marginLeft: "2px",
                              overflowWrap: "break-word",
                              background: callbackGroundpayerSystem("9"),
                            }}
                            variant="body1"
                          >
                            <span>
                              {" "}
                              {moment(
                                data?.data?.patientInsurance?.pcpInfo
                                  ?.effectiveEndDate
                              ).format("MM/DD/YYYY") == "Invalid date"
                                ? ""
                                : moment(
                                    data?.data?.patientInsurance?.pcpInfo
                                      ?.effectiveEndDate
                                  ).format("MM/DD/YYYY")}
                            </span>
                          </Typography>
                        </div>
                      </Grid>
                      <Grid xs={4}>
                        {" "}
                        <div>
                          <Typography
                            style={{
                              marginLeft: "2px",
                              overflowWrap: "break-word",
                              background: callbackGroundpayerSystem("10"),
                            }}
                            variant="body1"
                          >
                            <span>
                              {data?.data?.patientInsurance?.pcpInfo?.address}
                            </span>
                          </Typography>
                        </div>
                      </Grid>
                    </Grid>
                  </Card>
                </CardContent>
              </Card>
            )}

            {(data?.data?.patientInsurance?.medicareContractOnCard != null ||
              data?.data?.patientInsurance?.medicareContractInfo != null ||
              data?.data?.patientInsurance?.MedicareContractNumber != null ||
              data?.data?.patientInsurance?.MedicareContractPlanNumber !=
                null) && (
              <Card sx={{ mt: 2 }}>
                <CardContent>
                  <Typography sx={{ fontWeight: "bold" }} gutterBottom>
                    Medicare Contract Details{" "}
                    <span
                      style={{
                        color: "blue",
                        cursor: "hand",
                        paddingLeft: "0px",
                      }}
                    >
                      (
                      {
                        data?.data?.patientInsurance
                          ?.medicareContractValidationStatus
                      }
                      )
                    </span>
                  </Typography>
                  <Divider />
                  {data?.data?.patientInsurance?.medicareContractOnCard !=
                    null && (
                    <Grid>
                      <Grid container className="my-2">
                        <Grid xs={2}>Contract Number</Grid>
                        <Grid xs={3}>
                          <Grid container>
                            <Stack direction="row" spacing={1}>
                              <div>:</div>
                              <div>
                                <Typography
                                  style={{ overflowWrap: "break-word" }}
                                  variant="body1"
                                >
                                  <span>
                                    {
                                      data?.data?.patientInsurance
                                        ?.medicareContractOnCard?.contractNumber
                                    }
                                  </span>
                                </Typography>
                              </div>
                            </Stack>
                          </Grid>
                        </Grid>
                        <Grid xs={2} sx={{ pl: 2 }}>
                          Contract Plan Number
                        </Grid>
                        <Grid xs={3}>
                          <Grid container>
                            <Stack direction="row" spacing={1}>
                              <div>:</div>
                              <div>
                                <Typography
                                  style={{ overflowWrap: "break-word" }}
                                  variant="body1"
                                >
                                  <span>
                                    {
                                      data?.data?.patientInsurance
                                        ?.medicareContractOnCard?.planNumber
                                    }
                                  </span>
                                </Typography>
                              </div>
                            </Stack>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  )}
                  <Card
                    sx={{ mt: 1 }}
                    xs={12}
                    sm={12}
                    style={{
                      backgroundColor: "#fcfbfb",
                      color: "#000",
                      border: "2px #edf2f6 solid",
                    }}
                  >
                    <CardContent>
                      <Typography gutterBottom>
                        Details listed in Payer System
                      </Typography>
                      <Divider />
                    </CardContent>
                    <Grid container sx={{ pl: 2, pb: 1, fontSize: "14px" }}>
                      <Grid xs={2}>Contract Number</Grid>{" "}
                      <Grid xs={2}>Contract Plan Number</Grid>
                      <Grid xs={4}>Contract Description</Grid>
                    </Grid>
                    <Divider />
                    <Grid container sx={{ pl: 2, pt: 1, pb: 1 }}>
                      <Grid xs={2}>
                        <div>
                          <Typography
                            style={{
                              marginRight: "3px",
                              marginLeft: "2px",
                              overflowWrap: "break-word",
                              background: callbackGroundpayerSystem("7"),
                            }}
                            variant="body1"
                          >
                            {" "}
                            <span>
                              {
                                data?.data?.patientInsurance
                                  ?.medicareContractInfo?.contractNumber
                              }
                            </span>
                          </Typography>
                        </div>
                      </Grid>
                      <Grid xs={2}>
                        <div>
                          <Typography
                            style={{
                              marginRight: "3px",
                              marginLeft: "2px",
                              overflowWrap: "break-word",
                              background: callbackGroundpayerSystem("8"),
                            }}
                            variant="body1"
                          >
                            {" "}
                            <span>
                              {
                                data?.data?.patientInsurance
                                  ?.medicareContractInfo?.planNumber
                              }
                            </span>
                          </Typography>
                        </div>
                      </Grid>
                      <Grid xs={4}>
                        <div>
                          <Typography
                            style={{
                              marginRight: "3px",
                              marginLeft: "2px",
                              overflowWrap: "break-word",
                              background: callbackGroundpayerSystem("9"),
                            }}
                            variant="body1"
                          >
                            {" "}
                            {
                              data?.data?.patientInsurance?.medicareContractInfo
                                ?.planDescription
                            }
                          </Typography>
                        </div>
                      </Grid>
                    </Grid>
                  </Card>
                </CardContent>
              </Card>
            )}

            {(data?.data?.patientInsurance?.clientSystemPayerId?.length > 0 ||
              data?.data?.patientInsurance?.clientSystemPlanId?.length > 0) && (
              <Card sx={{ mt: 2, mb: 2 }}>
                <CardContent>
                  <Typography sx={{ fontWeight: "bold" }} gutterBottom>
                    EMR/PM System Insurance Details
                  </Typography>
                  <Divider />
                  <Divider />
                  <Grid container className="my-2">
                    <Grid xs={10}>
                      <Grid container className="my-2">
                        <Grid xs={2}>Payer Id</Grid>
                        <Grid xs={6}>
                          <Grid container>
                            <Stack direction="row" spacing={1}>
                              <div>:</div>
                              <div>
                                <Typography
                                  style={{ overflowWrap: "break-word" }}
                                  variant="body1"
                                >
                                  {
                                    data?.data?.patientInsurance
                                      ?.clientSystemPayerId
                                  }
                                </Typography>
                              </div>
                            </Stack>
                          </Grid>
                        </Grid>
                      </Grid>
                      <Grid container className="my-2">
                        <Grid xs={2}>Payer Name</Grid>
                        <Grid xs={6}>
                          <Grid container>
                            <Stack direction="row" spacing={1}>
                              <div>:</div>
                              <div>
                                <Typography
                                  style={{ overflowWrap: "break-word" }}
                                  variant="body1"
                                >
                                  {
                                    data?.data?.patientInsurance
                                      ?.clientSystemPayerName
                                  }
                                </Typography>
                              </div>
                            </Stack>
                          </Grid>
                        </Grid>
                      </Grid>
                      <Grid container className="my-2">
                        <Grid xs={2}>Plan ID</Grid>
                        <Grid xs={6}>
                          <Grid container>
                            <Stack direction="row" spacing={1}>
                              <div>:</div>
                              <div>
                                <Typography
                                  style={{ overflowWrap: "break-word" }}
                                  variant="body1"
                                >
                                  {
                                    data?.data?.patientInsurance
                                      ?.clientSystemPlanId
                                  }
                                </Typography>
                              </div>
                            </Stack>
                          </Grid>
                        </Grid>
                      </Grid>
                      <Grid container className="my-2">
                        <Grid xs={2}>Plan Name</Grid>
                        <Grid xs={6}>
                          <Grid container>
                            <Stack direction="row" spacing={1}>
                              <div>:</div>
                              <div>
                                <Typography
                                  style={{ overflowWrap: "break-word" }}
                                  variant="body1"
                                >
                                  {
                                    data?.data?.patientInsurance
                                      ?.clientSystemPlanName
                                  }
                                </Typography>
                              </div>
                            </Stack>
                          </Grid>
                        </Grid>
                      </Grid>

                      <Grid container className="my-2">
                        <Grid xs={2}>Contract Status</Grid>
                        <Grid xs={6}>
                          <Grid container>
                            <Stack direction="row" spacing={1}>
                              <div>:</div>
                              <div>
                                <Typography
                                  style={{ overflowWrap: "break-word" }}
                                  variant="body1"
                                ></Typography>
                              </div>
                            </Stack>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>

                    <Grid xs={2}></Grid>
                  </Grid>
                </CardContent>
              </Card>
            )}

            {data?.data?.patientInsurance?.carveouts != null &&
              data?.data?.patientInsurance?.carveouts?.length > 0 && (
                <Card sx={{ mt: 2 }}>
                  <CardContent>
                    <Typography sx={{ fontWeight: "bold" }} gutterBottom>
                      Mental Health Details{" "}
                      <span
                        style={{
                          color: "blue",
                          cursor: "hand",
                          paddingLeft: "0px",
                        }}
                      ></span>
                    </Typography>
                    <Divider />
                    <Card
                      sx={{ mt: 1 }}
                      xs={12}
                      sm={12}
                      style={{
                        backgroundColor: "#fcfbfb",
                        color: "#000",
                        border: "2px #edf2f6 solid",
                      }}
                    >
                      <CardContent>
                        <Typography gutterBottom>
                          Details listed in Payer System
                        </Typography>
                        <Divider />
                      </CardContent>
                      <Grid container sx={{ pl: 2, pb: 1, fontSize: "14px" }}>
                        <Grid xs={2}>Coverage Code</Grid>{" "}
                        <Grid xs={2}>Coverage</Grid>
                        <Grid xs={2}>Payer Name</Grid>
                        <Grid xs={2}>Phone</Grid>
                        <Grid xs={2}>Validatation Status</Grid>
                      </Grid>
                      <Divider />

                      {data?.data?.patientInsurance?.carveouts?.map(
                        (i, index) => {
                          return (
                            <Grid
                              container
                              sx={{ pl: 2, pt: 1, pb: 1 }}
                              key={index}
                            >
                              <Grid xs={2}>
                                <div>
                                  <Typography
                                    style={{
                                      marginRight: "3px",
                                      marginLeft: "2px",
                                      overflowWrap: "break-word",
                                    }}
                                    variant="body1"
                                  >
                                    {" "}
                                    <span>{i.coverageCode}</span>
                                  </Typography>
                                </div>
                              </Grid>
                              <Grid xs={2}>
                                <div>
                                  <Typography
                                    style={{
                                      marginRight: "3px",
                                      marginLeft: "2px",
                                      overflowWrap: "break-word",
                                    }}
                                    variant="body1"
                                  >
                                    {" "}
                                    <span>{i.coverage}</span>
                                  </Typography>
                                </div>
                              </Grid>
                              <Grid xs={2}>
                                <div>
                                  <Typography
                                    style={{
                                      marginRight: "3px",
                                      marginLeft: "2px",
                                      overflowWrap: "break-word",
                                    }}
                                    variant="body1"
                                  >
                                    {i.payerName}
                                  </Typography>
                                </div>
                              </Grid>
                              <Grid xs={2}>
                                <div>
                                  <Typography
                                    style={{
                                      marginRight: "3px",
                                      marginLeft: "2px",
                                      overflowWrap: "break-word",
                                    }}
                                    variant="body1"
                                  >
                                    {i.phone}
                                  </Typography>
                                </div>
                              </Grid>
                              <Grid xs={2}>
                                <div>
                                  <Typography
                                    style={{
                                      marginRight: "3px",
                                      marginLeft: "2px",
                                      overflowWrap: "break-word",
                                    }}
                                    variant="body1"
                                  >
                                    {i.validatationStatus}
                                  </Typography>
                                </div>
                              </Grid>
                            </Grid>
                          );
                        }
                      )}
                    </Card>
                  </CardContent>
                </Card>
              )}
          </div>
        </div>

        {(data?.data?.coverageBenefits?.length > 0 ||
          data?.data?.insurances?.length > 0) && (
          <Card sx={{ mt: 2 }}>
            <CardContent>
              <div className="w-full my-4">
                <div>
                  {/* <h 1  m-0 sm:mx-4 p-2  className="text-2xl font-medium my-2 text-slate-600">
            Benefit Details
          </h1> */}
                  <Typography sx={{ fontWeight: "bold" }} gutterBottom>
                    Benefit Summary
                  </Typography>
                  <Accordion
                    expanded={expandedsum}
                    onChange={handleChangesum(
                      `${data?.data?.patientInsurance?.payerName}`
                    )}
                  >
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="panel1bh-content"
                      id="panel1bh-header"
                      style={{ background: "#f1f1f1" }}
                    >
                      <Typography
                        component="div"
                        gutterBottom
                        sx={{
                          width: "73%",
                          flexShrink: 0,
                          fontWeight: "bold",
                        }}
                        variant="body2"
                      >
                        {data?.data?.patientInsurance?.priority} Insurance Payer
                        ({data?.data?.patientInsurance?.payerName})
                      </Typography>
                      <Typography
                        component="div"
                        gutterBottom
                        sx={{
                          width: "20%",
                          flexShrink: 0,
                          fontWeight: "bold",
                        }}
                        variant="body2"
                      >
                        Service Types ({" All "})
                      </Typography>
                    </AccordionSummary>
                    <Grid container className="my-2" sx={{ pl: 2 }}>
                      <Grid xs={6}>
                        <Grid container className="my-2">
                          <Grid xs={5}>Payer Id</Grid>
                          <Grid xs={5}>
                            <Grid container>
                              <Stack direction="row" spacing={1}>
                                <div>:</div>
                                <div>
                                  <Typography
                                    style={{ overflowWrap: "break-word" }}
                                    variant="body1"
                                  >
                                    {data?.data?.patientInsurance?.payerId}
                                  </Typography>
                                </div>
                              </Stack>
                            </Grid>
                          </Grid>
                        </Grid>
                        <Grid container className="my-2">
                          <Grid xs={5}>Insurance Type</Grid>
                          <Grid xs={5}>
                            <Grid container>
                              <Stack direction="row" spacing={1}>
                                <div>:</div>
                                <div>
                                  <Typography
                                    style={{ overflowWrap: "break-word" }}
                                    variant="body1"
                                  >
                                    {
                                      data?.data?.patientInsurance
                                        ?.insuranceType
                                    }
                                  </Typography>
                                </div>
                              </Stack>
                            </Grid>
                          </Grid>
                        </Grid>

                        <Grid container className="my-2">
                          <Grid xs={5}>Subscriber/Member ID</Grid>
                          <Grid xs={5}>
                            <Grid container>
                              <Stack direction="row" spacing={1}>
                                <div>:</div>
                                <div>
                                  <Typography
                                    style={{ overflowWrap: "break-word" }}
                                    variant="body1"
                                  >
                                    {data?.data?.patientInsurance?.subscriberId}
                                  </Typography>
                                </div>
                              </Stack>
                            </Grid>
                          </Grid>
                        </Grid>
                        <Grid container className="my-2">
                          <Grid xs={5}>Group No</Grid>
                          <Grid xs={5}>
                            <Grid container>
                              <Stack direction="row" spacing={1}>
                                <div>:</div>
                                <div>
                                  <Typography
                                    style={{ overflowWrap: "break-word" }}
                                    variant="body1"
                                  >
                                    {data?.data?.patientInsurance?.groupNumber}
                                  </Typography>
                                </div>
                              </Stack>
                            </Grid>
                          </Grid>
                        </Grid>

                        {/* <Grid container className="my-2">
                <Grid xs={5}>Provider Network Status</Grid>
                <Grid xs={5}>
                  <Grid container>
                    <Stack direction="row" spacing={1}>
                      <div>:</div>
                      <div><Typography style={{ overflowWrap: "break-word" }} variant="body1">
                      {BindInsuranceDetails(4)}
                        </Typography></div>
                    </Stack>
                  </Grid>
                </Grid>
              </Grid> */}
                        <Grid container className="my-2">
                          <Grid xs={5}>Deductible On </Grid>
                          <Grid xs={5}>
                            <Grid container>
                              <Stack direction="row" spacing={1}>
                                <div>:</div>
                                <div>
                                  <Typography
                                    style={{ overflowWrap: "break-word" }}
                                    variant="body1"
                                  >
                                    {BindInsuranceOtherDetails(
                                      1,
                                      "",
                                      data?.data
                                    )}
                                  </Typography>
                                </div>
                              </Stack>
                            </Grid>
                          </Grid>
                        </Grid>
                      </Grid>
                      <Grid xs={6}>
                        <Grid container className="my-2">
                          <Grid xs={5}>Plan Type</Grid>
                          <Grid xs={5}>
                            <Grid container>
                              <Stack direction="row" spacing={1}>
                                <div>:</div>
                                <div>
                                  <Typography
                                    style={{ overflowWrap: "break-word" }}
                                    variant="body1"
                                  >
                                    {
                                      data?.data?.patientInsurance
                                        ?.rteInsuranceType
                                    }
                                  </Typography>
                                </div>
                              </Stack>
                            </Grid>
                          </Grid>
                        </Grid>
                        <Grid container className="my-2">
                          <Grid xs={5}>Plan Name</Grid>
                          <Grid xs={5}>
                            <Grid container>
                              <Stack direction="row" spacing={1}>
                                <div>:</div>
                                <div>
                                  <Typography
                                    style={{ overflowWrap: "break-word" }}
                                    variant="body1"
                                  >
                                    {
                                      data?.data?.patientInsurance
                                        ?.rtePlanTypeDescription
                                    }
                                  </Typography>
                                </div>
                              </Stack>
                            </Grid>
                          </Grid>
                        </Grid>
                        <Grid container className="my-2">
                          <Grid xs={5}>Coverage Status</Grid>
                          <Grid xs={5}>
                            <Grid container>
                              <Stack direction="row" spacing={1}>
                                <div>:</div>
                                <div>
                                  <Typography
                                    style={{ overflowWrap: "break-word" }}
                                    variant="body1"
                                  >
                                    {data?.data?.eligibilityStatus}
                                  </Typography>
                                </div>
                              </Stack>
                            </Grid>
                          </Grid>
                        </Grid>

                        <Grid container className="my-2">
                          <Grid xs={5}>Eligibility Dates</Grid>
                          <Grid xs={5}>
                            <Grid container>
                              <Stack direction="row" spacing={1}>
                                <div>:</div>
                                <div>
                                  <Typography
                                    style={{ overflowWrap: "break-word" }}
                                    variant="body1"
                                  ></Typography>
                                </div>
                              </Stack>
                            </Grid>
                          </Grid>
                        </Grid>
                        <Grid container className="my-2">
                          <Grid xs={5}>Primary Care Physician (PCP)</Grid>
                          <Grid xs={5}>
                            <Grid container>
                              <Stack direction="row" spacing={1}>
                                <div>:</div>
                                <div>
                                  <Typography
                                    style={{ overflowWrap: "break-word" }}
                                    variant="body1"
                                  >
                                    {BindInsuranceOtherDetails(
                                      3,
                                      "PCP",
                                      data?.data
                                    )}
                                  </Typography>
                                </div>
                              </Stack>
                            </Grid>
                          </Grid>
                        </Grid>
                        <Grid container className="my-2">
                          <Grid xs={5}>Additional Coverage Detected (COB)</Grid>
                          <Grid xs={5}>
                            <Grid container>
                              <Stack direction="row" spacing={1}>
                                <div>:</div>
                                <div>
                                  <Typography
                                    style={{ overflowWrap: "break-word" }}
                                    variant="body1"
                                  >
                                    {data?.data?.insurances?.map((i) => {
                                      return (
                                        <Grid key={i.payerName}>
                                          {i.payerName}
                                        </Grid>
                                      );
                                    })}
                                  </Typography>
                                </div>
                              </Stack>
                            </Grid>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>

                    <Grid container>
                      <Grid
                        xs={6}
                        sx={{
                          paddingRight: "10px",
                          paddingLeft: "10px",
                          paddingBottom: "5px",
                        }}
                      >
                        <Card>
                          <CardContent sx={{ background: "#f4f4f4" }}>
                            <Typography
                              variant="overline"
                              sx={{ fontWeight: 600 }}
                              gutterBottom
                            >
                              In-Network
                            </Typography>
                            <Divider />
                            <Grid container className="my-2">
                              <Grid xs={5}>Co-Insurance</Grid>
                              <Grid xs={5}>
                                <Grid container>
                                  <Stack direction="row" spacing={1}>
                                    <div>:</div>
                                    <div>
                                      <Typography
                                        style={{ overflowWrap: "break-word" }}
                                        variant="body1"
                                      >
                                        {BindInsuranceOtherDetails(
                                          3,
                                          1,
                                          data?.data
                                        )}
                                      </Typography>
                                    </div>
                                  </Stack>
                                </Grid>
                              </Grid>
                            </Grid>
                            <Grid container className="my-2">
                              <Grid xs={5}>Physician Office Visit Co-pay</Grid>
                              <Grid xs={5}>
                                <Grid container>
                                  <Stack direction="row" spacing={1}>
                                    <div>:</div>
                                    <div>
                                      <Typography
                                        style={{ overflowWrap: "break-word" }}
                                        variant="body1"
                                      >
                                        {BindInsuranceOtherDetails(
                                          3,
                                          2,
                                          data?.data
                                        )}
                                      </Typography>
                                    </div>
                                  </Stack>
                                </Grid>
                              </Grid>
                            </Grid>
                            <Grid container className="my-2">
                              <Grid xs={5}>Specialist Co-pay</Grid>
                              <Grid xs={5}>
                                <Grid container>
                                  <Stack direction="row" spacing={1}>
                                    <div>:</div>
                                    <div>
                                      <Typography
                                        style={{ overflowWrap: "break-word" }}
                                        variant="body1"
                                      >
                                        {BindInsuranceOtherDetails(
                                          3,
                                          3,
                                          data?.data
                                        )}
                                      </Typography>
                                    </div>
                                  </Stack>
                                </Grid>
                              </Grid>
                            </Grid>
                            <Grid container className="my-2">
                              <Grid xs={5}>Telemedicine Co-pay</Grid>
                              <Grid xs={5}>
                                <Grid container>
                                  <Stack direction="row" spacing={1}>
                                    <div>:</div>
                                    <div>
                                      <Typography
                                        style={{ overflowWrap: "break-word" }}
                                        variant="body1"
                                      >
                                        {BindInsuranceOtherDetails(
                                          3,
                                          4,
                                          data?.data
                                        )}
                                      </Typography>
                                    </div>
                                  </Stack>
                                </Grid>
                              </Grid>
                            </Grid>
                            <Grid container className="my-2">
                              <Grid xs={5}>Behavioral Health Co-pay</Grid>
                              <Grid xs={5}>
                                <Grid container>
                                  <Stack direction="row" spacing={1}>
                                    <div>:</div>
                                    <div>
                                      <Typography
                                        style={{ overflowWrap: "break-word" }}
                                        variant="body1"
                                      >
                                        {BindInsuranceOtherDetails(
                                          3,
                                          5,
                                          data?.data
                                        )}
                                      </Typography>
                                    </div>
                                  </Stack>
                                </Grid>
                              </Grid>
                            </Grid>
                            <Grid container className="my-2">
                              <Grid xs={5}>Individual Deductibles </Grid>
                              <Grid xs={5}>
                                <Grid container>
                                  <Stack direction="row" spacing={1}>
                                    <div>:</div>
                                    <div>
                                      <Typography
                                        style={{ overflowWrap: "break-word" }}
                                        variant="body1"
                                      >
                                        {BindInsuranceOtherDetails(
                                          3,
                                          6,
                                          data?.data
                                        )}
                                      </Typography>
                                    </div>
                                  </Stack>
                                </Grid>
                              </Grid>
                            </Grid>
                            <Grid container className="my-2">
                              <Grid xs={5}>Family Deductibles</Grid>
                              <Grid xs={5}>
                                <Grid container>
                                  <Stack direction="row" spacing={1}>
                                    <div>:</div>
                                    <div>
                                      <Typography
                                        style={{ overflowWrap: "break-word" }}
                                        variant="body1"
                                      >
                                        {BindInsuranceOtherDetails(
                                          3,
                                          7,
                                          data?.data
                                        )}
                                      </Typography>
                                    </div>
                                  </Stack>
                                </Grid>
                              </Grid>
                            </Grid>
                            <Grid container className="my-2">
                              <Grid xs={5}>Max Out-of-Pocket Individual</Grid>
                              <Grid xs={5}>
                                <Grid container>
                                  <Stack direction="row" spacing={1}>
                                    <div>:</div>
                                    <div>
                                      <Typography
                                        style={{ overflowWrap: "break-word" }}
                                        variant="body1"
                                      >
                                        {BindInsuranceOtherDetails(
                                          3,
                                          8,
                                          data?.data
                                        )}
                                      </Typography>
                                    </div>
                                  </Stack>
                                </Grid>
                              </Grid>
                            </Grid>
                            <Grid container className="my-2">
                              <Grid xs={5}>Max Out-of-Pocket Family</Grid>
                              <Grid xs={5}>
                                <Grid container>
                                  <Stack direction="row" spacing={1}>
                                    <div>:</div>
                                    <div>
                                      <Typography
                                        style={{ overflowWrap: "break-word" }}
                                        variant="body1"
                                      >
                                        {BindInsuranceOtherDetails(
                                          3,
                                          9,
                                          data?.data
                                        )}
                                      </Typography>
                                    </div>
                                  </Stack>
                                </Grid>
                              </Grid>
                            </Grid>
                          </CardContent>
                        </Card>
                      </Grid>

                      <Grid
                        xs={6}
                        sx={{ paddingRight: "10px", paddingBottom: "5px" }}
                      >
                        <Card>
                          <CardContent sx={{ background: "#f4f4f4" }}>
                            <Typography
                              sx={{ fontWeight: 600 }}
                              variant="overline"
                              gutterBottom
                            >
                              Out-of-Network
                            </Typography>{" "}
                            <Divider />
                            <Grid container className="my-2">
                              <Grid xs={5}>Co-Insurance</Grid>
                              <Grid xs={5}>
                                <Grid container>
                                  <Stack direction="row" spacing={1}>
                                    <div>:</div>
                                    <div>
                                      <Typography
                                        style={{ overflowWrap: "break-word" }}
                                        variant="body1"
                                      >
                                        {BindInsuranceOtherDetails(
                                          4,
                                          1,
                                          data?.data
                                        )}
                                      </Typography>
                                    </div>
                                  </Stack>
                                </Grid>
                              </Grid>
                            </Grid>
                            <Grid container className="my-2">
                              <Grid xs={5}>Physician Office Visit Co-pay</Grid>
                              <Grid xs={5}>
                                <Grid container>
                                  <Stack direction="row" spacing={1}>
                                    <div>:</div>
                                    <div>
                                      <Typography
                                        style={{ overflowWrap: "break-word" }}
                                        variant="body1"
                                      >
                                        {BindInsuranceOtherDetails(
                                          4,
                                          2,
                                          data?.data
                                        )}
                                      </Typography>
                                    </div>
                                  </Stack>
                                </Grid>
                              </Grid>
                            </Grid>
                            <Grid container className="my-2">
                              <Grid xs={5}>Specialist Co-pay</Grid>
                              <Grid xs={5}>
                                <Grid container>
                                  <Stack direction="row" spacing={1}>
                                    <div>:</div>
                                    <div>
                                      <Typography
                                        style={{ overflowWrap: "break-word" }}
                                        variant="body1"
                                      >
                                        {BindInsuranceOtherDetails(
                                          4,
                                          3,
                                          data?.data
                                        )}
                                      </Typography>
                                    </div>
                                  </Stack>
                                </Grid>
                              </Grid>
                            </Grid>
                            <Grid container className="my-2">
                              <Grid xs={5}>Telemedicine Co-pay</Grid>
                              <Grid xs={5}>
                                <Grid container>
                                  <Stack direction="row" spacing={1}>
                                    <div>:</div>
                                    <div>
                                      <Typography
                                        style={{ overflowWrap: "break-word" }}
                                        variant="body1"
                                      >
                                        {BindInsuranceOtherDetails(
                                          4,
                                          4,
                                          data?.data
                                        )}
                                      </Typography>
                                    </div>
                                  </Stack>
                                </Grid>
                              </Grid>
                            </Grid>
                            <Grid container className="my-2">
                              <Grid xs={5}>Behavioral Health Co-pay</Grid>
                              <Grid xs={5}>
                                <Grid container>
                                  <Stack direction="row" spacing={1}>
                                    <div>:</div>
                                    <div>
                                      <Typography
                                        style={{ overflowWrap: "break-word" }}
                                        variant="body1"
                                      >
                                        {BindInsuranceOtherDetails(
                                          4,
                                          5,
                                          data?.data
                                        )}
                                      </Typography>
                                    </div>
                                  </Stack>
                                </Grid>
                              </Grid>
                            </Grid>
                            <Grid container className="my-2">
                              <Grid xs={5}>Individual Deductibles </Grid>
                              <Grid xs={5}>
                                <Grid container>
                                  <Stack direction="row" spacing={1}>
                                    <div>:</div>
                                    <div>
                                      <Typography
                                        style={{ overflowWrap: "break-word" }}
                                        variant="body1"
                                      >
                                        {BindInsuranceOtherDetails(
                                          4,
                                          6,
                                          data?.data
                                        )}
                                      </Typography>
                                    </div>
                                  </Stack>
                                </Grid>
                              </Grid>
                            </Grid>
                            <Grid container className="my-2">
                              <Grid xs={5}>Family Deductibles</Grid>
                              <Grid xs={5}>
                                <Grid container>
                                  <Stack direction="row" spacing={1}>
                                    <div>:</div>
                                    <div>
                                      <Typography
                                        style={{ overflowWrap: "break-word" }}
                                        variant="body1"
                                      >
                                        {BindInsuranceOtherDetails(
                                          4,
                                          7,
                                          data?.data
                                        )}
                                      </Typography>
                                    </div>
                                  </Stack>
                                </Grid>
                              </Grid>
                            </Grid>
                            <Grid container className="my-2">
                              <Grid xs={5}>Max Out-of-Pocket Individual</Grid>
                              <Grid xs={5}>
                                <Grid container>
                                  <Stack direction="row" spacing={1}>
                                    <div>:</div>
                                    <div>
                                      <Typography
                                        style={{ overflowWrap: "break-word" }}
                                        variant="body1"
                                      >
                                        {BindInsuranceOtherDetails(
                                          4,
                                          8,
                                          data?.data
                                        )}
                                      </Typography>
                                    </div>
                                  </Stack>
                                </Grid>
                              </Grid>
                            </Grid>
                            <Grid container className="my-2">
                              <Grid xs={5}>Max Out-of-Pocket Family</Grid>
                              <Grid xs={5}>
                                <Grid container>
                                  <Stack direction="row" spacing={1}>
                                    <div>:</div>
                                    <div>
                                      <Typography
                                        style={{ overflowWrap: "break-word" }}
                                        variant="body1"
                                      >
                                        {" "}
                                        {BindInsuranceOtherDetails(
                                          4,
                                          9,
                                          data?.data
                                        )}
                                      </Typography>
                                    </div>
                                  </Stack>
                                </Grid>
                              </Grid>
                            </Grid>
                          </CardContent>
                        </Card>
                      </Grid>
                    </Grid>

                    {stepServiceTypeCodes?.map((serviceType, index) => {
                      return (
                        <Accordion
                          key={index}
                          expanded={expandedsum}
                          onChange={handleChangesum(
                            `${data?.data?.patientInsurance?.payerName}`
                          )}
                        >
                          <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel1bh-content"
                            id="panel1bh-header"
                            style={{ background: "#f1f0f0" }}
                          >
                            <Typography
                              component="div"
                              gutterBottom
                              sx={{
                                width: "73%",
                                flexShrink: 0,
                                fontWeight: "bold",
                              }}
                              variant="body2"
                            >
                              {serviceType}
                            </Typography>
                            <Typography
                              component="div"
                              gutterBottom
                              sx={{
                                width: "20%",
                                flexShrink: 0,
                                fontWeight: "bold",
                              }}
                              variant="body2"
                            >
                              Service Types ({serviceType}){" "}
                              <span
                                style={{
                                  paddingLeft: "10px",
                                  color: "green",
                                  fontSize: "12px",
                                }}
                              >
                                {BindInsuranceOtherDetailsServiceName(
                                  3,
                                  1,
                                  data?.data,
                                  serviceType
                                )}
                              </span>
                            </Typography>
                          </AccordionSummary>

                          <Grid container>
                            <Grid
                              xs={6}
                              sx={{
                                paddingRight: "10px",
                                paddingLeft: "10px",
                                paddingBottom: "5px",
                              }}
                            >
                              <Card>
                                <CardContent sx={{ background: "#f4f4f4" }}>
                                  <Typography
                                    variant="overline"
                                    sx={{ fontWeight: 600 }}
                                    gutterBottom
                                  >
                                    In-Network
                                  </Typography>
                                  <Divider />
                                  <Grid container className="my-2">
                                    <Grid xs={5}>Co-Insurance</Grid>
                                    <Grid xs={5}>
                                      <Grid container>
                                        <Stack direction="row" spacing={1}>
                                          <div>:</div>
                                          <div>
                                            <Typography
                                              style={{
                                                overflowWrap: "break-word",
                                              }}
                                              variant="body1"
                                            >
                                              {BindInsuranceOtherDetails(
                                                3,
                                                1,
                                                data?.data,
                                                serviceType
                                              )}
                                            </Typography>
                                          </div>
                                        </Stack>
                                      </Grid>
                                    </Grid>
                                  </Grid>
                                  <Grid container className="my-2">
                                    <Grid xs={5}>
                                      Physician Office Visit Co-pay
                                    </Grid>
                                    <Grid xs={5}>
                                      <Grid container>
                                        <Stack direction="row" spacing={1}>
                                          <div>:</div>
                                          <div>
                                            <Typography
                                              style={{
                                                overflowWrap: "break-word",
                                              }}
                                              variant="body1"
                                            >
                                              {BindInsuranceOtherDetails(
                                                3,
                                                2,
                                                data?.data,
                                                serviceType
                                              )}
                                            </Typography>
                                          </div>
                                        </Stack>
                                      </Grid>
                                    </Grid>
                                  </Grid>
                                  <Grid container className="my-2">
                                    <Grid xs={5}>Specialist Co-pay</Grid>
                                    <Grid xs={5}>
                                      <Grid container>
                                        <Stack direction="row" spacing={1}>
                                          <div>:</div>
                                          <div>
                                            <Typography
                                              style={{
                                                overflowWrap: "break-word",
                                              }}
                                              variant="body1"
                                            >
                                              {BindInsuranceOtherDetails(
                                                3,
                                                3,
                                                data?.data,
                                                serviceType
                                              )}
                                            </Typography>
                                          </div>
                                        </Stack>
                                      </Grid>
                                    </Grid>
                                  </Grid>
                                  <Grid container className="my-2">
                                    <Grid xs={5}>Telemedicine Co-pay</Grid>
                                    <Grid xs={5}>
                                      <Grid container>
                                        <Stack direction="row" spacing={1}>
                                          <div>:</div>
                                          <div>
                                            <Typography
                                              style={{
                                                overflowWrap: "break-word",
                                              }}
                                              variant="body1"
                                            >
                                              {BindInsuranceOtherDetails(
                                                3,
                                                4,
                                                data?.data
                                              )}
                                            </Typography>
                                          </div>
                                        </Stack>
                                      </Grid>
                                    </Grid>
                                  </Grid>
                                  <Grid container className="my-2">
                                    <Grid xs={5}>Behavioral Health Co-pay</Grid>
                                    <Grid xs={5}>
                                      <Grid container>
                                        <Stack direction="row" spacing={1}>
                                          <div>:</div>
                                          <div>
                                            <Typography
                                              style={{
                                                overflowWrap: "break-word",
                                              }}
                                              variant="body1"
                                            >
                                              {BindInsuranceOtherDetails(
                                                3,
                                                5,
                                                data?.data,
                                                serviceType
                                              )}
                                            </Typography>
                                          </div>
                                        </Stack>
                                      </Grid>
                                    </Grid>
                                  </Grid>
                                  <Grid container className="my-2">
                                    <Grid xs={5}>Individual Deductibles </Grid>
                                    <Grid xs={5}>
                                      <Grid container>
                                        <Stack direction="row" spacing={1}>
                                          <div>:</div>
                                          <div>
                                            <Typography
                                              style={{
                                                overflowWrap: "break-word",
                                              }}
                                              variant="body1"
                                            >
                                              {BindInsuranceOtherDetails(
                                                3,
                                                6,
                                                data?.data,
                                                serviceType
                                              )}
                                            </Typography>
                                          </div>
                                        </Stack>
                                      </Grid>
                                    </Grid>
                                  </Grid>
                                  <Grid container className="my-2">
                                    <Grid xs={5}>Family Deductibles</Grid>
                                    <Grid xs={5}>
                                      <Grid container>
                                        <Stack direction="row" spacing={1}>
                                          <div>:</div>
                                          <div>
                                            <Typography
                                              style={{
                                                overflowWrap: "break-word",
                                              }}
                                              variant="body1"
                                            >
                                              {BindInsuranceOtherDetails(
                                                3,
                                                7,
                                                data?.data,
                                                serviceType
                                              )}
                                            </Typography>
                                          </div>
                                        </Stack>
                                      </Grid>
                                    </Grid>
                                  </Grid>
                                  <Grid container className="my-2">
                                    <Grid xs={5}>
                                      Max Out-of-Pocket Individual
                                    </Grid>
                                    <Grid xs={5}>
                                      <Grid container>
                                        <Stack direction="row" spacing={1}>
                                          <div>:</div>
                                          <div>
                                            <Typography
                                              style={{
                                                overflowWrap: "break-word",
                                              }}
                                              variant="body1"
                                            >
                                              {BindInsuranceOtherDetails(
                                                3,
                                                8,
                                                data?.data,
                                                serviceType
                                              )}
                                            </Typography>
                                          </div>
                                        </Stack>
                                      </Grid>
                                    </Grid>
                                  </Grid>
                                  <Grid container className="my-2">
                                    <Grid xs={5}>Max Out-of-Pocket Family</Grid>
                                    <Grid xs={5}>
                                      <Grid container>
                                        <Stack direction="row" spacing={1}>
                                          <div>:</div>
                                          <div>
                                            <Typography
                                              style={{
                                                overflowWrap: "break-word",
                                              }}
                                              variant="body1"
                                            >
                                              {BindInsuranceOtherDetails(
                                                3,
                                                9,
                                                data?.data,
                                                serviceType
                                              )}
                                            </Typography>
                                          </div>
                                        </Stack>
                                      </Grid>
                                    </Grid>
                                  </Grid>
                                </CardContent>
                              </Card>
                            </Grid>

                            <Grid
                              xs={6}
                              sx={{
                                paddingRight: "10px",
                                paddingBottom: "5px",
                              }}
                            >
                              <Card>
                                <CardContent sx={{ background: "#f4f4f4" }}>
                                  <Typography
                                    sx={{ fontWeight: 600 }}
                                    variant="overline"
                                    gutterBottom
                                  >
                                    Out-of-Network
                                  </Typography>{" "}
                                  <Divider />
                                  <Grid container className="my-2">
                                    <Grid xs={5}>Co-Insurance</Grid>
                                    <Grid xs={5}>
                                      <Grid container>
                                        <Stack direction="row" spacing={1}>
                                          <div>:</div>
                                          <div>
                                            <Typography
                                              style={{
                                                overflowWrap: "break-word",
                                              }}
                                              variant="body1"
                                            >
                                              {BindInsuranceOtherDetails(
                                                4,
                                                1,
                                                data?.data,
                                                serviceType
                                              )}
                                            </Typography>
                                          </div>
                                        </Stack>
                                      </Grid>
                                    </Grid>
                                  </Grid>
                                  <Grid container className="my-2">
                                    <Grid xs={5}>
                                      Physician Office Visit Co-pay
                                    </Grid>
                                    <Grid xs={5}>
                                      <Grid container>
                                        <Stack direction="row" spacing={1}>
                                          <div>:</div>
                                          <div>
                                            <Typography
                                              style={{
                                                overflowWrap: "break-word",
                                              }}
                                              variant="body1"
                                            >
                                              {BindInsuranceOtherDetails(
                                                4,
                                                2,
                                                data?.data,
                                                serviceType
                                              )}
                                            </Typography>
                                          </div>
                                        </Stack>
                                      </Grid>
                                    </Grid>
                                  </Grid>
                                  <Grid container className="my-2">
                                    <Grid xs={5}>Specialist Co-pay</Grid>
                                    <Grid xs={5}>
                                      <Grid container>
                                        <Stack direction="row" spacing={1}>
                                          <div>:</div>
                                          <div>
                                            <Typography
                                              style={{
                                                overflowWrap: "break-word",
                                              }}
                                              variant="body1"
                                            >
                                              {BindInsuranceOtherDetails(
                                                4,
                                                3,
                                                data?.data,
                                                serviceType
                                              )}
                                            </Typography>
                                          </div>
                                        </Stack>
                                      </Grid>
                                    </Grid>
                                  </Grid>
                                  <Grid container className="my-2">
                                    <Grid xs={5}>Telemedicine Co-pay</Grid>
                                    <Grid xs={5}>
                                      <Grid container>
                                        <Stack direction="row" spacing={1}>
                                          <div>:</div>
                                          <div>
                                            <Typography
                                              style={{
                                                overflowWrap: "break-word",
                                              }}
                                              variant="body1"
                                            >
                                              {BindInsuranceOtherDetails(
                                                4,
                                                4,
                                                data?.data,
                                                serviceType
                                              )}
                                            </Typography>
                                          </div>
                                        </Stack>
                                      </Grid>
                                    </Grid>
                                  </Grid>
                                  <Grid container className="my-2">
                                    <Grid xs={5}>Behavioral Health Co-pay</Grid>
                                    <Grid xs={5}>
                                      <Grid container>
                                        <Stack direction="row" spacing={1}>
                                          <div>:</div>
                                          <div>
                                            <Typography
                                              style={{
                                                overflowWrap: "break-word",
                                              }}
                                              variant="body1"
                                            >
                                              {BindInsuranceOtherDetails(
                                                4,
                                                5,
                                                data?.data,
                                                serviceType
                                              )}
                                            </Typography>
                                          </div>
                                        </Stack>
                                      </Grid>
                                    </Grid>
                                  </Grid>
                                  <Grid container className="my-2">
                                    <Grid xs={5}>Individual Deductibles </Grid>
                                    <Grid xs={5}>
                                      <Grid container>
                                        <Stack direction="row" spacing={1}>
                                          <div>:</div>
                                          <div>
                                            <Typography
                                              style={{
                                                overflowWrap: "break-word",
                                              }}
                                              variant="body1"
                                            >
                                              {BindInsuranceOtherDetails(
                                                4,
                                                6,
                                                data?.data,
                                                serviceType
                                              )}
                                            </Typography>
                                          </div>
                                        </Stack>
                                      </Grid>
                                    </Grid>
                                  </Grid>
                                  <Grid container className="my-2">
                                    <Grid xs={5}>Family Deductibles</Grid>
                                    <Grid xs={5}>
                                      <Grid container>
                                        <Stack direction="row" spacing={1}>
                                          <div>:</div>
                                          <div>
                                            <Typography
                                              style={{
                                                overflowWrap: "break-word",
                                              }}
                                              variant="body1"
                                            >
                                              {BindInsuranceOtherDetails(
                                                4,
                                                7,
                                                data?.data,
                                                serviceType
                                              )}
                                            </Typography>
                                          </div>
                                        </Stack>
                                      </Grid>
                                    </Grid>
                                  </Grid>
                                  <Grid container className="my-2">
                                    <Grid xs={5}>
                                      Max Out-of-Pocket Individual
                                    </Grid>
                                    <Grid xs={5}>
                                      <Grid container>
                                        <Stack direction="row" spacing={1}>
                                          <div>:</div>
                                          <div>
                                            <Typography
                                              style={{
                                                overflowWrap: "break-word",
                                              }}
                                              variant="body1"
                                            >
                                              {BindInsuranceOtherDetails(
                                                4,
                                                8,
                                                data?.data,
                                                serviceType
                                              )}
                                            </Typography>
                                          </div>
                                        </Stack>
                                      </Grid>
                                    </Grid>
                                  </Grid>
                                  <Grid container className="my-2">
                                    <Grid xs={5}>Max Out-of-Pocket Family</Grid>
                                    <Grid xs={5}>
                                      <Grid container>
                                        <Stack direction="row" spacing={1}>
                                          <div>:</div>
                                          <div>
                                            <Typography
                                              style={{
                                                overflowWrap: "break-word",
                                              }}
                                              variant="body1"
                                            >
                                              {" "}
                                              {BindInsuranceOtherDetails(
                                                4,
                                                9,
                                                data?.data,
                                                serviceType
                                              )}
                                            </Typography>
                                          </div>
                                        </Stack>
                                      </Grid>
                                    </Grid>
                                  </Grid>
                                </CardContent>
                              </Card>
                            </Grid>
                          </Grid>
                        </Accordion>
                      );
                    })}
                  </Accordion>

                  {data?.data?.insurances?.map((i, index) => {
                    return (
                      <Accordion
                        key={index + i.payerName}
                        expanded={expandedsumMul === `${i.payerName}`}
                        onChange={MultihandleChange(`${i.payerName}`)}
                      >
                        <AccordionSummary
                          expandIcon={<ExpandMoreIcon />}
                          aria-controls="panel1bh-content"
                          id="panel1bh-header"
                          style={{ background: "#f1f1f1" }}
                        >
                          <Typography
                            component="div"
                            gutterBottom
                            sx={{
                              width: "73%",
                              flexShrink: 0,
                              fontWeight: "bold",
                            }}
                            variant="body2"
                          >
                            {i.priority} ({i.payerName})
                          </Typography>
                          {/* <Typography variant="body2" sx={{ fontWeight: "bold" }}>
                    {i.coverageCode}
                  </Typography> */}
                        </AccordionSummary>
                        <AccordionDetails>
                          <Grid container className="my-2" sx={{ pl: 2 }}>
                            <Grid xs={6}>
                              <Grid container className="my-2">
                                <Grid xs={5}>Payer Id</Grid>
                                <Grid xs={5}>
                                  <Grid container>
                                    <Stack direction="row" spacing={1}>
                                      <div>:</div>
                                      <div>
                                        <Typography
                                          style={{ overflowWrap: "break-word" }}
                                          variant="body1"
                                        >
                                          {i?.payerId}
                                        </Typography>
                                      </div>
                                    </Stack>
                                  </Grid>
                                </Grid>
                              </Grid>
                              <Grid container className="my-2">
                                <Grid xs={5}>Insurance Type</Grid>
                                <Grid xs={5}>
                                  <Grid container>
                                    <Stack direction="row" spacing={1}>
                                      <div>:</div>
                                      <div>
                                        <Typography
                                          style={{ overflowWrap: "break-word" }}
                                          variant="body1"
                                        >
                                          {i.insuranceType}
                                        </Typography>
                                      </div>
                                    </Stack>
                                  </Grid>
                                </Grid>
                              </Grid>

                              <Grid container className="my-2">
                                <Grid xs={5}>Subscriber/Member ID</Grid>
                                <Grid xs={5}>
                                  <Grid container>
                                    <Stack direction="row" spacing={1}>
                                      <div>:</div>
                                      <div>
                                        <Typography
                                          style={{ overflowWrap: "break-word" }}
                                          variant="body1"
                                        >
                                          {i?.subscriberId}
                                        </Typography>
                                      </div>
                                    </Stack>
                                  </Grid>
                                </Grid>
                              </Grid>
                              <Grid container className="my-2">
                                <Grid xs={5}>Group No</Grid>
                                <Grid xs={5}>
                                  <Grid container>
                                    <Stack direction="row" spacing={1}>
                                      <div>:</div>
                                      <div>
                                        <Typography
                                          style={{ overflowWrap: "break-word" }}
                                          variant="body1"
                                        >
                                          {i.groupNumber}
                                        </Typography>
                                      </div>
                                    </Stack>
                                  </Grid>
                                </Grid>
                              </Grid>

                              {/* <Grid container className="my-2">
                <Grid xs={5}>Provider Network Status</Grid>
                <Grid xs={5}>
                  <Grid container>
                    <Stack direction="row" spacing={1}>
                      <div>:</div>
                      <div><Typography style={{ overflowWrap: "break-word" }} variant="body1">
                      {BindInsuranceDetails(4)}
                        </Typography></div>
                    </Stack>
                  </Grid>
                </Grid>
              </Grid> */}
                              <Grid container className="my-2">
                                <Grid xs={5}>Deductible On </Grid>
                                <Grid xs={5}>
                                  <Grid container>
                                    <Stack direction="row" spacing={1}>
                                      <div>:</div>
                                      <div>
                                        <Typography
                                          style={{ overflowWrap: "break-word" }}
                                          variant="body1"
                                        >
                                          {BindInsuranceOtherDetails(1, "", i)}
                                        </Typography>
                                      </div>
                                    </Stack>
                                  </Grid>
                                </Grid>
                              </Grid>
                            </Grid>
                            <Grid xs={6}>
                              <Grid container className="my-2">
                                <Grid xs={5}>Plan Type</Grid>
                                <Grid xs={5}>
                                  <Grid container>
                                    <Stack direction="row" spacing={1}>
                                      <div>:</div>
                                      <div>
                                        <Typography
                                          style={{ overflowWrap: "break-word" }}
                                          variant="body1"
                                        >
                                          {
                                            data?.data?.patientInsurance
                                              ?.rteInsuranceType
                                          }
                                        </Typography>
                                      </div>
                                    </Stack>
                                  </Grid>
                                </Grid>
                              </Grid>
                              <Grid container className="my-2">
                                <Grid xs={5}>Plan Name</Grid>
                                <Grid xs={5}>
                                  <Grid container>
                                    <Stack direction="row" spacing={1}>
                                      <div>:</div>
                                      <div>
                                        <Typography
                                          style={{ overflowWrap: "break-word" }}
                                          variant="body1"
                                        >
                                          {
                                            data?.data?.patientInsurance
                                              ?.rtePlanTypeDescription
                                          }
                                        </Typography>
                                      </div>
                                    </Stack>
                                  </Grid>
                                </Grid>
                              </Grid>
                              <Grid container className="my-2">
                                <Grid xs={5}>Coverage Status</Grid>
                                <Grid xs={5}>
                                  <Grid container>
                                    <Stack direction="row" spacing={1}>
                                      <div>:</div>
                                      <div>
                                        <Typography
                                          style={{ overflowWrap: "break-word" }}
                                          variant="body1"
                                        >
                                          {i.coverageStatus}
                                        </Typography>
                                      </div>
                                    </Stack>
                                  </Grid>
                                </Grid>
                              </Grid>

                              <Grid container className="my-2">
                                <Grid xs={5}>Eligibility Dates</Grid>
                                <Grid xs={5}>
                                  <Grid container>
                                    <Stack direction="row" spacing={1}>
                                      <div>:</div>
                                      <div>
                                        <Typography
                                          style={{ overflowWrap: "break-word" }}
                                          variant="body1"
                                        ></Typography>
                                      </div>
                                    </Stack>
                                  </Grid>
                                </Grid>
                              </Grid>
                              <Grid container className="my-2">
                                <Grid xs={5}>Primary Care Physician (PCP)</Grid>
                                <Grid xs={5}>
                                  <Grid container>
                                    <Stack direction="row" spacing={1}>
                                      <div>:</div>
                                      <div>
                                        <Typography
                                          style={{ overflowWrap: "break-word" }}
                                          variant="body1"
                                        >
                                          {BindInsuranceOtherDetails(
                                            3,
                                            "PCP",
                                            i
                                          )}
                                        </Typography>
                                      </div>
                                    </Stack>
                                  </Grid>
                                </Grid>
                              </Grid>
                              <Grid container className="my-2">
                                <Grid xs={5}>
                                  Additional Coverage Detected (COB)
                                </Grid>
                                <Grid xs={5}>
                                  <Grid container>
                                    <Stack direction="row" spacing={1}>
                                      <div>:</div>
                                      <div></div>
                                    </Stack>
                                  </Grid>
                                </Grid>
                              </Grid>
                            </Grid>
                            {i?.coverageErrors?.length > 0 && (
                              <Grid
                                container
                                className="my-2"
                                style={{
                                  background: "#fff5a1",
                                  fontWeight: "bold",
                                }}
                              >
                                <Grid xs={1}>Notes</Grid>
                                <Grid xs={10}>
                                  <Grid container>
                                    <Stack direction="row" spacing={1}>
                                      <div>:</div>
                                      <div>
                                        <Typography
                                          style={{
                                            overflowWrap: "break-word",
                                            fontWeight: "bold",
                                          }}
                                          variant="body1"
                                        >
                                          {i?.coverageErrors?.map((i) => {
                                            return (
                                              <Grid key={i.description}>
                                                {i.description}
                                              </Grid>
                                            );
                                          })}
                                        </Typography>
                                      </div>
                                    </Stack>
                                  </Grid>
                                </Grid>
                              </Grid>
                            )}
                          </Grid>
                          {i?.coverageBenefits?.length > 0 && (
                            <Grid container>
                              <Grid
                                xs={6}
                                sx={{
                                  paddingRight: "10px",
                                  paddingLeft: "10px",
                                  paddingBottom: "5px",
                                }}
                              >
                                <Card>
                                  <CardContent sx={{ background: "#f4f4f4" }}>
                                    <Typography
                                      variant="overline"
                                      sx={{ fontWeight: 600 }}
                                      gutterBottom
                                    >
                                      In-Network
                                    </Typography>
                                    <Divider />
                                    <Grid container className="my-2">
                                      <Grid xs={5}>Co-Insurance</Grid>
                                      <Grid xs={5}>
                                        <Grid container>
                                          <Stack direction="row" spacing={1}>
                                            <div>:</div>
                                            <div>
                                              <Typography
                                                style={{
                                                  overflowWrap: "break-word",
                                                }}
                                                variant="body1"
                                              >
                                                {BindInsuranceOtherDetails(
                                                  3,
                                                  1,
                                                  i
                                                )}
                                              </Typography>
                                            </div>
                                          </Stack>
                                        </Grid>
                                      </Grid>
                                    </Grid>
                                    <Grid container className="my-2">
                                      <Grid xs={5}>
                                        Physician Office Visit Co-pay
                                      </Grid>
                                      <Grid xs={5}>
                                        <Grid container>
                                          <Stack direction="row" spacing={1}>
                                            <div>:</div>
                                            <div>
                                              <Typography
                                                style={{
                                                  overflowWrap: "break-word",
                                                }}
                                                variant="body1"
                                              >
                                                {BindInsuranceOtherDetails(
                                                  3,
                                                  2,
                                                  i
                                                )}
                                              </Typography>
                                            </div>
                                          </Stack>
                                        </Grid>
                                      </Grid>
                                    </Grid>
                                    <Grid container className="my-2">
                                      <Grid xs={5}>Specialist Co-pay</Grid>
                                      <Grid xs={5}>
                                        <Grid container>
                                          <Stack direction="row" spacing={1}>
                                            <div>:</div>
                                            <div>
                                              <Typography
                                                style={{
                                                  overflowWrap: "break-word",
                                                }}
                                                variant="body1"
                                              >
                                                {BindInsuranceOtherDetails(
                                                  3,
                                                  3,
                                                  i
                                                )}
                                              </Typography>
                                            </div>
                                          </Stack>
                                        </Grid>
                                      </Grid>
                                    </Grid>
                                    <Grid container className="my-2">
                                      <Grid xs={5}>Telemedicine Co-pay</Grid>
                                      <Grid xs={5}>
                                        <Grid container>
                                          <Stack direction="row" spacing={1}>
                                            <div>:</div>
                                            <div>
                                              <Typography
                                                style={{
                                                  overflowWrap: "break-word",
                                                }}
                                                variant="body1"
                                              >
                                                {BindInsuranceOtherDetails(
                                                  3,
                                                  4,
                                                  i
                                                )}
                                              </Typography>
                                            </div>
                                          </Stack>
                                        </Grid>
                                      </Grid>
                                    </Grid>
                                    <Grid container className="my-2">
                                      <Grid xs={5}>
                                        Behavioral Health Co-pay
                                      </Grid>
                                      <Grid xs={5}>
                                        <Grid container>
                                          <Stack direction="row" spacing={1}>
                                            <div>:</div>
                                            <div>
                                              <Typography
                                                style={{
                                                  overflowWrap: "break-word",
                                                }}
                                                variant="body1"
                                              >
                                                {BindInsuranceOtherDetails(
                                                  3,
                                                  5,
                                                  i
                                                )}
                                              </Typography>
                                            </div>
                                          </Stack>
                                        </Grid>
                                      </Grid>
                                    </Grid>
                                    <Grid container className="my-2">
                                      <Grid xs={5}>
                                        Individual Deductibles 
                                      </Grid>
                                      <Grid xs={5}>
                                        <Grid container>
                                          <Stack direction="row" spacing={1}>
                                            <div>:</div>
                                            <div>
                                              <Typography
                                                style={{
                                                  overflowWrap: "break-word",
                                                }}
                                                variant="body1"
                                              >
                                                {BindInsuranceOtherDetails(
                                                  3,
                                                  6,
                                                  i
                                                )}
                                              </Typography>
                                            </div>
                                          </Stack>
                                        </Grid>
                                      </Grid>
                                    </Grid>
                                    <Grid container className="my-2">
                                      <Grid xs={5}>Family Deductibles</Grid>
                                      <Grid xs={5}>
                                        <Grid container>
                                          <Stack direction="row" spacing={1}>
                                            <div>:</div>
                                            <div>
                                              <Typography
                                                style={{
                                                  overflowWrap: "break-word",
                                                }}
                                                variant="body1"
                                              >
                                                {BindInsuranceOtherDetails(
                                                  3,
                                                  7,
                                                  i
                                                )}
                                              </Typography>
                                            </div>
                                          </Stack>
                                        </Grid>
                                      </Grid>
                                    </Grid>
                                    <Grid container className="my-2">
                                      <Grid xs={5}>
                                        Max Out-of-Pocket Individual
                                      </Grid>
                                      <Grid xs={5}>
                                        <Grid container>
                                          <Stack direction="row" spacing={1}>
                                            <div>:</div>
                                            <div>
                                              <Typography
                                                style={{
                                                  overflowWrap: "break-word",
                                                }}
                                                variant="body1"
                                              >
                                                {BindInsuranceOtherDetails(
                                                  3,
                                                  8,
                                                  i
                                                )}
                                              </Typography>
                                            </div>
                                          </Stack>
                                        </Grid>
                                      </Grid>
                                    </Grid>
                                    <Grid container className="my-2">
                                      <Grid xs={5}>
                                        Max Out-of-Pocket Family
                                      </Grid>
                                      <Grid xs={5}>
                                        <Grid container>
                                          <Stack direction="row" spacing={1}>
                                            <div>:</div>
                                            <div>
                                              <Typography
                                                style={{
                                                  overflowWrap: "break-word",
                                                }}
                                                variant="body1"
                                              >
                                                {BindInsuranceOtherDetails(
                                                  3,
                                                  9,
                                                  i
                                                )}
                                              </Typography>
                                            </div>
                                          </Stack>
                                        </Grid>
                                      </Grid>
                                    </Grid>
                                  </CardContent>
                                </Card>
                              </Grid>

                              <Grid
                                xs={6}
                                sx={{
                                  paddingRight: "10px",
                                  paddingBottom: "5px",
                                }}
                              >
                                <Card>
                                  <CardContent sx={{ background: "#f4f4f4" }}>
                                    <Typography
                                      sx={{ fontWeight: 600 }}
                                      variant="overline"
                                      gutterBottom
                                    >
                                      Out-of-Network
                                    </Typography>{" "}
                                    <Divider />
                                    <Grid container className="my-2">
                                      <Grid xs={5}>Co-Insurance</Grid>
                                      <Grid xs={5}>
                                        <Grid container>
                                          <Stack direction="row" spacing={1}>
                                            <div>:</div>
                                            <div>
                                              <Typography
                                                style={{
                                                  overflowWrap: "break-word",
                                                }}
                                                variant="body1"
                                              >
                                                {BindInsuranceOtherDetails(
                                                  4,
                                                  1,
                                                  i
                                                )}
                                              </Typography>
                                            </div>
                                          </Stack>
                                        </Grid>
                                      </Grid>
                                    </Grid>
                                    <Grid container className="my-2">
                                      <Grid xs={5}>
                                        Physician Office Visit Co-pay
                                      </Grid>
                                      <Grid xs={5}>
                                        <Grid container>
                                          <Stack direction="row" spacing={1}>
                                            <div>:</div>
                                            <div>
                                              <Typography
                                                style={{
                                                  overflowWrap: "break-word",
                                                }}
                                                variant="body1"
                                              >
                                                {BindInsuranceOtherDetails(
                                                  4,
                                                  2,
                                                  i
                                                )}
                                              </Typography>
                                            </div>
                                          </Stack>
                                        </Grid>
                                      </Grid>
                                    </Grid>
                                    <Grid container className="my-2">
                                      <Grid xs={5}>Specialist Co-pay</Grid>
                                      <Grid xs={5}>
                                        <Grid container>
                                          <Stack direction="row" spacing={1}>
                                            <div>:</div>
                                            <div>
                                              <Typography
                                                style={{
                                                  overflowWrap: "break-word",
                                                }}
                                                variant="body1"
                                              >
                                                {BindInsuranceOtherDetails(
                                                  4,
                                                  3,
                                                  i
                                                )}
                                              </Typography>
                                            </div>
                                          </Stack>
                                        </Grid>
                                      </Grid>
                                    </Grid>
                                    <Grid container className="my-2">
                                      <Grid xs={5}>Telemedicine Co-pay</Grid>
                                      <Grid xs={5}>
                                        <Grid container>
                                          <Stack direction="row" spacing={1}>
                                            <div>:</div>
                                            <div>
                                              <Typography
                                                style={{
                                                  overflowWrap: "break-word",
                                                }}
                                                variant="body1"
                                              >
                                                {BindInsuranceOtherDetails(
                                                  4,
                                                  4,
                                                  i
                                                )}
                                              </Typography>
                                            </div>
                                          </Stack>
                                        </Grid>
                                      </Grid>
                                    </Grid>
                                    <Grid container className="my-2">
                                      <Grid xs={5}>
                                        Behavioral Health Co-pay
                                      </Grid>
                                      <Grid xs={5}>
                                        <Grid container>
                                          <Stack direction="row" spacing={1}>
                                            <div>:</div>
                                            <div>
                                              <Typography
                                                style={{
                                                  overflowWrap: "break-word",
                                                }}
                                                variant="body1"
                                              >
                                                {BindInsuranceOtherDetails(
                                                  4,
                                                  5,
                                                  i
                                                )}
                                              </Typography>
                                            </div>
                                          </Stack>
                                        </Grid>
                                      </Grid>
                                    </Grid>
                                    <Grid container className="my-2">
                                      <Grid xs={5}>
                                        Individual Deductibles 
                                      </Grid>
                                      <Grid xs={5}>
                                        <Grid container>
                                          <Stack direction="row" spacing={1}>
                                            <div>:</div>
                                            <div>
                                              <Typography
                                                style={{
                                                  overflowWrap: "break-word",
                                                }}
                                                variant="body1"
                                              >
                                                {BindInsuranceOtherDetails(
                                                  4,
                                                  6,
                                                  i
                                                )}
                                              </Typography>
                                            </div>
                                          </Stack>
                                        </Grid>
                                      </Grid>
                                    </Grid>
                                    <Grid container className="my-2">
                                      <Grid xs={5}>Family Deductibles</Grid>
                                      <Grid xs={5}>
                                        <Grid container>
                                          <Stack direction="row" spacing={1}>
                                            <div>:</div>
                                            <div>
                                              <Typography
                                                style={{
                                                  overflowWrap: "break-word",
                                                }}
                                                variant="body1"
                                              >
                                                {BindInsuranceOtherDetails(
                                                  4,
                                                  7,
                                                  i
                                                )}
                                              </Typography>
                                            </div>
                                          </Stack>
                                        </Grid>
                                      </Grid>
                                    </Grid>
                                    <Grid container className="my-2">
                                      <Grid xs={5}>
                                        Max Out-of-Pocket Individual
                                      </Grid>
                                      <Grid xs={5}>
                                        <Grid container>
                                          <Stack direction="row" spacing={1}>
                                            <div>:</div>
                                            <div>
                                              <Typography
                                                style={{
                                                  overflowWrap: "break-word",
                                                }}
                                                variant="body1"
                                              >
                                                {BindInsuranceOtherDetails(
                                                  4,
                                                  8,
                                                  i
                                                )}
                                              </Typography>
                                            </div>
                                          </Stack>
                                        </Grid>
                                      </Grid>
                                    </Grid>
                                    <Grid container className="my-2">
                                      <Grid xs={5}>
                                        Max Out-of-Pocket Family
                                      </Grid>
                                      <Grid xs={5}>
                                        <Grid container>
                                          <Stack direction="row" spacing={1}>
                                            <div>:</div>
                                            <div>
                                              <Typography
                                                style={{
                                                  overflowWrap: "break-word",
                                                }}
                                                variant="body1"
                                              >
                                                {" "}
                                                {BindInsuranceOtherDetails(
                                                  4,
                                                  9,
                                                  i
                                                )}
                                              </Typography>
                                            </div>
                                          </Stack>
                                        </Grid>
                                      </Grid>
                                    </Grid>
                                  </CardContent>
                                </Card>
                              </Grid>
                            </Grid>
                          )}
                        </AccordionDetails>
                      </Accordion>
                    );
                  })}
                </div>
              </div>
            </CardContent>
          </Card>
        )}

        {data?.data?.coverageBenefits?.length > 0 && (
          <Card sx={{ mt: 2 }}>
            <CardContent>
              <div className="w-full my-4">
                <div>
                  {/* <h 1  m-0 sm:mx-4 p-2  className="text-2xl font-medium my-2 text-slate-600">
            Benefit Details
          </h1> */}
                  <Typography sx={{ fontWeight: "bold" }} gutterBottom>
                    Benefit Details
                  </Typography>
                  {/* <Typography
                  gutterBottom
                  variant="h6"
                  component="div"
                  mx={1}
                  sx={{ fontWeight: "bold" }}
                >
                  Benefit Details
                </Typography> */}
                  <Divider sx={{ mb: 2 }} />
                  {/* {data?.data?.eligibilityStatus != "" ? (
            <Typography
              gutterBottom
              variant="body2"
              component="div"
              sx={{ fontWeight: "medium" }}
              mx={1}
              my={2}
            >
              Eligibility Status : {data?.data?.eligibilityStatus}
            </Typography>
          ) : null} */}
                  {/* {data?.data?.eligibilityNotes != "" &&
          data?.data?.eligibilityNotes != null ? (
            <Typography
              gutterBottom
              variant="body2"
              component="div"
              sx={{ fontWeight: "medium" }}
              mx={1}
              my={2}
            >
              Notes : {data?.data?.eligibilityNotes}
            </Typography>
          ) : null} */}
                  <Accordion
                  // expanded={expanded === `${i.coverageCode}`}
                  // onChange={handleChange(`${i.coverage}`)}
                  >
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="PnlBenfitDetailsPrimary"
                      id="PnlBenfitDetailsPrimary-header"
                      style={{ background: "#FFFFE0" }}
                    >
                      <Typography
                        component="div"
                        gutterBottom
                        sx={{
                          width: "73%",
                          flexShrink: 0,
                          fontWeight: "bold",
                        }}
                        variant="body2"
                      >
                        {data?.data?.patientInsurance?.priority} Insurance Payer
                        ({data?.data?.patientInsurance?.payerName})
                      </Typography>
                      {/* <Typography variant="body2" sx={{ fontWeight: "bold" }}>
                    {i.coverageCode}
                  </Typography> */}
                    </AccordionSummary>
                    <AccordionDetails>
                      {data?.data?.coverageBenefits?.map((i, index) => {
                        return (
                          <Accordion
                            key={index}
                            // expanded={expanded === `${i.coverageCode}`}
                            // onChange={handleChange(`${i.coverage}`)}
                          >
                            <AccordionSummary
                              expandIcon={<ExpandMoreIcon />}
                              aria-controls="panel1bh-content"
                              id="panel1bh-header"
                              style={{ background: "#f1f1f1" }}
                            >
                              <Typography
                                component="div"
                                gutterBottom
                                sx={{
                                  width: "73%",
                                  flexShrink: 0,
                                  fontWeight: "bold",
                                }}
                                variant="body2"
                              >
                                {BindServiceTypesCodes(i.coverage)} :{" "}
                                {i.coverageCode}
                              </Typography>
                              {/* <Typography variant="body2" sx={{ fontWeight: "bold" }}>
                    {i.coverageCode}
                  </Typography> */}
                            </AccordionSummary>
                            <AccordionDetails>
                              <TableContainer>
                                {/* <TableContainer component={Paper}> */}
                                <Table
                                  sx={{ minWidth: 650 }}
                                  size="small"
                                  aria-label="a dense table"
                                >
                                  <TableHead style={{ background: "#f1f1f1" }}>
                                    <TableRow>
                                      <TableCell>Eligibility Benefit</TableCell>
                                      <TableCell>Coverage</TableCell>
                                      <TableCell>Insurance Type</TableCell>
                                      <TableCell>Coverage Plan</TableCell>
                                      <TableCell>Coverage Amount</TableCell>
                                      <TableCell>Coverage %</TableCell>
                                      <TableCell>Qty.Qualifie</TableCell>
                                      <TableCell>Quantity</TableCell>
                                      <TableCell>Auth Required</TableCell>
                                      <TableCell>Additional Info</TableCell>
                                    </TableRow>
                                  </TableHead>
                                  <TableBody>
                                    {i?.benefits.map((row) => (
                                      <TableRow
                                        key={row.title}
                                        sx={{
                                          "&:last-child td, &:last-child th": {
                                            border: 0,
                                          },
                                        }}
                                      >
                                        <TableCell component="th" scope="row">
                                          {row.title}
                                        </TableCell>
                                        <TableCell component="th" scope="row">
                                          {row.coverageTypeCode}
                                        </TableCell>
                                        <TableCell component="th" scope="row">
                                          {row.insuranceType}
                                        </TableCell>
                                        <TableCell component="th" scope="row">
                                          {row.coveragePlan}
                                        </TableCell>
                                        <TableCell component="th" scope="row">
                                          {row.coverageAmt}
                                        </TableCell>
                                        <TableCell component="th" scope="row">
                                          {row.coveragePercentage}
                                        </TableCell>
                                        <TableCell component="th" scope="row">
                                          {row.qtyQualifier}
                                        </TableCell>
                                        <TableCell component="th" scope="row">
                                          {row.quantity}
                                        </TableCell>
                                        <TableCell component="th" scope="row">
                                          {row.authRequired}
                                        </TableCell>
                                        <TableCell component="th" scope="row">
                                          <Chapters
                                            value={row.additionalInfo}
                                          ></Chapters>
                                        </TableCell>
                                      </TableRow>
                                    ))}
                                  </TableBody>
                                </Table>
                              </TableContainer>
                            </AccordionDetails>
                          </Accordion>
                        );
                      })}
                    </AccordionDetails>
                  </Accordion>

                  {data?.data?.insurances?.map((i, index) => {
                    return (
                      <div key={index}>
                        {i?.coverageBenefits?.length > 0 && (
                          <Accordion
                          // expanded={expanded === `${i.coverageCode}`}
                          // onChange={handleChange(`${i.coverage}`)}
                          >
                            <AccordionSummary
                              expandIcon={<ExpandMoreIcon />}
                              aria-controls="PnlBenfitDetailsOther"
                              id="PnlBenfitDetailsOther-header"
                              style={{ background: "#FFFFE0" }}
                            >
                              <Typography
                                component="div"
                                gutterBottom
                                sx={{
                                  width: "73%",
                                  flexShrink: 0,
                                  fontWeight: "bold",
                                }}
                                variant="body2"
                              >
                                {i.priority} Insurance Payer ({i?.payerName})
                              </Typography>
                              {/* <Typography variant="body2" sx={{ fontWeight: "bold" }}>
{i.coverageCode}
</Typography> */}
                            </AccordionSummary>
                            <AccordionDetails>
                              {i?.coverageBenefits?.map((kl, index) => {
                                return (
                                  <Accordion
                                    key={index}
                                    // expanded={expanded === `${i.coverageCode}`}
                                    // onChange={handleChange(`${i.coverage}`)}
                                  >
                                    <AccordionSummary
                                      expandIcon={<ExpandMoreIcon />}
                                      aria-controls="panel1bh-content"
                                      id="panel1bh-header"
                                      style={{ background: "#f1f1f1" }}
                                    >
                                      <Typography
                                        component="div"
                                        gutterBottom
                                        sx={{
                                          width: "73%",
                                          flexShrink: 0,
                                          fontWeight: "bold",
                                        }}
                                        variant="body2"
                                      >
                                        {BindServiceTypesCodes(kl.coverage)} :{" "}
                                        {kl.coverageCode}
                                      </Typography>
                                      {/* <Typography variant="body2" sx={{ fontWeight: "bold" }}>
                    {i.coverageCode}
                  </Typography> */}
                                    </AccordionSummary>
                                    <AccordionDetails>
                                      <TableContainer>
                                        {/* <TableContainer component={Paper}> */}
                                        <Table
                                          sx={{ minWidth: 650 }}
                                          size="small"
                                          aria-label="a dense table"
                                        >
                                          <TableHead
                                            style={{ background: "#f1f1f1" }}
                                          >
                                            <TableRow>
                                              <TableCell>
                                                Eligibility Benefit
                                              </TableCell>
                                              <TableCell>Coverage</TableCell>
                                              <TableCell>
                                                Insurance Type
                                              </TableCell>
                                              <TableCell>
                                                Coverage Plan
                                              </TableCell>
                                              <TableCell>
                                                Coverage Amount
                                              </TableCell>
                                              <TableCell>Coverage %</TableCell>
                                              <TableCell>
                                                Qty.Qualifie
                                              </TableCell>
                                              <TableCell>Quantity</TableCell>
                                              <TableCell>
                                                Auth Required
                                              </TableCell>
                                              <TableCell>
                                                Additional Info
                                              </TableCell>
                                            </TableRow>
                                          </TableHead>
                                          <TableBody>
                                            {kl?.benefits.map((row) => (
                                              <TableRow
                                                key={row.title}
                                                sx={{
                                                  "&:last-child td, &:last-child th":
                                                    { border: 0 },
                                                }}
                                              >
                                                <TableCell
                                                  component="th"
                                                  scope="row"
                                                >
                                                  {row.title}
                                                </TableCell>
                                                <TableCell
                                                  component="th"
                                                  scope="row"
                                                >
                                                  {row.coverageTypeCode}
                                                </TableCell>
                                                <TableCell
                                                  component="th"
                                                  scope="row"
                                                >
                                                  {row.insuranceType}
                                                </TableCell>
                                                <TableCell
                                                  component="th"
                                                  scope="row"
                                                >
                                                  {row.coveragePlan}
                                                </TableCell>
                                                <TableCell
                                                  component="th"
                                                  scope="row"
                                                >
                                                  {row.coverageAmt}
                                                </TableCell>
                                                <TableCell
                                                  component="th"
                                                  scope="row"
                                                >
                                                  {row.coveragePercentage}
                                                </TableCell>
                                                <TableCell
                                                  component="th"
                                                  scope="row"
                                                >
                                                  {row.qtyQualifier}
                                                </TableCell>
                                                <TableCell
                                                  component="th"
                                                  scope="row"
                                                >
                                                  {row.quantity}
                                                </TableCell>
                                                <TableCell
                                                  component="th"
                                                  scope="row"
                                                >
                                                  {row.authRequired}
                                                </TableCell>
                                                <TableCell
                                                  component="th"
                                                  scope="row"
                                                >
                                                  <Chapters
                                                    value={row.additionalInfo}
                                                  ></Chapters>
                                                </TableCell>
                                              </TableRow>
                                            ))}
                                          </TableBody>
                                        </Table>
                                      </TableContainer>
                                    </AccordionDetails>
                                  </Accordion>
                                );
                              })}
                            </AccordionDetails>
                          </Accordion>
                        )}{" "}
                      </div>
                    );
                  })}
                </div>
              </div>
            </CardContent>
          </Card>
        )}
      </Container>
      <Backdrop
        sx={{ color: "#1a73e8", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={loading || isLoading || isLoading1}
      >
        <CircularProgress color="inherit" />
      </Backdrop>

      <Notification notify={notify} setNotify={setNotify} />
      <Dialog
        open={open}
        onClose={handleClose}
        PaperComponent={PaperComponent}
        // hideBackdrop={true}
        aria-labelledby="draggable-dialog-title"
        // disableScrollLock={false}
        // hideBackdrop
        // disableEnforceFocus
        // style={{ position: 'initial' }}
        // open={dstate}
        // PaperComponent={PaperComponent}
        // aria-labelledby="draggable-dialog-title"
        // disableBackdropClick
      >
        <DialogTitle id="draggable-dialog-title">
          {(frontImage == true || backImage == true) && (
            <span>Insurance Card</span>
          )}{" "}
          {(frontDLImage == true || backDLImage == true) && (
            <span>Driving License Card</span>
          )}
          <div style={{ float: "right", cursor: "pointer" }}>
            <CloseIcon
              onClick={() => {
                handleClose();
              }}
              color="primary"
            />
          </div>
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            {data?.data?.insuranceCardFiles?.map((j, index) => {
              return (
                <Grid className="mb-2" xs={12} sm={12} key={index}>
                  {index == 0 && (
                    <img
                      src={j.fileName}
                      id={index}
                      className="gridImageDetails imgDivWidth"
                      style={{ display: frontImage == true ? "block" : "none" }}
                    />
                  )}
                  {index == 1 && (
                    <img
                      src={j.fileName}
                      id={index}
                      className="gridImageDetails imgDivWidth"
                      style={{ display: backImage == true ? "block" : "none" }}
                    />
                  )}
                </Grid>
              );
            })}
            {data?.data?.idCardFiles?.map((j, index) => {
              return (
                <Grid className="mb-2" xs={12} sm={12} key={index}>
                  {index == 0 && (
                    <img
                      src={j.fileName}
                      id={index + "dl"}
                      className="gridImageDetails imgDivWidth"
                      style={{
                        display: frontDLImage == true ? "block" : "none",
                      }}
                    />
                  )}
                  {index == 1 && (
                    <img
                      src={j.fileName}
                      id={index + "dl"}
                      className="gridImageDetails imgDivWidth"
                      style={{
                        display: backDLImage == true ? "block" : "none",
                      }}
                    />
                  )}
                </Grid>
              );
            })}
          </DialogContentText>
        </DialogContent>
      </Dialog>
      {isViewDocument && (
        <ViewDocument
          open={open1}
          handleClose={handleClose1}
          imageId={imageId}
          imageName={imageName}
          setLoading={setLoading}
          pdfURL={pdfURL}
          isPDF={isPDF}
        />
      )}
      {/* <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={modelstyle}>
        <div style={{float: 'right'}}><CloseIcon onClick={() => {handleClose() } } color="primary" /></div>
        <div >
                    {data?.data?.insuranceCardFiles?.mapp((j,index) => {
                      return (<Grid className="mb-2" xs={12} sm={12}>
                           {index == 0 && (
                              <img src={j.fileName} id={index} className="gridImageDetails" style={{display:frontImage==true?"block" :"none"}} />
                              )}
                               {index == 1 && (
                              <img src={j.fileName} id={index} className="gridImageDetails" style={{display:backImage==true?"block" :"none"}} />
                              )}
                      </Grid>);
                    }
                    )}
                    {data?.data?.insuranceCardFiles?.length == 1 && (
                      <Grid style={{ width: "50%", float: "left" }} xs={12} sm={6}>
                        <div className="rounded-sm h-52 mx-6 md:mx-10">
                          <div className="h-full flex justify-center items-center image-container">
                            <div className="truncate object-cover imagee">
                            </div>
                          </div>
                        </div>

                      </Grid>
                    )}
                  </div>
        </Box>
      </Modal> */}
    </div>
  );
};

export default PatientDetails;
