import { type } from "@testing-library/user-event/dist/type";
import Constants from "../constants";

export const setLocationLookup = (data) => {
  return {
    type: Constants.STORE_LOCATION_DATA,
    payload: data,
  };
};

export const setIsFromLocation = (data) => {
  return {
    type: Constants.STORE_ISFROM_LOCATION,
    payload: data,
  };
};
