import Constants from '../constants';

export const orderStatusIdReducer = (state = null, action) => {
    switch (action.type) {
        case Constants.ORDER_STATUS_ID:
            return action.payload;
        default:
            return state;
    }
};
export const orderStatusDescriptionReducer = (state = null, action) => {
    switch (action.type) {
        case Constants.ORDER_STATUS_DESCRIPTION:
            return action.payload;
        default:
            return state;
    }
};

export const orderSubStatusIdReducer = (state = null, action) => {
    switch (action.type) {
        case Constants.ORDER_SUB_STATUS_ID:
            return action.payload;
        default:
            return state;
    }
};
export const orderSubStatusDescriptionReducer = (state = null, action) => {
    switch (action.type) {
        case Constants.ORDER_SUB_STATUS_DESCRIPTION:
            return action.payload;
        default:
            return state;
    }
};
export const fromDateReducer = (state = null, action) => {
    switch (action.type) {
        case Constants.FROM_DATE:
            return action.payload;
        default:
            return state;
    }
};

export const toDateReducer = (state = null, action) => {
    switch (action.type) {
        case Constants.TO_DATE:
            return action.payload;
        default:
            return state;
    }
};