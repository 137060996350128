// Function to format the date and time
export default function formatDateStamp() {
  const date = new Date();
  const options = {
    year: "numeric",
    month: "2-digit",
    day: "2-digit",
    hour: "2-digit",
    minute: "2-digit",
    second: "2-digit",
    hour12: false, // 24-hour format
  };

  // Format the date to "YYYY-MM-DD_HH-MM-SS"
  const dateString = date
    .toLocaleString("en-US", options)
    .replace(/\/|,|\s/g, "")
    .replace(/:/g, "");
  return dateString;
}
