import { Button } from "@mui/material";
import React, { useEffect } from "react";
import { persistor } from "../redux/store";
import { logTelemetry } from "../logTelemetry";

const ErrorPage = () => {
  useEffect(() => {
    // sessionStorage.setItem('token', null);
    // persistor.purge();
    // setTimeout(() => {
    //   window.location.reload();
    // }, 5000);

    logTelemetry("Failed", "Application Fatal Error", "", "");
  }, []);

  return (
    <div>
      <h1>Data loading failed. Please refresh the screen again</h1>
    </div>
  );
};

export default ErrorPage;
